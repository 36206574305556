<div class="main-content">
	<div class="container-fluid p-0">
		<div class="card p-5">
			<p class="title">Account Statement</p>
			<div>
				<div class="row">
					<div class="col-lg-9 col-12 p-0">
						<div class="logo-section pl-4">
							<div *ngIf="iso_code_2.toLowerCase() !== 'gh'"
								class="d-flex justify-content-between">
								<div class="pt-4">
									<img src="{{merchantCountryDetails?.logo}}" *ngIf="merchantCountryDetails?.logo"/>
									<img src="assets/images/new-xcel-logo.svg" 
										*ngIf="!merchantCountryDetails?.logo && (merchant?.currency?.iso_code_2.toLowerCase() !== 'gh' 
											|| merchant?.currency?.iso_code_2.toLowerCase() !== 'cm' || 
												merchant?.currency?.iso_code_2.toLowerCase() !== 'cd')"/>
									<img src="/assets/images/new-etranzact-logo.svg" 
										*ngIf="merchant?.currency?.iso_code_2.toLowerCase() === 'gh' && !merchantCountryDetails?.logo" />
									<img src="/assets/images/congo.jpg" 
										*ngIf="merchant?.currency?.iso_code_2.toLowerCase() === 'cd' && !merchantCountryDetails?.logo"/>
									<img src="/assets/images/CAMPOST-LOGO.jpg" 
										*ngIf="merchant?.currency?.iso_code_2.toLowerCase() === 'cm' && !merchantCountryDetails?.logo"/>
									<br> <br>
									<strong>{{statementDetails?.name}}</strong>
									<!-- <p>{{statementDetails?.address || 'N/A'}}</p> -->
									<p>
										{{selecteduser?.address?.line1}} {{selecteduser?.address?.city}}, {{selecteduser?.address?.post_code}},
										<br>
										{{selecteduser?.address?.state}}, {{selecteduser?.address?.country}}
									</p>
								</div>

								<div class="details-main  " >
									<span class="">
										<p class="d-flex justify-content-between align-items-center">
											<span>Sort Code:</span> 
											&nbsp; &nbsp; &nbsp; &nbsp;
											<span>{{statementDetails?.sort_code || 'N/A'}}</span>
										</p>
				
										<p class="d-flex justify-content-between align-items-center">
											<span>Account Number:</span> 
											&nbsp; &nbsp; &nbsp; &nbsp;
											<span>{{statementDetails?.account_number || 'N/A'}}</span>
										</p>					
									</span>
	
									<div class="mt-5" style="color: black !important;">
										<p class="border-0">GBP Statement Date:<br> <strong>{{today}}</strong></p>
									</div>
								</div>
							</div>

							<div *ngIf="iso_code_2.toLowerCase() === 'gh'"
								class="d-flex justify-content-between">
								<div class="pt-4">
									<img src="/assets/images/new-etranzact-logo.svg" *ngIf="!merchantCountryDetails?.logo"/>
									<img src="{{merchantCountryDetails?.logo}}" *ngIf="merchantCountryDetails?.logo"/>
									<br> <br>
									<strong>{{statementDetails?.name}}</strong>
									<p>
										{{selecteduser?.address?.line1}} {{selecteduser?.address?.city}}, {{selecteduser?.address?.post_code}},
										<br>
										{{selecteduser?.address?.state}}, {{selecteduser?.address?.country}}
									</p>
								</div>

								<div class="details-main p-3">
									<p class="border-0 mb-0 px-3 gh-bg">
										<span>
											<span class="top-label">GBP Statement Date:</span><br> 
											<strong class="text-white">{{today}}</strong>
										</span>
									</p>
								</div>
							</div>

						</div>
						<div class="border table-responsive">
							<table #table class="table table-hover">
								<thead class="">
									<!-- <th>S/N</th> -->
									<th scope="col" class="border" >Date</th>
									<th scope="col" class="border" >Description</th>
									<th scope="col" class="border">
										<span class="d-flex justify-content-end">
											({{currency_code}}) In
										</span>
									</th>
									<th scope="col" class="border">
										<span class="d-flex justify-content-end">
											({{currency_code}}) Out
										</span>
									</th>
									<th scope="col" class="border">
										<span class="d-flex justify-content-end">
											({{currency_code}}) Balance
										</span>
									</th>
								</thead>
								<tbody *ngIf="transactionHistory.length > 0;">
									<tr style="cursor: pointer" *ngFor="let item of transactionHistory
									| paginate
									: {
										itemsPerPage: pageEvent?.pageSize || pageSize,
										currentPage: pageEvent?.pageIndex + 1,
										totalItems: transactionHistory?.length
									}
									 let i = index;">
										<!-- <td>{{i + 1}}</td> -->
										<td class="border" >{{item?.date | date: 'mediumDate'}}</td>
										<td class="border" >{{item?.description || 'N/A'}}</td>
										<td class="border">
											<span class="d-flex justify-content-end">
												<span *ngIf="item?.trans_type === 'credit'">{{item?.des_amount}}</span>
												<span *ngIf="item?.trans_type !== 'credit'">0.00</span>
											</span>
										</td>
										<td class="border">
											<span class="d-flex justify-content-end">
												<span *ngIf="item?.trans_type === 'debit'">{{item?.src_amount}}</span>
												<span *ngIf="item?.trans_type !== 'debit'">0.00</span>
											</span>
										</td>
										<td class="border">
											<span class="d-flex justify-content-end">
												{{item?.close_balance}}
											</span>
										</td>
									</tr>
								</tbody>
							</table>

							<div class="card w-100" *ngIf="transactionHistory.length === 0">
								<div class="d-flex justify-content-center">
									<div>
										<div class="d-flex justify-content-center">
											<img src="/assets/images/empty-data.svg" width="200" height="200" />
										</div>
										<h3 class="text-center">No Result Found!</h3>
										<p class="mb-5 text-center">
											No transactions for selected account and dates.
										</p>
									</div>	
								</div>
							</div>

							<div>
								<mat-paginator #paginator
									(page)="pageEvent = $event"
									[length]="transactionHistory?.length"
									[pageSizeOptions]="[5, 10, 25, 50, 100]"
									[pageSize]="pageSize"
									   aria-label="Select page">
								</mat-paginator>
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-12 p-0 ">
						<div class="cover-section"></div>
						<div class="summary-section">
							<span class="details" *ngIf="iso_code_2.toLowerCase() !== 'gh'">
								<p class="pt-0 d-flex justify-content-between align-items-center">
									<span>IBAN:</span> 
									<span>{{statementDetails?.iban || 'N/A'}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>BIC SWIFT:</span> 
									<span>{{statementDetails?.bic_swift || 'N/A'}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Start Balance:</span> 
									<span>({{currency_code}}) {{statementDetails?.start_balance}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>End Balance:</span> 
									<span>({{currency_code}}) {{statementDetails?.end_balance}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Average Credit:</span> 
									<span>({{currency_code}}) {{statementDetails?.credit_avg}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Average Debit:</span> 
									<span>({{currency_code}}) {{statementDetails?.debit_avg}}</span>
								</p>
								
							</span>

							<span class="details" *ngIf="iso_code_2.toLowerCase() === 'gh'">
								<p class="pt-0 d-flex justify-content-between align-items-center">
									<span>Card No:</span> 
									<span>{{'N/A'}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Mobile No.:</span> 
									<span>{{statementDetails?.wallet_id}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Start Balance:</span> 
									<span>({{currency_code}}) {{statementDetails?.start_balance}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>End Balance:</span> 
									<span>({{currency_code}}) {{statementDetails?.end_balance}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Average Credit:</span> 
									<span>({{currency_code}}) {{statementDetails?.credit_avg}}</span>
								</p>
		
								<p class="d-flex justify-content-between align-items-center">
									<span>Average Debit:</span> 
									<span>({{currency_code}}) {{statementDetails?.debit_avg}}</span>
								</p>
								
							</span>
						</div>

						<div class="pl-3">
							<div class="help-section"> 
								<p class="title">Need Help?</p>
	
								<p class="desc">
									We are happy to help in any way that we can. Contact us by any of the following channels:
								</p>
	
								<p class="desc">
									<svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.4" d="M8.25 5.97758C8.25 7.02383 7.41 7.87133 6.36375 7.87508H6.36H2.64375C1.60125 7.87508 0.75 7.03133 0.75 5.98508V5.98133C0.75 5.98133 0.75225 4.32158 0.75525 3.48683C0.755625 3.33008 0.935625 3.24233 1.05825 3.33983C1.94925 4.0467 3.54262 5.33558 3.5625 5.35245C3.82875 5.56583 4.16625 5.6862 4.51125 5.6862C4.85625 5.6862 5.19375 5.56583 5.46 5.34833C5.47988 5.3352 7.03763 4.08495 7.94213 3.36645C8.06513 3.26858 8.24587 3.35633 8.24625 3.5127C8.25 4.34108 8.25 5.97758 8.25 5.97758Z" fill="#130F26"/>
										<path d="M8.05358 2.1276C7.72883 1.5156 7.08983 1.12485 6.38633 1.12485H2.64383C1.94033 1.12485 1.30133 1.5156 0.976578 2.1276C0.903828 2.26447 0.938328 2.4351 1.05945 2.53185L3.84383 4.75897C4.03883 4.91647 4.27508 4.99485 4.51133 4.99485C4.51283 4.99485 4.51395 4.99485 4.51508 4.99485C4.5162 4.99485 4.5177 4.99485 4.51883 4.99485C4.75508 4.99485 4.99133 4.91647 5.18633 4.75897L7.9707 2.53185C8.09183 2.4351 8.12633 2.26447 8.05358 2.1276Z" fill="#130F26"/>
									</svg>
									&nbsp;
									<a href="mailto:{{merchantCountryDetails?.support_email}}" class="text-dark" target="_blank">
										{{merchantCountryDetails?.support_email || 'N/A'}}
									</a>
								</p>
	
								<p class="desc">
									<svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.4" d="M8.25 4.49996C8.25 6.57109 6.57113 8.24996 4.5 8.24996C2.42888 8.24996 0.75 6.57109 0.75 4.49996C0.75 2.42921 2.42888 0.749962 4.5 0.749962C6.57113 0.749962 8.25 2.42921 8.25 4.49996Z" fill="#130F26"/>
										<path d="M5.94752 3.26433L5.34002 5.18433C5.31752 5.26308 5.25377 5.32683 5.17502 5.34971L3.26252 5.94933C3.13502 5.99096 3.01127 5.86683 3.05252 5.73933L3.65252 3.81558C3.67502 3.73683 3.73877 3.67683 3.81752 3.65058L5.73752 3.05058C5.86877 3.00933 5.98877 3.13308 5.94752 3.26433Z" fill="#130F26"/>
									</svg>	
									&nbsp;							
									<a href="{{merchantCountryDetails?.web_address}}" target="_blank" class="text-dark">
										{{merchantCountryDetails?.web_address || 'N/A'}}
									</a>								
								</p>
	
								<p class="desc">
									<svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M3.19936 1.10129C4.01868 0.625228 5.02576 0.633549 5.83739 1.12309C6.64105 1.62259 7.12949 2.51407 7.12494 3.47304C7.10623 4.42573 6.58249 5.32125 5.9278 6.01353C5.54994 6.4149 5.12723 6.76981 4.66832 7.07101C4.62105 7.09834 4.56928 7.11664 4.51556 7.125C4.46385 7.1228 4.41349 7.10752 4.36903 7.08054C3.66841 6.62796 3.05375 6.05026 2.55462 5.37524C2.13697 4.81176 1.89969 4.131 1.875 3.42541C1.87446 2.46459 2.38004 1.57735 3.19936 1.10129ZM3.67281 3.82304C3.81063 4.16281 4.13594 4.38444 4.49685 4.38444C4.73329 4.38614 4.96057 4.29144 5.12805 4.12143C5.29554 3.95142 5.38931 3.72024 5.38847 3.47939C5.38973 3.11176 5.1733 2.77962 4.84022 2.63804C4.50715 2.49646 4.12314 2.57338 3.86749 2.83288C3.61185 3.09238 3.53499 3.48327 3.67281 3.82304Z" fill="#130F26"/>
										<ellipse opacity="0.4" cx="4.5" cy="7.875" rx="1.875" ry="0.375" fill="#130F26"/>
									</svg>		
									&nbsp;						
									<span>{{merchantCountryDetails?.company_address}}</span>								
								</p>
							</div>
							<!-- (click)="exporting = true; isDownload = true; export()" -->
							<button (click)="sendAccountStatement()"
								type="submit" [disabled]="exporting" class="exp-btn mt-3">
								<div *ngIf="exporting">
									<div style="width: 10px; height: 10px" class="spinner-grow text-light" role="status">
										<span class="sr-only"></span>
									</div>
									<div style="width: 10px; height: 10px" class="spinner-grow text-light" role="status">
										<span class="sr-only"></span>
									</div>
									<div style="width: 10px; height: 10px" class="spinner-grow text-light" role="status">
										<span class="sr-only"></span>
									</div>
								</div>
								<span *ngIf="!exporting">Send Account Statement</span>
							</button>
						</div>
					</div>
				</div>
				
				<div class="row footer-section mt-3 p-3 mb-0">
					<p>
						{{merchantCountryDetails?.footer_information || 'N/A'}}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
