import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as authActions from './auth.actions';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';





@Injectable({
    providedIn : 'root'
})
export class AuthEffects {

    resetPassword$ = createEffect(() =>
      this.actions$.pipe(
        ofType(authActions.resetPassword),
        exhaustMap((action) =>
          this.authService.resetPassword(action.newPassword, action.resetSid).pipe(
            map((response: any) =>
              authActions.resetPasswordSuccess()
            ),
            catchError((error: HttpErrorResponse) =>
              of(authActions.resetPasswordFailed())
            )
          )
        )
      )
    );

    checkResetSid$ = createEffect(() =>
      this.actions$.pipe(
        ofType(authActions.checkResetSid),
        exhaustMap((action) =>
          this.authService.getResetSidStatus(action.sid).pipe(
            map((response: any) =>
              authActions.resetSidValid()
            ),
            catchError((error: HttpErrorResponse) =>
              of(authActions.resetSidInValid())
            )
          )
        )
      )
    );

    forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.triggerForgotPassword),
      exhaustMap((action) =>
        this.authService.forgotPassword(action.email).pipe(
          map((response: any) =>
            authActions.triggerForgotPasswordSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(authActions.triggerForgotPasswordFailed())
          )
        )
      )
    )
  );

    fetchAuthUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.fetchAuthUser),
      exhaustMap((action) =>
        this.authService.fetchAuthUser().pipe(
          map((response: any) =>
          authActions.setAuthUser({
              authUser: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(authActions.fetchAuthUserFailed())
          )
        )
      )
    )
  );

  fetchAdminCountries$ = createEffect(() =>
  this.actions$.pipe(
    ofType(authActions.fetchAdminCountries),
    exhaustMap((action) =>
      this.authService
        .getCountries()
        .pipe(
          map((response: any) =>
            authActions.fetchAdminCountriesSuccess({ adminCountries: response.data })
          )
        )
    )
  )
);




  logout$ = createEffect(() =>
  this.actions$.pipe(
    ofType(authActions.logout),
    tap((action) =>
      this.authService.logOut()
    )
  )
);

    constructor(
        private actions$: Actions,
        private authService: AuthService,
      ) {}


}
