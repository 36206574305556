import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UpdatePasswordDto } from '../_dtos/update-password.dto';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable } from 'rxjs';
import { AuthHeaderService } from 'src/app/shared/services/authheaderservice';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private authHeaderService: AuthHeaderService
  ) {}

  fetchAdminUsers() {
    return this.http.get(`${environment.ADMIN_AUTH_URL}/user/all`);
  }

  // fetchPlatformsAccessLocks = () => this.http.get(`${environment.API_URL}/admin-management/platform-access-locks`);
  // fetchPlatforms = () => this.http.get(`${environment.XAS_URL}/admin-management/platforms`);

  fetchPlatformsAccessLocks = (): Observable<any> => {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

    let url = `${environment.XAS_URL}/admin-management/platform-access-locks`;
    return this.http.get(url, { headers });
  };



  createPlatform(payload) {
    const xAuthHeader = this.authHeaderService.generateAuthHeader(payload);
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
    return this.http.post(`${environment.XAS_URL}/admin-management/update-platform-access-lock`, payload, { headers });
  }

  fetchPlatform = (): Observable<any> => {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

    let url = `${environment.XAS_URL}/admin-management/platforms`;
    return this.http.get(url, { headers });
  };

  changePassword(updatePasswordDto: UpdatePasswordDto) {
    return this.http.put(`${environment.ADMIN_AUTH_URL}/user/update-password`, updatePasswordDto);
  }

  deleteAdminUser(userId) {
    return this.http.delete(`${environment.ADMIN_AUTH_URL}/user/account/${userId}`);
  }

  addCountry = (body) => this.http.post(`${environment.ADMIN_AUTH_URL}/countries`, body);

  updateCountry = (body, countryId) => this.http.put(`${environment.ADMIN_AUTH_URL}/countries/${countryId}`, body);

  deleteCountry = (countryId) => this.http.delete(`${environment.ADMIN_AUTH_URL}/countries/${countryId}`);

  //Merchant category config

  getMerchantCategories = () => {
    let params = new HttpParams();
    params = params.append('country_code', this.sharedService.getSelectedCountryFromSession());
    return this.http.get(`${environment.API_URL}/transactions-service/ent`, { params });
  };

  fetchTransactionLogsSummary = (type: string, start_date?: string, end_date?: string): Observable<any> => {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

    let url = `${
      environment.XAS_URL
    }/admin/transactions/log/summary/${this.sharedService.getSelectedCountryFromSession()}`;
    if (start_date && end_date) {
      url = url + `?start_date=${start_date}&end_date=${end_date}`;
    }

    if (type) {
      if (start_date && end_date) {
        url = url + `&status=${type}`;
      } else {
        url = url + `?status=${type}`;
      }
    }

    return this.http.get(url, { headers });
  };

  fetchWalletSummary = (
    page: number,
    limit: number,
    status: string,
    start_date?: string,
    end_date?: string,
    search?: string
  ): Observable<any> => {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

    let url = `${
      environment.XAS_URL
    }/admin/transactions/logs/${this.sharedService.getSelectedCountryFromSession()}?page=${page}&limit=${limit}`;
    if (status) {
      url = url + `&status=${status}`;
    }

    if (start_date && end_date) {
      url = url + `&start_date=${start_date}&end_date=${end_date}`;
    }

    if (search) {
      url = url + `&search=${search}`;
    }

    return this.http.get(url, { headers });
  };

  fetchMomoWalletSummary = (
    page: number,
    limit: number,
    status: string,
    start_date?: string,
    end_date?: string,
    search?: string
  ): Observable<any> => {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

    let url = `${
      environment.XAS_URL
    }/admin/transactions/momo/logs/${this.sharedService.getSelectedCountryFromSession()}?page=${page}&limit=${limit}`;
    if (status) {
      url = url + `&status=${status}`;
    }

    if (start_date && end_date) {
      url = url + `&start_date=${start_date}&end_date=${end_date}`;
    }

    if (search) {
      url = url + `&search=${search}`;
    }

    return this.http.get(url, { headers });
  };

  searchTransactionLogs = (reference: string): Observable<any> => {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
    const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

    let url = `${
      environment.XAS_URL
    }/admin/transactions/log/${this.sharedService.getSelectedCountryFromSession()}/${reference}`;

    return this.http.get(url, { headers });
  };

  createMerchantCategory = (payload) => {
    payload.country_code = this.sharedService.getSelectedCountryFromSession();
    return this.http.post(`${environment.API_URL}/transactions-service/ent`, payload);
  };

  updateMerchantCategory = (payload, id) => {
    payload.country_code = this.sharedService.getSelectedCountryFromSession();
    return this.http.put(`${environment.API_URL}/transactions-service/ent/${id}`, payload);
  };

  fetchbanks = () =>
    this.http.get(`${environment.API_URL}/banks/${this.sharedService.getSelectedCountryCodeFromSession()}`);

  addbank = (body) => this.http.post(`${environment.API_URL}/banks`, body);

  updatebank = (body, id, bankCode) =>
    this.http.put(
      `${environment.API_URL}/banks/${id}/${bankCode}/${this.sharedService.getSelectedCountryCodeFromSession()}`,
      body
    );

  deletebank = (id) => this.http.delete(`${environment.API_URL}/banks/${id}`);
}
