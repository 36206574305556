import { createAction, props } from '@ngrx/store';
import { AdminActivityLog } from '../_interfaces/admin-activity-log-interface';

export const fetchAdminActivityLogs = createAction(
    '[Activity Logs Page] READ: fetch admin activity logs'
);

export const fetchAdminActivityLogSuccess = createAction(
    '[Activity Logs Page] STATUS(read): fetch admin activity log success'
);

export const fetchAdminActivityLogFailed = createAction(
    '[Activity Logs Page] STATUS(read): fetch admin activity log failed'
);

export const setAdminActivityLog = createAction(
    '[Activity Logs Page] set admin activity log',
    props<{adminActivityLogs: AdminActivityLog[]}>()
);
