import { ActionReducer, ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { activityLogReducer, ActivityLogState } from '../modules/activity-log/_state/activity-log.reducer';
import { amlReducer, AmlState } from '../modules/aml/_state/aml.reducers';
import { logout } from '../modules/auth/_state/auth.actions';
import { authReducer, AuthState } from '../modules/auth/_state/auth.reducers';
import { customerReducer, CustomerState } from '../modules/customers/_state/customers.reducers';
import { exRatesReducer, ExRatesState } from '../modules/exchange-rates/_state/rates.reducers';
import { feesReducer, FeesState } from '../modules/fees/_state/fees.reducer';
import { issuerReducer, IssuerState } from '../modules/issuers/_state/issuers.reducers';
import { kycReducer, KycState } from '../modules/kyc/_state/kyc.reducer';
import { organisationReducer, OrganisationState } from '../modules/organisation/_state/organisation.reducer';
import { settingsReducer, SettingsState } from '../modules/settings/_state/settings.reducer';
import { IssuerUsersState, usersReducer } from '../modules/users/_state/users.reducer';
import { fwpReducer, fwpState } from '../modules/fwp/_state/fwp.reducer';
import {
  adminManageAccountReducer,
  AdminManageAccountState
} from '../modules/admin-manage-accounts/_state/admin-manage-account.reducer';
import {
  TransactionMonitoringReducer,
  TransactionMonitoringState
} from '../modules/transaction-monitoring/_state/trans-monitoring.reducer';

export interface AppState {
  auth: AuthState;
  adminManageAccount: AdminManageAccountState;
  customers: CustomerState;
  kyc: KycState;
  organisation: OrganisationState;
  fees: FeesState;
  exRates: ExRatesState;
  issuers: IssuerState;
  activityLog: ActivityLogState;
  settings: SettingsState;
  users: IssuerUsersState;
  aml: AmlState;
  fwp: fwpState;
  transMonitoringState: TransactionMonitoringState;
}

export function flush(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === logout.type) {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  adminManageAccount: adminManageAccountReducer,
  customers: customerReducer,
  kyc: kycReducer,
  organisation: organisationReducer,
  fees: feesReducer,
  exRates: exRatesReducer,
  issuers: issuerReducer,
  activityLog: activityLogReducer,
  settings: settingsReducer,
  users: usersReducer,
  aml: amlReducer,
  fwp: fwpReducer,
  transMonitoringState: TransactionMonitoringReducer
};

// AUTH SELECTORS
export const selectAuth = (state: AppState) => state.auth;
export const selectAdmin = (state: AppState) => state.adminManageAccount;
export const selectAuthState = createSelector(selectAuth, (state: AuthState) => state);
export const selectSignUpDetails = createSelector(selectAuth, (state: AuthState) => state.signup);
export const selectAuthenticatedUser = createSelector(selectAuth, (state: AuthState) => state.authUser);

//Admin Bill Config
export const selectAdminBillerCountries = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminBillerCountries
);
export const selectAdminBillerServices = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminBillerServices
);
export const selectAdminBillerChannels = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminBillerChannels
);
export const selectAdminBillerChannelServices = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminBillerChannelServices
);

// (Auth selectors) selectors for country state
export const selectAdminCountries = createSelector(selectAuth, (state: AuthState) => state.adminCountries);
export const selectAdminCountriesLoading = createSelector(
  selectAuth,
  (state: AuthState) => state.adminCountriesLoading
);
export const selectAdminCountriesError = createSelector(selectAuth, (state: AuthState) => state.adminCountriesError);
export const selectAdminSelectedCountry = createSelector(selectAuth, (state: AuthState) => state.adminSelectedCountry);
export const selectAdminBanks = createSelector(selectAuth, (state: AuthState) => state.adminBanks);
export const selectAdminFeesAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminFeeAccounts
);
export const selectAdminGlAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminGlAccounts
);
export const selectAdminHoldAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminHoldAccounts
);
export const selectAdminSettlementAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminSettlementAccounts
);
export const selectAdminNonSettlementAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminNonSettlementAccounts
);
export const selectAdminSettlementAccounts2 = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminSettlementAccounts2
);
export const selectAdminLocalPaymentAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminLocalPaymentAccounts
);
export const selectAdminAgentAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminAgentAccounts
);
export const selectAdminSubAgentAccounts = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminSubAgentAccounts
);
// KYC Selectors
export const selectKyc = (state: AppState) => state.kyc;
export const selectAdminKycAll = createSelector(selectKyc, (state: KycState) => state.adminKycAll);
export const selectAdminKycErrors = createSelector(selectKyc, (state: KycState) => state.adminKycErrors);
export const selectKycLoading = createSelector(selectKyc, (state: KycState) => state.loading);
export const selectKycRecords = createSelector(selectKyc, (state: KycState) => state.kycRecords);
export const selectKycErrors = createSelector(selectKyc, (state: KycState) => state.kycErrors);
export const selectKycErrorsNoId = createSelector(selectKyc, (state: KycState) => state.kycErrorsNoId);
export const selectSelectedKyc = createSelector(selectKyc, (state: KycState) => state.selectedKyc);
export const selectKycRecordsPage = createSelector(selectKyc, (state: KycState) => state.kycRecordsPage);
export const selectKycRecordsPageSize = createSelector(selectKyc, (state: KycState) => state.kycRecordsPageSize);
export const selectKycRecordsTotalCount = createSelector(selectKyc, (state: KycState) => state.kycRecordsTotalCount);
export const selectKycErrorsPage = createSelector(selectKyc, (state: KycState) => state.kycErrorsPage);
export const selectKycErrorsPageSize = createSelector(selectKyc, (state: KycState) => state.kycErrorsPageSize);
export const selectKycErrorsTotalCount = createSelector(selectKyc, (state: KycState) => state.kycErrorsTotalCount);
export const selectKycErrorsNoIdPage = createSelector(selectKyc, (state: KycState) => state.kycErrorsNoIdPage);
export const selectKycErrorsNoIdPageSize = createSelector(selectKyc, (state: KycState) => state.kycErrorsNoIdPageSize);
export const selectKycErrorsNoIdTotalCount = createSelector(
  selectKyc,
  (state: KycState) => state.kycErrorsNoIdTotalCount
);
export const selectFailedRegList = createSelector(selectKyc, (state: KycState) => state.failedRegList);
export const selectAdminBlacklist = createSelector(selectKyc, (state: KycState) => state.adminBlacklistInfo);

// FWP Selectors
export const selectFwp = (state: AppState) => state.fwp;
export const selectFwpLoading = createSelector(selectFwp, (state: fwpState) => state.loading);
export const selectFwpOverview = createSelector(selectFwp, (state: fwpState) => state.overviews);
export const selectFwpMerchant = createSelector(selectFwp, (state: fwpState) => state.merchants);

// Organisation Selectors
export const selectOrganisation = (state: AppState) => state.organisation;
export const selectAdminOrganisations = createSelector(
  selectOrganisation,
  (state: OrganisationState) => state.adminOrganisations
);
export const selectSelectedAdminOrganisation = createSelector(
  selectOrganisation,
  (state: OrganisationState) => state.adminSelectedOrgAccount
);
export const selectAdminOrganisationStaffs = createSelector(
  selectOrganisation,
  (state: OrganisationState) => state.adminOrganisationStaffs
);
export const selectAdminOrganisationStaffLoanHistory = createSelector(
  selectOrganisation,
  (state: OrganisationState) => state.adminOrgStaffLoanHistory
);
export const selectAdminOrganisationStaffTrans = createSelector(
  selectOrganisation,
  (state: OrganisationState) => state.adminOrgStaffLoanTrans
);

//Aml Selectors
export const selectAml = (state: AppState) => state.aml;
export const selectRules = createSelector(selectAml, (state: AmlState) => state.rules);
export const selectRulesError = createSelector(selectAml, (state: AmlState) => state.rulesError);
export const selectRulesLoading = createSelector(selectAml, (state: AmlState) => state.rulesLoading);

export const selectRuleSets = createSelector(selectAml, (state: AmlState) => state.ruleSets);
export const selectRuleSetsError = createSelector(selectAml, (state: AmlState) => state.ruleSetsError);
export const selectRuleSetsLoading = createSelector(selectAml, (state: AmlState) => state.ruleSetsLoading);

export const selectFlaggedTransactions = createSelector(selectAml, (state: AmlState) => state.flaggedTransactions);
export const selectFlaggedTransactionsError = createSelector(
  selectAml,
  (state: AmlState) => state.flaggedTransactionsError
);
export const selectFlaggedTransactionsLoading = createSelector(
  selectAml,
  (state: AmlState) => state.flaggedTransactionsLoading
);

export const selectLoggedTransactions = createSelector(selectAml, (state: AmlState) => state.loggedTransactions);
export const selectLoggedTransactionsError = createSelector(
  selectAml,
  (state: AmlState) => state.loggedTransactionsError
);
export const selectLoggedTransactionsLoading = createSelector(
  selectAml,
  (state: AmlState) => state.loggedTransactionsLoading
);

export const selectChannels = createSelector(selectAml, (state: AmlState) => state.channels);
export const selectChannelsError = createSelector(selectAml, (state: AmlState) => state.channelsError);
export const selectChannelsLoading = createSelector(selectAml, (state: AmlState) => state.channelsLoading);

export const selectCoporateRule = createSelector(selectAml, (state: AmlState) => state.coporateRule);
export const selectCoporateRuleError = createSelector(selectAml, (state: AmlState) => state.coporateRuleError);
export const selectCoporateRuleLoading = createSelector(selectAml, (state: AmlState) => state.coporateRuleLoading);

export const selectauditTrail = createSelector(selectAml, (state: AmlState) => state.auditTrail);
export const selectauditTrailError = createSelector(selectAml, (state: AmlState) => state.auditTrailError);
export const selectauditTrailLoading = createSelector(selectAml, (state: AmlState) => state.auditTrailLoading);

export const selectTransactionTypes = createSelector(selectAml, (state: AmlState) => state.transactionTypes);
export const selectTransactionTypesError = createSelector(selectAml, (state: AmlState) => state.transactionTypesError);
export const selectTransactionTypesLoading = createSelector(
  selectAml,
  (state: AmlState) => state.transactionTypesLoading
);

export const selectFIs = createSelector(selectAml, (state: AmlState) => state.FIs);
export const selectFIsError = createSelector(selectAml, (state: AmlState) => state.FIsError);
export const selectFIsLoading = createSelector(selectAml, (state: AmlState) => state.FIsLoading);

// Fees Selectors
export const selectFees = (state: AppState) => state.fees;
export const selectAdminMerchantFees = createSelector(selectFees, (state: FeesState) => state.adminMerchantFees);
export const selectAdminTransferFees = createSelector(selectFees, (state: FeesState) => state.adminTransferFees);
export const selectAdminProductFees = createSelector(selectFees, (state: FeesState) => state.adminProductFees);

// Activity Log Selectors
export const selectActivityLog = (state: AppState) => state.activityLog;
export const selectAdminActivityLogs = createSelector(
  selectActivityLog,
  (state: ActivityLogState) => state.adminActivityLogs
);

// CUSTOMER SELECTORS
export const selectCustomers = (state: AppState) => state.customers;

// (customer selectors) selectors for country limits
export const selectLimits = createSelector(selectCustomers, (state: CustomerState) => state.limits);
export const selectLimitsLoading = createSelector(selectCustomers, (state: CustomerState) => state.limitsLoading);
export const selectLimitsError = createSelector(selectCustomers, (state: CustomerState) => state.limitsError);

// (customer selectors) selectors for users
export const selectUsers = createSelector(selectCustomers, (state: CustomerState) => state.users);
export const selectUsersLoading = createSelector(selectCustomers, (state: CustomerState) => state.usersLoading);
export const selectUsersError = createSelector(selectCustomers, (state: CustomerState) => state.usersError);

// (customer selectors) selectors for users
export const selectUsersNew = createSelector(selectCustomers, (state: CustomerState) => state.usersNew);
export const selectUsersNewLoading = createSelector(selectCustomers, (state: CustomerState) => state.usersNewLoading);
export const selectUsersNewError = createSelector(selectCustomers, (state: CustomerState) => state.usersNewError);

// (customer selectors) selectors for users
export const selectUsersPendingApplication = createSelector(
  selectCustomers,
  (state: CustomerState) => state.usersPendingApplication
);
export const selectUsersPendingApplicationLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.usersPendingApplicationLoading
);
export const selectUsersPendingApplicationError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.usersPendingApplicationError
);
export const selectAdminCustomerUsers = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminCustomerUsers
);
export const selectAdminCustomerUserTransactions = createSelector(
  selectAdmin,
  (state: AdminManageAccountState) => state.adminCustomerUserTransactions
);
// (customer selectors) selectors for all transactions
export const selectTransactions = createSelector(selectCustomers, (state: CustomerState) => state.transactions);
export const selectTransactionsLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.transactionsLoading
);
export const selectTransactionsError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.transactionsError
);

//filters on admin transactions table
export const selectUniqueTransTypes = createSelector(selectCustomers,  (state: CustomerState) => state.transactionTypes);
export const selectUniqueChannelIDs = createSelector(selectCustomers,  (state: CustomerState) => state.channelIDs);
export const selectUniqueAccountTypes = createSelector(selectCustomers,  (state: CustomerState) => state.accountTypes);

// (customer selectors) selectors for customer transactions
export const selectUserTransaction = createSelector(
  selectCustomers,
  (state: CustomerState) => state.adminCustomerUserTransactions
);
export const selectUserTransactionLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.adminCustomerUserTransactionsLoading
);
export const selectUserTransactionError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.adminCustomerUserTransactionsError
);

export const selectAdminCustomerPendingTransactionFees = createSelector(
  selectCustomers,
  (state: CustomerState) => state.adminCustomerUserPendingTransactionFees
);

// (customer selectors) merchants
export const selectMerchants = createSelector(selectCustomers, (state: CustomerState) => state.merchants);
export const selectMerchantsLoading = createSelector(selectCustomers, (state: CustomerState) => state.merchantsLoading);
export const selectMerchantsError = createSelector(selectCustomers, (state: CustomerState) => state.merchantsError);

// (customer selectors) merchant accounts
export const selectMerchantAccounts = createSelector(selectCustomers, (state: CustomerState) => state.merchantAccounts);
export const selectMerchantAccountsLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantAccountsLoading
);
export const selectMerchantAccountsError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantAccountsError
);

// (customer selectors) Fee Groups
export const selectFeeGroups = createSelector(selectCustomers, (state: CustomerState) => state.feeGroups);
export const selectFeeGroupsLoading = createSelector(selectCustomers, (state: CustomerState) => state.feeGroupsLoading);
export const selectFeeGroupsError = createSelector(selectCustomers, (state: CustomerState) => state.feeGroupsError);

// (customer selectors) Merchant
export const selectMerchant = createSelector(selectCustomers, (state: CustomerState) => state.merchant);
export const selectMerchantLoading = createSelector(selectCustomers, (state: CustomerState) => state.merchantLoading);
export const selectMerchantError = createSelector(selectCustomers, (state: CustomerState) => state.merchantError);

// (customer selectors) Merchant account list
export const selectMerchantAccounList = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantAccountList
);
export const selectMerchantAccounListLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantAccountListLoading
);
export const selectMerchantAccounListError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantAccountListError
);

// (customer selectors) fee accounts
export const selectFeesAccountByCountry = createSelector(
  selectCustomers,
  (state: CustomerState) => state.feesAccountByCountry
);
export const selectFeesAccountByCountryLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.feesAccountByCountryLoading
);
export const selectFeesAccountByCountryError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.feesAccountByCountryError
);

// (customer selectors) merchant entity types
export const selectMerchantEntTypes = createSelector(selectCustomers, (state: CustomerState) => state.merchantEntTypes);
export const selectMerchantEntTypesLoading = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantEntTypesLoading
);
export const selectMerchantEntTypesError = createSelector(
  selectCustomers,
  (state: CustomerState) => state.merchantEntTypesError
);

// EXCHANGE RATES SELECTORS
export const selectRates = (state: AppState) => state.exRates;

// (exchange rates selectors) selectors for exchange rates for payments
export const selectExRatesForPayment = createSelector(
  selectRates,
  (state: ExRatesState) => state.exchangeRatesForPayment
);
export const selectExRatesForPaymentLoading = createSelector(
  selectRates,
  (state: ExRatesState) => state.exchangeRatesForPaymentLoading
);
export const selectExRatesForPaymentError = createSelector(
  selectRates,
  (state: ExRatesState) => state.exchangeRatesForPaymentError
);

// (exchange rates selectors) selectors for exchange rates for payments
export const selectExRatesForTransfer = createSelector(
  selectRates,
  (state: ExRatesState) => state.exchangeRatesForTransfer
);
export const selectExRatesForTransferLoading = createSelector(
  selectRates,
  (state: ExRatesState) => state.exchangeRatesForTransferLoading
);
export const selectExRatesForTransferError = createSelector(
  selectRates,
  (state: ExRatesState) => state.exchangeRatesForTransferError
);

// ISSUER SELECTORS
export const selectXcelIssuers = (state: AppState) => state.issuers;
export const selectIssuers = createSelector(selectXcelIssuers, (state: IssuerState) => state.issuers);
export const selectIssuersLoading = createSelector(selectXcelIssuers, (state: IssuerState) => state.issuersLoading);
export const selectIssuersError = createSelector(selectXcelIssuers, (state: IssuerState) => state.issuersError);

export const selectSubIssuers = createSelector(selectXcelIssuers, (state: IssuerState) => state.subIssuers);
export const selectSubIssuersLoading = createSelector(
  selectXcelIssuers,
  (state: IssuerState) => state.subIssuersLoading
);
export const selectSubIssuersError = createSelector(selectXcelIssuers, (state: IssuerState) => state.subIssuersError);

// Settings Selectors
export const selectSettings = (state: AppState) => state.settings;
export const selectAdminUsers = createSelector(selectSettings, (state: SettingsState) => state.adminUsers);

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [flush] : [flush];

export const selectIssuerUsers = (state: AppState) => state.users.users;

// TRANSACTION MONITORING SELECTORS
export const selectTransactionMonitoring = (state: AppState) => state.transMonitoringState;
export const myRulesSets = createSelector(
  selectTransactionMonitoring,
  (state: TransactionMonitoringState) => state.rulesSets
);
export const uKCorporateRules = createSelector(
  selectTransactionMonitoring,
  (state: TransactionMonitoringState) => state.CorporateRules
);

export const ukFinanacialInstitutions = createSelector(
  selectTransactionMonitoring,
  (state: TransactionMonitoringState) => state.FinancialInstitutions
);