import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError, exhaustMap } from "rxjs/operators";
import { AmlService } from "../aml.service";
import * as amlActions from '../_state/aml.actions'

@Injectable({
    providedIn: 'root'
})
export class AmlEffects{
    constructor(
        private actions$: Actions,
        private toastr: ToastrService,
        private amlService: AmlService
    ) {}

    fetchRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchRules),
      exhaustMap((action) =>
        this.amlService
          .getRules()
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchRulesSuccess({ rules: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchRulesFailed())
            }
            )
          )
      )
    )
  );
    fetchRuleSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchRuleSets),
      exhaustMap((action) =>
        this.amlService
          .getRuleSets()
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchRuleSetsSuccess({ rulesets: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchRuleSetsFailed())
            }
            )
          )
      )
    )
  );


    fetchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchFlaggedTransactions),
      exhaustMap((action) =>
        this.amlService
          .getFlaggedTransactions(action)
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchFlaggedTransactionsSuccess({ flaggedTransactions: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchFlaggedTransactionsFailed())
            }
            )
          )
      )
    )
  );
    fetchLoggedTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchLoggedTransactions),
      exhaustMap((action) =>
          this.amlService
          .getLoggedTransactions(action)
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchLoggedTransactionsSuccess({ loggedTransactions: response }),

            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchLoggedTransactionsFailed())
            }
            )
          )
      )
    )
  );

    fetchChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchChannels),
      exhaustMap((action) =>
        this.amlService
          .getChannels()
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchChannelsSuccess({ channels: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchChannelsFailed())
            }
            )
          )
      )
    )
    );

    fetchCoporateRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlActions.fetchCoporateRule),
      exhaustMap((action) =>
          this.amlService
              //.getCoporateRule(action.page, action.pageSize)
              .getCoporateRule(action.page, action.pageSize, action.accountNumber)
          .pipe(
              exhaustMap((response: any) => [
                  amlActions.fetchCoporateRuleSuccess({ coporateRule: response } ),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchCoporateRuleFailed())
            }
            )
          )
      )
    )
  );
    fetchAuditTrail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlActions.fetchAuditTrail),
      exhaustMap((action) =>
          this.amlService
              .getAuditTrail(action.page, action.pageSize)
          .pipe(
              exhaustMap((response: any) => [
                  amlActions.fetchAuditTrailSuccess({ auditTrail: response } ),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchAuditTrailFailed())
            }
            )
          )
      )
    )
  );
    fetchTransactionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchTransactionTypes),
      exhaustMap((action) =>
        this.amlService
          .getTransactionTypes()
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchTransactionTypesSuccess({ transactionTypes: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchTransactionTypesFailed())
            }
            )
          )
      )
    )
  );
    fetchFI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.fetchFIs),
      exhaustMap((action) =>
        this.amlService
          .getFIs()
          .pipe(
            exhaustMap((response: any) => [
                amlActions.fetchFIsSuccess({ FIs: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.errors, 'An error occured');
                return of(amlActions.fetchFIsFailed())
            }
            )
          )
      )
    )
  );

  searchPepSanctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(amlActions.searchPepAndSanctions),
      exhaustMap((action) =>
        this.amlService
          .searchPepAndSanction(action.payload)
          .pipe(
            exhaustMap((response: any) => [
                amlActions.searchPepAndSanctionsSuccess({ sanctions: response }),
            ]),
            catchError((error: HttpErrorResponse) => {
              this.toastr.error(error?.error?.message || 'Error occured');
                return of(amlActions.searchPepAndSanctionsFailed({message: error?.error?.message, code: error.status}))
            }
            )
          )
      )
    )
  );

}