import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthHeaderService {
  constructor() { }

  generateAuthHeader(payload: any): string {
    const APP_ID = `${environment.X_APP_ID}`;
    const ISSUER_ID = `${environment.X_ISSUER_ID}`;
    const PUBLIC_KEY = `${environment.PUBLIC_KEY}`;
    const payloadStr = JSON.stringify(payload);
    const hashStr = `${payloadStr}etz${APP_ID}etz${PUBLIC_KEY}etz${ISSUER_ID}`;
    const xAuthHeader = CryptoJS.HmacSHA512(hashStr, PUBLIC_KEY).toString(CryptoJS.enc.Base64);

    return xAuthHeader;
  }

}