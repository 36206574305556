import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { ActivityLogService } from '../_services/activity-log.service';
import { fetchAdminActivityLogs, setAdminActivityLog, fetchAdminActivityLogFailed } from './activity-log.actions';

@Injectable({
    providedIn: 'root'
})
export class ActivityLogEffects {

    fetchAdminActivityLog$ = createEffect(() =>
        this.action$.pipe(
            ofType(fetchAdminActivityLogs),
            exhaustMap((_action) =>
                this.activityLogService.fetchAdminActivityLog().pipe(
                map((response: any) =>
                    setAdminActivityLog({adminActivityLogs : response.data })
                ),
                catchError((error: HttpErrorResponse) =>
                    of(fetchAdminActivityLogFailed())
                )
                )
            )
        )
    );

    constructor(private action$: Actions, private activityLogService: ActivityLogService) {}

}
