import { createReducer, on } from '@ngrx/store';
import {
  CorporateRuleModel,
  FinancialInstitutionsModel,
  RulesSetsModel
} from '../_interfaces/trans-monitoring.interface';
import { createRuleSetsSuccess, fetchRulesSetsSuccess } from './trans-monitoring.actions';
import * as TransMonitoringActions from './trans-monitoring.actions';

export interface TransactionMonitoringState {
  rulesSets: RulesSetsModel[] | null;
  CorporateRules: CorporateRuleModel[] | null;
  FinancialInstitutions: FinancialInstitutionsModel[] | null;
}

export const initialState: TransactionMonitoringState = {
  rulesSets: [],
  CorporateRules: [],
  FinancialInstitutions: []
};

export const TransactionMonitoringReducer = createReducer(
  initialState,

  on(fetchRulesSetsSuccess, (state, { rulesSets }) => ({
    ...state,
    rulesSets
  })),

  on(TransMonitoringActions.fetchCorporateRulesSuccess, (state, { CorporateRules }) => ({
    ...state,
    CorporateRules
  })),

  on(TransMonitoringActions.fetchFinanceInstitutionSuccess, (state, { FinancialInstitutions }) => ({
    ...state,
    FinancialInstitutions
  })),

  on(createRuleSetsSuccess, (state, { newRuleSets }) => ({
    ...state,
    users: [...state.rulesSets, newRuleSets]
  }))
);

// export const TransactionMonitoringReducer = (state: TransactionMonitoringState, action) => _rulesSetsReducer(state, action);
