import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/reducers';
import * as authActions from '../_state/auth.actions';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;
  private unsubscribe$ = new Subject();
  requestLoading = false;
  requestSent = false;
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private toastService: ToastrService,
    private translate: TranslateService
  ) { }


  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email : new FormControl(null, [Validators.email, Validators.required])
    });

    this.onForgotPasswordFailed();
    this.onForgotPasswordSuccess();
  }

  onSubmit(){
      const formData = this.forgotPasswordForm.get('email').value;
      this.store.dispatch(authActions.triggerForgotPassword({email : formData}));
  }

  onForgotPasswordSuccess(){
    this.actions$.pipe(
      ofType(authActions.triggerForgotPasswordSuccess),
      takeUntil(this.unsubscribe$)
    ).subscribe(response => {
      this.requestSent = true;
      this.requestLoading = false;
      // this.closeDeleteModal()
      this.toastService.success(this.translate.instant('AUTH.FORGOT_SUCCESS'), this.translate.instant('AUTH.SUCCESS'));
    });
  }

  onForgotPasswordFailed(){
    this.actions$.pipe(
      ofType(authActions.triggerForgotPasswordFailed),
      takeUntil(this.unsubscribe$)
    ).subscribe(response => {
      this.requestLoading = false;
      // this.closeDeleteModal()
      this.toastService.error(this.translate.instant('AUTH.FAILED_MSG'), this.translate.instant('AUTH.FAILED'));
    });
  }

  enterEmail(){
    this.requestSent = false;

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
