<div class="row p-4">
  <div class="col-md-12 table-div table-responsive">
    <table
      id="myTable"
      class="table specialTable table-bordered table-responsive-md mb-0"
    >
      <thead>
        <th *ngFor="let column of tableColumns">{{ column }}</th>
      </thead>
    </table>
    <div
      class="col-md-12 border"
      style="padding: 1rem; border: 1px solid #dee2e6;"
    >
      <div class="loader-container">
        <div class="loader-wrapper running">
          <div class="ld ld-ring ld-spin-fast"></div>
        </div>
      </div>
      <h2 class="text-center"
          style="color: #dee2e6;">{{'EMPTY_STATE.LOADING_STATE_TABLE.LOADING_DATA' | translate}}</h2>
    </div>
  </div>
</div>
