import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppLocalStorage } from 'src/app/storage-manager/local-storage';
import { environment } from 'src/environments/environment';
import { CreateActivityLogDto, SignUpWithOtpDto } from '../_state/auth.dtos';
import { SESSION_KEY } from 'src/app/storage-manager/session-storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private appStorage: AppLocalStorage,
){}

DEFAULT_COUNTRY_CODE = '234';
DEFAULT_COUNTRY_ISO_2 = 'NG';
DEFAULT_CURRENCY_CODE = 'NGN';

checkAvail(type, value){
        // return this.http.post(`${environment.ABBAS_API_URL}'/check-email-exists', email)
        return this.http.get(`${environment.ADMIN_AUTH_URL}/user/check-availability?type=${type}?value=${value}`);
}

fetchSelectedUserViaWalletNo(walletNo, schemeCode){
    return this.http.get(`${environment.API_URL}/source/details/${walletNo}/${schemeCode}`);
}

fetchAuthUser(){
    return this.http.get(`${environment.ADMIN_AUTH_URL}/auth/user`);
}

checkIfUserExistsBy(type, value){
    // return this.http.head(`${environment.ABBAS_API_URL}`/user/exists?${type}=${value}`)
    return this.http.head(`${environment.ABBAS_API_URL}user/exists?${type}=${value}`);
}

verifyUsername(username){
    // return this.http.post(`${environment.ABBAS_API_URL}'/check-username-exists', username)
    return this.http.post(`${environment.ABBAS_API_URL}/auth/check-username-exists`, username);


}

getCountries(){
    // https://sandbox-api.etranzactglobal.com/countries --- use this for alt details
    return this.http.get(`${environment.ADMIN_AUTH_URL}/countries`).pipe(retry(3));
}


signup(signUpDetails){
    return this.http.post(`${environment.ABBAS_API_URL}/auth/signup`, signUpDetails).pipe(map(res => {
        this.setSession(res);
        return res;
    }));
}

verifyLoginCredentials(loginDetails){
    return this.http.post(`${environment.ABBAS_API_URL}/verify-login-credentials`, loginDetails);
}

verifyLoginEmailCode(loginDetails){
    return this.http.post(`${environment.ABBAS_API_URL}/verify-login-email-code`, loginDetails).pipe(map(res => {
        this.setSession(res);
        return res;
    }));
}

login(loginDetails){
    return this.http.post(`${environment.ABBAS_API_URL}/auth/login`, loginDetails).pipe(map(res => {
        this.setSession(res);
        return res;
    }));
 }

forgotPassword(email){
    return this.http.post(`${environment.ADMIN_AUTH_URL}/user/forgot-password`, {email});
}

resetPassword(newPassword, sid){
    return this.http.post(`${environment.ADMIN_AUTH_URL}/user/reset-password/${sid}`, {newPassword});
}

getResetSidStatus(sid){
    return this.http.get(`${environment.ADMIN_AUTH_URL}/user/reset-sid/status/${sid}`);
}

registerPassword(emailNdPassword){
    return this.http.post(`${environment.ABBAS_API_URL}/auth/register-password`, emailNdPassword).pipe(map(res => {
        this.setSession(res);
        return res;
    }));
}


isNotLoggedIn(){
    // return (localStorage.getItem('access_token') !== null || localStorage.getItem('access_token') !== undefined);
    return !localStorage.getItem('access_token');
    // return this.verifyAccessToken();
}

verifyEmailRegistered(emailAndCode){
        return this.http.post(`${environment.ABBAS_API_URL}/auth/verify/email-registered`, emailAndCode);
}

verifyPhoneRegistered(phoneAndCode){
        return this.http.post(`${environment.ABBAS_API_URL}/auth/verify/phone-registered`, phoneAndCode);
}

resendEmailVerificationCode(email){
    return this.http.post(`${environment.ABBAS_API_URL}/auth/regenerate/email-token`, email);
}

resendPhoneVerificationCode(phone){
    return this.http.post(`${environment.ABBAS_API_URL}/auth/regenerate/phone-token`, phone);
}

getUserRole(){
    return this.http.get(`${environment.ABBAS_API_URL}/user/role`);
}


refreshToken(){
    return this.http.get(`${environment.ABBAS_API_URL}/auth/refresh-token`).pipe(map(res => {
        this.setSession(res);
        return res;
    }));
}

getSelectedCountryCodeFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_CODE)) ?
    this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_CODE) : this.DEFAULT_COUNTRY_CODE;
}

getSelectedCurrencyCodeFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_CURRENCY_CODE)) ?
    this.appStorage.getFromStorage(SESSION_KEY.DBOARD_CURRENCY_CODE) : this.DEFAULT_CURRENCY_CODE;
}

getLoggedInSchemeCode(){
    return (this.getSelectedCountryCodeFromSession() + '004').padStart(6, '0');
}


logOut(){
    localStorage.clear();
    sessionStorage.clear();
    // this.deleteSession('access_token');
    // this.deleteSession('roles');
    this.router.navigateByUrl('/auth/login');
}

postActivityLog(createActivityLogDto: CreateActivityLogDto){
    return this.http.post(`${environment.ADMIN_AUTH_URL}/activity-log`, createActivityLogDto);
}

 setSession(val){
    localStorage.setItem('access_token', val.access_token);
    this.appStorage.storeToStorage('roles', val.roles);
}

private verifyAccessToken(){
    const tk = { token : localStorage.getItem('access_token')};
    return this.http.post<boolean>(`${environment.ABBAS_API_URL}/auth/verify`, tk).pipe(map(res => {
        if (res){
           return true;
        }
    }));
}

private deleteSession(key){
    localStorage.removeItem(key);
}

 _login(loginDetails){
    return this.http.post(`${environment.ADMIN_AUTH_URL}/auth/login`, loginDetails);
 }

 findUserFromMerchant(walletNo, schemeCode){
    return this.http.get(`${environment.ADMIN_AUTH_URL}/user/merchant/${walletNo}/${schemeCode}`);
 }

 sendOtp(walletNo){
    return this.http.post(`${environment.ADMIN_AUTH_URL}/auth/otp/generation/${walletNo}`, {});
 }

 _createUser(signupWithOtp: SignUpWithOtpDto){
    return this.http.post(`${environment.ADMIN_AUTH_URL}/auth/otp/merged/user-signup`, signupWithOtp);
 }

 getSelectedCountryFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO)) ?
            this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO) : this.DEFAULT_COUNTRY_ISO_2;
}

}
