import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FeesService {

  constructor(private http: HttpClient, private authService: AuthService, private sharedService: SharedService) { }

  //
 // ─── ADMIN MERCHANT FEES ────────────────────────────────────────────────────────
 //

  getMerchantFee(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/fees/country/${this.authService.getSelectedCountryCodeFromSession()}`, { params });
  }

  createMerchantFee(data){
    return this.http.post(`${environment.API_URL}/fees`, data);
  }

  updateMerchantFee(data){
    return this.http.put(`${environment.API_URL}/fees`, data);
  }
  //
  // ─── ADMIN TRANSFER FEES ────────────────────────────────────────────────────────
  //
  getTransferFee(){
    // let params = new HttpParams();
    // params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    // params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.BUSINESS_API_URL}/transfers/fees/${this.authService.getSelectedCountryFromSession()}`);
  }

  createTransferFee(data){
    return this.http.post(`${environment.BUSINESS_API_URL}/transfers/fees`, data);
  }

  updateTransferFee(data, id){
    return this.http.put(`${environment.BUSINESS_API_URL}/transfers/fees/${id}`, data);
  }

  deleteTransferFee(id){
    return this.http.delete(`${environment.BUSINESS_API_URL}/transfers/fees/${id}`);
  }

  //
  // ─── ADMIN TRANSFER FEES ────────────────────────────────────────────────────────
  //
  getProductFee(merchantId){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/merchant/products/` + merchantId, { params });
  }
  createProductFee(productId, data){
    return this.http.put(`${environment.API_URL}/product/fee/${productId}`, data);
  }

  updateProductFee(productId, data){
    return this.http.put(`${environment.API_URL}/product/fee/${productId}`, data);
  }

}
