import { createReducer, on } from '@ngrx/store';
import { AdminOrganisation, AdminOrganisationStaff, AdminOrganisationStaffLoanHistory, AdminOrganisationStaffLoanTrans } from '../_interfaces/admin-organisation.interface';
import * as organisationActions from './organisation.actions';

export interface OrganisationState {
    adminOrganisations: AdminOrganisation[] | null;
    adminSelectedOrgAccount: AdminOrganisation | null;
    adminOrganisationStaffs: AdminOrganisationStaff[] | null;
    adminOrgStaffLoanHistory: AdminOrganisationStaffLoanHistory[] | null;
    adminOrgStaffLoanTrans: AdminOrganisationStaffLoanTrans[] | null;
}

export const initialState: OrganisationState = {
    adminOrganisations : null,
    adminOrganisationStaffs : null,
    adminOrgStaffLoanHistory : null,
    adminOrgStaffLoanTrans : null,
    adminSelectedOrgAccount : null
};

export const _organisationReducer = createReducer(initialState,

    on(organisationActions.setSelectedAdminOrgAccount, (state, { adminSelectedOrgAccount }) => ({
        ...state, adminSelectedOrgAccount
    })),

    on(organisationActions.setAdminOrgStaffAccounts, (state, { adminOrgStaffAccounts }) => ({
        ...state, adminOrganisationStaffs: adminOrgStaffAccounts
    })),

    on(organisationActions.setAdminOrgAccounts, (state, { adminOrgAccounts }) => ({
        ...state, adminOrganisations: adminOrgAccounts
    })),

    on(organisationActions.setAdminOrgStaffLoanHistory, (state, { adminOrgStaffLoanHistory }) => ({
        ...state, adminOrgStaffLoanHistory
    })),

    on(organisationActions.setAdminOrgStaffLoanTrans, (state, { adminOrgStaffLoanTrans }) => ({
        ...state, adminOrgStaffLoanTrans
    }))
);

export const organisationReducer = (state: OrganisationState, action) => _organisationReducer(state, action);
