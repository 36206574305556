import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { DashboardPreloadingStrategyService } from './app-layout/dashboard-preloading-strategy.service';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { AuthGuard } from './modules/auth/services/auth.guard';



const routes: Routes = [
  {
    path: 'account/reset-password/:sid',
    component: ResetPasswordComponent
  },
  {
    path: 'account/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path : '',
    redirectTo: '/auth/login',
    pathMatch : 'full'
  },
  {
    path : 'auth',
    loadChildren : () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path : 'dashboard', component : AppLayoutComponent,
    data : { preload : true },
    canActivate: [AuthGuard],
    loadChildren : () => import('./app-layout/app-layout.module').then(m => m.AppLayoutModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
        useHash: true,
        relativeLinkResolution: 'legacy',
        preloadingStrategy : DashboardPreloadingStrategyService
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
