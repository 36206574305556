import { createReducer, on } from '@ngrx/store';
import { AuthDto, PersonalInformation } from './auth.dtos';
import { AdminCountry, AuthUser, OtpVerification } from './auth.interfaces';
import * as AuthActions from './auth.actions';
import { Bank } from '../../customers/_state/customers.interfaces';

export interface AuthState {
    authUser: AuthUser | null;
    login: AuthDto;
    signup: {
        personalData: PersonalInformation | null
        otpVerificationDetails: OtpVerification | null
    };

    adminCountries: AdminCountry[] | null;
    adminCountriesLoading: boolean;
    adminCountriesError: boolean;
    adminSelectedCountry: AdminCountry | null;

    adminBanks: Bank[] | null
}

export const initialState: AuthState = {
    authUser : null,
    login : {
        access_token : null,
        roles : null
    },
    signup : {
        personalData: {
            createUserDto : null,
            isEmpty : true,
            isComplete : false
        },
        otpVerificationDetails : {
            walletNo : null,
            code : null,
            isVerified : false
        },
    },

    adminCountries: null,
    adminCountriesLoading: null,
    adminCountriesError: null,
    adminSelectedCountry : null,

    adminBanks: null
};

export const _authReducer = createReducer(initialState,

    on(AuthActions.setAuthUser, (state, { authUser }) => ({
        ...state, authUser
    })),

    on(AuthActions.setPersonalInformation, (state, { personalData }) => ({
        ...state, signup :  { ...state.signup, personalData : { ...personalData, isComplete : true, isEmpty : false},  }
    })),

    on(AuthActions.setOtpVerification, (state, { otpVerificationDetails }) => ({
        ...state, signup :  { ...state.signup, otpVerificationDetails : { ...otpVerificationDetails},  }
    })),

    on(AuthActions.StoreAuthDto, (state, { authDto }) => ({
        ...state, login : { access_token : authDto.access_token, roles : authDto.roles}
    })),

    on(AuthActions.fetchAdminCountries, (state) => ({
        ...state, adminCountries : null, adminCountriesLoading: true, adminCountriesError: false
    })),

    on(AuthActions.fetchAdminCountriesFailed, (state) => ({
        ...state, adminCountries : null, adminCountriesLoading: false, adminCountriesError: true
    })),
    on(AuthActions.fetchAdminCountriesSuccess, (state, {adminCountries}) => ({
        ...state, adminCountries, adminCountriesLoading: false, adminCountriesError: false
    })),

    on(AuthActions.setAdminSelectedCountry, (state, { adminCountry }) => ({
        ...state, adminSelectedCountry : adminCountry
    })),

    on(AuthActions.setAdminBanks, (state, { adminBanks }) => ({
        ...state, adminBanks: adminBanks
    })),

);


export function authReducer( state, action ){
    return _authReducer(state, action);
}
