import { createAction, props } from '@ngrx/store';
import { AdminExchangeRate } from './rates.interfaces';

export const fetchAdminExchangeRatesForPayment = createAction(
    '[Admin Page] READ: fetch Admin Exchange Rates For Paymemt'
);

export const fetchAdminExchangeRatesForPaymentFailed = createAction(
    '[Admin Page] READ: fetch Admin Exchange Rates For Paymemt Failed'
);
export const setAdminExchangeRatesForPayment = createAction(
    '[Admin Page] Set Admin Exchange Rates For Paymemt',
    props<{adminExchangeRatesForPayment: AdminExchangeRate[]}>()
);







export const fetchAdminExchangeRatesForTransfer = createAction(
    '[Admin Page] READ: fetch Admin Exchange Rates For Transfer'
);

export const fetchAdminExchangeRatesForTransferFailed = createAction(
    '[Admin Page] READ: fetch Admin Exchange Rates For Transfer Failed'
);
export const setAdminExchangeRatesForTransfer = createAction(
    '[Admin Page] Set Admin Exchange Rates For Transfer',
    props<{adminExchangeRatesForTransfer: AdminExchangeRate[]}>()
);
