import { createAction, props } from "@ngrx/store"
import { Channel, CoporateRule,AuditTrailResponse, CoporateRuleResponse, FI, FlaggedTransaction, FlaggedTransactionsResponse, LoggedTransactionsResponse, Rule, RuleSet, TransactionType } from "./aml.interfaces"

export const fetchRules = createAction(
    '[AML Module] fetch rules'
)

export const fetchRulesFailed = createAction(
    '[AML Module] STATUS: fetch rules failed'
)

export const fetchRulesSuccess = createAction(
    '[AML Module] STATUS: fetch rules success',
    props<{rules: Rule[]}>()
)


export const fetchRuleSets = createAction(
    '[AML Module] fetch rule sets'
)

export const fetchRuleSetsFailed = createAction(
    '[AML Module] STATUS: fetch rule sets failed'
)

export const fetchRuleSetsSuccess = createAction(
    '[AML Module] STATUS: fetch rule sets success',
    props<{rulesets: RuleSet[]}>()
)


export const fetchFlaggedTransactions= createAction(
    '[AML Module] fetch flagged transactions',
    props<{responsePayload}>()
)

export const fetchFlaggedTransactionsFailed = createAction(
    '[AML Module] STATUS: fetch flagged transactions failed'
)

export const fetchFlaggedTransactionsSuccess = createAction(
    '[AML Module] STATUS: fetch flagged transactions success',
    props<{flaggedTransactions: FlaggedTransactionsResponse}>()
)


export const fetchLoggedTransactions= createAction(
    '[AML Module] fetch logged transactions',
    props<{requestPayload}>()
)

export const fetchLoggedTransactionsFailed = createAction(
    '[AML Module] STATUS: fetch logged transactions failed'
)

export const fetchLoggedTransactionsSuccess = createAction(
    '[AML Module] STATUS: fetch logged transactions success',
    props<{loggedTransactions: LoggedTransactionsResponse}>()
)

export const fetchCoporateRule = createAction(
    '[AML Module] fetch Coporate Rule',
    props<{ page: number, pageSize: number, accountNumber:string }>()
)

export const fetchCoporateRuleFailed = createAction(
    '[AML Module] STATUS: fetch Coporate Rule failed'
)

export const fetchCoporateRuleSuccess = createAction(
    '[AML Module] STATUS: fetch Coporate Rule success',
    props<{ coporateRule: CoporateRuleResponse }>()
)
export const fetchAuditTrail = createAction(
    '[AML Module] fetch Audit Trail',
    props<{ page: number, pageSize: number, }>()
)

export const fetchAuditTrailFailed = createAction(
    '[AML Module] STATUS: fetch Audit Trail failed'
)

export const fetchAuditTrailSuccess = createAction(
    '[AML Module] STATUS: fetch Audit Trail success',
    props<{ auditTrail: AuditTrailResponse }>()
)

export const fetchChannels= createAction(
    '[AML Module] fetch channels'
)

export const fetchChannelsFailed = createAction(
    '[AML Module] STATUS: fetch channels failed'
)

export const fetchChannelsSuccess = createAction(
    '[AML Module] STATUS: fetch channels success',
    props<{channels: Channel[]}>()
)


export const fetchTransactionTypes= createAction(
    '[AML Module] fetch transaction types'
)

export const fetchTransactionTypesFailed = createAction(
    '[AML Module] STATUS: fetch transaction types failed'
)

export const fetchTransactionTypesSuccess = createAction(
    '[AML Module] STATUS: fetch transaction types success',
    props<{transactionTypes: TransactionType[]}>()
)


export const searchPepAndSanctions= createAction(
    '[AML Module] search pep and sanctions',
    props<{payload: any}>()
)

export const searchPepAndSanctionsFailed = createAction(
    '[AML Module] STATUS: search pep and sanctions failed',
    props<{message: string, code: number}>()
)

export const searchPepAndSanctionsSuccess = createAction(
    '[AML Module] STATUS: search pep and sanctions success',
    props<{sanctions: any}>()
)


export const fetchFIs= createAction(
    '[AML Module] fetch financial intitutions'
)

export const fetchFIsFailed = createAction(
    '[AML Module] STATUS: fetch financial intitutions failed'
)

export const fetchFIsSuccess = createAction(
    '[AML Module] STATUS: fetch financial intitutions success',
    props<{FIs: FI[]}>()
)