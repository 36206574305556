<div class="section-1-container section-container gen-bg">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2 div-wrapper d-flex justify-content-center align-items-center">
 
                <div class="div-to-align" *ngIf="!sidIsInvalid; else showInvalidDiv">

                            <div class="shadow bg-white rounded p-4">
                                <div class="d-flex justify-content-end">
                                    <app-language-switch></app-language-switch>
                                </div>
                                    <div class="row">
                                            <div class="col-md-12 p-4 text-center">
                                                    <a routerLink="/auth/login"><img src="/assets/images/logo.png" height="55px" alt="xcel-admin"></a> | {{ 'AUTH.RESET' | translate }}
                                                    
                                            </div>
                                    </div>
                                    <!-- <h5>Login</h5> -->
                                    <ng-container *ngIf="!requestSent">
                                        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="my-4 p-4" autocomplete="off">
                                           
                                            <div class="form-group">
                                                <label for="email" translate>AUTH.ENTER_NEW_PASS</label>
                                                <input type="password" formControlName="newPassword" id="newPassword" class="form-control login-form-control">
                                                <div *ngIf="newPassword.touched && newPassword.invalid" class="text-right text-danger error-msg">
                                                    <small class="d-block" *ngIf="newPassword.errors.minlength">{{'SETTINGS.CHANGE_PASSWORD.PASSWORD_LESS_THAN' |
                                                      translate}}</small>
                                                    <small class="d-block" *ngIf="newPassword.errors.required">{{'SETTINGS.CHANGE_PASSWORD.REQUIRED_FIELD' |
                                                      translate}}</small>
                                                    <small class="d-block" *ngIf="newPassword.errors.pattern">{{'SETTINGS.CHANGE_PASSWORD.CONTAIN_AT_LEAST' |
                                                      translate}}</small>
                                                  </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="email" translate>AUTH.REPEAT_NEW_PASS</label>
                                                <input type="text" formControlName="verifyPassword" id="verifyPassword" class="form-control login-form-control">
                                            </div>
                                            <div class="text-center">
                                                    <button type="submit" class="btn btn-primary ld-ext-right" 
                                                    [disabled]="requestLoading || resetPasswordForm.invalid"
                                                    [ngClass]="{'running': requestLoading}">{{ 'AUTH.RESET' | translate }}
                                                            <div class="ld ld-ring ld-spin-fast"></div>
                                                    </button>
                                                    
                                            </div>
                                        </form>
                                    <p><a routerLink="/auth/login">&larr; {{ 'AUTH.BACK_TO_LOGIN' | translate }}</a></p>
                                    </ng-container>
                                    <ng-container *ngIf="requestSent">
                        
                                            <p class="text-center text-success font-weight-bold" translate>AUTH.RESET_SUCCESS</p>
                                            <div class="text-center">
                                                    <a routerLink="/auth/login" class="btn btn-primary text-white" translate>AUTH.GO_TO_LOGIN</a>   
                                            </div>
                                    </ng-container>
                                    

    
                            </div>


                </div>
 
            </div>
        </div>
    </div>
</div>

<ng-template #showInvalidDiv>
    <div class="text-center">
        <h3 translate>AUTH.INVALID_PAGE</h3>
        <p translate>AUTH.RETURN_TO_LOGIN</p>
        <a class="btn btn-primary" routerLink="/auth/login" translate>AUTH.GO_TO_LOGIN</a>
    </div>
</ng-template>



