import { Injectable } from '@angular/core';
import { UserPermissions } from 'src/app/modules/users/_interfaces/issuer-user.interface';
import { AppLocalStorage } from 'src/app/storage-manager/local-storage';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private appLocalStorage: AppLocalStorage) { }

   dashboardConfig = this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG');
  canAccess(role: UserPermissions): boolean {
    const roles = this.appLocalStorage.getFromStorage('roles');

    // if (dashboardConfig.type === 'SUPER_ADMIN' || dashboardConfig.type === 'COUNTRY_ADMIN' || roles.includes('superadmin') || roles.includes(role)) {
    if (roles.includes('admin') || roles.includes('superadmin') || roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  isIssuer = () => this.dashboardConfig.issuer? true : false
}
