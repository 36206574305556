import { createAction, props } from '@ngrx/store';
import { AdminKycAll, AdminKycError } from '../_interfaces/admin-kyc.interface';
import { SearchData } from '../../../shared/interfaces/search-data.interface';
import { FailedRegistration } from '../_interfaces/failed-registration.interface';
import { AdminBlacklistInfo } from '../_interfaces/admin-blacklist-info.interface';
import { CreateBlacklistDto } from '../_dtos/create-blacklist.dto';
import { UpdateBlacklistInfoDto } from '../_dtos/update-blacklist.dto';

export const fetchAdminKycAll = createAction(
    '[KYC Page] READ: fetch Admin Kyc All'
);

export const fetchAdminKycByDateRange = createAction(
    '[KYC Page] READ: fetch Admin kyc by date range',
    props<{ searchData: SearchData }>()
);

export const fetchAdminKycAllFailed = createAction(
    '[KYC Page] READ: fetch Admin Kyc All Failed'
);

export const fetchAdminKycErrors = createAction(
    '[KYC Page] READ: fetch Admin Kyc Errors'
);

export const fetchAdminKycErrorsByDateRange = createAction(
    '[KYC Page] READ: fetch Admin kyc errors by date range',
    props<{ searchData: SearchData }>()
);

export const fetchAdminKycErrorsFailed = createAction(
    '[KYC Page] READ: fetch Admin Kyc errors Failed'
);

export const fetchFailedUserRegistration = createAction(
    '[KYC Page] READ: fetch failed user registration'
);

export const fetchFailedUserRegistrationSuccess = createAction(
    '[KYC Page] READ: fetch failed user registration success'
);

export const fetchFailedUserRegistrationFailed = createAction(
    '[KYC Page] READ: fetch failed user registration failed'
);

export const deleteFailedUserRegistration = createAction(
    '[KYC Page] DELETE: delete failed user registration',
    props<{id: string}>()
);

export const deleteFailedUserRegistrationSuccess = createAction(
    '[KYC Page] STATUS: delete failed user registration success'
);

export const deleteFailedUserRegistrationFailed = createAction(
    '[KYC Page] STATUS: delete failed user registration failed'
);

export const setAdminKycAll = createAction(
    '[KYC Page] Set Admin Kyc All',
    props<{adminKycAll: AdminKycAll[]}>()
);

export const setAdminKycErrors = createAction(
    '[KYC Page] Set Admin Kyc Errors',
    props<{adminKycErrors: AdminKycError[]}>()
);

export const sendKycEmail = createAction(
    '[KYC Page] send kyc email',
    props<{payload: {email: string}}>()
);

export const sendKycEmailSuccess = createAction(
    '[KYC Page] READ: send kyc email success'
);

export const sendKycEmailFailed = createAction(
    '[KYC Page] STATUS(read): send kyc email failed'
);

export const fetchKycRecords = createAction(
    '[KYC Module] Fetch KYC Records',
    props<{ startDate?: string, endDate?: string, page?: number, pageSize?: number, is_business?: boolean }>()
);

export const fetchKycRecordsSuccess = createAction(
    '[KYC Module] Fetch KYC Records Success',
    props<{ kycRecordsPage: number, kycRecordsPageSize: number, kycRecordsTotalCount: number, kycRecords: AdminKycAll[] }>()
);

export const setFailedUserRegistration = createAction(
    '[KYC Page] set failed user registration',
    props<{failedReg: FailedRegistration[]}>()
);

export const fetchKycRecordsFailed = createAction(
    '[KYC Module] Fetch KYC Records Failed',
    props<{ message: string, code: number }>()
);

export const fetchKycErrors = createAction(
    '[KYC Module] Fetch KYC Errors',
    props<{ startDate?: string, endDate?: string, page?: number, pageSize?: number, userId?:string, is_business?: boolean }>()
);

export const fetchKycErrorsSuccess = createAction(
    '[KYC Module] Fetch KYC Errors Success',
    props<{ kycErrorsPage: number, kycErrorsPageSize: number, kycErrorsTotalCount: number, kycErrors: AdminKycError[] }>()
);

export const fetchKycErrorsFailed = createAction(
    '[KYC Module] Fetch KYC Errors Failed',
    props<{ message: string, code: number }>()
);

export const fetchKycErrorsNoId = createAction(
    '[KYC Module] Fetch KYC Errors No Id',
    props<{ startDate?: string, endDate?: string, page?: number, pageSize?: number, userId?:string, is_business?: boolean }>()
);

export const fetchKycErrorsNoIdSuccess = createAction(
    '[KYC Module] Fetch KYC Errors No Id Success',
    props<{ kycErrorsNoIdPage: number, kycErrorsNoIdPageSize: number, kycErrorsNoIdTotalCount: number, kycErrorsNoId: AdminKycError[] }>()
);

export const fetchKycErrorsNoIdFailed = createAction(
    '[KYC Module] Fetch KYC Errors No Id Failed',
    props<{ message: string, code: number }>()
);

export const createFailedUser = createAction(
    '[KYC Page] CREATE: create failed user',
    props<{payload: any}>()
);

export const createFailedUserSuccess = createAction(
    '[KYC Page] STATUS: create failed user successful'
);

export const createFailedUserFailed = createAction(
    '[KYC Page] STATUS: create failed user failed'
);

export const adminCreateBlacklistInfo = createAction(
    '[KYC Page] CREATE: blacklist information',
    props<{ createBlacklistInfoDto: CreateBlacklistDto}>()
);

export const adminCreateBlacklistInfoSuccessful = createAction(
    '[KYC Page] STATUS: blacklist information creation successful'
);

export const adminCreateBlacklistInfoFailed = createAction(
    '[KYC Page] STATUS: blacklist information creation failed'
);

export const adminUpdateBlacklistInfo = createAction(
    '[KYC Page] UPDATE: blacklist information',
    props<{id: string, updateBlacklistInfoDto: UpdateBlacklistInfoDto}>()
);

export const adminUpdateBlacklistInfoSuccessful = createAction(
    '[KYC Page] STATUS: blacklist information update successfull'
);

export const adminUpdateBlacklistInfoFailed = createAction(
    '[KYC Page] STATUS: blacklist information update failed'
);

export const adminDeleteBlacklistInfo = createAction(
    '[KYC Page] DELETE: blacklist information',
    props<{ id: string}>()
);

export const adminDeleteBlacklistInfoSuccessful = createAction(
    '[KYC Page] STATUS: blacklist information deletion successfull'
);

export const adminDeleteBlacklistInfoFailed = createAction(
    '[KYC Page] STATUS: blacklist information deletion failed'
);

export const fetchBlacklistInfosByCountryCode = createAction(
    '[KYC Page] READ: fetch blacklist infos by country code',
);

export const setBlacklistInfoList = createAction(
    '[KYC Page] set blacklist infos',
    props<{ blacklistInfos: AdminBlacklistInfo[]}>()
);

export const fetchBlacklistInfosByCountryCodeSuccessfull = createAction(
    '[KYC Page] STATUS(read): fetch blacklist infos by country code successfull'
);

export const fetchBlacklistInfosByCountryCodeFailed = createAction(
    '[KYC Page] STATUS(read): fetch blacklist infos by country code failed'
);
