<div class="section-1-container section-container gen-bg">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2 div-wrapper d-flex justify-content-center align-items-center">
 
                <div class="div-to-align">

                            <div class="shadow bg-white rounded p-4">
                                <div class="d-flex justify-content-end">
                                        <app-language-switch></app-language-switch>
                                </div>
                                    <div class="row">
                                            <div class="col-md-12 p-4 text-center">
                                                <img src="/assets/images/logo.png" height="55px" alt="xcel-admin"> | {{ 'AUTH.FORGOT' | translate }}
                                                    
                                            </div>
                                    </div>
                                    <!-- <h5>Login</h5> -->
                                    <ng-container *ngIf="!requestSent">
                                        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="my-4 p-4" autocomplete="off">
                                        <p>{{ 'AUTH.RESET_LINK_SENDING' | translate }}: {{ forgotPasswordForm.get("email").value }}</p>
                                            <div class="form-group">
                                                <label for="email" translate>AUTH.ENTER_EMAIL</label>
                                                <input type="text" formControlName="email" id="email" class="form-control login-form-control">
                                            </div>
                                            <div class="text-center">
                                                    <button type="submit" class="btn btn-primary ld-ext-right" 
                                                    [disabled]="requestLoading || forgotPasswordForm.invalid"
                                                    [ngClass]="{'running': requestLoading}">{{ 'AUTH.SEND_LINK' | translate }}
                                                            <div class="ld ld-ring ld-spin-fast"></div>
                                                    </button>
                                                    
                                            </div>
                                        </form>
                                    </ng-container>
                                    <ng-container *ngIf="requestSent">
                        
                                        <p class="text-center text-success font-weight-bold">{{ 'AUTH.RESET_LINK_SENT' | translate:{ 'email':
                                                forgotPasswordForm.get("email").value } }}</p>
                                            <div class="text-center">
                                                    <button type="button" (click)="onSubmit()" class="btn m-2 btn-primary ld-ext-right" 
                                                    [disabled]="requestLoading || forgotPasswordForm.invalid"
                                                    [ngClass]="{'running': requestLoading}">{{ 'AUTH.RESEND_LINK' | translate }}
                                                            <div class="ld ld-ring ld-spin-fast"></div>
                                                    </button>
                                                    <button type="button"  (click)="enterEmail()" class="btn m-2 btn-outline-primary ld-ext-right" 
                                                    [disabled]="requestLoading || forgotPasswordForm.invalid"
                                                    [ngClass]="{'running': requestLoading}">{{ 'AUTH.ENTER_EMAIL' | translate }}
                                                            <div class="ld ld-ring ld-spin-fast"></div>
                                                    </button>
                                                    
                                            </div>
                                    </ng-container>
                                    
                                    

                                <p><a routerLink="/auth/login">&larr; {{ 'AUTH.BACK_TO_LOGIN' | translate }}</a></p>

    
                            </div>


                </div>
 
            </div>
        </div>
    </div>
</div>



