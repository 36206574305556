import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { SettingsService } from '../_services/settings.service';
import * as settingsActions from './settings.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class SettingsEffects {

    fetchAdminUsers$ = createEffect(() =>
    this.action$.pipe(
        ofType(settingsActions.fetchAdminUsers),
        exhaustMap((action) =>
        this.settingsService
            .fetchAdminUsers()
            .pipe(
            map((response: any) =>
                settingsActions.setAdminUsers({ adminUsers: response.data })
            ),
            catchError((error: HttpErrorResponse) =>
                of(settingsActions.fetchAdminUsersFailed())
            )
            )
        )
    )
    );

    changeAdminPassword$ = createEffect(() =>
    this.action$.pipe(
    ofType(settingsActions.changePassword),
    exhaustMap((action) =>
        this.settingsService
        .changePassword(action.updatePasswordDto)
        .pipe(
            map((response: any) =>
            settingsActions.changePasswordSuccess()
            ),
            catchError((error: HttpErrorResponse) =>
            of(settingsActions.changePasswordFailed())
            )
        )
    )
    )
    );

    addCountry$ = createEffect(() =>
    this.action$.pipe(
    ofType(settingsActions.addCountry),
    exhaustMap((action) =>
        this.settingsService
        .addCountry(action.countryDto)
        .pipe(
            map((response: any) =>
            settingsActions.addCountrySuccess()
            ),
            catchError((error: HttpErrorResponse) =>
            of(settingsActions.addCountryFailed())
            )
        )
    )
    )
    );

    updateCountry$ = createEffect(() =>
    this.action$.pipe(
    ofType(settingsActions.updateCountry),
    exhaustMap((action) =>
        this.settingsService
        .updateCountry(action.countryDto, action.countryId)
        .pipe(
            map((response: any) =>
            settingsActions.updateCountrySuccess()
            ),
            catchError((error: HttpErrorResponse) =>
            of(settingsActions.updateCountryFailed())
            )
        )
    )
    )
    );

    deleteCountry$ = createEffect(() =>
    this.action$.pipe(
    ofType(settingsActions.deleteCountry),
    exhaustMap((action) =>
        this.settingsService
        .deleteCountry(action.countryId)
        .pipe(
            map((response: any) =>
            settingsActions.deleteCountrySuccess()
            ),
            catchError((error: HttpErrorResponse) =>
            of(settingsActions.deleteCountryFailed())
            )
        )
    )
    )
    );

    deleteAdminUser$ = createEffect(() =>
    this.action$.pipe(
    ofType(settingsActions.deleteAdminUsers),
    exhaustMap((action) =>
        this.settingsService
        .deleteAdminUser(action.userId)
        .pipe(
            map((response: any) =>
            settingsActions.deleteAdminUsersSuccess()
            ),
            catchError((error: HttpErrorResponse) =>
            of(settingsActions.deleteAdminUsersFailed())
            )
        )
    )
    )
    );
    
    fetchAdminBanks$ = createEffect(() =>
        this.action$.pipe(
          ofType(settingsActions.fetchAdminBanks),
          exhaustMap((action) =>
            this.settingsService
              .fetchbanks()
              .pipe(
                map((response: any) =>
                    settingsActions.setAdminBanks({ adminBanks: response.data })
                ),
                catchError((error: HttpErrorResponse) =>{
                  this.toastr.error(error.error?.errors, 'An error occured');
                  return of(settingsActions.fetchAdminBanksFailed())
                }
                )
              )
          )
        )
        );
        addBanks$ = createEffect(() =>
        this.action$.pipe(
          ofType(settingsActions.addBank),
          exhaustMap((action) =>
            this.settingsService
              .addbank(action.bankDto)
              .pipe(
                map((response: any) =>
                    settingsActions.addBankSuccess()
                ),
                catchError((error: HttpErrorResponse) =>
                  of(settingsActions.addBankFailed())
                )
              )
          )
        )
        );
        
        deleteBank$ = createEffect(() =>
        this.action$.pipe(
          ofType(settingsActions.deleteBank),
          exhaustMap((action) =>
            this.settingsService
              .deletebank(action.bankId)
              .pipe(
                map((response: any) =>
                    settingsActions.deleteBankSuccess()
                ),
                catchError((error: HttpErrorResponse) =>
                  of(settingsActions.deleteBankFailed())
                )
              )
          )
        )
        );
        
        updateBank$ = createEffect(() =>
        this.action$.pipe(
          ofType(settingsActions.updateBank),
          exhaustMap((action) =>
            this.settingsService
              .updatebank(action.bankDto, action.bankId, action.bankCode)
              .pipe(
                map((response: any) =>
                    settingsActions.updateBankSuccess()
                ),
                catchError((error: HttpErrorResponse) =>
                  of(settingsActions.updateBankFailed())
                )
              )
          )
        )
        );

    constructor(private action$: Actions, private settingsService: SettingsService,
        private toastr: ToastrService
    ) {}

}
