import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import * as fwpAction from './fwp.action';
import { FwpService } from '../_services/fwp.service';
import { IFwpMerchantResp, IFwpOverviewResp } from '../_interfaces/fwp.interface.';

@Injectable({
    providedIn: 'root'
})
export class FwpEffects {

 

    fetchFwpOverview$ = createEffect(() => this.action$.pipe(
        ofType(fwpAction.fetchFwpOverviews),
        exhaustMap(({
            startDate,
            endDate
        }) => this.fwpServices.getOverviewData(startDate, endDate).pipe(
            exhaustMap((response: IFwpOverviewResp) => [
                fwpAction.fetchFwpOverviewsSuccess({
                  overviews:response
                })
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors);
                return of(fwpAction.fetchFwpOverviewsFailed({ message: error.error?.errors, code: error.status }));
            })
        ))
      ));

    fetchFwpMerchant$ = createEffect(() => this.action$.pipe(
        ofType(fwpAction.fetchMerchantLoans),
        exhaustMap(({
            page,
            pageSize
        }) => this.fwpServices.getMerchantLoanData(page, pageSize).pipe(
            exhaustMap((response: IFwpMerchantResp) => [
                fwpAction.fetchMerchantLoansSuccess({
                  merchants:response
                })
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors);
                return of(fwpAction.fetchMerchantLoansFailed({ message: error.error?.errors, code: error.status }));
            })
        ))
      ));






  constructor(private action$: Actions, private fwpServices: FwpService, private toastr: ToastrService, ) {}

}
