import { createReducer, on } from '@ngrx/store';
import * as adminActions from './admin-manage-account.actions';
import { AdminFeeAccount, AdminGlAccounts, AdminSettlementAccounts2, AdminSettlementAccount, AdminNonSettlementAccount, AdminLocalPaymentAccount, AdminAgentAccount } from '../_interfaces/admin-accounts.interface';
import { AdminCustomerUserTransaction } from '../_interfaces/admin-customer-user-transaction.interface';
import { BillerCountry, BillerService, BillerChannel, BillerChannelService } from '../_interfaces/admin-biller-config.interfaces';

export interface AdminManageAccountState {
    adminFeeAccounts : AdminFeeAccount[] | null,
    adminGlAccounts : AdminGlAccounts[] | null,
    adminHoldAccounts : AdminSettlementAccounts2[] | null,
    adminSettlementAccounts : AdminSettlementAccount[] | null,
    adminNonSettlementAccounts : AdminNonSettlementAccount[] | null,
    adminSettlementAccounts2 : AdminSettlementAccounts2[] | null,
    adminLocalPaymentAccounts : AdminLocalPaymentAccount[] | null,
    adminAgentAccounts : AdminAgentAccount[] | null,
    adminSubAgentAccounts : AdminAgentAccount[] | null,
    adminCustomerUsers;
    adminCustomerUserTransactions : AdminCustomerUserTransaction[] | null;

    //Admin biller config
    adminBillerCountries: BillerCountry[] | null;
    adminBillerServices: BillerService[] | null;
    adminBillerChannels: BillerChannel[] | null;
    adminBillerChannelServices: BillerChannelService[] | null;
}

export const initialState: AdminManageAccountState = {
    adminFeeAccounts : null,
    adminSettlementAccounts : null,
    adminNonSettlementAccounts : null,
    adminSettlementAccounts2: null,
    adminHoldAccounts: null,
    adminGlAccounts: null,
    adminLocalPaymentAccounts : null,
    adminAgentAccounts : null,
    adminSubAgentAccounts : null,
    adminCustomerUsers: null,
    adminCustomerUserTransactions :  null,
    adminBillerCountries: null,
    adminBillerServices: null,
    adminBillerChannels:  null,
    adminBillerChannelServices:  null,
};

export const _adminManageAccountReducer = createReducer(initialState,

    on(adminActions.setAdminFeeAccounts, (state, { adminFeeAccounts }) => ({
        ...state, adminFeeAccounts : adminFeeAccounts
    })),

    on(adminActions.setAdminSettlementAccounts, (state, { adminSettlementAccounts }) => ({
        ...state, adminSettlementAccounts : adminSettlementAccounts 
    })),

    on(adminActions.setAdminNonSettlementAccounts, (state, { adminNonSettlementAccounts }) => ({
        ...state, adminNonSettlementAccounts : adminNonSettlementAccounts 
    })),

    on(adminActions.setAdminSettlementAccounts2, (state, { adminSettlementAccounts2 }) => ({
        ...state, adminSettlementAccounts2 : adminSettlementAccounts2
    })),

    on(adminActions.setAdminGlAccounts, (state, { adminGLAccounts }) => ({
        ...state, adminGlAccounts : adminGLAccounts
    })),

    on(adminActions.setAdminHoldAccounts, (state, { adminHoldAccounts }) => ({
        ...state, adminHoldAccounts : adminHoldAccounts
    })),

    on(adminActions.setAdminLocalPaymentAccount, (state, { adminLocalPaymentAccounts }) => ({
        ...state, adminLocalPaymentAccounts : adminLocalPaymentAccounts 
    })),

    on(adminActions.setAdminAgentAccounts, (state, { adminAgentAccounts }) => ({
        ...state, adminAgentAccounts : adminAgentAccounts
    })),

    on(adminActions.setAdminSubAgentAccounts, (state, { adminSubAgentAccounts }) => ({
        ...state, adminSubAgentAccounts : adminSubAgentAccounts
    })),

    on(adminActions.setAdminCustomerUsers, (state, { adminCustomerUsers }) => ({
        ...state, adminCustomerUsers : adminCustomerUsers.data
    })),

    on(adminActions.resetCustomerUserTransaction, (state)=> ({
        ...state, adminCustomerUserTransactions : null
    })),

    on(adminActions.setAdminCustomerUserTransactions, (state, { adminCustomerUserTransactions }) => ({
        ...state, adminCustomerUserTransactions : adminCustomerUserTransactions
    })),

    on(adminActions.fetchBillerCountriesSuccess, (state, { billerCountries }) => ({
        ...state, adminBillerCountries : billerCountries
    })),
    on(adminActions.fetchBillerServicesSuccess, (state, { billerServices }) => ({
        ...state, adminBillerServices : billerServices
    })),
    on(adminActions.fetchBillerChannelsSuccess, (state, { billerChannels }) => ({
        ...state, adminBillerChannels : billerChannels
    })),
    on(adminActions.fetchBillerChannelServicesSuccess, (state, { billerChannelsServices }) => ({
        ...state, adminBillerChannelServices : billerChannelsServices
    })),
);

export const adminManageAccountReducer = (state: AdminManageAccountState, action) => _adminManageAccountReducer(state, action);
