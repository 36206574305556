import { createAction, props } from "@ngrx/store";
import { IFwpOverviewResp } from "../_interfaces/fwp.interface.";



export const fetchFwpOverviews = createAction(
  '[FWP Module] Fetch FWP',
  props<{ startDate?: string, endDate?: string }>()
);

export const fetchFwpOverviewsSuccess = createAction(
  '[FWP Module] Fetch FWP Success',
  props<{ overviews: IFwpOverviewResp}>()
);

export const fetchFwpOverviewsFailed = createAction(
  '[FWP Module] Fetch FWP Failed',
  props<{ message: string, code: number }>()
);

export const fetchMerchantLoans = createAction(
  '[FWP Module] Fetch FWP',
  props<{ page?: any, pageSize?: any }>()
);

export const fetchMerchantLoansSuccess = createAction(
  '[FWP Module] Fetch FWP Success',
  props<{ merchants: any}>()
);

export const fetchMerchantLoansFailed = createAction(
  '[FWP Module] Fetch FWP Failed',
  props<{ message: string, code: number }>()
);
