import { createReducer, on } from '@ngrx/store';
import * as exRatesActions from './rates.actions';
import { AdminExchangeRate } from './rates.interfaces';


export interface ExRatesState {
    exchangeRatesForPayment: AdminExchangeRate[] | null;
    exchangeRatesForPaymentLoading: boolean;
    exchangeRatesForPaymentError: boolean;



    exchangeRatesForTransfer: AdminExchangeRate[] | null;
    exchangeRatesForTransferLoading: boolean;
    exchangeRatesForTransferError: boolean;
}

export const initialState: ExRatesState = {
    exchangeRatesForPayment : null,
    exchangeRatesForPaymentLoading : null,
    exchangeRatesForPaymentError : null,

    exchangeRatesForTransfer : null,
    exchangeRatesForTransferLoading : null,
    exchangeRatesForTransferError : null,
};

export const exRatesReducer = createReducer(initialState,
    on(exRatesActions.fetchAdminExchangeRatesForPayment, (state) => ({
        ...state, exchangeRatesForPayment : null, exchangeRatesForPaymentLoading: true, exchangeRatesForPaymentError: false
    })),

    on(exRatesActions.fetchAdminExchangeRatesForPaymentFailed, (state) => ({
        ...state, exchangeRatesForPayment : null, exchangeRatesForPaymentLoading: false, exchangeRatesForPaymentError: true
    })),
    on(exRatesActions.setAdminExchangeRatesForPayment, (state, {adminExchangeRatesForPayment}) => ({
        ...state,
        exchangeRatesForPayment: adminExchangeRatesForPayment,
        exchangeRatesForPaymentLoading: false,
        exchangeRatesForPaymentError: false
    })),


    on(exRatesActions.fetchAdminExchangeRatesForTransfer, (state) => ({
        ...state, exchangeRatesForTransfer : null, exchangeRatesForTransferLoading: true, exchangeRatesForTransferError: false
    })),

    on(exRatesActions.fetchAdminExchangeRatesForTransferFailed, (state) => ({
        ...state, exchangeRatesForTransfer : null, exchangeRatesForTransferLoading: false, exchangeRatesForTransferError: true
    })),
    on(exRatesActions.setAdminExchangeRatesForTransfer, (state, {adminExchangeRatesForTransfer}) => ({
        ...state,
        exchangeRatesForTransfer: adminExchangeRatesForTransfer,
        exchangeRatesForTransferLoading: false,
        exchangeRatesForTransferError: false
    })),


);
