
        <table id="myTable" class="table specialTable table-bordered table-responsive-md mb-0">
                <thead>
                    <th *ngFor="let column of tableColumns">{{ column }}</th>
                </thead>
            </table>
        <div class="col-md-12 border" style="padding : 5rem; border: 1px solid #dee2e6;">
            <div style="height: 100px; width: 100px; margin: 0 auto">
                    <img src="/assets/images/report.png" height="100"
                         alt="{{'EMPTY_STATE.EMPTY_STATE_TABLE.NO_DATA_FOUND' | translate}}">
                </div>
                <br>
                <h2 class="text-center" style="color : #dee2e6">
                  {{'EMPTY_STATE.EMPTY_STATE_TABLE.NO_DATA_FOUND' | translate}}
                </h2>
                <p class="text-center" style="color : #dee2e6">{{ lead }}</p>

        </div>

