import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AmlService{
    constructor(private http: HttpClient){
    }
    
    // let headers = new HttpHeaders()
    // headers = headers.append('x-api-key', this.apiKey)
    // apiKey = localStorage.getItem('serviceApiKey')


    getRuleSets = () => this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/list-ruleset`).pipe(retry(3))
    
    getRuleSetById = (id) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/get-ruleset/${id}`).pipe(retry(3))

    searchPepAndSanction (payload: any) {
        return this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/peps-and-sanctions`, payload).pipe(retry(3))
    }

    getSanctions = (id: string, check: string) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/flagged-transaction/${id}?sanctionCheck=${check}`).pipe(retry(3))

    createRuleSet = (requestBody) =>  this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/create-ruleset`, requestBody ).pipe(retry(3))

    updateRuleSet = (requestBody, id) =>  this.http.put(`${environment.TRANSACTION_SERVICE_URL}/aml/update-ruleset/${id}`, requestBody ).pipe(retry(3))

    deleteRuleSet = (id) => this.http.delete(`${environment.TRANSACTION_SERVICE_URL}/aml/delete-ruleset/${id}`).pipe(retry(3))

    //getCoporateRule = () => this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/corporate-rule`).pipe(retry(3))

    getCoporateRule = (page, pageSize, accountNumber) => {
        let params = new HttpParams();
        params = params.append('page', page)
        params = params.append('pageSize', pageSize)
        params = params.append('accountNumber', accountNumber)
        return this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/corporate-rule`, { params }).pipe(retry(3))
    }

    getCoporateRuleById = (id) => this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/corporate-rule/${id}`).pipe(retry(3))

    createCoporateRule = (requestBody) => this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/corporate-rule`, requestBody).pipe(retry(3))

    updateCoporateRule = (requestBody, id) => this.http.put(`${environment.TRANSACTION_SERVICE_URL}/aml/corporate-rule/${id}`, requestBody).pipe(retry(3))

    deleteCoporateRule = (id) => this.http.delete(`${environment.TRANSACTION_SERVICE_URL}/aml/corporate-rule/${id}`).pipe(retry(3))
    
    getRules = () =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/list-rules`).pipe(retry(3))
    
    getRuleById = (id) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/get-rule/${id}` ).pipe(retry(3))
    
    deleteRule = (id) =>  this.http.delete(`${environment.TRANSACTION_SERVICE_URL}/aml/delete-rule/${id}` ).pipe(retry(3))

    createRule = (requestBody, rulesetId) =>  {
        let params = new HttpParams();
        params = params.append('rulesetId', rulesetId)
       return this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/create-rule`, requestBody, {params} ).pipe(retry(3))
    }

    updateRule = (requestBody, id) => this.http.put(`${environment.TRANSACTION_SERVICE_URL}/aml/update-rule/${id}`, requestBody ).pipe(retry(3))

    getFlaggedTransactions = (data: any) =>  {
      let params = new HttpParams();      
      params = params.append('page', data.page)          
      params = params.append('pageSize', data.pageSize)
      if(data.startDate)
        params = params.append('startDate', data.startDate)
      if(data.endDate)
         params = params.append('endDate', data.endDate)
     if (data.srcAccId)
         params = params.append('srcAccId', data.srcAccId)
     if (data.desAccId)
         params = params.append('desAccId', data.desAccId)
     if (data.channelCode)
         params = params.append('channelCode', data.channelCode)
     if (data.desInstId)
         params = params.append('desInstId', data.desInstId)
     if (data.srcInstId)
         params = params.append('srcInstId', data.srcInstId)
     if (data.transactionTypeCode)
         params = params.append('transactionTypeCode', data.transactionTypeCode)
     if (data.transactionRef)
         params = params.append('transactionRef', data.transactionRef)
      return  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/flagged-transactions`, {params}).pipe(retry(3))
    }

    getFlaggedTransactionTrail=(data:any, id, ruleid)=>{
      let params = new HttpParams();      
      params = params.append('page', data.page)          
      params = params.append('pageSize', data.pageSize)
      return  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/flagged-trailing-transactions/${id}/${ruleid}`, {params}).pipe(retry(3))
    }

    getLoggedTransactions = (data: any) =>  {
      let params = new HttpParams();      
      params = params.append('page', data.page)          
      params = params.append('pageSize', data.pageSize)
      if(data.tartDate)
        params = params.append('startDate', data.startDate)
      if(data.endDate)
        params = params.append('endDate', data.endDate)
        if (data.srcAccId)
            params = params.append('srcAccId', data.srcAccId)
        if (data.desAccId)
            params = params.append('desAccId', data.desAccId)
        if (data.channelCode)
            params = params.append('channelCode', data.channelCode)
        if (data.desInstId)
            params = params.append('desInstId', data.desInstId)
        if (data.srcInstId)
            params = params.append('srcInstId', data.srcInstId)
        if (data.transactionTypeCode)
            params = params.append('transactionTypeCode', data.transactionTypeCode)
        if (data.transactionRef)
            params = params.append('transactionRef', data.transactionRef)
      return  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/logs`, {params}).pipe(retry(0))
    }

    getChannels = () =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/list-channels`).pipe(retry(3))

    getChannelById = (id) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/get-channel/${id}`).pipe(retry(3))

    deleteChannel = (id) =>  this.http.delete(`${environment.TRANSACTION_SERVICE_URL}/aml/delete-channel/${id}`).pipe(retry(3))

    createChannel = (requestBody) =>  this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/create-channel`, requestBody).pipe(retry(3))

    updateChannel = (requestBody, id) =>  this.http.put(`${environment.TRANSACTION_SERVICE_URL}/aml/update-channel/${id}`, requestBody).pipe(retry(3))

    getTransactionTypes = () =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/list-transaction-types`).pipe(retry(3))

    getTransactionTypeById = (id) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/get-transaction-type/${id}`).pipe(retry(3))

    deleteTransactionType = (id) =>  this.http.delete(`${environment.TRANSACTION_SERVICE_URL}/aml/delete-transaction-type/${id}`).pipe(retry(3))

    createTransactionType = (requestBody) =>  this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/create-transaction-type`, requestBody).pipe(retry(3))

    updateTransactionType = (requestBody, id) =>  this.http.put(`${environment.TRANSACTION_SERVICE_URL}/aml/update-transaction-type/${id}`, requestBody).pipe(retry(3))

    getFIs = () =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/list-finance-institutions`).pipe(retry(3))

    getFIById = (id) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/get-finance-institution/${id}`).pipe(retry(3))

    getFlaggedTransactionById = (id) =>  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/flagged-transaction/${id}`).pipe(retry(3))

    deleteFI = (id) =>  this.http.delete(`${environment.TRANSACTION_SERVICE_URL}/aml/delete-finance-institution/${id}`).pipe(retry(3))

    createFI = (requestBody) =>  this.http.post(`${environment.TRANSACTION_SERVICE_URL}/aml/create-finance-institution`, requestBody).pipe(retry(3))

    updateFI = (requestBody, id) =>  this.http.put(`${environment.TRANSACTION_SERVICE_URL}/aml/update-finance-institution/${id}`, requestBody).pipe(retry(3))

    getAuditTrail = (page,pageSize) =>  {
        let params = new HttpParams();
        params = params.append('page', page)
        params = params.append('pageSize', pageSize)
        return  this.http.get(`${environment.TRANSACTION_SERVICE_URL}/aml/actions`,{params}).pipe(retry(3))
    }


    
}