import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name : 'decimate'
})
export class DecimatePipe implements PipeTransform {
    transform(value: string){
        return parseFloat(value).toFixed(2);
    }
}
