import { createReducer, on } from '@ngrx/store';
import { AdminUsers } from '../../activity-log/_interfaces/admin.interface';
import { setAdminUsers } from './settings.actions';

export interface SettingsState {
    adminUsers: AdminUsers[] | null;
}

export const initialState: SettingsState = {
    adminUsers : null
};

export const _settingsReducer = createReducer(initialState,

    on(setAdminUsers, (state, { adminUsers }) => ({
        ...state, adminUsers
    }))
);

export const settingsReducer = (state: SettingsState, action) => _settingsReducer(state, action);
