import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateIssuerUserDto } from '../_dtos/create-issuer-user.dto';
import { AppLocalStorage } from 'src/app/storage-manager/local-storage';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private appLocalStorage: AppLocalStorage,private sharedService: SharedService) { }

  getIssuerUsers() {
    let user = this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG')
    if(!user.issuer){
      return this.http.get(`${environment.ADMIN_REPORT_URL}/admin/allusers?isocode=${this.sharedService.getSelectedCountryFromSession()}`);
    }

    else
     return this.http.get(`${environment.ADMIN_REPORT_URL}/user/issuer-user`);
  }

  createIssuerUser(createIssuerUserDto: CreateIssuerUserDto) {
    let user = this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG')
    if(!user.issuer)
      return this.http.post(`${environment.ADMIN_REPORT_URL}/admin/createUser`, createIssuerUserDto);
    else
      return this.http.post(`${environment.ADMIN_REPORT_URL}/user/issuer-user`, createIssuerUserDto);
  }

  deleteIssuerUser(issuerUserId: string) {
    let user = this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG')
    if(!user.issuer)
      return  this.http.delete(`${environment.ADMIN_REPORT_URL}/admin/${issuerUserId}`);
    else
      return this.http.delete(`${environment.ADMIN_REPORT_URL}/user/issuer-user/${issuerUserId}`);
  }

  updateIssuerUser(issuerUserId: string, updateIssuerUserDto: CreateIssuerUserDto) {
    let user = this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG')
    if(!user.issuer)
      return this.http.patch(`${environment.ADMIN_REPORT_URL}/admin/${issuerUserId}`, updateIssuerUserDto);
    else
      return this.http.put(`${environment.ADMIN_REPORT_URL}/user/issuer-user/${issuerUserId}`, updateIssuerUserDto);
  }

}
