import { createAction, props } from '@ngrx/store';
import { CreateIssuerUserDto } from '../_dtos/create-issuer-user.dto';
import { IssuerUser } from '../_interfaces/issuer-user.interface';

export const fetchIssuerUsers = createAction(
  '[Users Page] fetch issuer users'
);

export const fetchIssuerUsersSuccess = createAction(
  '[Users Page] fetch issuer users successful',
  props<{ users: IssuerUser[] }>()
);

export const fetchIssuerUsersFailed = createAction(
  '[Users Page] fetch issuer users failed'
);

export const createIssuerUser = createAction(
  '[Users Page] create issuer user',
  props<{ createIssuerUserDto: CreateIssuerUserDto }>()
);

export const createIssuerUserSuccess = createAction(
  '[Users Page] create issuer user successful',
  props<{ newUser: IssuerUser }>()
);

export const createIssuerUserFailed = createAction(
  '[Users Page] create issuer user failed'
);

export const deleteIssuerUser = createAction(
  '[Users Page] delete issuer user',
  props<{ issuerUserId: string }>()
);

export const deleteIssuerUserSuccess = createAction(
  '[Users Page] delete issuer user successful'
);

export const deleteIssuerUserFailed = createAction(
  '[Users Page] delete issuer user failed'
);

export const updateIssuerUser = createAction(
  '[Users Page] update issuer user',
  props<{ issuerUserId: string, updateIssuerUserDto: CreateIssuerUserDto }>()
);

export const updateIssuerUserSuccess = createAction(
  '[Users Page] update issuer user successful'
);

export const updateIssuerUserFailed = createAction(
  '[Users Page] update issuer user failed'
);
