import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    constructor(private authService: AuthService, private toastService: ToastrService ){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const token = localStorage.getItem('access_token');
        if (token && !req.url.includes('aml')){
            const clonedReq = req.clone({
                headers : req.headers.set('Authorization', 'Bearer ' + token)
            });
            return next.handle(clonedReq).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (
                        (
                            error.error.message === 'Unauthorized'
                            ) && (error.error.statusCode === 401) ||  (
                                error.error.errors === 'Token is not valid'
                                ) && (error.status === 400)){
                                this.authService.logOut();
                    } 
                    return throwError(error);
                })
              );
        }
        else if (token && req.url.includes('aml')){
            const xapikey = localStorage.getItem('serviceApiKey')
            // console.log("xapikey-",xapikey)
            const clonedReq = req.clone({
              headers: req.headers
                .set('Authorization', 'Bearer ' + token)
                // .set('x-api-key', localStorage.getItem('serviceApiKey'))
                .set(
                  'x-api-key',
                  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkIjoxNjU0NzYwNjE0MjUwLCJlbnYiOiJkZXZlbG9wbWVudCIsImlkIjoiNjJhMWE0YTY4MDRmN2EwMDEzNzVkZGMxIiwidmVyc2lvbiI6MCwiaWF0IjoxNjU0NzYwNjE0LCJleHAiOjE2NTQ3Njc2MTR9.xo5LLqxhakDwknrBPc-guLyeE97UBaLupjSXn4rNfM4'
                )
            });
            return next.handle(clonedReq).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (
                        (
                            error?.error?.message === 'Unauthorized'
                            ) && (error?.error?.statusCode === 401)){
                                this.authService.logOut();
                    }
                    return throwError(error);
                })
              );
        }
        else{
            return next.handle(req).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (
                        (
                            error.error.message === 'Unauthorized'
                            ) && (error.error.statusCode === 401)){
                                this.authService.logOut();
                    }
                    return throwError(error);
                })
              );
        }
    }
}
