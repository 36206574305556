import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthService } from '../modules/auth/services/auth.service';
import { AdminCountry, AuthUser } from '../modules/auth/_state/auth.interfaces';
import {
  AppState,
  selectAdminCountries,
  selectAdminCountriesError,
  selectAdminCountriesLoading,
  selectAuthenticatedUser
} from '../reducers';
import { AppLocalStorage } from '../storage-manager/local-storage';
import { AppSessionStorage, SESSION_KEY } from '../storage-manager/session-storage';
import * as authActions from '../modules/auth/_state/auth.actions';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Issuer, SubIssuer } from '../modules/issuers/_state/issuers.interfaces';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared/services/shared.service';
import { take } from 'rxjs/operators';
import { PermissionService } from '../shared/services/permission.service';
import { UserPermissions } from '../modules/users/_interfaces/issuer-user.interface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit, OnDestroy {

  constructor(
    // private dashboardService : DashboardService,
    private authService: AuthService,
    public router: Router,
    private store: Store<AppState>,
    private appStorage: AppSessionStorage,
    private appLocalStorage: AppLocalStorage,
    private toast: ToastrService,
    private http: HttpClient,
    private sharedService: SharedService,
    private permissionService: PermissionService,
    private translate: TranslateService
  ) { }
  get issuerCode(){return this.issuerForm.get('issuerCode'); }
  user: Observable<any>;
  routeLoading = false;
  selectedCountry = 'NG';
  selectedCountryObj: AdminCountry;

  logoUrl = '/assets/images/mainlogo.png';
  issuers: Issuer[];
  selectedIssuer: Issuer;

  countries$ = this.store.pipe(select(selectAdminCountries));
  countriesLoading$ = this.store.pipe(select(selectAdminCountriesLoading));
  countriesError$ = this.store.pipe(select(selectAdminCountriesError));

  issuerForm = new FormGroup({
    issuerCode: new FormControl('', Validators.required)
  });
  dashboardConfig;


  authUser: AuthUser;
  issuerLogo: string;
  subIssuers: SubIssuer[];
  selectedSubIssuer: SubIssuer;
  selectedFlag: string;
  countryFlags = [
    {iso2: 'CN', name: 'China', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg'},
    {iso2: 'RW', name: 'Rwanda', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg'},
    {iso2: 'US', name: 'United States', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'},
    {iso2: 'GB', name: 'United Kingdom', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'},
    {iso2: 'IN', name: 'India', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg'},
    {iso2: 'ZA', name: 'South Africa', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg'},
    {iso2: 'GH', name: 'Ghana', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg'},
    {iso2: 'AE', name: 'United Arab Emirates', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg'},
    {iso2: 'NG', name: 'Nigeria', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg'},
    {iso2: 'CM', name: 'Cameroon', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg'},
    {iso2: 'CI', name: 'Côte d\'Ivoire', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg'},
    {iso2: 'LR', name: 'Liberia', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg'},
    {iso2: 'JP', name: 'Japan', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg'},
  ];

  issuerRefreshSubscription: Subscription
  subIssuerRefreshSubscription: Subscription

  ngOnInit(): void {
    this.dashboardConfig = this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG');
    this.issuerLogo = `${environment.ADMIN_AUTH_URL}${this.appLocalStorage.getFromStorage('logo')}`;
    this.getCountries();
    this.initCountryForDashboard();
    this.issuerRefreshSubscription = this.sharedService.issuerRefreshSubject.subscribe(
      res => {
        if(res == true){
          this.getIssuers()
        }
      }
    )
    this.subIssuerRefreshSubscription = this.sharedService.subIssuerRefreshSubject.subscribe(
      res => {
        if(res == true){
          this.getSubIssuers()
        }
      }
    )
    if (this.dashboardConfig.type === 'SUPER_ADMIN' || this.dashboardConfig.type === 'COUNTRY_ADMIN' && this.dashboardConfig.issuer?.type !== 'issuer'){
      this.getIssuers();
    }  else if (this.dashboardConfig.issuer?.type === 'issuer'){
      this.getSubIssuers();
    }
    this.router.events.subscribe((event: Event) => {
      switch (true){
        case event instanceof NavigationStart :
          this.routeLoading = true;
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          setTimeout(() => {
            this.routeLoading = false;
          }, 500);
          break;
        default:
          setTimeout(() => {
            this.routeLoading = true;
          }, 500);
          break;

      }
    });
    this.countries$.subscribe(contris => {
      if (contris){
        this.selectedCountryObj = contris.filter(e => e.iso_code_2 === this.selectedCountry)[0];
        this.store.dispatch(authActions.setAdminSelectedCountry({ adminCountry : this.selectedCountryObj}));
        this.selectedFlag = this.countryFlags.find(flagObj => flagObj.iso2 == this.selectedCountryObj.iso_code_2).flag
        this.getUserServices()
      }
    });
    // this.issuers$.pipe(take(2)).subscribe( issuers => {
    //   this.issuers = issuers;
    //   if(this.issuers){
    //     this.selectedIssuer = this.issuers.find(issuer => issuer.issuerCode == this.appLocalStorage.getFromStorage('issuer'))
    //   }
    // })
  }

 ngOnDestroy(): void {
   if(this.issuerRefreshSubscription){
    this.issuerRefreshSubscription.unsubscribe()
   }
   if(this.subIssuerRefreshSubscription){
    this.subIssuerRefreshSubscription.unsubscribe()
   }
 }
  isIssuer(): boolean {
    return this.permissionService.isIssuer()
  }

  canViewCustomers(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_CUSTOMER) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_MERCHANTS) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_TRANSACTIONS);
  }

  canViewKyc(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_KYC_RECORDS) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_FAILED_REGISTRATIONS) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_BLACKLIST_RECORDS);
  }

  canViewCountryLimit(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_COUNTRY_LIMITS);
  }

  canViewExchangeRates(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_PAYMENT_RATES) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_TRANSFER_RATE);
  }

  canViewFees(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_MERCHANT_FEES) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_TRANSFER_FEES) ||
      this.permissionService.canAccess(UserPermissions.CAN_VIEW_PRODUCT_FEES);
  }

  canViewIssuers(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_ISSUERS);
  }

  canViewServices(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_SERVICES);
  }

  canViewTransactionMonitoring(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_TRANSACTION_MONITORING);
  }

  canViewFwp(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_FWP);
  }

  canViewManageAccounts(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_MANAGE_ACCOUNTS);
  }

  canViewOrganisations(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_ORGANISATIONS);
  }

  canViewIssuerUsers(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_USERS);
  }

  canViewActivityLogs(): boolean {
    return this.permissionService.canAccess(UserPermissions.CAN_VIEW_ACTIVITY_LOGS);
  }

  logOut(){
    this.authService.logOut();
  }

  changeCountryForDashboard(){
    this.selectedCountry = this.selectedCountryObj.iso_code_2;
    this.store.dispatch(authActions.setAdminSelectedCountry({ adminCountry : this.selectedCountryObj}));
    this.setDisplayRecordsByCountry();
    window.location.reload();
  }

  getCountries(){
    this.store.dispatch(authActions.fetchAdminCountries());
  }


  setDisplayRecordsByCountry(){
    this.appLocalStorage.storeToStorage(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO, this.selectedCountryObj.iso_code_2);
    this.appLocalStorage.storeToStorage(SESSION_KEY.DBOARD_COUNTRY_CODE, this.selectedCountryObj.country_code);
    this.appLocalStorage.storeToStorage(SESSION_KEY.DBOARD_CURRENCY_CODE, this.selectedCountryObj.currency_code);
  }

  // checkOnStorageChange(){
  //   window.addEventListener('storage', () => {
  //     this.updateDashboardConfigOfAuthUser();
  //   });
  // }

  displayCountryForSuperAdmin(){

    return this.appLocalStorage.isStoredInSession('DASHBOARD_CONFIG') &&
    this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG').level > 1 &&
    this.appLocalStorage.getFromStorage('DASHBOARD_CONFIG').type === 'SUPER_ADMIN';
  }


  initCountryForDashboard(){
    this.selectedCountry = this.authService.getSelectedCountryFromSession();
    this.setLogoUrl();
  }

  setLogoUrl(){
    switch (this.selectedCountry){
      case 'ZA':
        this.logoUrl = '/assets/images/post-sa.png';
        break;

      case 'CM':
        this.logoUrl = '/assets/images/campost.png';
        break;

      case 'CD':
        this.logoUrl = '/assets/images/DRC 2-2.png';
        break;

      default :
        this.logoUrl = '/assets/images/XCel Logo.svg';
        break;

    }

  }

  getBackgroundColor(){
    if (this.selectedCountry === 'ZA'){
      return '#431a1e !important;';
    }else{
      return '#2b3c4e !important;';
    }
  }

  compareCountries(a: AdminCountry, b: AdminCountry){
    return (a && b) ? a._id === b._id : false;
  }



  // issuers$ = this.store.pipe(select(selectIssuers))

  getIssuers(){
    let params = new HttpParams();
    params = params.append('issuerCode', '');
    params = params.append('isoCode2', this.sharedService.getSelectedCountryFromSession());
    return this.http.get(`${environment.ADMIN_AUTH_URL}/user/issuer`, {params}).subscribe((res: any) => {
      this.issuers = res.data;
      this.selectedIssuer = this.issuers.find(issuer => issuer.issuerCode === this.appLocalStorage.getFromStorage('issuer'));
    });
}
  getSubIssuers(){
    let params = new HttpParams();
    params = params.append('issuerCode', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('subIssuerCode', this.sharedService.getSelectedSubIssuerCodeFromSession());
    params = params.append('isoCode2', this.sharedService.getSelectedCountryFromSession());
    return this.http.get(`${environment.ADMIN_AUTH_URL}/user/sub-issuer`, {params}).subscribe((res: any) => {
      this.subIssuers = res.data;
      this.selectedSubIssuer = this.subIssuers.find(issuer => issuer.subIssuerCode === this.appLocalStorage.getFromStorage('sub_issuer'));
    });
}

  // getIssuers = () => {

  //   this.store.dispatch(issuerActions.fetchIssuers({subIssuerCode: '', orgCode: '', orgBrCode: ''}))
  // }

  selectAndStoreIssuer = () => {
    this.selectedIssuer = this.issuers.find(issuer => issuer.issuerCode === this.issuerCode.value);
    if (this.selectedIssuer){
     this.appLocalStorage.storeToStorage('issuer', this.issuerCode.value);
     window.location.reload();
   } else{
      this.toast.error(this.translate.instant('MENU.CHECK_CODE'), this.translate.instant('MENU.NO_ISSUER'));
      this.selectedIssuer = this.issuers.find(issuer => issuer.issuerCode === this.appLocalStorage.getFromStorage('issuer'));
   }
  }
  clearIssuer = () => {
    this.appLocalStorage.storeToStorage('issuer', '');
    window.location.reload();
  }
  selectAndStoreSubIssuer = () => {
    this.selectedSubIssuer = this.subIssuers.find(issuer => issuer.subIssuerCode === this.issuerCode.value);
    if (this.selectedSubIssuer){
     this.appLocalStorage.storeToStorage('sub_issuer', this.issuerCode.value);
     window.location.reload();
   } else {
      this.toast.error(this.translate.instant('MENU.CHECK_CODE'), this.translate.instant('MENU.NO_SUB_ISSUER'));
      this.selectedSubIssuer = this.subIssuers.find(issuer => issuer.subIssuerCode === this.appLocalStorage.getFromStorage('sub_issuer'));
   }
  }
  clearSubIssuer = () => {
    this.appLocalStorage.storeToStorage('sub_issuer', '');
    window.location.reload();
  }

  loadingServices: boolean
  services: Offering[]
  getUserServices = () => {
    this.services = null
    this.loadingServices = true
    this.sharedService.getServices().subscribe(
      (res: {data:Services}) => {
        if(res.data.offerings.length){
          if(res.data.apiKey){
            //console.log('apiKeyFromService', res.data.apiKey);
            localStorage.setItem('serviceApiKey', res.data.apiKey)
          }
          this.loadingServices = false
          this.services = res.data.offerings
          this.services = this.services.map(service => {
            if(service.name == 'Xcel AML Service')
              service.route = '/dashboard/aml/rules-sets'
              return service
          })
        }
      },
      err => {
        this.loadingServices = false
      }
    )
  }
}


export interface Offering {
  name: string
  _id: string
  description: string
  active: boolean
  route: string
  sandboxBaseUrl: string,
  prodBaseUrl: string,
  documentationUrl: string,
  details: {
    sandboxKey: string
    prodKey: string
  },
}

export interface Services{
  apiKeyVersion: number
  offerings: Offering[] 
  _id: string
  name:  string
  description: string
  country: string
  createdAt: string
  updatedAt: string
  __v: number
  apiKey: string
  schemeCode: string
}