import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, retry } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AppLocalStorage } from 'src/app/storage-manager/local-storage';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/services/auth.service';
import {
    ExternalFundTransfer,
    InternalFundTransfer,
    MerchantAccountOpeningRequestDto,
    TransactionParamsDto,
    UserAccountOpeningRequestDto
} from './_state/customers.dtos';
import { AdminCustomerTransaction, AdminRefundAfrica, SearchData, FilterType } from './_state/customers.interfaces';
import { Observable } from 'rxjs';
import { SESSION_KEY } from 'src/app/storage-manager/session-storage';
import { AuthHeaderService } from 'src/app/shared/services/authheaderservice';

export interface XcelHttpResponse<T> {
    status: string | boolean;
    success: string | boolean;
    message: string;
    data: T;
}
export interface OtpDl {
    otpSid: string;
    dlCode: string;
}

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    DEFAULT_COUNTRY_CODE = '234';
    DEFAULT_COUNTRY_ISO_2 = 'NG';
    DEFAULT_CURRENCY_CODE = 'NGN';

    constructor(
        private authHeaderService: AuthHeaderService,
        private http: HttpClient,
        private router: Router,
        private appStorage: AppLocalStorage,
        private authService: AuthService,
        private sharedService: SharedService
    ) { }

    generateOtpAndDlCode(payload: TransactionParamsDto, country?: string, userId?: string) {
        return this.http.post<XcelHttpResponse<OtpDl>>(
            `${environment.ADMIN_AUTH_URL}/user/generate/dl-authorization?toSuperAdmin=true&country=${country}`,
            payload
        );
    }
    // generateOtpAndDlCode(payload: TransactionParamsDto, country?: string, userId?: string) {
    //     return this.http.post<XcelHttpResponse<OtpDl>>(
    //         `${environment.ADMIN_AUTH_URL}/user/generate/dl-authorization?toSuperAdmin=true&country=${country}&userId=${userId}`,
    //         payload
    //     );
    // }

    runMomoTsq(reference: string, ext_reference: string) {
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
        .set('X-Auth-Signature', xAuthHeader)
        .set('X-APP-ID', `${environment.X_APP_ID}`)
        .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

        return this.http.get<XcelHttpResponse<any>>(
            `${environment.XAS_URL}/transactions/fundgate/momo/tsq/${reference}/${ext_reference}`, { headers }
        );
    }

    runTsq(reference: string) {
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
            .set('X-Auth-Signature', xAuthHeader)
            .set('X-APP-ID', `${environment.X_APP_ID}`)
            .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

        return this.http.get<XcelHttpResponse<any>>(
            `${environment.XAS_URL}/transactions/tsq/${reference}`, { headers }
        );
    }

    fetchBroadcasts = (page: number, limit: number, search?: string): Observable<any> => {
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
        .set('X-Auth-Signature', xAuthHeader)
        .set('X-APP-ID', `${environment.X_APP_ID}`)
        .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        
        let url = `${environment.XAS_URL}/notifications/blast?page=${page}&limit=${limit}`;

        if(search) {
          url = url + `&search=${search}`;
        }
        
        return this.http.get(url, {headers});
    }

    fetchTopics = (): Observable<any> => {
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
        .set('X-Auth-Signature', xAuthHeader)
        .set('X-APP-ID', `${environment.X_APP_ID}`)
        .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        
        let url = `${environment.XAS_URL}/notifications/topics?country=${this.sharedService.getSelectedCountryFromSession()}`;

        return this.http.get(url, {headers});
    }

    createBlast = (payload: any): Observable<any> => {
        const xAuthHeader = this.authHeaderService.generateAuthHeader(payload);
        const headers = new HttpHeaders()
        .set('X-Auth-Signature', xAuthHeader)
        .set('X-APP-ID', `${environment.X_APP_ID}`)
        .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        
        let url = `${environment.XAS_URL}/notifications/save/blast`;
        
        return this.http.post(url, payload, {headers});
    }

    updateBlast = (payload: any, id: any): Observable<any> => {
        const xAuthHeader = this.authHeaderService.generateAuthHeader(payload);
        const headers = new HttpHeaders()
        .set('X-Auth-Signature', xAuthHeader)
        .set('X-APP-ID', `${environment.X_APP_ID}`)
        .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        
        let url = `${environment.XAS_URL}/notifications/save/blast/${id}`;
        
        return this.http.put(url, payload, {headers});
    }

    sendBlast = (id: string): Observable<any> => {
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
        .set('X-Auth-Signature', xAuthHeader)
        .set('X-APP-ID', `${environment.X_APP_ID}`)
        .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        
        let url = `${environment.XAS_URL}/notifications/send/blast?message_id=${id}`;
        
        return this.http.get(url, {headers});
    }

    reverseTransaction(payload: any) {
        const xAuthHeader = this.authHeaderService.generateAuthHeader(payload);
        const headers = new HttpHeaders()
            .set('X-Auth-Signature', xAuthHeader)
            .set('X-APP-ID', `${environment.X_APP_ID}`)
            .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

        return this.http.post<XcelHttpResponse<any>>(
            `${environment.XAS_URL}/admin/transactions/refund`,
            payload, { headers }
        );
    }

    fetchMnos(){
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
            .set('X-Auth-Signature', xAuthHeader)
            .set('X-APP-ID', `${environment.X_APP_ID}`)
            .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        return this.http.get(`${environment.XAS_URL}/accounts/momo-mnos/${this.sharedService.getSelectedCountryFromSession()}`, {headers}).pipe(retry(3))
    }

    fetchAuthorizers(): Observable<any> {
        return this.http.get(
            `${environment.ADMIN_AUTH_URL}/user/authorizers`,
        );
    }

    updateAdminMerchant(merchantId, requestBody) {
        return this.http.put(`${environment.API_URL}/business-api/updateUser/${merchantId}`, requestBody)
    }
    updateMerchantPermission(merchantId, requestBody) {
        return this.http.put(`${environment.API_URL}/xas/v1//admin/permissions/${merchantId}`, requestBody)
    }

    fetchUtilityTypes(): Observable<any> {
        return this.http.get(`${environment.API_URL}/transactions-service/utility-type`).pipe(retry(3))
    }

    fetchImageUrl(file: any): Observable<any> {
        let form = new FormData();
        form.append('image', file);
        return this.http.post(`${environment.API_URL}/transactions-service/bucket/upload`, form).pipe(retry(3))
    }
    //Merchant Limit level

    setAdminMerchantLevel(requestBody) {
        return this.http.put(`${environment.API_URL}/business-api/merchant/account/limit`, requestBody)
    }

    updateUtilityMerchant = (requestBody, id): Observable<any> => this.http.put(`${environment.API_URL}/transactions-service/merchant/${id}`, requestBody).pipe(retry(3))

    postFundTransfer(payload: InternalFundTransfer | ExternalFundTransfer) {
        return this.http.post(`${environment.API_URL}/business-api/psp/admin/payment`, payload);
    }
    postRefundTransfer(payload: AdminRefundAfrica) {
        return this.http.post(`${environment.API_URL}/business-api/psp/admin/refund`, payload);
    }
    getAllLimits() {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(`${environment.BUSINESS_API_URL}/limits/${this.sharedService.getSelectedCountryCodeFromSession()}`, { params });
    }

    getUserFilteredByTransactions(searchData: SearchData, flag) {
        let thresholdCategory = 'inbound_threshold';
        switch (flag) {
            case 'received':
                thresholdCategory = 'inbound_threshold';
                break;
            case 'sent':
                thresholdCategory = 'outbound_threshold';
                break;
            default:
                thresholdCategory = 'inbound_threshold';
        }
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(`${environment.API_URL}/reports/${thresholdCategory}/${searchData.searchValue}/${searchData.startDate}/${searchData.endDate}/${this.sharedService.getSelectedCountryFromSession()}`, { params });
    }

    getUserFilteredByRegistrationDate(searchData: SearchData) {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(`${environment.API_URL}/reports/users/${searchData.startDate}/${searchData.endDate}/${this.sharedService.getSelectedCountryFromSession()}`, { params }).pipe(retry(3));
    }

    getUserFilteredByMoneySentByCountry(searchData: SearchData, flag) {
        let amountCategory = 'inbound_over_amount';
        switch (flag) {
            case 'received':
                amountCategory = 'inbound_over_amount';
                break;
            case 'sent':
                amountCategory = 'outbound_over_amount';
                break;
            default:
                amountCategory = 'inbound_over_amount';
        }
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(`${environment.API_URL}/reports/${amountCategory}/${searchData.searchValue}/${searchData.countryCode}/${searchData.startDate}/${searchData.endDate}/${this.sharedService.getSelectedCountryFromSession()}`, { params }).pipe(retry(3));
    }
    getAllUsers(page, pageSize, search) {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        params = params.append('page', page)
        params = params.append('limit', pageSize)
        params = params.append('search', search)
        return this.http.get(
            `${environment.API_URL}/business-api/reports/users/all/all/${this.sharedService.getSelectedCountryFromSession()}`,
            { params }
        ).pipe(retry(3));
    }

    getAllUsersNew(page, pageSize, search: string, isCompact, appNames?: string[], searchData?: any) {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        params = params.append('page', page);
        params = params.append('limit', pageSize);
        params = params.append('search', search);
    
        if (this.sharedService.getSelectedCountryFromSession() === "GB") {
            params = params.append('compact', isCompact);
        }
    
        if (appNames?.length > 0) {
            appNames.forEach(appName => {
                params = params.append('app_name', appName);
            });
        }
    
        const baseUrl = `${environment.XAS_URL}/accounts/${this.sharedService.getSelectedCountryFromSession()}`;
        
        if (searchData) {
            return this.http.get(
                `${baseUrl}?start_date=${searchData.startDate}&end_date=${searchData.endDate}`,
                { params }
            ).pipe(retry(3));
        } else {
            return this.http.get(baseUrl, { params }).pipe(retry(3));
        }
    }

    getAllPendingTransactions(page, pageSize, search, empty_kyc) {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        params = params.append('page', page)
        params = params.append('limit', pageSize)
        params = params.append('search', search)
        if (this.sharedService.getSelectedCountryFromSession() == "GB") {
            params = params.append('empty_kyc', empty_kyc)
        }
        return this.http.get(
            `${environment.XAS_URL}/accounts/${this.sharedService.getSelectedCountryFromSession()}`,
            { params }
        ).pipe(retry(3));
    }


    synchronizeBalance(accountId) {
        return this.http.get(`${environment.API_URL}/statement-sync/sync/${accountId}`).pipe(retry(3));
    }

    // - Activate/Deactivate Card (UK Card currently)
    adminCustomerUserActivationCardChange(setToActivate: boolean, cardId: string) {
        if (setToActivate) {
            return this.http.post(`${environment.API_URL}/psp/card/${cardId}/unfreeze`, {});
        } else {
            return this.http.post(`${environment.API_URL}/psp/card/${cardId}/suspend`, {});
        }
    }

    // - Destory Card (UK Card currently)
    adminCustomerUserDestroy(cardId: string) {
        return this.http.delete(`${environment.API_URL}/psp/card/${cardId}/close`);
    }

    // - Close Account (UK Account currently)
    adminCustomerUserCloseAcountChange(setToClose: boolean, accountId: string) {
        if (setToClose) {
            return this.http.delete(`${environment.API_URL}/psp/account/${accountId}/close`);

        } else {
            return this.http.post(`${environment.API_URL}/psp/account/${accountId}/close`, {});

        }
    }


    // - User Trust Toggle
    adminCustomerUserTrustChange(setToTrusted: boolean, walletNo: string, schemeCode: string) {
        if (setToTrusted) {
            return this.http.post(`${environment.API_URL}/source/wallet/trust/${walletNo}/${schemeCode}`, {});
        } else {
            return this.http.delete(`${environment.API_URL}/source/wallet/trust/${walletNo}/${schemeCode}`);
        }
    }

    // page: number, limit: number,
    userTransactionByWalletId(walletNo: string, start_date: string, end_date: string, searchKey: string): Observable<any> {
        let url: string = `${environment.API_URL}/xas/v1/admin/transactions/${this.sharedService.getSelectedCountryFromSession()}/${walletNo}?start_date=${start_date}&end_date=${end_date}`;
        // ?page=${page}&limit=${limit}
        if (searchKey) {
            url = `${url}&search=${searchKey}`;
        }
        return this.http.get(url);
    }

    // - User International Transfer
    adminCustomerUserInternationTransferOptionChange(walletNo: string, schemeCode: string) {
        return this.http.post(`${environment.API_URL}/source/wallet/toogle/international/${walletNo}/${schemeCode}`, {});
    }

    requestCard(payload: any): Observable<any> {
        return this.http.post(`${environment.API_URL}/xas/v1/admin/cards/request`, payload);
    }

    mergeProfile(payload: any): Observable<any> {
        return this.http.post(`${environment.API_URL}/xas/v1/admin/accounts/account-merge`, payload);
    }

    sendWelcomeEmail(payload: any): Observable<any> {
        return this.http.post(`${environment.BUSINESS_API_URL}/admin/welcome/mails`, payload);
    }

    updateTrustedOrInternationalSettings(
        isTrusted: boolean | null,
        isInternational: boolean | null,
        _id: string
    ): Observable<any> {
        const payload: any = {};
        if (isTrusted !== null) {
            payload.trusted = isTrusted;
        }
        if (isInternational !== null) {
            payload.international = isInternational;
        }
        return this.http.put(
            `${environment.API_URL}/xas/v1/admin/accounts/${this.sharedService.getSelectedCountryFromSession()}/${_id}`,
            payload
        );
    }

    validateWallet(walletId: any, iso2?: string): Observable<any> {
        const xAuthHeader = this.authHeaderService.generateAuthHeader({});
        const headers = new HttpHeaders()
            .set('X-Auth-Signature', xAuthHeader)
            .set('X-APP-ID', `${environment.X_APP_ID}`)
            .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

        return this.http.get(`${environment.XAS_URL}/accounts/users/${iso2 || this.authService.getSelectedCountryFromSession()}/${walletId}`, { headers });
        // return this.http.get(`${environment.XAS_URL}/accounts/users/${walletId}/enquiry`, { headers });
    }

    // Admin user level set

    setAdminUserLevel(walletNo: string, level: number) {
        return this.http.put(
            `${environment.API_URL}/source/limit/${this.authService.getSelectedCountryCodeFromSession()}/${walletNo}`,
            { limit_level: level }
        );
    }
    updateUserLimit(payload) {
        payload.country_code = this.sharedService.getSelectedCountryFromSession()
        return this.http.post(`${environment.XAS_URL}/admin/accounts/user/limit/update`, payload)
    }

    // - User Pin Block and Unblock
    adminCustomerUserPinBlockChange(setToLock: boolean, walletNo: string, schemeCode: string) {
        if (setToLock) {
            return this.http.post(`${environment.API_URL}/ban/${walletNo}/${schemeCode}/pin`, {});
        } else {
            return this.http.delete(`${environment.API_URL}/ban/${walletNo}/${schemeCode}`);
        }
    }

    setUserLockStatusByWalletNoAndSchemeCode(lock_type: string, walletNo, schemeCode) {
        return this.http.get(`${environment.API_URL}/source/${lock_type}/${walletNo}/${schemeCode}`).pipe(retry(3));

    }

    fetchAccountBalance(numberId, country, type) {
        if (type == 'merchant') {

            // return this.http.get(`${environment.API_URL}/admin/accounts/balance/${country}/${numberId}`)
            // .pipe(map((response: any) => response.data.currentBalance));
            if (country == 'GB') {
                return this.http.get(`${environment.API_URL}/psp/account/balance/${numberId}`)
                    .pipe(map((response: any) => response.data.balance));
            } else {
                return this.http.get(`${environment.API_URL}/source/wallet/balance/${numberId}`)
                    .pipe(map((response: any) => response.data.currentBalance));
            }

        } else {

            return this.http.get(`${environment.XAS_URL}/admin/accounts/balance/${country}/${numberId}`)
                .pipe(map((response: any) => response.data.currentBalance));
        }

    }


    fetchAccount(numberId, country, type): Observable<any> {
        if (type == 'merchant') {

            return this.http.get(`${environment.API_URL}/admin/accounts/balance/${country}/${numberId}`);
        } else {

            return this.http.get(`${environment.XAS_URL}/admin/accounts/balance/${country}/${numberId}`);
        }
    }

    createUserCard(account_id: string, card_type: string) {
        return this.http.post(`${environment.API_URL}/psp/card/create/${account_id}/${card_type}`, {});
    }

    sendUserWelcomeMail(payload: UserAccountOpeningRequestDto) {
        // return this.http.post(`${environment.ADMIN_AUTH_URL}/notification/customer/account-opening`, payload)
        return this.http.post(`${environment.API_URL}/document/email/registration`, payload);
    }
    sendAccountStatement = (payload, wallet) => this.http.post(`${environment.API_URL}/document/pdf/account_statement/${wallet}`, payload)

    updateAdminUser(scheme_code, wallet_no, requestBody) {
        return this.http.put(`${environment.XAS_URL}/admin/accounts`, requestBody)
    }

    getAllUserTransactionsById(walletNo, page = null, pageSize = null) {
        let params = new HttpParams();
        if (page) {
            params = params.append('page', page);
        }
        if (pageSize) {
            params = params.append('limit', pageSize);
        }
        return this.http.get(`${environment.API_URL}/business-api/reports/users/${walletNo}/transactions/all/all/${this.sharedService.getSelectedCountryFromSession()}`, { params });
    }

    getUserTransactionsByIdAndDateRange(walletNo, searchData: SearchData) {
        return this.http.get(`${environment.API_URL}/reports/users/${walletNo}/transactions/${searchData.startDate}/${searchData.endDate}/${this.authService.getSelectedCountryFromSession()}`);
    }

    initiateTransactionRefund(transactionDto: AdminCustomerTransaction, type) {
        return this.http.post(`${environment.ADMIN_AUTH_URL}/user/transaction-refund/${type}`, transactionDto);
        // return this.http.post(`http://localhost:3000/user/transaction-refund/${type}`, transactionDto)
    }

    getTransactionsByDateRange(searchData: SearchData) {
        return this.http.get(`${environment.API_URL}/reports/transactions/${searchData.startDate}/${searchData.endDate}/${this.sharedService.getSelectedCountryFromSession()}`).pipe(retry(3));
    }

    getMerchantAccountList() {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(
            `${environment.API_URL}/business-api/merchant/accounts/country/${this.authService.getSelectedCountryCodeFromSession()}`,
            { params }
        ).pipe(retry(3));
    }

    getAllTransactions(page, pageSize, search, searchData = null) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('limit', pageSize);
        params = params.append('search', search);
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        if (searchData) {
            searchData?.selectedAccountTypes.forEach(selectedAccType => {
                params = params.append('account_type', selectedAccType)
            })
            searchData?.selectedTransactionTypes.forEach(selectedTransType => {
                params = params.append('trans_type', selectedTransType)
            })
            searchData?.selectedChannelIDs.forEach(selectedChannelId => {
                params = params.append('channel_id', selectedChannelId)
            })
        }
        return this.http.get(
            `${environment.API_URL}/business-api/reports/transactions/all/all/${this.sharedService.getSelectedCountryFromSession()}`,
            { params }
        ).pipe(retry(3));
    }

    getFilters(filterType: FilterType) {
        return this.http.get(
            `${environment.API_URL}/business-api/reports/transactions_filters/${this.sharedService.getSelectedCountryFromSession()}/${filterType}`
        )
    }

    getDuplicateTransactions(page, limit, startDate, endDate, accountType) {
        let params = new HttpParams();
        params = params.append('page', page)
        params = params.append('limit', limit)
        params = params.append('iso_code', this.sharedService.getSelectedCountryFromSession())
        if (startDate)
            params = params.append('start_date', startDate)
        if (endDate)
            params = params.append('end_date', endDate)
        if (accountType)
            params = params.append('accountType', accountType)
        return this.http.get(`${environment.API_URL}/transactions-service/transactions/duplicate-logs`, { params })
    }

    deleteDuplicateTransactions(payload) {
        payload.iso_code = this.sharedService.getSelectedCountryFromSession()
        return this.http.post(`${environment.API_URL}/transactions-service/transactions/duplicate-logs`, payload)
    }

    saveToSource(payload) {
        return this.http.post(`${environment.API_URL}/admin/accounts/move-merchant-account`, payload);
    }

    getAllMerchants() {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(
            `${environment.API_URL}/business-api/reports/merchants/all/all/${this.sharedService.getSelectedCountryFromSession()}`,
            { params }
        ).pipe(retry(3));
    }

    getMerchantsFilteredByRegistrationDate(searchData: SearchData) {
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
        return this.http.get(`${environment.API_URL}/reports/merchants/${searchData.startDate}/${searchData.endDate}/${this.sharedService.getSelectedCountryFromSession()}`, { params }).pipe(retry(3));
    }

    updateMerchant(updateData: any) {
        return this.http.put(`${environment.API_URL}/business-api/merchant/update`, updateData);
    }

    sendMerchantWelcomeMail(payload: MerchantAccountOpeningRequestDto) {
        return this.http.post(`${environment.ADMIN_AUTH_URL}/notification/merchant/account-opening`, payload);
    }

    createXcelMerchantCustomer(requestBody) {
        return this.http.post(`${environment.BUSINESS_API_URL}/psp/customer`, requestBody).pipe(retry(3));
    }

    createMerchantWalletUK1 = (body) => this.http.post(`${environment.BUSINESS_API_URL}/psp/account`, body)

    createMerchantWalletUK2 = (body) => this.http.post(`${environment.BUSINESS_API_URL}/admin/merchants/modulr/account`, body)


    getAdminFeeGroupsByCountry(type) {
        return this.http.get(`${environment.API_URL}/fees/country/${this.sharedService.getSelectedCountryCodeFromSession()}/${type}`);
    }

    getMerchantById(merchantId) {
        return this.http.get(`${environment.API_URL}/merchant/details/${merchantId}`).pipe(retry(3));
    }

    getMerchantAccountDetailsById(merchantId) {
        return this.http.get(`${environment.API_URL}/psp/customer/accounts/${merchantId}`);
    }

    getAdminFeesAccountByCountry() {
        return this.http.get(`${environment.API_URL}/admin/accounts/fees/${this.sharedService.getSelectedCountryFromSession()}/all`);
    }
    getAdminMerchantEntTypes() {
        return this.http.get(`${environment.API_URL}/merchants/types`);
    }

    setupMerchantPaymentDetails = (merchantID, body) => this.http.post(`${environment.API_URL}/business-api/updateUser/${merchantID}`, body)

    getMerchantPaymentDetails = (merchantID) => this.http.get(`${environment.API_URL}/business-api/merchant/details/${merchantID}`)

    getMerchantDetails = (merchantID) => this.http.get(`${environment.TRANSACTION_SERVICE_URL}/merchant/${merchantID}`).pipe(retry(3))

    setupMerchantPaymentOptionFees = (merchantID, body) => this.http.patch(`${environment.TRANSACTION_SERVICE_URL}/merchant/payment-options/fees/${merchantID}`, body)

    getAddressByPostCode(postCode, houseNumber) {
        return this.http.get(`https://api.getaddress.io/find/${postCode}/${houseNumber}?api-key=cpH_hsIApUu03RKeBkhH1g16515`)
    }

    createCard(requestBody, ledger_id, type) {
        return this.http.post(`${environment.API_URL}/business-api/psp/card/business/create/${ledger_id}/${type}`, requestBody)
    }

    getUserPendingTransactionFeesByIdAndDateRange(walletNo, searchData: SearchData, page: number, limit: number) {
        if (searchData.startDate && searchData.endDate)
            return this.http.get(`${environment.TRANSACTION_SERVICE_URL}/transactions/charge-pending-fees/?des_account=${walletNo}&start_date=${searchData.startDate}&end_date=${searchData.endDate}&country_code=${this.getSelectedCountryFromSession()}&page=${page}&limit=${limit}`)

        return this.http.get(`${environment.TRANSACTION_SERVICE_URL}/transactions/charge-pending-fees/?des_account=${walletNo}&country_code=${this.getSelectedCountryFromSession()}&page=${page}&limit=${limit}`)

    }

    chargePendingTransactionFees(payload: any) {
        return this.http.post(`${environment.TRANSACTION_SERVICE_URL}/transactions/charge-pending-fees`, payload)
    }

    getSelectedCountryFromSession() {
        return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO)) ?
            this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO) : this.DEFAULT_COUNTRY_ISO_2
    }

    getSelectedCountryCodeFromSession() {
        return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_CODE)) ?
            this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_CODE) : this.DEFAULT_COUNTRY_CODE
    }

    activateUserCard(card_id) {
        return this.http.post(`${environment.XAS_URL}/admin/cards/${this.sharedService.getSelectedCountryFromSession()}/${card_id}/activate`, {})
    }

    closeUserCard(card_id) {
        return this.http.post(`${environment.XAS_URL}/admin/cards/${this.sharedService.getSelectedCountryFromSession()}/${card_id}/close`, {})
    }

    getAccountCards(account_id) {
        return this.http.get(`${environment.XAS_URL}/admin/cards/${this.sharedService.getSelectedCountryFromSession()}/${account_id}/`)
    }

    sendCardPin(card_id) {
        return this.http.get(`${environment.XAS_URL}/admin/cards/${this.sharedService.getSelectedCountryFromSession()}/${card_id}/send-pin`)
    }

    suspendCard(card_id, status) {
        return this.http.post(`${environment.XAS_URL}/admin/cards/${this.sharedService.getSelectedCountryFromSession()}/${card_id}/toggle-suspense`, { status })
    }

    unblockLockedPin(card_id) {
        return this.http.post(`${environment.XAS_URL}/admin/cards/${this.sharedService.getSelectedCountryFromSession()}/${card_id}/pin/retry-reset`, {})
    }

    lockUserAccount(user_id, lock) {
        return this.http.post(`${environment.XAS_URL}/admin/accounts/${user_id}/lock-toggle`, { lock })
    }

    blockUserAccountPIN(user_id, block) {
        return this.http.post(`${environment.XAS_URL}/admin/accounts/${this.sharedService.getSelectedCountryFromSession()}/${user_id}/pin-block-toggle`, { block })
    }

    closeUserAccount(user_id, lock) {
        return this.http.post(`${environment.XAS_URL}/admin/accounts/${this.sharedService.getSelectedCountryFromSession()}/${user_id}/close`, { lock })
    }


    fetchMerchantProducts(searchData: string, page: number, limit: number, merchant_id: string = ''): Observable<any> {
        if (searchData)
            return this.http.get(`${environment.API_URL}/business-api/products/admin?search=${searchData}&page=${page}&limit=${limit}&country_code=${this.sharedService.getSelectedCountryCodeFromSession()}&merchant_id=${merchant_id}`);

        return this.http.get(`${environment.API_URL}/business-api/products/admin?page=${page}&limit=${limit}&country_code=${this.sharedService.getSelectedCountryCodeFromSession()}&merchant_id=${merchant_id}`);
    }

    toggleProductStatus = (status: string, merchant_id: string, product_id: string): Observable<any> =>
        this.http.put(`${environment.API_URL}/business-api/investment/admin/update/${status}/${merchant_id}/${product_id}`, '')

    getSettlementAccount(type: string, countryCode: string, currency: string) {
        return this.http.get(`${environment.XAS_URL}/admin/accounts/settlement/${countryCode}/${currency}/${type}`)
    }

    processAdminPayment(payload) {
        const xAuthHeader = this.authHeaderService.generateAuthHeader(payload);
        const headers = new HttpHeaders()
            .set('X-Auth-Signature', xAuthHeader)
            .set('X-APP-ID', `${environment.X_APP_ID}`)
            .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
        return this.http.post(`${environment.XAS_URL}/admin/transactions/admin-payment`, payload, { headers })
    }



}