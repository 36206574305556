import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'empty-state-table',
  templateUrl: './empty-state-table.component.html',
  styleUrls: ['./empty-state-table.component.css']
})


export class EmptyStateTableComponent implements OnInit {
  @Input() tableColumns: any[];
  @Input() lead ?: string;
  constructor() { }

  ngOnInit() {
  }

}
