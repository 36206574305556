import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import {
  createCorporateRule,
  createCorporateRuleSuccess,
  createRuleSets,
  createRuleSetsFailed,
  createRuleSetsSuccess,
  // deleteCreateCorporateRuleFailed,
  // deleteCreateCorporateRuleSuccess,
  deleteRulesSets,
  deleteRulesSetsFailed,
  deleteRulesSetsSuccess,
  // deletecreateCorporateRule,
  updateCorporateRule,
  updateCorporateRuleFailed,
  updateCorporateRuleSuccess,
  updateRulesSets,
  updateRulesSetsFailed,
  updateRulesSetsSuccess
} from './trans-monitoring.actions';
import { TransMonitoringService } from '../_services/trans-monitoring.service';
import * as TransMonitoringActions from './trans-monitoring.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { CorporateRuleModel, FinancialInstitutionsModel, RulesSetsModel } from '../_interfaces/trans-monitoring.interface';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TransMonitoringEffects {
  constructor(
    private action$: Actions,
    private TransMonitoringService: TransMonitoringService,
    private ToastService: ToastrService
  ) {}

  fetchRulesSets$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.fetchRulesSets),
      exhaustMap(() =>
        this.TransMonitoringService.fetchRuleSets().pipe(
          map((response: RulesSetsModel[]) => TransMonitoringActions.fetchRulesSetsSuccess({ rulesSets: response })),
          catchError(() => of(TransMonitoringActions.fetchRulesSetsFailed()))
        )
      )
    )
  );

  fetchFinancialInstitutions$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.fetchFinanceInstitution),
      exhaustMap(() =>
        this.TransMonitoringService.fetchFinacialInstitutions().pipe(
          map((response: FinancialInstitutionsModel[]) =>
            TransMonitoringActions.fetchFinanceInstitutionSuccess({ FinancialInstitutions: response })
          ),
          catchError(() => of(TransMonitoringActions.fetchFinanceInstitutionFailed()))
        )
      )
    )
  );

  fetchCorporateRule$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.fetchCorporateRules),
      exhaustMap(({ page, pageSize, accountNumber }) =>
        this.TransMonitoringService.fetchCorporateRule(page, pageSize, accountNumber).pipe(
          map((response: CorporateRuleModel[]) =>
            TransMonitoringActions.fetchCorporateRulesSuccess({ CorporateRules: response })
          ),
          tap((response) => {
            // console.log(' response', response);
          }),
          // mergeMap(() => of(TransMonitoringActions.fetchRulesSets())),
          catchError(() => of(TransMonitoringActions.fetchCorporateRulesFailed()))
        )
      )
    )
  );

  createRulesSets$ = createEffect(() =>
    this.action$.pipe(
      ofType(createRuleSets),
      exhaustMap(({ RulesSetsModel }) =>
        this.TransMonitoringService.createRulesSets(RulesSetsModel).pipe(
          map((response: any) => createRuleSetsSuccess({ newRuleSets: response })),
          tap((response) => {
            // this.ToastService.success("Ruleset created successfully")
            this.ToastService.success('Great!, ruleset created successfully ');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchRulesSets())),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry ruleset couldn't be created");
            return of(TransMonitoringActions.createRuleSetsFailed());
          })
        )
      )
    )
  );

  createFinancialInstitution$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.createFinanceInstitution),
      exhaustMap(({ FinancialInstitutionModel }) =>
        this.TransMonitoringService.createFinancialInstitution(FinancialInstitutionModel).pipe(
          map((response: any) =>
            TransMonitoringActions.createFinanceInstitutionSuccess({ newFinancialInstitution: response })
          ),
          tap((response) => {
            this.ToastService.success('Great!, financial institution created successfully');
            // console.log('create response', response);
          }),
          mergeMap(() => of(TransMonitoringActions.fetchFinanceInstitution())),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry finance institution couldn't be created");
            return of(TransMonitoringActions.createFinanceInstitutionFailed());
          })
          // catchError(() => of(TransMonitoringActions.createFinanceInstitutionFailed()))
        )
      )
    )
  );

  updateFinancialInstitution$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.updateFinanceInstitution),
      exhaustMap(({ financialInstitutionId, financialInstitution }) =>
        this.TransMonitoringService.updateFinancialInstitution(financialInstitutionId, financialInstitution).pipe(
          map(() => TransMonitoringActions.updateFinanceInstitutionSuccess()),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, financial institution updated successfully');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchFinanceInstitution())),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry financial institution couldn't be updated");
            return of(TransMonitoringActions.updateFinanceInstitutionFailed());
          })
          // catchError(() => of(TransMonitoringActions.updateFinanceInstitutionFailed()))
        )
      )
    )
  );

  deleteFinancialInstitution$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.deleteFinanceInstitution),
      exhaustMap(({ financialInstitutionId }) =>
        this.TransMonitoringService.deleteFinancialInstitution(financialInstitutionId).pipe(
          map(() => TransMonitoringActions.deleteFinanceInstitutionSuccess()),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, financial institution successfully deleted');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchFinanceInstitution())),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry financial institution couldn't be deleted");
            return of(TransMonitoringActions.deleteFinanceInstitutionFailed());
          })
          // catchError(() => of(TransMonitoringActions.deleteFinanceInstitutionFailed()))
        )
      )
    )
  );

  updateRulesSets$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateRulesSets),
      exhaustMap(({ rulesSetsId, RulesSetsModel }) =>
        this.TransMonitoringService.updateRulesSets(rulesSetsId, RulesSetsModel).pipe(
          map(() => updateRulesSetsSuccess()),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, ruleset updated successfully!');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchRulesSets())),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry! ruleset couldn't be updated");
            return of(TransMonitoringActions.updateRulesSetsFailed());
          })
          // catchError(() => of(updateRulesSetsFailed()))
        )
      )
    )
  );

  deleteRulesSets$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteRulesSets),
      exhaustMap(({ rulesSetsId }) =>
        this.TransMonitoringService.deleteRulesSets(rulesSetsId).pipe(
          map(() => deleteRulesSetsSuccess()),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, ruleset was successfully deleted!');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchRulesSets())),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry! ruleset couldn't be deleted");
            return of(TransMonitoringActions.deleteRulesSetsFailed());
          })
          // catchError(() => of(deleteRulesSetsFailed()))
        )
      )
    )
  );

  createCorporateRule$ = createEffect(() =>
    this.action$.pipe(
      ofType(createCorporateRule),
      exhaustMap(({ CorporateRuleModel }) =>
        this.TransMonitoringService.createCorporateRule(CorporateRuleModel).pipe(
          map((response: any) => createCorporateRuleSuccess({ newCorporateRule: response })),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, corporate rule successfully created');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchCorporateRules({ page: '1' }))),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry! corporate rule couldn't be created");
            return of(TransMonitoringActions.createRuleSetsFailed());
          })
          // catchError(() => of(createRuleSetsFailed()))
        )
      )
    )
  );

  updateCorporateRule$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateCorporateRule),
      exhaustMap(({ corporateRuleId, CorporateRuleModel }) =>
        this.TransMonitoringService.updateCorporateRule(corporateRuleId, CorporateRuleModel).pipe(
          map(() => updateCorporateRuleSuccess()),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, corporate rule successfully updated');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchCorporateRules({ page: '1' }))),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry! corporate rule couldn't be updated");
            return of(TransMonitoringActions.updateCorporateRuleFailed());
          })
          // catchError(() => of(updateCorporateRuleFailed()))
        )
      )
    )
  );

  deleteCorporateRule$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.deleteCorporateRule),
      exhaustMap(({ corporateRuleId }) =>
        this.TransMonitoringService.deleteCorporateRule(corporateRuleId).pipe(
          map(() => deleteRulesSetsSuccess()),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, corporate rule successfully deleted!');
          }),
          mergeMap(() => of(TransMonitoringActions.fetchCorporateRules({ page: '1' }))),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry! corporate rule couldn't be deleted");
            return of(TransMonitoringActions.deleteCorporateRuleFailed());
          })
          // catchError(() => of(TransMonitoringActions.deleteCorporateRuleFailed()))
        )
      )
    )
  );

  createPepsAndSanctions$ = createEffect(() =>
    this.action$.pipe(
      ofType(TransMonitoringActions.createPepsAndSanctions),
      exhaustMap(({ pepsAndSanctionsModel }) =>
        this.TransMonitoringService.createPepAndSanctions(pepsAndSanctionsModel).pipe(
          map((response: any) => TransMonitoringActions.createPepsAndSanctionsSuccess({ pepsAndSanctions: response })),
          tap((response) => {
            // console.log('create response', response);
            this.ToastService.success('Great!, peps and sactions created successfully!');
          }),
          catchError((error: HttpErrorResponse) => {
            this.ToastService.error("Sorry! peps and sanctions couldn't be created");
            return of(TransMonitoringActions.createPepsAndSanctionsFailed());
          })
          // mergeMap(() => of(TransMonitoringActions.fetchCorporateRules({ page: '1' }))),
          // catchError(() => of(TransMonitoringActions.createPepsAndSanctionsFailed()))
        )
      )
    )
  );
}
