import { Injectable } from '@angular/core';

@Injectable({
    providedIn : 'root'
})
export class AppLocalStorage{


    storeToStorage(key, value){
        localStorage.setItem(key, btoa(JSON.stringify(value)));
    }


    getFromStorage(key){
        try {
            return JSON.parse(atob(localStorage.getItem(key)));

        } catch (error) {
            return null;
        }
    }


    isStoredInSession(key){
        return localStorage.getItem(key) ? true : false;
    }

}
