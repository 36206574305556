import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { OrganisationService } from '../_services/organisation.service';
import * as organisationActions from './organisation.actions';

@Injectable({
  providedIn: 'root'
})
export class OrganisationEffects {

  createAdminOrganisation$ = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.createAdminOrgAccount),
      exhaustMap((action) =>
        action.createType === 'create'
          ? this.organisationService.createOrganisation(action.orgAccount).pipe(
              map((response: any) =>
                organisationActions.createAdminOrgAccountSuccess({
                  successType: 'create',
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  organisationActions.createAdminOrgAccountFailed({
                    failType: 'create',
                  })
                )
              )
            )
          : this.organisationService
              .updateOrganisation(action.orgAccount, action.editId)
              .pipe(
                map((response: any) =>
                  organisationActions.createAdminOrgAccountSuccess({
                    successType: 'update',
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    organisationActions.createAdminOrgAccountFailed({
                      failType: 'update',
                    })
                  )
                )
              )
      )
    )
  );

  createAdminOrgAccountSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.createAdminOrgAccountSuccess),
      exhaustMap((action) => [organisationActions.fetchAdminOrgAccounts()])
    )
  );

  fetchAdminOrganisationAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.fetchAdminOrgAccounts),
      exhaustMap((action) =>
        this.organisationService.getAllOrganisation().pipe(
          map((response: any) =>
            organisationActions.setAdminOrgAccounts({
              adminOrgAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(organisationActions.fetchAdminOrgAccountsFailed())
          )
        )
      )
    )
  );


  fetchSelectedAdminOrganisationAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.fetchSelectedAdminOrgAccount),
      exhaustMap((action) =>
        this.organisationService.getAdminOrganisationById(action.orgId).pipe(
          map((response: any) =>
            organisationActions.setSelectedAdminOrgAccount({
              adminSelectedOrgAccount: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(organisationActions.fetchSelectedAdminOrgAccountsFailed())
          )
        )
      )
    )
  );

    createAdminOrganisationStaff$ = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.createAdminOrgStaffAccount),
      exhaustMap((action) =>
        action.createType === 'create'
          ? this.organisationService.createOrganisationStaff(action.staffAccount, action.orgId).pipe(
              map((response: any) =>
                organisationActions.createAdminOrgStaffAccountSuccess({
                  successType: 'create',
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  organisationActions.createAdminOrgStaffAccountFailed({
                    failType: 'create',
                  })
                )
              )
            )
          : this.organisationService
              .updateOrganisationStaff({
                  createOrgDto : action.staffAccount,
                  orgId : action.orgId,
                  editId : action.editId
              })
              .pipe(
                map((response: any) =>
                  organisationActions.createAdminOrgStaffAccountSuccess({
                    successType: 'update',
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    organisationActions.createAdminOrgStaffAccountFailed({
                      failType: 'update',
                    })
                  )
                )
              )
      )
    )
  );

  createAdminOrgBulkStaffAccount = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.createAdminOrgBulkStaffAccount),
      exhaustMap((action) =>
      this.organisationService.createOrganisationBulkStaff(action.staffAccounts).pipe(
        map((response: any) =>
          organisationActions.createAdminOrgBulkStaffAccountSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(organisationActions.createAdminOrgBulkStaffAccountFailed())
        )
      )
      )
    )
  );

  fetchAdminOrganisationStaffAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(organisationActions.fetchAdminOrgStaffAccounts),
      exhaustMap((action) =>
        this.organisationService.getAllStaffForOrganisation(action.orgId).pipe(
          map((response: any) =>
            organisationActions.setAdminOrgStaffAccounts({
              adminOrgStaffAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(organisationActions.fetchAdminOrgStaffAccountsFailed())
          )
        )
      )
    )
  );

  fetchAdminOrganisationStaffLoanHistory$ = createEffect(() =>
  this.action$.pipe(
    ofType(organisationActions.fetchAdminOrgStaffLoanHistory),
    exhaustMap((action) =>
      this.organisationService.getStaffLoanHistory(action.walletNo, action.schemeCode).pipe(
        map((response: any) =>
          organisationActions.setAdminOrgStaffLoanHistory({
            adminOrgStaffLoanHistory: response.data,
          })
        ),
        catchError((error: HttpErrorResponse) =>
          of(organisationActions.fetchAdminOrgStaffLoanHistorysFailed())
        )
      )
    )
  )
);



    fetchAdminOrganisationStaffLoanTrans$ = createEffect(() =>
        this.action$.pipe(
            ofType(organisationActions.fetchAdminOrgStaffLoanTrans),
            exhaustMap((action) =>
                this.organisationService.getStaffLoanTransaction(action.staffId).pipe(
                    map((response: any) =>
                            organisationActions.setAdminOrgStaffLoanTrans({
                            adminOrgStaffLoanTrans: response.data,
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(organisationActions.fetchAdminOrgStaffLoanTransFailed())
                    )
                )
            )
        )
    );

    constructor(private action$: Actions, private organisationService: OrganisationService) {}

}
