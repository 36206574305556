import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppLocalStorage } from 'src/app/storage-manager/local-storage';
import { SESSION_KEY } from 'src/app/storage-manager/session-storage';
import { environment } from 'src/environments/environment';
import { AuthHeaderService } from './authheaderservice';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  DEFAULT_ISSUER_CODE = '';
  DEFAULT_SUB_ISSUER_CODE = '';

  DEFAULT_COUNTRY_CODE = '234';
  DEFAULT_COUNTRY_ISO_2 = 'NG';
  DEFAULT_CURRENCY_CODE = 'NGN';

  constructor(private appStorage: AppLocalStorage, private http: HttpClient,private authHeaderService:AuthHeaderService) { }

  issuerRefreshSubject = new BehaviorSubject(null)
  subIssuerRefreshSubject = new BehaviorSubject(null)
  getSelectedCountryFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO)) ?
            this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO) : this.DEFAULT_COUNTRY_ISO_2;
  }

  getSelectedCurrencyCodeFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_CURRENCY_CODE)) ?
    this.appStorage.getFromStorage(SESSION_KEY.DBOARD_CURRENCY_CODE) : this.DEFAULT_CURRENCY_CODE;
  }

  getSelectedCountryCodeFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_CODE)) ?
    this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_CODE) : this.DEFAULT_COUNTRY_CODE;
  }

  getCountryDetails = (iso_2: string): Observable<any> => this.http.get(`${environment.API_URL}/administrator/countries/${iso_2}`)

  getSelectedIssuerCodeFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.ISSUER)) ?
    this.appStorage.getFromStorage(SESSION_KEY.ISSUER) : this.DEFAULT_ISSUER_CODE;
  }
  getSelectedSubIssuerCodeFromSession(){
    return (this.appStorage.isStoredInSession(SESSION_KEY.SUB_ISSUER)) ?
    this.appStorage.getFromStorage(SESSION_KEY.SUB_ISSUER) : this.DEFAULT_SUB_ISSUER_CODE;
  }

  getServices = () => {
    let params = new HttpParams();
    params = params.append('schemeCode', this.getSelectedCountryCodeFromSession() + '004');
    return this.http.get(`${environment.USER_SERVICE_URL}/service/get-service`, {params})
  }

  

  // createStaff = (requestBody) => {
  //   const xAuthHeader = this.authHeaderService.generateAuthHeader(requestBody);
  //   const headers = new HttpHeaders()
  //   .set('X-Auth-Signature', xAuthHeader)
  //   .set('X-APP-ID', `${environment.X_APP_ID}`)
  //   .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);

  //   return this.http.post(`${environment.API_URL3}/external/staff`, requestBody, { headers }).pipe(retry(3));
  // }

  exportAccountStatement(from: string, to: string, account_id: string, iso: string, download: boolean = false): Observable<any> {
    return this.http.get(`${environment.XAS_URL}/admin/transactions/statements/${iso}/${account_id}?from_date=${from}&to_date=${to}&download=${download}`, {responseType: 'blob' as 'json'});
  }

  getAccountStatement (from: string, to: string, account_id: string, iso: string, download: boolean = false): Observable<any> {
    return this.http.get(`${environment.XAS_URL}/admin/transactions/statements/${iso}/${account_id}?from_date=${from}&to_date=${to}&download=${download}`);
  }

  // NEW GENERATE STATEMENT API
  // getAccountStatement (from: string, to: string, account_id: string, iso: string, download: boolean = false): Observable<any> {
  //   return this.http.get(`${environment.API_URL}/xas-live/v1/admin/transactions/statements/${iso}/${account_id}?from_date=${from}&to_date=${to}&download=${download}`);
  // }

  fetchImageUrl(file: any): Observable<any> {
    let form = new FormData();
    form.append('image', file);
    return this.http.post(`${environment.API_URL}/transactions-service/bucket/upload`, form).pipe(retry(3))
  }

}
