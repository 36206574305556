import { Injectable } from "@angular/core";
import { AdminService } from "../_services/admin-manage-account.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as adminActions from "./admin-manage-account.actions";
import { exhaustMap, map, catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AdminUserSortOptions } from "../../customers/_state/customers.effects";
import { AdminKycAll, AdminKycError } from "../_interfaces/admin-kyc.interface";

@Injectable({
  providedIn: "root",
})
export class AdminEffects {

  refundTransferAfrica$ = createEffect(() => 
    this.action$.pipe(
      ofType(adminActions.initializeRefundAfrica),
      exhaustMap((action) =>
      this.adminService
        .postRefundTransfer(action.payload)
        .pipe(
          map((response: any) =>
            adminActions.refundTransactionAfricaSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.refundTransactionAfricaFailed())
          )
        )
    )
    )
  )

  internalFundTransfer$ = createEffect(() => 
  this.action$.pipe(
    ofType(adminActions.postInternalFundTransfer),
    exhaustMap((action) =>
    this.adminService
      .postFundTransfer(action.payload)
      .pipe(
        map((response: any) =>
          adminActions.postTransferFundsSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.postTransferFundsFailed())
        )
      )
  )
  )
)

  externalFundTransfer$ = createEffect(() => 
  this.action$.pipe(
    ofType(adminActions.postExternalFundTransfer),
    exhaustMap((action) =>
    this.adminService
      .postFundTransfer(action.payload)
      .pipe(
        map((response: any) =>
          adminActions.postTransferFundsSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.postTransferFundsFailed())
        )
      )
  )
  )
 )

  fetchAdminUsers$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminUsers),
    exhaustMap((action) =>
      this.adminService
        .fetchAdminUsers()
        .pipe(
          map((response: any) =>
            adminActions.setAdminUsers({ adminUsers: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminUsersFailed())
          )
        )
    )
  )
);

changeAdminPassword$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.changePassword),
  exhaustMap((action) =>
    this.adminService
      .changePassword(action.updatePasswordDto)
      .pipe(
        map((response: any) =>
          adminActions.changePasswordSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.changePasswordFailed())
        )
      )
  )
)
);

addCountry$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.addCountry),
  exhaustMap((action) =>
    this.adminService
      .addCountry(action.countryDto)
      .pipe(
        map((response: any) =>
          adminActions.addCountrySuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.addCountryFailed())
        )
      )
  )
)
);

updateCountry$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.updateCountry),
  exhaustMap((action) =>
    this.adminService
      .updateCountry(action.countryDto, action.countryId)
      .pipe(
        map((response: any) =>
          adminActions.updateCountrySuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.updateCountryFailed())
        )
      )
  )
)
);

deleteCountry$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.deleteCountry),
  exhaustMap((action) =>
    this.adminService
      .deleteCountry(action.countryId)
      .pipe(
        map((response: any) =>
          adminActions.deleteCountrySuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.deleteCountryFailed())
        )
      )
  )
)
);

fetchAdminBanks$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchAdminBanks),
  exhaustMap((action) =>
    this.adminService
      .fetchbanks()
      .pipe(
        map((response: any) =>
          adminActions.setAdminBanks({ adminBanks: response.data })
        ),
        catchError((error: HttpErrorResponse) =>{
          this.toastr.error(error.error?.errors, 'An error occured');
          return of(adminActions.fetchAdminBanksFailed())
        }
        )
      )
  )
)
);
addBanks$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.addBank),
  exhaustMap((action) =>
    this.adminService
      .addbank(action.bankDto)
      .pipe(
        map((response: any) =>
          adminActions.addBankSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.addBankFailed())
        )
      )
  )
)
);

deleteBank$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.deleteBank),
  exhaustMap((action) =>
    this.adminService
      .deletebank(action.bankId)
      .pipe(
        map((response: any) =>
          adminActions.deleteBankSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.deleteBankFailed())
        )
      )
  )
)
);

updateBank$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.updateBank),
  exhaustMap((action) =>
    this.adminService
      .updatebank(action.bankDto, action.bankId, action.bankCode)
      .pipe(
        map((response: any) =>
          adminActions.updateBankSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.updateBankFailed())
        )
      )
  )
)
);

deleteAdminUser$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.deleteAdminUsers),
  exhaustMap((action) =>
    this.adminService
      .deleteAdminUser(action.userId)
      .pipe(
        map((response: any) =>
          adminActions.deleteAdminUsersSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.deleteAdminUsersFailed())
        )
      )
  )
)
);

  fetchAdminCountries$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCountries),
      exhaustMap((action) =>
        this.adminService
          .getCountries()
          .pipe(
            map((response: any) =>
              adminActions.setAdminCountries({ adminCountries: response.data })
            )
          )
      )
    )
  );

  fetchAdminCustomerUsers$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUsers),
      exhaustMap((action) =>
        this.adminService.getAllUsers(action.page, action.pageSize, action.search).pipe(
          map((response: any) =>
            adminActions.setAdminCustomerUsers({
              adminCustomerUsers: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerUsersFailed({ errorStatus : error.status}))
          )
        )
      )
    )
  );
  // fetchAdminCustomerUsers$ = createEffect(() =>
  //   this.action$.pipe(
  //     ofType(adminActions.fetchAdminCustomerUsers),
  //     exhaustMap((action) =>
  //       this.adminService.getAllUsers().pipe(
  //         map((response: any) =>
  //           adminActions.setAdminCustomerUsers({
  //             adminCustomerUsers: response.data,
  //           })
  //         ),
  //         catchError((error: HttpErrorResponse) =>
  //           of(adminActions.fetchAdminCustomerUsersFailed({ errorStatus : error.status}))
  //         )
  //       )
  //     )
  //   )
  // );
  
  fetchAdminCustomerUsersByRegistrationDate$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUsersByRegistrationDate),
      exhaustMap((action) =>
        this.adminService
          .getUserFilteredByRegistrationDate(action.searchData)
          .pipe(
            exhaustMap((response: any) => [
              adminActions.setAdminCustomerUsers({
                adminCustomerUsers: this.sortOptionsForAdminUsers(response.data, action.sortOption),
              }),
              adminActions.customerUserSearchSuccessfull(),
            ]),
            catchError((error: HttpErrorResponse) =>
              of(adminActions.fetchAdminCustomerUsersFailed({ errorStatus : error.status}))
            )
          )
      )
    )
  );

  fetchAdminCustomerUsersByTransactions$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUsersByTransactions),
      exhaustMap((action) =>
        this.adminService
          .getUserFilteredByTransactions(action.searchData, action.category)
          .pipe(
            exhaustMap((response: any) => {
              let filteredResponse = response.data
                .map((res) => res.wallet_data)
                .filter((res) => res.length > 0);
              filteredResponse = filteredResponse.map((res) => res[0]);
              return [
                adminActions.setAdminCustomerUsers({
                  adminCustomerUsers: filteredResponse,
                }),
                adminActions.customerUserSearchSuccessfull(),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(adminActions.fetchAdminCustomerUsersFailed({ errorStatus : error.status}))
            )
          )
      )
    )
  );

  fetchAdminCustomerUsersByMoneySentByCountry$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUsersByMoneySentByCountry),
      exhaustMap((action) =>
        this.adminService
          .getUserFilteredByMoneySentByCountry(action.searchData, action.flag)
          .pipe(
            exhaustMap((response: any) => {
              let filteredResponse = response.data
                .map((res) => res.wallet_data)
                .filter((res) => res.length > 0);
              filteredResponse = filteredResponse.map((res) => res[0]);
              return [
                adminActions.setAdminCustomerUsers({
                  adminCustomerUsers: filteredResponse,
                }),
                adminActions.customerUserSearchSuccessfull(),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(adminActions.fetchAdminCustomerUsersFailed({errorStatus : error.status}))
            )
          )
      )
    )
  );

  fetchAdminCustomerUsersFailed$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUsersFailed),
      exhaustMap((action) => [
        action.errorStatus == 400
          ? adminActions.setAdminCustomerUsers({
              adminCustomerUsers: [],
            })
          : null,
      ])
    )
  );

  fetchAdminCustomerUserTransactionsById$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUserTransactionsById),
      exhaustMap((action) =>
        this.adminService.getAllUserTransactionsById(action.id).pipe(
          exhaustMap((response: any) => {
            return [
              adminActions.setAdminCustomerUserTransactions({
                adminCustomerUserTransactions: response.data,
              }),
              adminActions.customerUserSearchSuccessfull(),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              adminActions.fetchAdminCustomerUserTransactionsFailed({
                errorStatus: error.status,
              })
            )
          )
        )
      )
    )
  );

  fetchAdminCustomerUserTransactionsByIdAndDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUserTransactionsByIdAndDateRange),
      exhaustMap((action) =>
        this.adminService
          .getUserTransactionsByIdAndDateRange(action.id, action.searchData)
          .pipe(
            exhaustMap((response: any) => [
              adminActions.setAdminCustomerUserTransactions({
                adminCustomerUserTransactions: response.data,
              }),
              adminActions.customerUserSearchSuccessfull(),
            ]),
            catchError((error: HttpErrorResponse) =>
              of(
                adminActions.fetchAdminCustomerUserTransactionsFailed({
                  errorStatus: error.status,
                })
              )
            )
          )
      )
    )
  );

  fetchAdminCustomerUserPendingTransactionFeesByIdAndDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUserPendingTransactionFeesByIdAndDateRange),
      exhaustMap((action) =>
        this.adminService
          .getUserPendingTransactionFeesByIdAndDateRange(action.id, action.searchData, action.page, action.limit)
          .pipe(
            exhaustMap((response: any) => [
              adminActions.setAdminCustomerUserPendingTransactionFees({
                pendingFees: response.data,
              }),
              
              
              
            ]),
            catchError((error: HttpErrorResponse) =>
              of(
                adminActions.fetchAdminCustomerUserPendingTransactionFeesFailed({
                  errorStatus: error.status,
                })
              )
            )
          )
      )
    )
  );
  // chargePendingTransactionFees = createEffect(() =>
  //   this.action$.pipe(
  //     ofType(adminActions.chargeAdminUserPendingTransactionFees),
  //     exhaustMap((action) =>
  //       this.adminService.chargePendingTransactionFees(action.payload).pipe(
  //         map((response: any) =>
  //           adminActions.chargeAdminUserPendingTransactionFeesSuccess()
  //         ),
  //         catchError((error: HttpErrorResponse) =>
  //           of(adminActions.chargeAdminUserPendingTransactionFeesFailed())
  //         )
  //       )
  //     )
  //   )
  // );

  fetchAdminCustomerUserTransactionsFailed$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerUserTransactionsFailed),
      exhaustMap((action) => [
        action.errorStatus == 400
          ? adminActions.setAdminCustomerUserTransactions({
              adminCustomerUserTransactions: [],
            })
          : null,
      ])
    )
  );

  createXcelMerchantAccount$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.createXcelMerchantCustomer),
    exhaustMap((action) =>
      this.adminService.createXcelMerchantCustomer(action.requestBody)
      .pipe(
        exhaustMap((response: any) => [
            adminActions.createXcelMerchantCustomerSuccess(),
        ]),
        catchError((error: HttpErrorResponse) => {
            this.toastr.error(error.error?.errors, 'An error occured');
            return of(adminActions.createXcelMerchantCustomerFailed())
        }
        )
      )
    )
  )
);

  fetchAdminMerchantAccounts$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminCustomerMerchantAccounts),
    exhaustMap((action) =>
      this.adminService.getMerchantAccountList(action.page, action.pageSize, action.search).pipe(
        map((response: any) =>
          adminActions.setAdminCustomerMerchantAccounts({
            merchantAccounts: response,
          })
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.fetchAdminMerchantAccountFailed())
        )
      )
    )
  )
);
//   fetchAdminMerchantAccounts$ = createEffect(() =>
//   this.action$.pipe(
//     ofType(adminActions.fetchAdminCustomerMerchantAccounts),
//     exhaustMap((action) =>
//       this.adminService.getMerchantAccountList().pipe(
//         map((response: any) =>
//           adminActions.setAdminCustomerUsers({
//             adminCustomerUsers: response.data,
//           })
//         ),
//         catchError((error: HttpErrorResponse) =>
//           of(adminActions.fetchAdminMerchantAccountFailed())
//         )
//       )
//     )
//   )
// );
  createCard$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.createMerchantCard),
    exhaustMap((action) =>
      this.adminService.createCard(action.createCardDto, action.ledger_id, action.cardType).pipe(
        map((response: any) =>
          adminActions.createMerchantCardSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.createMerchantCardFailed())
        )
      )
    )
  )
);
  synchronizeBalance$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.synchronizeBalance),
    exhaustMap((action) =>
      this.adminService.synchronizeBalance(action.accountId).pipe(
        map((response: any) =>
          adminActions.synchronizeBalanceSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.synchronizeBalanceFailed())
        )
      )
    )
  )
);

fetchAdminMerchantAccountsByRegistrationDate$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminMerchantAccountByRegistrationDate),
    exhaustMap((action) =>
      this.adminService
        .getUserFilteredByRegistrationDate(action.searchData)
        .pipe(
          exhaustMap((response: any) => [
            adminActions.setAdminCustomerMerchantAccounts({
              merchantAccounts: this.sortOptionsForAdminUsers(response.data, action.sortOption),
            }),
            adminActions.customerUserSearchSuccessfull(),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminMerchantAccountFailed())
          )
        )
    )
  )
);

fetchAdminMerchantAccountByTransactions$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminMerchantAccountByTransactions),
    exhaustMap((action) =>
      this.adminService
        .getUserFilteredByTransactions(action.searchData, action.category)
        .pipe(
          exhaustMap((response: any) => {
            let filteredResponse = response.data
              .map((res) => res.wallet_data)
              .filter((res) => res.length > 0);
            filteredResponse = filteredResponse.map((res) => res[0]);
            return [
              adminActions.setAdminCustomerUsers({
                adminCustomerUsers: filteredResponse,
              }),
              adminActions.customerUserSearchSuccessfull(),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerUsersFailed({ errorStatus : error.status}))
          )
        )
    )
  )
);

fetchAdminMerchantAccountByMoneySentByCountry$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminCustomerUsersByMoneySentByCountry),
    exhaustMap((action) =>
      this.adminService
        .getUserFilteredByMoneySentByCountry(action.searchData, action.flag)
        .pipe(
          exhaustMap((response: any) => {
            let filteredResponse = response.data
              .map((res) => res.wallet_data)
              .filter((res) => res.length > 0);
            filteredResponse = filteredResponse.map((res) => res[0]);
            return [
              adminActions.setAdminCustomerUsers({
                adminCustomerUsers: filteredResponse,
              }),
              adminActions.customerUserSearchSuccessfull(),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerUsersFailed({errorStatus : error.status}))
          )
        )
    )
  )
);

fetchAdminMerchantAccountFailed$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminCustomerUsersFailed),
    exhaustMap((action) => [
      action.errorStatus == 400
        ? adminActions.setAdminCustomerMerchantAccounts({
            merchantAccounts: [],
          })
        : null,
    ])
  )
);

fetchAdminMerchantAccountTransactionsById$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminMerchantAccountTransactionsById),
    exhaustMap((action) =>
      this.adminService.getAllUserTransactionsById(action.id).pipe(
        exhaustMap((response: any) => {
          return [
            adminActions.setAdminCustomerUserTransactions({
              adminCustomerUserTransactions: response.data,
            }),
            adminActions.customerUserSearchSuccessfull(),
          ];
        }),
        catchError((error: HttpErrorResponse) =>
          of(
            adminActions.fetchAdminCustomerUserTransactionsFailed({
              errorStatus: error.status,
            })
          )
        )
      )
    )
  )
);


  changeAdminCustomerUserStatus$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.changeAdminCustomerUserStatus),
      exhaustMap((action) =>
        this.adminService
          .setUserLockStatusByWalletNoAndSchemeCode(
            action.statusType,
            action.walletNo,
            action.schemeCode
          )
          .pipe(
            map((response: any) =>
              adminActions.adminCustomerUserStatusChangeSuccessfull({
                statusType: action.statusType,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                adminActions.adminCustomerUserStatusChangeFailed({
                  statusType: action.statusType,
                })
              )
            )
          )
      )
    )
  );

  fetchAdminCustomerMerchants$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerMerchants),
      exhaustMap((action) =>
        this.adminService.getAllMerchants(action.page, action.pageSize, action.search).pipe(
          map((response: any) =>
            adminActions.setAdminCustomerMerchants({
              adminCustomerMerchants: response
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerMerchantsFailed())
          )
        )
      )
    )
  );

  fetchAdminCustomerMerchantById$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerMerchantById),
      exhaustMap((action) =>
        this.adminService.getMerchantById(action.merchantId).pipe(
          map((response: any) =>
            adminActions.setFetchedAdminCustomerMerchant({
              fetchedAdminCustomerMerchant: response.data.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerMerchantsFailed())
          )
        )
      )
    )
  );

  fetchAdminCustomerMerchantAccountById$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminCustomerMerchantAccountById),
    exhaustMap((action) =>
      this.adminService.getMerchantAccountDetailsById(action.customerId).pipe(
        map((response: any) =>
          adminActions.setAdminCustomerMerchantAccountList({
            accountList: response.data,
          })
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.fetchAdminCustomerMerchantAccountByIdFailed())
        )
      )
    )
  )
);

  updateAdminCustomerMerchant$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.updateAdminCustomerMerchant),
      exhaustMap((action) =>
        this.adminService
          .updateMerchant({
            merchant_id: action.merchantId,
            ...action.updateData,
          })
          .pipe(
            map((response: any) =>
              adminActions.updateAdminCustomerMerchantSuccessful()
            ),
            catchError((error: HttpErrorResponse) =>
              of(adminActions.updateAdminCustomerMerchantSuccessful())
            )
          )
      )
    )
  );

  changeAdminCustomerMerchantStatus$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.changeAdminCustomerMerchantStatus),
      exhaustMap((action) =>
        this.adminService
          .updateMerchant({
            merchant_id: action.merchantId,
            activate: action.activate,
          })
          .pipe(
            map((response: any) =>
              adminActions.adminCustomerMerchantStatusChangeSuccessfull({
                activate: action.activate,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                adminActions.adminCustomerMerchantStatusChangeFailed({
                  activate: action.activate,
                })
              )
            )
          )
      )
    )
  );

  fetchAdminCustomerMerchantsByRegistrationDate$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerMerchantsByRegistrationDate),
      exhaustMap((action) =>
        this.adminService
          .getMerchantsFilteredByRegistrationDate(action.searchData)
          .pipe(
            exhaustMap((response: any) => [
              adminActions.setAdminCustomerMerchants({
                adminCustomerMerchants: response.data,
              }),
              adminActions.customerMerchantSearchSuccessfull(),
            ]),
            catchError((error: HttpErrorResponse) =>
              of(adminActions.fetchAdminCustomerMerchantsFailed())
            )
          )
      )
    )
  );

  sendAdminCustomerMerchantWelcomeMail$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.sendAdminMerchantWelcomeMail),
    exhaustMap((action) =>
      this.adminService.sendMerchantWelcomeMail(action.messagePayload).pipe(
        map((response: any) =>
          adminActions.sendAdminMerchantWelcomeMailSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.sendAdminMerchantWelcomeMailFailed())
        )
      )
    )
  )
);


sendAdminCustomerUserWelcomeMail$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.sendAdminUserWelcomeMail),
  exhaustMap((action) =>
    this.adminService.sendUserWelcomeMail(action.messagePayload).pipe(
      map((response: any) =>
        adminActions.sendAdminUserWelcomeMailSuccess()
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.sendAdminUserWelcomeMailFailed())
      )
    )
  )
)
);

  fetchAdminCustomerTransactions$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerTransactions),
      exhaustMap((action) =>
      this.adminService.getAllTransactions(action.page, action.pageSize, action.search).pipe(
          map((response: any) =>
            adminActions.setAdminCustomerTransactions({
              adminCustomerTransactions: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerTransactionsFailed())
          )
        )
      )
    )
  );
  // fetchAdminCustomerTransactions$ = createEffect(() =>
  //   this.action$.pipe(
  //     ofType(adminActions.fetchAdminCustomerTransactions),
  //     exhaustMap((action) =>
  //       this.adminService.getAllTransactions().pipe(
  //         map((response: any) =>
  //           adminActions.setAdminCustomerTransactions({
  //             adminCustomerTransactions: response.data,
  //           })
  //         ),
  //         catchError((error: HttpErrorResponse) =>
  //           of(adminActions.fetchAdminCustomerTransactionsFailed())
  //         )
  //       )
  //     )
  //   )
  // );

  fetchAdminCustomerTransactionsByDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCustomerTransactionsByDateRange),
      exhaustMap((action) =>
        this.adminService.getTransactionsByDateRange(action.searchData).pipe(
          exhaustMap((response: any) => [
            adminActions.setAdminCustomerTransactions({
              adminCustomerTransactions: response,
            }),
            adminActions.customerTransactionSearchSuccessfull(),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCustomerTransactionsFailed())
          )
        )
      )
    )
  );
  // fetchAdminCustomerTransactionsByDateRange$ = createEffect(() =>
  //   this.action$.pipe(
  //     ofType(adminActions.fetchAdminCustomerTransactionsByDateRange),
  //     exhaustMap((action) =>
  //       this.adminService.getTransactionsByDateRange(action.searchData).pipe(
  //         exhaustMap((response: any) => [
  //           adminActions.setAdminCustomerTransactions({
  //             adminCustomerTransactions: response.data,
  //           }),
  //           adminActions.customerTransactionSearchSuccessfull(),
  //         ]),
  //         catchError((error: HttpErrorResponse) =>
  //           of(adminActions.fetchAdminCustomerTransactionsFailed())
  //         )
  //       )
  //     )
  //   )
  // );

  fetchAdminKycAll$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminKycAll),
      exhaustMap((action) =>
        this.adminService.getAllKyc().pipe(
          map((response: any) =>
            adminActions.setAdminKycAll({ adminKycAll: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminKycAllFailed())
          )
        )
      )
    )
  );

  fetchAdminKycByDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminKycByDateRange),
      exhaustMap((action) =>
        this.adminService.getKycByDateRange(action.searchData).pipe(
          map((response: any) =>
            adminActions.setAdminKycAll({ adminKycAll: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminKycAllFailed())
          )
        )
      )
    )
  );

  fetchAdminKycErrors$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminKycErrors),
      exhaustMap((action) =>
        this.adminService.getAllFailedTransaction().pipe(
          map((response: any) =>
            adminActions.setAdminKycErrors({ adminKycErrors: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminKycErrorsFailed())
          )
        )
      )
    )
  );

  fetchAdminKycErrorsByDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminKycErrorsByDateRange),
      exhaustMap((action) =>
        this.adminService.getKycErrorsByDateRange(action.searchData).pipe(
          map((response: any) =>
            adminActions.setAdminKycErrors({ adminKycErrors: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminKycErrorsFailed())
          )
        )
      )
    )
  );

  fetchAdminExchangeRateForTransfer$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminExchangeRatesForTransfer),
      exhaustMap((action) =>
        this.adminService.getExchangeRateByType("transfer").pipe(
          map((response: any) =>
            adminActions.setAdminExchangeRatesForTransfer({
              adminExchangeRatesForTransfer: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminExchangeRatesForTransferFailed())
          )
        )
      )
    )
  );

  fetchAdminExchangeRateForPayment$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminExchangeRatesForPayment),
      exhaustMap((action) =>
        this.adminService.getExchangeRateByType("payment").pipe(
          map((response: any) =>
            adminActions.setAdminExchangeRatesForPayment({
              adminExchangeRatesForPayment: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminExchangeRatesForPaymentFailed())
          )
        )
      )
    )
  );

  fetchAdminLimits$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminLimits),
      exhaustMap((action) =>
        this.adminService.getAllLimits().pipe(
          map((response: any) =>
            adminActions.setAdminLimits({ adminLimits: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminLimitsFailed())
          )
        )
      )
    )
  );

  fetchAdminFeeGroups$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminFeeGroups),
      exhaustMap((action) =>
        this.adminService.getAdminFeeGroupsByCountry(action.feeType).pipe(
          map((response: any) =>
            adminActions.setAdminFeeGroups({ adminFeeGroups: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminFeeGroupsFailed())
          )
        )
      )
    )
  );

  fetchAdminMerchantFees$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminMerchantFees),
      exhaustMap((action) =>
        this.adminService.getMerchantFee().pipe(
          map((response: any) =>
            adminActions.setAdminMerchantFees({
              adminMerchantFees: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminMerchantFeesFailed())
          )
        )
      )
    )
  );

  fetchAdminTransferFees$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminTransferFees),
      exhaustMap((action) =>
        this.adminService.getTransferFee().pipe(
          map((response: any) =>
            adminActions.setAdminTransferFees({
              adminTransferFees: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminTransferFeesFailed())
          )
        )
      )
    )
  );

  fetchAdminProductFees$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminProductFees),
      exhaustMap((action) =>
        this.adminService.getProductFee(action.merchantId).pipe(
          map((response: any) =>
            adminActions.setAdminProductFees({
              adminProductFees: response.data.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminTransferFeesFailed())
          )
        )
      )
    )
  );

  createAdminMerchantFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminMerchantFee),
      exhaustMap((action) =>
        action.createType == "create"
          ? this.adminService.createMerchantFee(action.adminMerchantFee).pipe(
              map((response: any) =>
                adminActions.createAdminMerchantFeeSuccessful({
                  successType: "create",
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  adminActions.createAdminMerchantFeeFailed({
                    failType: "create",
                  })
                )
              )
            )
          : this.adminService.updateMerchantFee(action.adminMerchantFee).pipe(
              map((response: any) =>
                adminActions.createAdminMerchantFeeSuccessful({
                  successType: "update",
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  adminActions.createAdminMerchantFeeFailed({
                    failType: "update",
                  })
                )
              )
            )
      )
    )
  );

  createAdminMerchantFeeSuccessful$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminMerchantFeeSuccessful),
      exhaustMap((action) => [adminActions.fetchAdminMerchantFees()])
    )
  );

  createAdminTransferFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminTransferFee),
      exhaustMap((action) =>
        action.createType == "create"
          ? this.adminService.createTransferFee(action.adminTransferFee).pipe(
              map((response: any) =>
                adminActions.createAdminTransferFeeSuccessful({
                  successType: "create",
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  adminActions.createAdminTransferFeeFailed({
                    failType: "create",
                  })
                )
              )
            )
          : this.adminService
              .updateTransferFee(action.adminTransferFee, action.feeId)
              .pipe(
                map((response: any) =>
                  adminActions.createAdminTransferFeeSuccessful({
                    successType: "update",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminTransferFeeFailed({
                      failType: "update",
                    })
                  )
                )
              )
      )
    )
  );

  createAdminTransferFeeSuccessful$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminTransferFeeSuccessful),
      exhaustMap((action) => [adminActions.fetchAdminTransferFees()])
    )
  );

  deleteTransferFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.deleteAdminTransferFee),
      exhaustMap((action) =>
        this.adminService.deleteTransferFee(action.feeId).pipe(
          map((response: any) =>
            adminActions.deleteAdminTransferFeeSuccessful()
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.deleteAdminTransferFeeFailed())
          )
        )
      )
    )
  );

  createAdminProductFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminProductFee),
      exhaustMap((action) =>
        action.createType == "create"
          ? this.adminService
              .createProductFee(action.productId, action.adminProductFee)
              .pipe(
                map((response: any) =>
                  adminActions.createAdminProductFeeSuccessful({
                    successType: "create",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminProductFeeFailed({
                      failType: "create",
                    })
                  )
                )
              )
          : this.adminService
              .updateProductFee(action.productId, action.adminProductFee)
              .pipe(
                map((response: any) =>
                  adminActions.createAdminProductFeeSuccessful({
                    successType: "update",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminProductFeeFailed({
                      failType: "update",
                    })
                  )
                )
              )
      )
    )
  );

  fetchAdminCardConfiguration$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminCardConfigurations),
      exhaustMap((action) =>
        this.adminService.fetchCardConfiguration().pipe(
          map((response: any) =>
            adminActions.setAdminCardConfigurations({
              adminCardConfigurations: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminCardConfigurationsFailed())
          )
        )
      )
    )
  );

  createAdminCardConfiguration$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminCardConfiguration),
      exhaustMap((action) =>
        action.createType == "create"
          ? this.adminService
              .createCardConfiguration(action.adminCardConfiguration)
              .pipe(
                map((response: any) =>
                  adminActions.createAdminCardConfigurationSuccess({
                    successType: "create",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminCardConfigurationFailed({
                      failType: "create",
                    })
                  )
                )
              )
          : this.adminService
              .updateCardConfiguration(action.adminCardConfiguration)
              .pipe(
                map((response: any) =>
                  adminActions.createAdminCardConfigurationSuccess({
                    successType: "update",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminCardConfigurationFailed({
                      failType: "update",
                    })
                  )
                )
              )
      )
    )
  );

  createAdminCardConfigurationSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminCardConfigurationSuccess),
      exhaustMap((action) => [adminActions.fetchAdminCardConfigurations()])
    )
  );

  //
  // ─── ADMIN MANAGE ORGANISATION EFFECTS ─────────────────────────────────────────────────
  //

  createAdminOrganisation$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminOrgAccount),
      exhaustMap((action) =>
        action.createType == "create"
          ? this.adminService.createOrganisation(action.orgAccount).pipe(
              map((response: any) =>
                adminActions.createAdminOrgAccountSuccess({
                  successType: "create",
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  adminActions.createAdminOrgAccountFailed({
                    failType: "create",
                  })
                )
              )
            )
          : this.adminService
              .updateOrganisation(action.orgAccount, action.editId)
              .pipe(
                map((response: any) =>
                  adminActions.createAdminOrgAccountSuccess({
                    successType: "update",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminOrgAccountFailed({
                      failType: "update",
                    })
                  )
                )
              )
      )
    )
  );

  createAdminOrgAccountSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminOrgAccountSuccess),
      exhaustMap((action) => [adminActions.fetchAdminOrgAccounts()])
    )
  );

  fetchAdminOrganisationAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminOrgAccounts),
      exhaustMap((action) =>
        this.adminService.getAllOrganisation().pipe(
          map((response: any) =>
            adminActions.setAdminOrgAccounts({
              adminOrgAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminOrgAccountsFailed())
          )
        )
      )
    )
  );

  
  fetchSelectedAdminOrganisationAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchSelectedAdminOrgAccount),
      exhaustMap((action) =>
        this.adminService.getAdminOrganisationById(action.orgId).pipe(
          map((response: any) =>
            adminActions.setSelectedAdminOrgAccount({
              adminSelectedOrgAccount: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchSelectedAdminOrgAccountsFailed())
          )
        )
      )
    )
  );


  //
  // ───────────────────────────────────────────────────────────────────────── ADMIN ORGANISATION STAFF EFFECTS ─────
  //

  createAdminOrganisationStaff$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminOrgStaffAccount),
      exhaustMap((action) =>
        action.createType == "create"
          ? this.adminService.createOrganisationStaff(action.staffAccount, action.orgId).pipe(
              map((response: any) =>
                adminActions.createAdminOrgStaffAccountSuccess({
                  successType: "create",
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  adminActions.createAdminOrgStaffAccountFailed({
                    failType: "create",
                  })
                )
              )
            )
          : this.adminService
              .updateOrganisationStaff({
                  createOrgDto : action.staffAccount,
                  orgId : action.orgId,
                  editId : action.editId
              })
              .pipe(
                map((response: any) =>
                  adminActions.createAdminOrgStaffAccountSuccess({
                    successType: "update",
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    adminActions.createAdminOrgStaffAccountFailed({
                      failType: "update",
                    })
                  )
                )
              )
      )
    )
  );

  createAdminOrgBulkStaffAccount = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminOrgBulkStaffAccount),
      exhaustMap((action) => 
      this.adminService.createOrganisationBulkStaff(action.staffAccounts).pipe(
        map((response: any) =>
          adminActions.createAdminOrgBulkStaffAccountSuccess()
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.createAdminOrgBulkStaffAccountFailed())
        )
      )
      )
    )
  );

  fetchAdminOrganisationStaffAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminOrgStaffAccounts),
      exhaustMap((action) =>
        this.adminService.getAllStaffForOrganisation(action.orgId).pipe(
          map((response: any) =>
            adminActions.setAdminOrgStaffAccounts({
              adminOrgStaffAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminOrgStaffAccountsFailed())
          )
        )
      )
    )
  );

  fetchAdminOrganisationStaffLoanHistory$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchAdminOrgStaffLoanHistory),
    exhaustMap((action) =>
      this.adminService.getStaffLoanHistory(action.walletNo, action.schemeCode).pipe(
        map((response: any) =>
          adminActions.setAdminOrgStaffLoanHistory({
            adminOrgStaffLoanHistory: response.data,
          })
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.fetchAdminOrgStaffLoanHistorysFailed())
        )
      )
    )
  )
);



fetchAdminOrganisationStaffLoanTrans$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchAdminOrgStaffLoanTrans),
  exhaustMap((action) =>
    this.adminService.getStaffLoanTransaction(action.staffId).pipe(
      map((response: any) =>
        adminActions.setAdminOrgStaffLoanTrans({
          adminOrgStaffLoanTrans: response.data,
        })
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.fetchAdminOrgStaffLoanTransFailed())
      )
    )
  )
)
);


  // ────────────────────────────────────────────────────────────────────────────────

  // ────────────────────────────────────────────────────────────────────────────────
  //
  // ─── ADMIN MANAGE ACCOUNT EFFECTS ───────────────────────────────────────────────
  //

  createGlAccount$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.createGlAccount),
    exhaustMap((action) =>
      this.adminService
        .createGlAccount(action.glAccountDto)
        .pipe(
          map((response: any) =>
            adminActions.createGlAccountSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.createGlAccountFailed())
          )
        )
    )
  )
);

createHoldAccount$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.createHoldAccount),
    exhaustMap((action) =>
      this.adminService
        .createHoldAccount(action.holdAccountDto)
        .pipe(
          map((response: any) =>
            adminActions.createHoldAccountSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.createHoldAccountFailed())
          )
        )
    )
  )
);

logAdminAccount$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.logAdminAccount),
    exhaustMap((action) =>
      this.adminService
        .logAdminAccount(action.logAdminAccountDto)
        .pipe(
          map((response: any) =>
            adminActions.logAdminAccountSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.logAdminAccountFailed())
          )
        )
    )
  )
);

fetchSettlementAccounts$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.fetchSettlementAccounts),
    exhaustMap((action) =>
      this.adminService
        .getSettlementAccounts(action.countryISO2)
        .pipe(
          exhaustMap((response: any) => [
            adminActions.fetchSettlementAccountsSuccess(),
            adminActions.setAdminSettlementAccounts2({
              adminSettlementAccounts2: response.data
            }),
            adminActions.setAdminGlAccounts({
              adminGLAccounts: response.data.filter(account => account.account_id.includes('99999'))
            }),
            adminActions.setAdminHoldAccounts({
              adminHoldAccounts: response.data.filter(account => account.account_id.includes('88888'))
            })
          ]
        ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchSettlementAccountsFailed())
          )
        )
    )
  )
);
  

  fetchAdminManagedAccounts$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminManagedAccounts),
      exhaustMap((action) =>
        this.adminService.getManagedAccounts().pipe(
          exhaustMap((response: any) => [
            adminActions.setAdminSettlementAccounts({
              adminSettlementAccounts: response.data.filter(
                (i) => i.type == "settlement" && i.country == this.adminService.getSelectedCountryFromSession()
              ),
            }),
            adminActions.setAdminFeeAccounts({
              adminFeeAccounts: response.data.filter((i) => i.type == "fees" && i.country == this.adminService.getSelectedCountryFromSession()),
            }),
            adminActions.setAdminLocalPaymentAccount({
              adminLocalPaymentAccounts: response.data.filter(
                (i) => i.type == "local" && i.country == this.adminService.getSelectedCountryFromSession()
              ),
            }),
            adminActions.setAdminNonSettlementAccounts({
              adminNonSettlementAccounts: response.data.filter(account => account.type !== 'settlement' && account.country == this.adminService.getSelectedCountryFromSession())
            }),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminManagedAccountsFailed())
          )
        )
      )
    )
  );

  fetchAdminFeeAccountsByCountry$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminFeeAccountsByCountry),
      exhaustMap((action) =>
        this.adminService.getAdminFeesAccountByCountry().pipe(
          map((response: any) =>
            adminActions.setAdminFeesAccountByCountry({
              adminFeesAccountByCountry: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminManagedAccountsFailed())
          )
        )
      )
    )
  );

  fetchAdminMerhcantEntTypes$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminMerchantEntTypes),
      exhaustMap((action) =>
        this.adminService.getMerchantCategories().pipe(
          map((response: any) =>
            adminActions.setAdminMerchantEntTypes({
              adminMerchantEntTypes: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminMerchantEntTypesFailed())
          )
        )
      )
    )
  );

  createAdminAgentAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createAdminAgentAccount),
      exhaustMap((action) =>
        this.adminService.createAgent(action.agentAccount).pipe(
          map((response: any) => adminActions.createAdminAgentAccountSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.createAdminAgentAccountFailed())
          )
        )
      )
    )
  );

  fetchAdminAgentAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminAgentAccounts),
      exhaustMap((action) =>
        this.adminService.getAllAgentsByISOTwo().pipe(
          map((response: any) =>
            adminActions.setAdminAgentAccounts({
              adminAgentAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminAgentAccountsFailed())
          )
        )
      )
    )
  );

  fetchAdminSubAgentAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchAdminSubAgentAccounts),
      exhaustMap((action) =>
        this.adminService.getAllSubAgentsByISOTwo(action.id).pipe(
          map((response: any) =>
            adminActions.setAdminSubAgentAccounts({
              adminSubAgentAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.fetchAdminSubAgentAccountsFailed())
          )
        )
      )
    )
  );

  changeSuspensionStatusForAgent$ = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.changeSuspensionStatusForAgent),
    exhaustMap((action) =>
      this.adminService.adminAgentSuspension(action.isSuspend, action.userId).pipe(
        map((response: any) =>
          adminActions.changeSuspensionStatusForAgentSuccess({isSuspend : action.isSuspend})
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.changeSuspensionStatusForAgentFailed({isSuspend : action.isSuspend}))
        )
      )
    )
  )
);


  createUserCard$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createUserCard),
      exhaustMap((action) =>
        this.adminService.createUserCard(action.account_id, action.card_type).pipe(
          exhaustMap((response: any) =>
           [
             adminActions.createUserCardSuccess(),
             adminActions.fetchAdminCustomerUsers({page: 1, pageSize: 500, search: ''})
            ]
          ),
          catchError((error: HttpErrorResponse) =>
            of(adminActions.createUserCardFailed())
          )
        )
      )
    )
  );

  initialRefundTransaction = createEffect(() =>
  this.action$.pipe(
    ofType(adminActions.initializeRefundTransaction),
    exhaustMap((action) =>
      this.adminService.initiateTransactionRefund(action.transactionDto, action.setting).pipe(
        map((response: any) =>
         
           adminActions.refundTransactionSuccess(),
        ),
        catchError((error: HttpErrorResponse) =>
          of(adminActions.refundTransactionFailed({ message : error.error.message || "Reversal Transaction failed", statusCode : error.status }))
        )
      )
    )
  )
);


adminCustomerUserPinBlockChange = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCustomerUserPinBlockChange),
  exhaustMap((action) =>
    this.adminService.adminCustomerUserPinBlockChange(action.blockAction, action.walletNo, action.schemeCode).pipe(
      map((response: any) =>
       
         adminActions.adminCustomerUserPinBlockChangeSuccessfull({blockAction : action.blockAction}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCustomerUserPinBlockChangeFailed({blockAction : action.blockAction}))
      )
    )
  )
)
);


adminCustomerUserTrustChange = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCustomerUserTrustChange),
  exhaustMap((action) =>
    this.adminService.adminCustomerUserTrustChange(action.trustAction, action.walletNo, action.schemeCode).pipe(
      map((response: any) =>
       
         adminActions.adminCustomerUserTrustChangeSuccessfull({trustAction : action.trustAction}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCustomerUserTrustChangeFailed({trustAction : action.trustAction}))
      )
    )
  )
)
);


adminCustomerInternationalTransferChange = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCustomerUserInternationTransferOptionChange),
  exhaustMap((action) =>
    this.adminService.adminCustomerUserInternationTransferOptionChange(action.walletNo, action.schemeCode).pipe(
      map((response: any) =>
       
         adminActions.adminCustomerUserInternationTransferOptionChangeSuccessfull({ international: action.international}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCustomerUserInternationTransferOptionChangeFailed({ international: action.international}))
      )
    )
  )
)
);

adminCustomerUserLimitLevelChange = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.setAdminUserLevel),
  exhaustMap((action) =>
    this.adminService.setAdminUserLevel(action.walletNo, action.level).pipe(
      map((response: any) =>
       
         adminActions.setAdminUserLevelSuccess({levelChanged : action.level}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.setAdminUserLevelFailed())
      )
    )
  )
)
);

adminCustomerUserAccountCloseChange = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCustomerUserCloseChange),
  exhaustMap((action) =>
    this.adminService.adminCustomerUserCloseAcountChange(action.closeAction, action.accountId).pipe(
      map((response: any) =>
       
         adminActions.adminCustomerUserCloseChangeSuccessfull({closeAction : action.closeAction}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCustomerUserCloseChangeFailed({closeAction : action.closeAction}))
      )
    )
  )
)
);

adminCustomerUserCardActivation = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCustomerUserCardActivationChange),
  exhaustMap((action) =>
    this.adminService.adminCustomerUserActivationCardChange(action.activateAction, action.cardId).pipe(
      map((response: any) =>
       
         adminActions.adminCustomerUserCardActivationChangeSuccessfull({activateAction : action.activateAction}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCustomerUserCardActivationChangeFailed({activateAction : action.activateAction}))
      )
    )
  )
)
);




adminCustomerUserCardDestory = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCustomerUserCardDestory),
  exhaustMap((action) =>
    this.adminService.adminCustomerUserDestroy(action.cardId).pipe(
      map((response: any) =>
       
         adminActions.adminCustomerUserCardDestroySuccessfull(),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCustomerUserCardDestroyFailed())
      )
    )
  )
)
);



fetchBillInfosByCountryCode$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchBillInfosByCountryCode),
  exhaustMap((action) =>
    this.adminService.fetchBillInformationList().pipe(
      map((response: any) =>
       
         adminActions.setBillInfoList({billInfos : response.data}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.fetchBillInfosByCountryCodeFailed())
      )
    )
  )
)
);


createBillInformation$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCreateBillInfo),
  exhaustMap((action) =>
    this.adminService.createBillInformation(action.createBillInfoDto).pipe(
      exhaustMap((response: any) =>
        [
          adminActions.adminCreateBillInfoSuccessful(),
          adminActions.fetchBillInfosByCountryCode()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCreateBillInfoFailed())
      )
    )
  )
)
);



updateBillInformation$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminUpdateBillInfo),
  exhaustMap((action) =>
    this.adminService.updateBillInformation(action.payment_id, action.updateBillInfoDto).pipe(
      exhaustMap((response: any) =>
        [
          adminActions.adminUpdateBillInfoSuccessful(),
          adminActions.fetchBillInfosByCountryCode()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminUpdateBillInfoFailed())
      )
    )
  )
)
);


deleteBillInformation$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminDeleteBillInfo),
  exhaustMap((action) =>
    this.adminService.deleteBillInformation(action.payment_id).pipe(
      exhaustMap((response: any) =>
        [
          adminActions.adminDeleteBillInfoSuccessful(),
          adminActions.fetchBillInfosByCountryCode()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminDeleteBillInfoFailed())
      )
    )
  )
)
);

//ADMIN BILLER CONFIG
fetchBillerCountries$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchBillerCountries),
      exhaustMap((action) =>
        this.adminService
          .getBillerCountries()
          .pipe(
            exhaustMap((response: any) => [
                adminActions.fetchBillerCountriesSuccess({ billerCountries: response.data }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.fetchBillerCountriesFailed())
            }
            )
          )
      )
    )
  );

fetchBillerServices$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchBillerServices),
      exhaustMap((action) =>
        this.adminService
          .getBillerServices()
          .pipe(
            exhaustMap((response: any) => [
                adminActions.fetchBillerServicesSuccess({ billerServices: response.data }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.fetchBillerServicesFailed())
            }
            )
          )
      )
    )
  );

fetchBillerChannels$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchBillerChannels),
      exhaustMap((action) =>
        this.adminService
          .getBillerChannels()
          .pipe(
            exhaustMap((response: any) => [
                adminActions.fetchBillerChannelsSuccess({ billerChannels: response.data }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.fetchBillerChannelsFailed())
            }
            )
          )
      )
    )
  );

fetchBillerChannelsServices$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.fetchBillerChannelServices),
      exhaustMap((action) =>
        this.adminService
          .getBillerChannelServices(action.countryCode)
          .pipe(
            exhaustMap((response: any) => [
                adminActions.fetchBillerChannelServicesSuccess({ billerChannelsServices: response.data }),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.fetchBillerChannelServicesFailed())
            }
            )
          )
      )
    )
  );

createBillerChannelsService$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createBillerChannelService),
      exhaustMap((action) =>
        this.adminService
          .createBillerChannelServices(action.billerChannelServiceDto)
          .pipe(
            exhaustMap((response: any) => [
                adminActions.createBillerChannelServiceSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.createBillerChannelServiceFailed())
            }
            )
          )
      )
    )
  );

createBillerChannel$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createBillerChannel),
      exhaustMap((action) =>
        this.adminService
          .createBillerChannel(action.billerChannelDto)
          .pipe(
            exhaustMap((response: any) => [
                adminActions.createBillerChannelSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.createBillerChannelFailed())
            }
            )
          )
      )
    )
  );

createBillerService$ = createEffect(() =>
    this.action$.pipe(
      ofType(adminActions.createBillerService),
      exhaustMap((action) =>
        this.adminService
          .createBillerService(action.billerServiceDto)
          .pipe(
            exhaustMap((response: any) => [
                adminActions.createBillerServiceSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors, 'An error occured');
                return of(adminActions.createBillerServiceFailed())
            }
            )
          )
      )
    )
  );

// ADMIN BLACKLIST INFORMATION

fetchBlacklistInfosByCountryCode$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchBlacklistInfosByCountryCode),
  exhaustMap((action) =>
    this.adminService.fetchBlacklistInformationList().pipe(
      map((response: any) =>
       
         adminActions.setBlacklistInfoList({blacklistInfos : response.data}),
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.fetchBlacklistInfosByCountryCodeFailed())
      )
    )
  )
)
);


createBlacklistInformation$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminCreateBlacklistInfo),
  exhaustMap((action) =>
    this.adminService.createBlacklistInformation(action.createBlacklistInfoDto).pipe(
      exhaustMap((response: any) =>
        [
          adminActions.adminCreateBlacklistInfoSuccessful(),
          adminActions.fetchBlacklistInfosByCountryCode()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminCreateBlacklistInfoFailed())
      )
    )
  )
)
);



updateBlacklistInformation$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminUpdateBlacklistInfo),
  exhaustMap((action) =>
    this.adminService.updateBlacklistInformation(action.id, action.updateBlacklistInfoDto).pipe(
      exhaustMap((response: any) =>
        [
          adminActions.adminUpdateBlacklistInfoSuccessful(),
          adminActions.fetchBlacklistInfosByCountryCode()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        {
          return of(adminActions.adminUpdateBlacklistInfoFailed())
        }
      )
    )
  )
)
);


deleteBlacklistInformation$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.adminDeleteBlacklistInfo),
  exhaustMap((action) =>
    this.adminService.deleteBlacklistInformation(action.id).pipe(
      exhaustMap((response: any) =>
        [
          adminActions.adminDeleteBlacklistInfoSuccessful(),
          adminActions.fetchBlacklistInfosByCountryCode()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.adminDeleteBlacklistInfoFailed())
      )
    )
  )
)
);


fetchAdminCustomerAccountBalance$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchAdminCustomerAccountBalance),
  exhaustMap((action) =>
    this.adminService.fetchAccountBalance(action.cardNumOrAccountId, action.country).pipe(
      map((response: any) => 
         adminActions.fetchAdminCustomerAccountBalanceSuccess({balance : response })
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.fetchAdminCustomerAccountBalanceFailed())
      )
    )
  )
)
);

fetchAdminActivityLog$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchAdminActivityLogs),
  exhaustMap((action) =>
    this.adminService.fetchAdminActivityLog().pipe(
      map((response: any) =>
         adminActions.setAdminActivityLog({adminActivityLogs : response.data })
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.fetchAdminActivityLogFailed())
      )
    )
  )
)
);

fetchFailedRegList$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.fetchFailedUserRegistration),
  exhaustMap((action) =>
    this.adminService.fetchFailedReg().pipe(
      map((response: any) =>
         adminActions.setFailedUserRegistration({failedReg : response.data })
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.fetchFailedUserRegistrationFailed())
      )
    )
  )
)

);

deleteFailedRegistration$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.deleteFailedUserRegistration),
  exhaustMap((action) =>
    this.adminService.deleteFailedReg(action.id).pipe(
      exhaustMap((response: any) =>
         [
           adminActions.fetchFailedUserRegistration(),
           adminActions.deleteFailedUserRegistrationSuccess()
        ]
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.deleteFailedUserRegistrationFailed())
      )
    )
  )
)

);


createFailedUser$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.createFailedUser),
  exhaustMap((action) =>
    this.adminService.createFailedReg(action.payload).pipe(
      map((response: any) =>
         adminActions.createFailedUserSuccess()
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.createFailedUserFailed())
      )
    )
  )
)

);


sendKycMail$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.sendKycEmail),
  exhaustMap((action) =>
    this.adminService.sendKycMail(action.payload).pipe(
      map((response: any) =>
         adminActions.sendKycEmailSuccess()
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.sendKycEmailFailed())
      )
    )
  )
));

saveToSource$ = createEffect(() =>
this.action$.pipe(
  ofType(adminActions.saveToSource),
  exhaustMap((action) =>
    this.adminService.saveToSource(action.payload).pipe(
      map((response: any) =>
         adminActions.saveToSourceSuccess()
      ),
      catchError((error: HttpErrorResponse) =>
        of(adminActions.saveToSourceFailed())
      )
    )
  )
));

//New Kyc Effects
fetchKycRecords$ = createEffect(() => this.action$.pipe(
  ofType(adminActions.fetchKycRecords),
  exhaustMap(({
      startDate,
      endDate,
      page,
      pageSize
  }) => this.adminService.getKycRecords(startDate || 'all', endDate || 'all', page, pageSize).pipe(
      exhaustMap((response: {
          meta: {},
          status: boolean,
          data: {
              page: number,
              pageSize: number,
              totalCount: number,
              data: AdminKycAll[]
          }
      }) => [
          adminActions.fetchKycRecordsSuccess({
              kycRecords: response.data.data,
              kycRecordsPage: response.data.page,
              kycRecordsPageSize: response.data.pageSize,
              kycRecordsTotalCount: response.data.totalCount
          })
      ]),
      catchError((error: HttpErrorResponse) => {
          this.toastr.error(error.error?.errors);
          return of(adminActions.fetchKycRecordsFailed({ message: error.error?.errors, code: error.status }))
      })
  ))
))

fetchKycErrors$ = createEffect(() => this.action$.pipe(
  ofType(adminActions.fetchKycErrors),
  exhaustMap(({
      startDate,
      endDate,
      page,
      pageSize
  }) => this.adminService.getFailedKycRecords(startDate || 'all', endDate || 'all', page, pageSize).pipe(
      exhaustMap((response: {
          meta: {},
          status: boolean,
          data: {
              page: number,
              pageSize: number,
              totalCount: number,
              data: AdminKycError[]
          }
      }) => [
        adminActions.fetchKycErrorsSuccess({
              kycErrors: response.data.data,
              kycErrorsPage: response.data.page,
              kycErrorsPageSize: response.data.pageSize,
              kycErrorsTotalCount: response.data.totalCount
          })
      ]),
      catchError((error: HttpErrorResponse) => {
          this.toastr.error(error.error?.errors);
          return of(adminActions.fetchKycRecordsFailed({ message: error.error?.errors, code: error.status }))
      })
  ))
))

// ────────────────────────────────────────────────────────────────────────────────

  constructor(private action$: Actions, private adminService: AdminService, private toastr: ToastrService,) {}

  private sortOptionsForAdminUsers(userDataList : any[], sortOption?:any){
    let data = userDataList
    const sortOptions = Object.values(AdminUserSortOptions)
    if(sortOptions.includes(sortOption)){
      data = userDataList.filter(e => e[sortOption])
    }
    return data;
  }
}
