import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerService } from '../customers.service';
import * as customerActions from './customers.actions';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings/_services/settings.service';
import { FilterType } from './customers.interfaces';




@Injectable({
  providedIn: 'root'
})
export class CustomerEffects {

  internalFundTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.postInternalFundTransfer),
      exhaustMap((action) =>
        this.customerService
          .postFundTransfer(action.payload)
          .pipe(
            map((response: any) =>
              customerActions.postTransferFundsSuccess()
            ),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.postTransferFundsFailed())
            )
          )
      )
    )
  );

  externalFundTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.postExternalFundTransfer),
      exhaustMap((action) =>
        this.customerService
          .postFundTransfer(action.payload)
          .pipe(
            map((response: any) =>
              customerActions.postTransferFundsSuccess()
            ),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.postTransferFundsFailed())
            )
          )
      )
    )
  );
  refundTransferAfrica$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.initializeRefundAfrica),
      exhaustMap((action) =>
        this.customerService
          .postRefundTransfer(action.payload)
          .pipe(
            map((response: any) =>
              customerActions.refundTransactionAfricaSuccess()
            ),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.refundTransactionAfricaFailed())
            )
          )
      )
    )
  );

  fetchAdminLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminLimits),
      exhaustMap((action) =>
        this.customerService.getAllLimits().pipe(
          map((response: any) =>
            customerActions.fetchAdminLimitsSuccess({ adminLimits: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminLimitsFailed())
          )
        )
      )
    )
  );


  fetchAdminCustomerUsersByTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUsersByTransactions),
      exhaustMap((action) =>
        this.customerService
          .getUserFilteredByTransactions(action.searchData, action.category)
          .pipe(
            exhaustMap((response: any) => {
              let filteredResponse = response.data
                .map((res) => res.wallet_data)
                .filter((res) => res.length > 0);
              filteredResponse = filteredResponse.map((res) => res[0]);
              return [
                customerActions.fetchAdminCustomerUsersByTransactionsSuccess({
                  adminCustomerUsers: {
                    data: filteredResponse,
                    meta: { total: response.meta.total, page: response.meta.page },
                    status: response.status
                  }

                }),
                customerActions.customerUserSearchSuccessfull(),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.fetchAdminCustomerUsersByTransactionsFailed({ errorStatus: error.status }))
            )
          )
      )
    )
  );

  fetchAdminCustomerUsersByRegistrationDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUsersByRegistrationDate),
      exhaustMap((action) =>
        this.customerService
          .getUserFilteredByRegistrationDate(action.searchData)
          .pipe(
            exhaustMap((response: any) => [
              customerActions.fetchAdminCustomerUsersByRegistrationDateSuccess({
                adminCustomerUsers: {
                  data: this.sortOptionsForAdminUsers(response.data, action.sortOption),
                  meta: { total: response.meta.total, page: response.meta.page },
                  status: response.status
                }
              }),
              customerActions.customerUserSearchSuccessfull(),
            ]),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.fetchAdminCustomerUsersByRegistrationDateFailed({ errorStatus: error.status }))
            )
          )
      )
    )
  );

  fetchAdminCustomerUsersByMoneySentByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUsersByMoneySentByCountry),
      exhaustMap((action) =>
        this.customerService
          .getUserFilteredByMoneySentByCountry(action.searchData, action.flag)
          .pipe(
            exhaustMap((response: any) => {
              let filteredResponse = response.data
                .map((res) => res.wallet_data)
                .filter((res) => res.length > 0);
              filteredResponse = filteredResponse.map((res) => res[0]);
              return [
                customerActions.fetchAdminCustomerUsersByMoneySentByCountrySuccess({
                  adminCustomerUsers: {
                    data: filteredResponse,
                    meta: { total: response.meta.total, page: response.meta.page },
                    status: response.status
                  }
                }),
                customerActions.customerUserSearchSuccessfull(),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.fetchAdminCustomerUsersByMoneySentByCountryFailed({ errorStatus: error.status }))
            )
          )
      )
    )
  );

  fetchAdminCustomerUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUsers),
      exhaustMap((action) =>
        this.customerService.getAllUsers(action.page, action.pageSize, action.search).pipe(
          map((response: any) =>
            customerActions.fetchAdminCustomerUsersSuccess({
              adminCustomerUsers: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerUsersFailed({ errorStatus: error.status }))
          )
        )
      )
    )
  );

  fetchAdminCustomerUsersNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUsersNew),
      exhaustMap((action) =>
        this.customerService.getAllUsersNew(action.page, action.pageSize, action.search, action.isCompact, action.appNames, action.searchData).pipe(
          map((response: any) =>
            customerActions.fetchAdminCustomerUsersNewSuccess({
              adminCustomerUsersNew: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerUsersNewFailed({ errorStatus: error.status }))
          )
        )
      )
    )
  );

  fetchPendingApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchPendingApplications),
      exhaustMap((action) =>
        this.customerService.getAllPendingTransactions(action.page, action.pageSize, action.search, action.empty_kyc).pipe(
          map((response: any) =>
            customerActions.fetchPendingApplicationsSuccess({
              adminCustomerUsersNew: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchPendingApplicationsFailed({ errorStatus: error.status }))
          )
        )
      )
    )
  );

  synchronizeBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.synchronizeBalance),
      exhaustMap((action) =>
        this.customerService.synchronizeBalance(action.accountId).pipe(
          map((response: any) =>
            customerActions.synchronizeBalanceSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.synchronizeBalanceFailed())
          )
        )
      )
    )
  );

  adminCustomerUserCardActivation = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.adminCustomerUserCardActivationChange),
      exhaustMap((action) =>
        this.customerService.adminCustomerUserActivationCardChange(action.activateAction, action.cardId).pipe(
          map((response: any) =>

            customerActions.adminCustomerUserCardActivationChangeSuccessfull({ activateAction: action.activateAction }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.adminCustomerUserCardActivationChangeFailed({ activateAction: action.activateAction }))
          )
        )
      )
    )
  );

  adminCustomerUserCardDestory = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.adminCustomerUserCardDestory),
      exhaustMap((action) =>
        this.customerService.adminCustomerUserDestroy(action.cardId).pipe(
          map((response: any) =>

            customerActions.adminCustomerUserCardDestroySuccessfull(),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.adminCustomerUserCardDestroyFailed())
          )
        )
      )
    )
  );

  adminCustomerUserAccountCloseChange = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.adminCustomerUserCloseChange),
      exhaustMap((action) =>
        this.customerService.adminCustomerUserCloseAcountChange(action.closeAction, action.accountId).pipe(
          map((response: any) =>

            customerActions.adminCustomerUserCloseChangeSuccessfull({ closeAction: action.closeAction }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.adminCustomerUserCloseChangeFailed({ closeAction: action.closeAction }))
          )
        )
      )
    )
  );

  adminCustomerUserTrustChange = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.adminCustomerUserTrustChange),
      exhaustMap((action) =>
        this.customerService.adminCustomerUserTrustChange(action.trustAction, action.walletNo, action.schemeCode).pipe(
          map((response: any) =>

            customerActions.adminCustomerUserTrustChangeSuccessfull({ trustAction: action.trustAction }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.adminCustomerUserTrustChangeFailed({ trustAction: action.trustAction }))
          )
        )
      )
    )
  );

  adminCustomerInternationalTransferChange = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.adminCustomerUserInternationTransferOptionChange),
      exhaustMap((action) =>
        this.customerService.adminCustomerUserInternationTransferOptionChange(action.walletNo, action.schemeCode).pipe(
          map((response: any) =>

            customerActions.adminCustomerUserInternationTransferOptionChangeSuccessfull({ international: action.international }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.adminCustomerUserInternationTransferOptionChangeFailed({ international: action.international }))
          )
        )
      )
    )
  );

  adminCustomerUserLimitLevelChange = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.setAdminUserLevel),
      exhaustMap((action) =>
        this.customerService.setAdminUserLevel(action.walletNo, action.level).pipe(
          map((response: any) =>

            customerActions.setAdminUserLevelSuccess({ levelChanged: action.level }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.setAdminUserLevelFailed())
          )
        )
      )
    )
  );


  adminCustomerUserPinBlockChange = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.adminCustomerUserPinBlockChange),
      exhaustMap((action) =>
        this.customerService.adminCustomerUserPinBlockChange(action.blockAction, action.walletNo, action.schemeCode).pipe(
          map((response: any) =>

            customerActions.adminCustomerUserPinBlockChangeSuccessfull({ blockAction: action.blockAction }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.adminCustomerUserPinBlockChangeFailed({ blockAction: action.blockAction }))
          )
        )
      )
    )
  );

  changeAdminCustomerUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.changeAdminCustomerUserStatus),
      exhaustMap((action) =>
        this.customerService
          .setUserLockStatusByWalletNoAndSchemeCode(
            action.statusType,
            action.walletNo,
            action.schemeCode
          )
          .pipe(
            map((response: any) =>
              customerActions.adminCustomerUserStatusChangeSuccessfull({
                statusType: action.statusType,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                customerActions.adminCustomerUserStatusChangeFailed({
                  statusType: action.statusType,
                })
              )
            )
          )
      )
    )
  );

  fetchAdminCustomerAccountBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerAccountBalance),
      exhaustMap((action) =>
        this.customerService.fetchAccountBalance(action.cardNumOrAccountId, action.country, action.accountType).pipe(
          map((response: any) =>
            customerActions.fetchAdminCustomerAccountBalanceSuccess({ balance: response })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerAccountBalanceFailed())
          )
        )
      )
    )
  );

  createUserCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.createUserCard),
      exhaustMap((action) =>
        this.customerService.createUserCard(action.account_id, action.card_type).pipe(
          exhaustMap((response: any) =>
            [
              customerActions.createUserCardSuccess(),
              customerActions.fetchAdminCustomerUsers({ page: 1, pageSize: 500, search: '' })
            ]
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.createUserCardFailed())
          )
        )
      )
    )
  );

  sendAdminCustomerUserWelcomeMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.sendAdminUserWelcomeMail),
      exhaustMap((action) =>
        this.customerService.sendUserWelcomeMail(action.messagePayload).pipe(
          map((response: any) =>
            customerActions.sendAdminUserWelcomeMailSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.sendAdminUserWelcomeMailFailed())
          )
        )
      )
    )
  );

  // user transactions effects
  fetchAdminCustomerUserTransactionsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUserTransactionsById),
      exhaustMap((action) =>
        this.customerService.getAllUserTransactionsById(action.id, action.page, action.pageSize).pipe(
          exhaustMap((response: any) => {
            return [
              customerActions.fetchAdminCustomerUserTransactionsByIdSuccess({
                adminCustomerUserTransactions: response,
              }),
              customerActions.customerUserSearchSuccessfull(),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toast.error(error.error?.message, 'An error occured');
            return of(
              customerActions.fetchAdminCustomerUserTransactionsByIdFailed({
                errorStatus: error.status,
              })
            );
          }
          )
        )
      )
    )
  );

  fetchAdminCustomerUserTransactionsByIdAndDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerUserTransactionsByIdAndDateRange),
      exhaustMap((action) =>
        this.customerService
          .getUserTransactionsByIdAndDateRange(action.id, action.searchData)
          .pipe(
            exhaustMap((response: any) => [
              customerActions.fetchAdminCustomerUserTransactionsByIdSuccess({
                adminCustomerUserTransactions: response.data,
              }),
              customerActions.customerUserSearchSuccessfull(),
            ]),
            catchError((error: HttpErrorResponse) => {
              this.toast.error(error.error?.message, 'An error occured');
              return of(
                customerActions.fetchAdminCustomerUserTransactionsByIdFailed({
                  errorStatus: error.status,
                })
              );
            }
            )
          )
      )
    )
  );

  adminCustomerFiltersAccountType = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerFiltersAccountType),
      exhaustMap((action) =>
        this.customerService.getFilters(FilterType.ACCOUNT_TYPE).pipe(
          map((response: any) => {
            return customerActions.fetchAdminCustomerFiltersSuccess({ adminCustomerFiltersArray: response.data, filterType: FilterType.ACCOUNT_TYPE })
          })
        )
      )
    )
  )

  adminCustomerFiltersChannelID = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerFiltersChannelID),
      exhaustMap((action) =>
        this.customerService.getFilters(FilterType.CHANNEL_ID).pipe(
          map((response: any) => {
            return customerActions.fetchAdminCustomerFiltersSuccess({ adminCustomerFiltersArray: response.data, filterType: FilterType.CHANNEL_ID })
          })
        )
      )
    )
  )

  adminCustomerFiltersTransType = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerFiltersTransType),
      exhaustMap((action) =>
        this.customerService.getFilters(FilterType.TRANSACTION_TYPE).pipe(
          map((response: any) => {
            return customerActions.fetchAdminCustomerFiltersSuccess({ adminCustomerFiltersArray: response.data, filterType: FilterType.TRANSACTION_TYPE })
          })
        )
      )
    )
  )

  initialRefundTransaction = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.initializeRefundTransaction),
      exhaustMap((action) =>
        this.customerService.initiateTransactionRefund(action.transactionDto, action.setting).pipe(
          map((response: any) =>

            customerActions.refundTransactionSuccess(),
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.refundTransactionFailed({
              message: error.error.message || 'Reversal Transaction failed',
              statusCode: error.status
            }))
          )
        )
      )
    )
  );

  fetchAdminCustomerTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerTransactions),
      exhaustMap((action) =>
        this.customerService.getAllTransactions(action.page, action.pageSize, action.search, action.searchData).pipe(
          map((response: any) =>
            customerActions.fetchAdminCustomerTransactionsSuccess({
              adminCustomerTransactions: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerTransactionsFailed())
          )
        )
      )
    )
  );

  fetchAdminCustomerTransactionsByDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerTransactionsByDateRange),
      exhaustMap((action) =>
        this.customerService.getTransactionsByDateRange(action.searchData).pipe(
          exhaustMap((response: any) => [
            customerActions.fetchAdminCustomerTransactionsSuccess({
              adminCustomerTransactions: response,
            }),
            customerActions.customerTransactionSearchSuccessfull(),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerTransactionsFailed())
          )
        )
      )
    )
  );

  fetchAdminMerchantAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminMerchantAccounts),
      exhaustMap((action) =>
        this.customerService.getMerchantAccountList().pipe(
          map((response: any) =>
            customerActions.setAdminCustomerMerchantAccounts({
              merchantAccounts: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminMerchantAccountsFailed())
          )
        )
      )
    )
  );

  saveToSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.saveToSource),
      exhaustMap((action) =>
        this.customerService.saveToSource(action.payload).pipe(
          map((response: any) =>
            customerActions.saveToSourceSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.saveToSourceFailed())
          )
        )
      )
    ));

  fetchAdminCustomerMerchants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerMerchants),
      exhaustMap((action) =>
        this.customerService.getAllMerchants().pipe(
          map((response: any) =>
            customerActions.setAdminCustomerMerchants({
              adminCustomerMerchants: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerMerchantsFailed())
          )
        )
      )
    )
  );

  fetchAdminCustomerMerchantsByRegistrationDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerMerchantsByRegistrationDate),
      exhaustMap((action) =>
        this.customerService
          .getMerchantsFilteredByRegistrationDate(action.searchData)
          .pipe(
            exhaustMap((response: any) => [
              customerActions.setAdminCustomerMerchants({
                adminCustomerMerchants: response.data,
              }),
              customerActions.customerMerchantSearchSuccessfull(),
            ]),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.fetchAdminCustomerMerchantsFailed())
            )
          )
      )
    )
  );


  changeAdminCustomerMerchantStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.changeAdminCustomerMerchantStatus),
      exhaustMap((action) =>
        this.customerService
          .updateMerchant({
            merchant_id: action.merchantId,
            activate: action.activate,
          })
          .pipe(
            map((response: any) =>
              customerActions.adminCustomerMerchantStatusChangeSuccessfull({
                activate: action.activate,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                customerActions.adminCustomerMerchantStatusChangeFailed({
                  activate: action.activate,
                })
              )
            )
          )
      )
    )
  );

  sendAdminCustomerMerchantWelcomeMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.sendAdminMerchantWelcomeMail),
      exhaustMap((action) =>
        this.customerService.sendMerchantWelcomeMail(action.messagePayload).pipe(
          map((response: any) =>
            customerActions.sendAdminMerchantWelcomeMailSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.sendAdminMerchantWelcomeMailFailed())
          )
        )
      )
    )
  );

  createXcelMerchantAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.createXcelMerchantCustomer),
      exhaustMap((action) =>
        this.customerService.createXcelMerchantCustomer(action.requestBody)
          .pipe(
            exhaustMap((response: any) => [
              customerActions.createXcelMerchantCustomerSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
              this.toast.error(error.error?.errors, 'An error occured');
              return of(customerActions.createXcelMerchantCustomerFailed());
            }
            )
          )
      )
    )
  );

  fetchAdminFeeGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminFeeGroups),
      exhaustMap((action) =>
        this.customerService.getAdminFeeGroupsByCountry(action.feeType).pipe(
          map((response: any) =>
            customerActions.setAdminFeeGroups({ adminFeeGroups: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminFeeGroupsFailed())
          )
        )
      )
    )
  );

  fetchAdminCustomerMerchantById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerMerchantById),
      exhaustMap((action) =>
        this.customerService.getMerchantById(action.merchantId).pipe(
          map((response: any) =>
            customerActions.setFetchedAdminCustomerMerchant({
              fetchedAdminCustomerMerchant: response.data.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerMerchantByIdFailed())
          )
        )
      )
    )
  );

  updateAdminCustomerMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.updateAdminCustomerMerchant),
      exhaustMap((action) =>
        this.customerService
          .updateMerchant({
            merchant_id: action.merchantId,
            ...action.updateData,
          })
          .pipe(
            map((response: any) =>
              customerActions.updateAdminCustomerMerchantSuccessful()
            ),
            catchError((error: HttpErrorResponse) =>
              of(customerActions.updateAdminCustomerMerchantSuccessful())
            )
          )
      )
    )
  );

  fetchAdminCustomerMerchantAccountById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminCustomerMerchantAccountById),
      exhaustMap((action) =>
        this.customerService.getMerchantAccountDetailsById(action.customerId).pipe(
          map((response: any) =>
            customerActions.setAdminCustomerMerchantAccountList({
              accountList: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminCustomerMerchantAccountByIdFailed())
          )
        )
      )
    )
  );

  fetchAdminFeeAccountsByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminFeeAccountsByCountry),
      exhaustMap((action) =>
        this.customerService.getAdminFeesAccountByCountry().pipe(
          map((response: any) =>
            customerActions.setAdminFeesAccountByCountry({
              adminFeesAccountByCountry: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminManagedAccountsFailed())
          )
        )
      )
    )
  );

  fetchAdminMerhcantEntTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerActions.fetchAdminMerchantEntTypes),
      exhaustMap((action) =>
        this.settingsService.getMerchantCategories().pipe(
          map((response: any) =>
            customerActions.setAdminMerchantEntTypes({
              adminMerchantEntTypes: response,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(customerActions.fetchAdminMerchantEntTypesFailed())
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private toast: ToastrService,
    private settingsService: SettingsService,
  ) { }
  private sortOptionsForAdminUsers(userDataList: any[], sortOption?: any) {
    let data = userDataList;
    const sortOptions = Object.values(AdminUserSortOptions);
    if (sortOptions.includes(sortOption)) {
      data = userDataList.filter(e => e[sortOption]);
    }
    return data;
  }

}

export enum AdminUserSortOptions {
  TRUSTED = 'trusted',
  BANNED = 'banned',
  DISABLED = 'disabled',
  CLOSED = 'closed'
}
