import { Injectable } from '@angular/core';

@Injectable({
    providedIn : 'root'
})
export class AppSessionStorage{

    storeToStorage(key, value){
        sessionStorage.setItem(key, btoa(JSON.stringify(value)));
    }


    getFromStorage(key){
        return JSON.parse(atob(sessionStorage.getItem(key)));
    }


    isStoredInSession(key){
        return (sessionStorage.getItem(key) !== null || sessionStorage.getItem(key) !== undefined) ? true : false;
    }

    clearAll(){
        sessionStorage.clear();
    }

}

export enum SESSION_KEY{
    DBOARD_COUNTRY_ISO_TWO = 'DBD_COUNTRY_ISO_2',
    DBOARD_COUNTRY_CODE = 'DBD_COUNTRY_CODE',
    DBOARD_CURRENCY_CODE = 'DBD_CURRENCY_CODE',
    ACCOUNT_OBJ = 'create_account_dto_obj',
    ISSUER = 'issuer',
    SUB_ISSUER = 'sub_issuer',
    LOGO = 'logo',
    ORG_TYPE = 'org_type',
    ORG_ID = 'org_id',
}
