import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { IssuerService } from '../issuers.service';
import * as issuerActions from './issuers.actions';


@Injectable({
  providedIn: 'root',
})
export class IssuersEffects {

    fetchIssuers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(issuerActions.fetchIssuers),
      exhaustMap((action) =>
        this.issuerService
          .getIssuers()
          .pipe(
            exhaustMap((response: any) => [
                issuerActions.fetchIssuersSuccess({ issuers: response.data}),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.message, 'An error occured');
                return of(issuerActions.fetchIssuersFailed());
            }
            )
          )
      )
    )
  );
    fetchSubIssuers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(issuerActions.fetchSubIssuers),
      exhaustMap((action) =>
        this.issuerService
          .getSubIssuers()
          .pipe(
            exhaustMap((response: any) => [
                issuerActions.fetchSubIssuersSuccess({ subIssuers: response.data}),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.message, 'An error occured');
                return of(issuerActions.fetchSubIssuersFailed());
            }
            )
          )
      )
    )
  );

    createIssuer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(issuerActions.createIssuers),
      exhaustMap((action) =>
        this.issuerService
          .createIssuer(action.issuerDto)
          .pipe(
            exhaustMap((response: any) => [
                issuerActions.createIssuersSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.message, 'An error occured');
                return of(issuerActions.createIssuersFailed());
            }
            )
          )
      )
    )
  );

    updateIssuer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(issuerActions.updateIssuers),
      exhaustMap((action) =>
        this.issuerService
          .updateIssuerById(action.issuerDto, action.issuerId)
          .pipe(
            exhaustMap((response: any) => [
                issuerActions.updateIssuersSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.message, 'An error occured');
                return of(issuerActions.updateIssuersFailed());
            }
            )
          )
      )
    )
  );

    deleteIssuer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(issuerActions.deleteIssuer),
      exhaustMap((action) =>
        this.issuerService
          .deleteIssuerById(action.issuerId)
          .pipe(
            exhaustMap((response: any) => [
                issuerActions.deleteIssuerSuccess(),
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.message, 'An error occured');
                return of(issuerActions.deleteIssuerFailed());
            }
            )
          )
      )
    )
  );


  createSubIssuer$ = createEffect(() =>
  this.actions$.pipe(
    ofType(issuerActions.createSubIssuers),
    exhaustMap((action) =>
      this.issuerService
        .createSubIssuer(action.subIssuerDto)
        .pipe(
          exhaustMap((response: any) => [
              issuerActions.createSubIssuersSuccess(),
          ]),
          catchError((error: HttpErrorResponse) => {
              this.toastr.error(error.error?.message, 'An error occured');
              return of(issuerActions.createSubIssuersFailed());
          }
          )
        )
    )
  )
);

updateSubIssuer$ = createEffect(() =>
this.actions$.pipe(
  ofType(issuerActions.updateSubIssuers),
  exhaustMap((action) =>
    this.issuerService
      .updateSubIssuerById(action.subIssuerDto, action.subIssuerId)
      .pipe(
        exhaustMap((response: any) => [
            issuerActions.updateSubIssuersSuccess(),
        ]),
        catchError((error: HttpErrorResponse) => {
            this.toastr.error(error.error?.message, 'An error occured');
            return of(issuerActions.updateSubIssuersFailed());
        }
        )
      )
  )
)
);

deleteSubIssuer$ = createEffect(() =>
this.actions$.pipe(
  ofType(issuerActions.deleteSubIssuer),
  exhaustMap((action) =>
    this.issuerService
      .deleteSubIssuerById(action.subIssuerId)
      .pipe(
        exhaustMap((response: any) => [
            issuerActions.deleteSubIssuerSuccess(),
        ]),
        catchError((error: HttpErrorResponse) => {
            this.toastr.error(error.error?.message, 'An error occured');
            return of(issuerActions.deleteSubIssuerFailed());
        }
        )
      )
  )
)
);



    constructor(
        private actions$: Actions,
        private issuerService: IssuerService,
        private toastr: ToastrService,
        ) {}
}
