import { createAction, props } from '@ngrx/store';
import { CorporateRuleModel, FinancialInstitutionsModel, PepAndSanctionsModel, RulesSetsModel } from '../_interfaces/trans-monitoring.interface';



// Rules Sets
export const fetchRulesSets = createAction('[RulesSets Page] fetch issuer users');
export const fetchRulesSetsSuccess = createAction(
  '[RulesSets Page] fetch rulesSetss successful',
  props<{ rulesSets: RulesSetsModel[] }>()
);
export const fetchRulesSetsFailed = createAction('[Rules sets Page] fetch rules sets failed');
export const createRuleSets = createAction(
  '[RulesSets page] create rules-sets',
  props<{ RulesSetsModel: RulesSetsModel }>()
);
export const createRuleSetsSuccess = createAction(
  '[RulesSets page] create rules-sets successful',
  props<{ newRuleSets: RulesSetsModel }>()
);
export const createRuleSetsFailed = createAction('[RulesSets page] create rules-sets failed');
export const deleteRulesSets = createAction('[RulesSets Page] delete rules sets', props<{ rulesSetsId: string }>());
export const deleteRulesSetsSuccess = createAction('[RulesSets Page] delete rules sets successful');
export const deleteRulesSetsFailed = createAction('[RulesSets Page] delete rules sets failed');
export const updateRulesSets = createAction(
  '[Rulessets Page] update RulesSets',
  props<{ rulesSetsId: string, RulesSetsModel: RulesSetsModel }>()
);
export const updateRulesSetsSuccess = createAction('[RulesSets Page] update RulesSets successful');
export const updateRulesSetsFailed = createAction('[RulesSets Page] update RulesSets failed');



// Corporate Rules
export const fetchCorporateRules = createAction(
  '[CorporateRules Page] fetch CorporateRules',
  props<{ page?: string; pageSize?: string, accountNumber?: string}>()
);
export const fetchCorporateRulesSuccess = createAction(
  '[CorporateRules Page] fetch CorporateRules successful',
  props<{ CorporateRules: CorporateRuleModel[] }>()
);
export const fetchCorporateRulesFailed = createAction('[CorporateRules sets Page] fetch CorporateRules failed');
export const createCorporateRule = createAction(
  '[CorporateRules page] create CorporateRules',
  props<{ CorporateRuleModel: CorporateRuleModel }>()
);
export const createCorporateRuleSuccess = createAction(
  '[CorporateRules page] create CorporateRules successful',
  props<{ newCorporateRule: CorporateRuleModel }>()
);
export const createCorporateRuleFailed = createAction('[CorporateRules page] create CorporateRules failed');
export const deleteCorporateRule = createAction(
  '[CorporateRules Page] delete CorporateRules sets',
  props<{ corporateRuleId: string }>()
);
export const deleteCorporateRuleSuccess = createAction('[CorporateRules Page] delete CorporateRules successful');
export const deleteCorporateRuleFailed = createAction('[CorporateRules Page] delete CorporateRules failed');
export const updateCorporateRule = createAction(
  '[CorporateRules Page] update CorporateRules',
  props<{ corporateRuleId: string, CorporateRuleModel: CorporateRuleModel }>()
);
export const updateCorporateRuleSuccess = createAction('[CorporateRules Page] update CorporateRules successful');
export const updateCorporateRuleFailed = createAction('[CorporateRules Page] update CorporateRules failed');


export const createPepsAndSanctions = createAction(
  '[PepsAndSanctions page] create PepsAndSanctions',
  props<{ pepsAndSanctionsModel: PepAndSanctionsModel }>()
);
export const createPepsAndSanctionsSuccess = createAction(
  '[PepsAndSanctions page] create PepsAndSanctions success',
  props<{ pepsAndSanctions: PepAndSanctionsModel }>()
);
export const createPepsAndSanctionsFailed = createAction('[createPepsAndSanctions page] create PepsAndSanctions failed');





export const fetchFinanceInstitution = createAction(
  '[financeInst Page] fetch finance Institutions',
);
export const fetchFinanceInstitutionSuccess = createAction(
  '[finance Inst Page] fetch financial instition successful',
  props<{ FinancialInstitutions: FinancialInstitutionsModel[] }>()
);
export const fetchFinanceInstitutionFailed = createAction('[Finance Insitution Page] fetch finance institution failed');

export const createFinanceInstitution = createAction(
  '[financeInst page] create financeInst',
  props<{ FinancialInstitutionModel: FinancialInstitutionsModel }>()
);
export const createFinanceInstitutionSuccess = createAction(
  '[financeInst page] create financeInst successful',
  props<{ newFinancialInstitution: FinancialInstitutionsModel }>()
);
export const createFinanceInstitutionFailed = createAction('[financeInst page] create financeInst failed');
export const deleteFinanceInstitution = createAction(
  '[financeInst Page] delete financeInst ',
  props<{ financialInstitutionId: string }>()
);
export const deleteFinanceInstitutionSuccess = createAction('[financeInst Page] delete financeInst successful');
export const deleteFinanceInstitutionFailed = createAction('[financeInst Page] delete financeInst failed');
export const updateFinanceInstitution = createAction(
  '[financeInst Page] update financeInst',
  props<{ financialInstitutionId: string; financialInstitution: FinancialInstitutionsModel }>()
);
export const updateFinanceInstitutionSuccess = createAction('[financeInst Page] update financeInst successful');
export const updateFinanceInstitutionFailed = createAction('[financeInst Page] update financeInst failed');

