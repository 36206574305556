import { createAction, props } from '@ngrx/store';
import { IssuerDto, SubIssuerDto } from './issuers.dtos';
import { Issuer } from './issuers.interfaces';

export const fetchIssuers = createAction(
    '[Issuers Module] READ: fetch issuers'
);

export const fetchIssuersFailed = createAction(
    '[Issuers Module] READ: fetch issuers Failed'
);
export const fetchIssuersSuccess = createAction(
    '[Issuers Module] fetch issuers success',
    props<{issuers: Issuer[]}>()
);

export const fetchSubIssuers = createAction(
    '[Issuers Module] READ: fetch sub issuers'
);

export const fetchSubIssuersFailed = createAction(
    '[Issuers Module] READ: fetch sub issuers Failed'
);
export const fetchSubIssuersSuccess = createAction(
    '[Issuers Module] fetch sub issuers success',
    props<{subIssuers: Issuer[]}>()
);



export const createIssuers = createAction(
    '[Issuers Module] READ: create issuers',
    props<{issuerDto}>()
);

export const createIssuersFailed = createAction(
    '[Issuers Module] READ: create issuers Failed'
);
export const createIssuersSuccess = createAction(
    '[Issuers Module] create issuers success'
);


export const updateIssuers = createAction(
    '[Issuers Module] READ: update issuers',
    props<{issuerDto, issuerId: string}>()
);

export const updateIssuersFailed = createAction(
    '[Issuers Module] READ: update issuers Failed'
);
export const updateIssuersSuccess = createAction(
    '[Issuers Module] update issuers success'
);


export const deleteIssuer = createAction(
    '[Issuers Module] READ: delete issuers',
    props<{issuerId: string}>()
);

export const deleteIssuerFailed = createAction(
    '[Issuers Module] READ: delete issuers Failed'
);
export const deleteIssuerSuccess = createAction(
    '[Issuers Module] delete issuers success'
);


export const createSubIssuers = createAction(
    '[Issuers Module] READ: create sub issuers',
    props<{subIssuerDto}>()
);

export const createSubIssuersFailed = createAction(
    '[Issuers Module] READ: create sub issuers Failed'
);
export const createSubIssuersSuccess = createAction(
    '[Issuers Module] create sub issuers success'
);

export const updateSubIssuers = createAction(
    '[Issuers Module] READ: update sub issuers',
    props<{subIssuerDto: SubIssuerDto, subIssuerId: string}>()
);

export const updateSubIssuersFailed = createAction(
    '[Issuers Module] READ: update sub issuers Failed'
);
export const updateSubIssuersSuccess = createAction(
    '[Issuers Module] update issuers sub success'
);

export const deleteSubIssuer = createAction(
    '[Issuers Module] READ: delete sub issuers',
    props<{subIssuerId: string}>()
);

export const deleteSubIssuerFailed = createAction(
    '[Issuers Module] READ: delete sub issuers Failed'
);
export const deleteSubIssuerSuccess = createAction(
    '[Issuers Module] delete sub issuers success'
);

