import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AuthHeaderService } from 'src/app/shared/services/authheaderservice';

@Injectable({
  providedIn: 'root'
})
export class FwpService  {

  constructor(private http: HttpClient,private authHeaderService:AuthHeaderService, private sharedService: SharedService) { }

  getOverviewData(startDate,endDate) {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
      const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
    let params = new HttpParams();
    if(startDate){
      params = params.append('startDate', startDate);
    }
    if(endDate){
      params = params.append('endDate', endDate);
    }

    const httpOptions = {
      headers: headers,
      params: params
    };

     return this.http.get(`${environment.XAS_URL}/external/fwp-loan/analytics/${this.sharedService.getSelectedCountryFromSession()}`,httpOptions);
  }
  getOverviewDataById(id,startDate,endDate) {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
      const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
    let params = new HttpParams();
    if(startDate){
      params = params.append('startDate', startDate);
    }
    if(endDate){
      params = params.append('endDate', endDate);
    }

    const httpOptions = {
      headers: headers,
      params: params
    };

     return this.http.get(`${environment.XAS_URL}/external/fwp-loan/analytics/merchant/${id}`,httpOptions);
  }

  getLoanHistoryById(id,skip,limit,startDate,endDate) {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
      const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
    let params = new HttpParams();
    if(skip){
      params = params.append('skip', skip);
    }
    if(limit){
      params = params.append('limit', limit);
    }
    if(startDate){
      params = params.append('startDate', startDate);
    }
    if(endDate){
      params = params.append('endDate', endDate);
    }

    const httpOptions = {
      headers: headers,
      params: params
    };

     return this.http.get(`${environment.XAS_URL}/external/fwp-loan/history/accumulated/merchant/${id}`,httpOptions);
  }

  getOutStandingLoanById(id,skip,limit,startDate,endDate) {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
      const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
    let params = new HttpParams();
    if(skip){
      params = params.append('skip', skip);
    }
    if(limit){
      params = params.append('limit', limit);
    }
    if(startDate){
      params = params.append('startDate', startDate);
    }
    if(endDate){
      params = params.append('endDate', endDate);
    }

    const httpOptions = {
      headers: headers,
      params: params
    };

     return this.http.get(`${environment.XAS_URL}/external/fwp-loan/outstanding/accumulated/merchant/${id}`,httpOptions);
  }

  getMerchantLoanData(skip,limit) {
    const xAuthHeader = this.authHeaderService.generateAuthHeader({});
      const headers = new HttpHeaders()
      .set('X-Auth-Signature', xAuthHeader)
      .set('X-APP-ID', `${environment.X_APP_ID}`)
      .set('X-ISSUER-ID', `${environment.X_ISSUER_ID}`);
    let params = new HttpParams();
    
    if(skip){
      params = params.append('skip', skip);
    }
    if(limit){
      params = params.append('limit', limit);
    }

    const httpOptions = {
      headers: headers,
      params: params
    };

     return this.http.get(`${environment.XAS_URL}/external/fwp-loan/setup/issuer/${this.sharedService.getSelectedCountryFromSession()}`,httpOptions);

  }


}
