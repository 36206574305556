<div class="container-fluid p-0 m-0 bg-light admin-container">
  <header class="header">
    <div
      class="header-topbar"
      [ngClass]="{ 'country-sa': selectedCountry == 'ZA' }"
    >
      <!-- <div class="wrapper d-flex container pt-3 pb-3">
                <div class="page-brand p-2">
                    <a class="text-white brand">
                        <img alt="logo" src="{{ logoUrl }}" class="logoSmall link">
                    </a>
                </div>
                <div class="col-md" *ngIf="displayCountryForSuperAdmin() && ( countries$ | async) as countries">
                    <div class="float-right">
                        <select name="country" class="form-control country-option" [(ngModel)]="selectedCountryObj" (change)="changeCountryForDashboard()" id="" [compareWith]="compareCountries">
                            <option [ngValue]="country" *ngFor="let country of countries">{{country.name}} - {{ country.iso_code_2}}</option>
                        </select>
                    </div>
                </div>
            </div> -->

      <!---NAVBAR-->
      <div class="container p-0">
        <nav class="navbar navbar-expand bg-transparent">
          <a *ngIf="dashboardConfig" class="navbar-brand">
            <img
              alt="logo"
              [src]="dashboardConfig.issuer ? issuerLogo : logoUrl"
              class="link"
              style="height: 50px"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="navbar-collapse">
            <ul class="navbar-nav ml-auto d-flex align-items-center">
              <li class="nav-item mt-3 active">
                <app-language-switch [noBorder]="true"></app-language-switch>
              </li>
              <li class="nav-item dropdown">
                <span class="" *ngIf="selectedFlag"
                  ><img height="30" [src]="selectedFlag" alt=""
                /></span>
                <a
                  class="nav-link dropdown-toggle text-white d-inline"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ "MENU.HI" | translate }}, {{ dashboardConfig?.firstName }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="dropdown-item"
                    *ngIf="
                      dashboardConfig.type == 'COUNTRY_ADMIN' ||
                      dashboardConfig.type == 'SUPER_ADMIN' ||
                      canViewIssuerUsers()
                    "
                    routerLink="/dashboard/issuer-users"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    translate
                    >MENU.USERS</a
                  >
                  <a
                    class="dropdown-item"
                    routerLink="/dashboard/settings"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    translate
                    >MENU.SETTINGS</a
                  >
                  <a
                    class="dropdown-item"
                    *ngIf="canViewActivityLogs()"
                    routerLink="/dashboard/activity-log"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    translate
                    >MENU.ACTIVITY_LOGS</a
                  >
                  <!-- <a class="dropdown-item"
                                routerLink="/dashboard/biller-config"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                            >Billers Configuration</a> -->
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    (click)="logOut()"
                    translate
                    >MENU.LOGOUT</a
                  >
                </div>
              </li>
              <li
                class="nav-item active"
                *ngIf="
                  displayCountryForSuperAdmin() &&
                  (countries$ | async) as countries
                "
              >
                <select
                  name="country"
                  class="form-control country-option"
                  [(ngModel)]="selectedCountryObj"
                  (change)="changeCountryForDashboard()"
                  id=""
                  [compareWith]="compareCountries"
                >
                  <option [ngValue]="country" *ngFor="let country of countries">
                    {{ "COUNTRY." + country.iso_code_2 | translate }}
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- END-OF-NAVBAR -->

      <!-- <div class="float-right mt-3 d-none">
                <button class="btn btn-sm btn-danger mx-2" (click)="logOut()">Log Out</button>
            </div> -->
    </div>
    <div class="top-navbar">
      <div class="wrapper d-flex justify-content-center" id="navbar-wrapper">
        <ul class="nav-menu">
          <li *ngIf="canViewCustomers()" class="admin-nav-item">
            <a
              routerLink="/dashboard/customers"
              routerLinkActive="active"
              translate
              >MENU.CUSTOMERS</a
            >
          </li>
          <li *ngIf="canViewKyc()" class="admin-nav-item">
            <a routerLink="/dashboard/kyc" routerLinkActive="active" translate
              >MENU.KYC</a
            >
          </li>
          <li *ngIf="canViewCountryLimit()" class="admin-nav-item">
            <a
              routerLink="/dashboard/country-limits"
              routerLinkActive="active"
              translate
              >MENU.COUNTRY_LIMIT</a
            >
          </li>
          <li *ngIf="canViewExchangeRates()" class="admin-nav-item">
            <a
              routerLink="/dashboard/exchange-rates"
              routerLinkActive="active"
              translate
              >MENU.EXCHANGE_RATES</a
            >
          </li>
          <ng-container *ngIf="!isIssuer() && canViewManageAccounts()">
            <li class="admin-nav-item">
              <a
                routerLink="/dashboard/manage-accounts"
                routerLinkActive="active"
                translate
                >Manage Accounts</a
              >
            </li>
          </ng-container>
          <li *ngIf="canViewFees()" class="admin-nav-item">
            <a routerLink="/dashboard/fees" routerLinkActive="active" translate
              >MENU.FEES</a
            >
          </li>
          <li *ngIf="canViewFees()" class="admin-nav-item">
            <a
              routerLink="/dashboard/charged-fees"
               routerLinkActive="active"
              translate
              >Charged Fees</a
            >
          </li>
          <li
            *ngIf="
              (dashboardConfig.type === 'SUPER_ADMIN' && canViewIssuers()) ||
              dashboardConfig?.type === 'COUNTRY_ADMIN'
            "
            class="admin-nav-item"
          >
            <a
              *ngIf="canViewIssuerUsers()"
              routerLink="/dashboard/issuers"
              routerLinkActive="active"
              translate
              >MENU.ISSUERS</a
            >
          </li>
          <li
            *ngIf="
              dashboardConfig?.issuer?.type === 'issuer' && canViewIssuers()
            "
            class="admin-nav-item"
          >
            <a
              routerLink="/dashboard/issuers/sub-issuers"
              routerLinkActive="active"
              translate
              >MENU.SUB_ISSUERS</a
            >
          </li>
          <li class="admin-nav-item" *ngIf="canViewFwp()">
            <a routerLink="/dashboard/fwp" routerLinkActive="active">FWP</a>
          </li>
          <li
            *ngIf="
              (dashboardConfig?.issuer?.type === 'issuer' &&
                canViewIssuers()) ||
              (dashboardConfig?.type === 'COUNTRY_ADMIN' && canViewServices())
            "
            class="admin-nav-item"
          >
            <a
              class="dropdown-toggle"
              routerLinkActive="active"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              translate
              >Services</a
            >
            <div class="dropdown-menu">
              <object
                *ngIf="loadingServices && !services"
                class="d-block mx-auto mt-4 mb-4"
                data="/assets/images/Spinner-1s-200px.svg"
                height="50"
                type="image/svg+xml"
              ></object>
              <div *ngIf="!loadingServices && services">
                <a
                  class="dropdown-item"
                  [ngClass]="!service.route ? 'is-disabled' : 'is-enabled'"
                  *ngFor="let service of services"
                  [routerLink]="service.route || router.url"
                  ><span
                    class="mr-2"
                    [ngClass]="service.active ? 'bg-success' : 'bg-danger'"
                    style="
                      border-radius: 100%;
                      padding: 1px 1px;
                      height: max-content;
                      width: max-content;
                    "
                  ></span
                  >{{ service.name }}</a
                >
              </div>
              <div *ngIf="!loadingServices && !services">
                <a class="dropdown-item text-danger"
                  >Error fetching services, click
                  <span
                    style="cursor: pointer; color: blue"
                    (click)="getUserServices()"
                    >here</span
                  >
                  to try again</a
                >
              </div>
            </div>
          </li>

          <!-- <li class="admin-nav-item">
                        <a
                        routerLink="/dashboard/admin/manage-accounts"
                        routerLinkActive="active"
                        >Manage Accounts</a></li> -->
          <!-- <li class="admin-nav-item">
                        <a
                        routerLink="/dashboard/manage-cards"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        translate
                        >MENU.MANAGE_CARDS</a></li>

                    <li *ngIf="canViewOrganisations()" class="admin-nav-item">
                        <a
                        routerLink="/dashboard/organisation"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        translate
                        >MENU.MANAGE_ORGS</a></li> -->

          <!-- <li class="admin-nav-item">
                        <a
                        routerLink="/dashboard/admin/bill-payment"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        >Bills And Payment</a></li> -->
          <!-- <li class="admin-nav-item">
            <a
              routerLink="/dashboard/biller-config"
              routerLinkActive="active"
              >Biller-config</a
            >
          </li> -->
          <li class="admin-nav-item" *ngIf="canViewTransactionMonitoring()">
            <a routerLink="/dashboard/transaction-monitoring" routerLinkActive="active"
              >Transaction Monitoring</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <mat-progress-bar
    *ngIf="routeLoading"
    class="etz-route-progress"
    mode="indeterminate"
  ></mat-progress-bar>
  <section class="user-page">
    <div class="row">
      <div class="col-xl-10 offset-xl-1 col-12 p-4">
        <div class="flexbox-b mt-3 mb-3 ml-2 row">
          <div class="col-md-11 d-none">
            <div class="row">
              <span class="mr-4 static-badge badge-info"
                ><i class="fa-building fa"></i
              ></span>
              <div>
                <h5 class="font-strong" translate>MENU.ADMIN_PORTAL</h5>
                <div class="text-secondary">
                  {{ "COUNTRY." + selectedCountryObj?.iso_code_2 | translate }}
                  - {{ selectedCountryObj?.iso_code_2 }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <form
          *ngIf="
            dashboardConfig.type == 'SUPER_ADMIN' ||
            dashboardConfig.type == 'COUNTRY_ADMIN'
          "
          [formGroup]="issuerForm"
        >
          <div
            *ngIf="dashboardConfig?.issuer?.type !== 'issuer'"
            class="row mb-4"
          >
            <div class="col-xl-4 col-lg-6 col-md-6 mb-3 mb-lg-0">
              <input
                style="background-color: inherit"
                type="text"
                formControlName="issuerCode"
                [placeholder]="'MENU.SEARCH_RECORDS_DESC' | translate"
                class="form-control"
                list="issuers"
              />
              <datalist id="issuers">
                <option
                  *ngFor="let issuer of issuers"
                  [value]="issuer.issuerCode"
                >
                  {{ issuer.name }}
                </option>
              </datalist>
            </div>
            <div class="col-xl-2 col-lg-6 col-md-6 mb-3 mb-lg-0">
              <button
                [disabled]="issuerForm.invalid"
                (click)="selectAndStoreIssuer()"
                type="button"
                class="btn btn-primary w-100 px-4 ld-ext-right"
                translate
              >
                MENU.SEARCH_RECORDS
              </button>
            </div>
            <div class="col-xl-2 col-lg-6 col-md-6 mb-3 mb-lg-0">
              <button
                *ngIf="selectedIssuer"
                (click)="clearIssuer()"
                type="button"
                class="btn btn-outline-primary w-100 px-4 ld-ext-right"
                translate
              >
                MENU.CLEAR_ISSUER
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 text-right">
              {{ "MENU.SELECTED_ISSUER" | translate }}:
              <span class="text-success" translate>{{
                selectedIssuer?.name || "MENU.NONE"
              }}</span>
            </div>
          </div>
        </form>
        <form
          *ngIf="dashboardConfig?.issuer?.type == 'issuer'"
          [formGroup]="issuerForm"
        >
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-6 col-md-6 mb-3 mb-lg-0">
              <input
                style="background-color: inherit"
                type="text"
                formControlName="issuerCode"
                [placeholder]="'MENU.SEARCH_RECORDS_DESC' | translate"
                class="form-control"
                list="subIssuers"
              />
              <datalist id="subIssuers">
                <option
                  *ngFor="let subIssuer of subIssuers"
                  [value]="subIssuer.subIssuerCode"
                >
                  {{ subIssuer.name }}
                </option>
              </datalist>
            </div>
            <div class="col-xl-2 col-lg-6 col-md-6 mb-3 mb-lg-0">
              <button
                [disabled]="issuerForm.invalid"
                (click)="selectAndStoreSubIssuer()"
                type="button"
                class="btn btn-primary w-100 px-4 ld-ext-right"
                translate
              >
                MENU.SEARCH_RECORDS
              </button>
            </div>
            <div class="col-xl-2 col-lg-6 col-md-6 mb-3 mb-lg-0">
              <button
                *ngIf="selectedSubIssuer"
                (click)="clearSubIssuer()"
                type="button"
                class="btn btn-outline-primary w-100 px-4 ld-ext-right"
                translate
              >
                MENU.CLEAR_SUB_ISSUER
              </button>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 text-right">
              {{ "MENU.SELECTED_SUB_ISSUER" | translate }}:
              <span class="text-success" translate>{{
                selectedSubIssuer?.name || "MENU.NONE"
              }}</span>
            </div>
          </div>
        </form>
        <div class="card shadow-lg border-0">
          <div class="card-body p-4">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
