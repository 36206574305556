import { createReducer, on } from '@ngrx/store';
import { IssuerUser } from '../_interfaces/issuer-user.interface';
import { createIssuerUserSuccess, fetchIssuerUsersSuccess } from './users.actions';

export interface IssuerUsersState {
  users: IssuerUser[];
}

export const initialState: IssuerUsersState = {
  users: []
};

export const _usersReducer = createReducer(initialState,

  on(fetchIssuerUsersSuccess, (state, { users }) => ({
    ...state, users
  })),

  on(createIssuerUserSuccess, (state, { newUser }) => ({
    ...state, users: [...state.users, newUser]
  }))
);

export const usersReducer = (state: IssuerUsersState, action) => _usersReducer(state, action);
