import { createAction, props } from '@ngrx/store';
import { AdminMerchantFee, AdminProductFee, AdminTransferFee } from '../_interfaces/admin-fees.interface';

export const createAdminMerchantFee = createAction(
    '[Fees Page] CREATE: Admin merchant fee record',
    props<{ adminMerchantFee: AdminMerchantFee, createType: string}>()
);

export const createAdminMerchantFeeSuccessful = createAction(
    '[Fees Page] STATUS: Admin merchant fee record creation successfull',
    props<{ successType: string }>()
);

export const createAdminMerchantFeeFailed = createAction(
    '[Fees Page] STATUS: Admin merchant fee record creation failed',
    props<{ failType: string }>()
);

export const createAdminTransferFee = createAction(
    '[Fees Page] CREATE: Admin transfer fee record',
    props<{ adminTransferFee: AdminTransferFee,  createType: string, feeId ?: string}>()
);

export const createAdminTransferFeeSuccessful = createAction(
    '[Fees Page] STATUS: Admin transfer fee record creation successful',
    props<{ successType: string }>()
);

export const createAdminTransferFeeFailed = createAction(
    '[Fees Page] STATUS: Admin transfer fee record creation failed',
    props<{ failType: string }>()
);

export const deleteAdminTransferFee = createAction(
    '[Fees Page] DELETE: Admin transfer fee record',
    props<{ feeId ?: string}>()
);

export const deleteAdminTransferFeeSuccessful = createAction(
    '[Fees Page] STATUS: Admin transfer fee record deletion successful'
);

export const deleteAdminTransferFeeFailed = createAction(
    '[Fees Page] STATUS: Admin transfer fee record deletion failed'
);

export const createAdminProductFee = createAction(
    '[Fees Page] CREATE: Admin product fee record',
    props<{ adminProductFee: AdminProductFee, productId: string, createType: string}>()
);

export const createAdminProductFeeSuccessful = createAction(
    '[Fees Page] STATUS: Admin product fee record creation successful',
    props<{ successType: string }>()
);

export const createAdminProductFeeFailed = createAction(
    '[Fees Page] STATUS: Admin product fee record creation failed',
    props<{ failType: string }>()
);

export const fetchAdminMerchantFees = createAction(
    '[Fees Page] READ: fetch Admin Merchant Fees'
);

export const fetchAdminMerchantFeesFailed = createAction(
    '[Fees Page] READ: fetch Admin Merchant Fees Failed'
);

export const fetchAdminTransferFees = createAction(
    '[Fees Page] READ: fetch Admin Transfer Fees'
);

export const fetchAdminTransferFeesFailed = createAction(
    '[Fees Page] READ: fetch Admin Transfer Fees Failed'
);

export const fetchAdminProductFees = createAction(
    '[Fees Page] READ: fetch Admin Product Fees',
    props<{merchantId: string}>()
);

export const fetchAdminProductFeesFailed = createAction(
    '[Fees Page] READ: fetch Admin Product Fees Failed'
);

export const setAdminMerchantFees = createAction(
    '[Fees Page] Set Admin Merchant Fees',
    props<{adminMerchantFees: AdminMerchantFee[]}>()
);

export const setAdminTransferFees = createAction(
    '[Fees Page] Set Admin Transfer Fees',
    props<{adminTransferFees: AdminTransferFee[]}>()
);

export const setAdminProductFees = createAction(
    '[Fees Page] Set Admin Product Fees',
    props<{adminProductFees: AdminProductFee[]}>()
);
