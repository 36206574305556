import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './modules/auth/services/auth.interceptor';
import { metaReducers, reducers } from './reducers';
import { AuthEffects } from './modules/auth/_state/auth.effects';
import { CustomerEffects } from './modules/customers/_state/customers.effects';
import { KycEffects } from './modules/kyc/_state/kyc.effects';
import { OrganisationEffects } from './modules/organisation/_state/organisation.effects';
import { FeesEffects } from './modules/fees/_state/fees.effects';
import { ExchangeRatesEffects } from './modules/exchange-rates/_state/rates.effects';
import { IssuersEffects } from './modules/issuers/_state/issuers.effects';
import { ActivityLogEffects } from './modules/activity-log/_state/activity-log.effects';
import { SettingsEffects } from './modules/settings/_state/settings.effects';
import { UsersEffects } from './modules/users/_state/users.effects';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AmlEffects } from './modules/aml/_state/aml.effects';
import { FwpEffects } from './modules/fwp/_state/fwp.effect';
import { AdminEffects } from './modules/admin-manage-accounts/_state/admin-manage-account.effects';
import { TransMonitoringEffects } from './modules/transaction-monitoring/_state/trans-monitoring.effect';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // StoreModule.forRoot({ auth : fromAuth.reducer}),
    EffectsModule.forRoot([
      AuthEffects,
      CustomerEffects,
      KycEffects,
      OrganisationEffects,
      FeesEffects,
      ExchangeRatesEffects,
      IssuersEffects,
      ActivityLogEffects,
      SettingsEffects,
      UsersEffects,
      AmlEffects,
      AdminEffects,
      FwpEffects,
      TransMonitoringEffects
    ]),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService) {
  return async () => {
    translate.setDefaultLang('en');
    await translate.use('fr').toPromise();
    await translate.use('es').toPromise();
    await translate.use('pt').toPromise();
    const returnValue = await translate.use('en').toPromise();
    return returnValue;
  };
}
