import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { FeesService } from '../_services/fees.service';
import * as feesActions from './fees.actions';

@Injectable({
    providedIn: 'root'
})
export class FeesEffects {

    fetchAdminMerchantFees$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.fetchAdminMerchantFees),
      exhaustMap((action) =>
        this.feesService.getMerchantFee().pipe(
          map((response: any) =>
            feesActions.setAdminMerchantFees({
              adminMerchantFees: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(feesActions.fetchAdminMerchantFeesFailed())
          )
        )
      )
    )
  );

  fetchAdminTransferFees$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.fetchAdminTransferFees),
      exhaustMap((action) =>
        this.feesService.getTransferFee().pipe(
          map((response: any) =>
            feesActions.setAdminTransferFees({
              adminTransferFees: response.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(feesActions.fetchAdminTransferFeesFailed())
          )
        )
      )
    )
  );

  fetchAdminProductFees$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.fetchAdminProductFees),
      exhaustMap((action) =>
        this.feesService.getProductFee(action.merchantId).pipe(
          map((response: any) =>
            feesActions.setAdminProductFees({
              adminProductFees: response.data.data,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(feesActions.fetchAdminTransferFeesFailed())
          )
        )
      )
    )
  );

  createAdminMerchantFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.createAdminMerchantFee),
      exhaustMap((action) =>
        action.createType === 'create'
          ? this.feesService.createMerchantFee(action.adminMerchantFee).pipe(
              map((response: any) =>
                feesActions.createAdminMerchantFeeSuccessful({
                  successType: 'create',
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  feesActions.createAdminMerchantFeeFailed({
                    failType: 'create',
                  })
                )
              )
            )
          : this.feesService.updateMerchantFee(action.adminMerchantFee).pipe(
              map((response: any) =>
                feesActions.createAdminMerchantFeeSuccessful({
                  successType: 'update',
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  feesActions.createAdminMerchantFeeFailed({
                    failType: 'update',
                  })
                )
              )
            )
      )
    )
  );

  createAdminMerchantFeeSuccessful$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.createAdminMerchantFeeSuccessful),
      exhaustMap((action) => [feesActions.fetchAdminMerchantFees()])
    )
  );

  createAdminTransferFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.createAdminTransferFee),
      exhaustMap((action) =>
        action.createType === 'create'
          ? this.feesService.createTransferFee(action.adminTransferFee).pipe(
              map((response: any) =>
                feesActions.createAdminTransferFeeSuccessful({
                  successType: 'create',
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  feesActions.createAdminTransferFeeFailed({
                    failType: 'create',
                  })
                )
              )
            )
          : this.feesService
              .updateTransferFee(action.adminTransferFee, action.feeId)
              .pipe(
                map((response: any) =>
                  feesActions.createAdminTransferFeeSuccessful({
                    successType: 'update',
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    feesActions.createAdminTransferFeeFailed({
                      failType: 'update',
                    })
                  )
                )
              )
      )
    )
  );

  createAdminTransferFeeSuccessful$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.createAdminTransferFeeSuccessful),
      exhaustMap((action) => [feesActions.fetchAdminTransferFees()])
    )
  );

  deleteTransferFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.deleteAdminTransferFee),
      exhaustMap((action) =>
        this.feesService.deleteTransferFee(action.feeId).pipe(
          map((response: any) =>
            feesActions.deleteAdminTransferFeeSuccessful()
          ),
          catchError((error: HttpErrorResponse) =>
            of(feesActions.deleteAdminTransferFeeFailed())
          )
        )
      )
    )
  );

  createAdminProductFee$ = createEffect(() =>
    this.action$.pipe(
      ofType(feesActions.createAdminProductFee),
      exhaustMap((action) =>
        action.createType === 'create'
          ? this.feesService
              .createProductFee(action.productId, action.adminProductFee)
              .pipe(
                map((response: any) =>
                  feesActions.createAdminProductFeeSuccessful({
                    successType: 'create',
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    feesActions.createAdminProductFeeFailed({
                      failType: 'create',
                    })
                  )
                )
              )
          : this.feesService
              .updateProductFee(action.productId, action.adminProductFee)
              .pipe(
                map((response: any) =>
                  feesActions.createAdminProductFeeSuccessful({
                    successType: 'update',
                  })
                ),
                catchError((error: HttpErrorResponse) =>
                  of(
                    feesActions.createAdminProductFeeFailed({
                      failType: 'update',
                    })
                  )
                )
              )
      )
    )
  );

  constructor(private action$: Actions, private feesService: FeesService) {}

}
