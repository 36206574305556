import { createAction, props } from '@ngrx/store';
import { AdminCustomerUserTransaction } from '../_interfaces/admin-customer-user-transaction.interface';
import { AdminCustomerMerchant, AdminCustomerMerchantAccount } from '../_interfaces/admin-customer-merchant.interface';
import { AdminCustomerTransaction } from '../_interfaces/admin-customer-transaction.interface';
import { AdminExchangeRate } from '../_interfaces/admin-exchange-rate.interface';
import { AdminKycAll, AdminKycError } from '../_interfaces/admin-kyc.interface';
import { AdminLimit } from '../_interfaces/admin-limit.interface';
import { AdminFeeAccount, AdminSettlementAccount, AdminLocalPaymentAccount, AdminAgentAccount, AdminSettlementAccounts2, AdminGlAccounts, AdminNonSettlementAccount } from '../_interfaces/admin-accounts.interface';
import { AdminMerchantFee, AdminTransferFee, AdminProductFee, AdminFees } from '../_interfaces/admin-fees.interface';
import { AdminCardConfiguration, AdminCardConfigurationCreate } from '../_interfaces/admin-cards.interface';
import { AdminCountry } from '../_interfaces/admin-country.interface';
import { CreateAgentDto } from '../_dto/create-agent.dto';
import { CreateOrganisationDto } from '../_dto/create-org.dto';
import { AdminOrganisation, AdminOrganisationStaff, AdminOrganisationStaffLoanHistory, AdminOrganisationStaffLoanTrans } from '../_interfaces/admin-organisation.interface';
import { CreateStaffDto, CreateBulkStaffDto } from '../_dto/create-staff.dto';
import { AdminUsers } from '../_interfaces/admin.interface';
import { UpdatePasswordDto } from '../_dto/update-password.dto';
import { CreateBillInfoDto } from '../_dto/create-bill-info.dto';
import { AdminBillInfo } from '../_interfaces/admin-bill-info.interface';
import { UpdateBillInfoDto } from '../_dto/update-bill-info.dto';
import { AdminBlacklistInfo } from '../_interfaces/admin-blacklist-info.interface';
import { UpdateBlacklistInfoDto } from '../_dto/update-blacklist.dto';
import { CreateBlacklistDto } from '../_dto/create-blacklist.dto';
import { MerchantAccountOpeningRequestDto } from '../_dto/send-merchant-acc-open.dto';
import { UserAccountOpeningRequestDto } from '../_dto/send-user-acc-open.dto';
import { AdminActivityLog } from '../_interfaces/admin-activity-log-interface';
import { CountryDto } from '../_dto/country.dto';
import { ExternalFundTransfer, InternalFundTransfer } from '../_dto/fund-transfer.dto';
import { FailedRegistration } from '../_interfaces/failed-registration.interface';
import { SettlementAccountDto } from '../_dto/create-settlement-account.dto';
import { LogAdminAccountDto } from '../_dto/log-admin-account.dto';
import { AdminRefundAfrica } from '../_interfaces/admin-refund.interface';
import { BillerChannel, BillerChannelService, BillerCountry, BillerService } from '../_interfaces/admin-biller-config.interfaces';
import { BillerChannelDto, BillerChannelServiceDto, BillerServiceDto } from '../_dto/biller-config.dto';
import { CreateXcelMerchantCustomerDto } from '../_dto/create-merchant.dto';
import { BankDto } from '../_dto/bank.dto';
import { Bank } from '../_interfaces/admin-bank.interface';
import { CreateCardDto } from '../_dto/create-card.dto';
import { SearchData } from '../../customers/_state/customers.interfaces';


/********************************************************************************************
 * L I S T   O F   A C T I O N S
 ********************************************************************************************
********************************************************************************************
*
*  -   A D M I N   C R E A T E    A C T I O N S 
*  -   A D M I N   F E T C H      A C T I O N S
*  -   A D M I N   S E T          A C T I O N S
* 
********************************************************************************************
*********************************************************************************************/
   
/********************************************************************************************
 * A D M I N   C R E A T E    A C T I O N S
 * 
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend a nisl sit amet 
 * aliquet. Suspendisse eu viverra nunc. Maecenas
 * 
*********************************************************************************************/


//
// ─── ADMIN Settings ─────────────────────────────────────────────────────────────────
//

    export const fetchAdminUsers = createAction('[Admin Settings] Fetch Admin Users')
    export const fetchAdminUsersFailed = createAction('[Admin Settings] Fetch Admin Users failed')
    export const fetchAdminUsersSuccess = createAction('[Admin Settings] Fetch Admin Users Success')

    export const setAdminUsers = createAction(
        '[Admin Settings] Set Admin Users',
        props<{ adminUsers : AdminUsers[]}>()
    )

    export const deleteAdminUsers = createAction(
        '[Admin Settings] DELETE: delete Admin User',
        props<{ userId : string}>()
    )
    export const deleteAdminUsersFailed = createAction('[Admin Settings] STATUS: delete Admin User failed')
    export const deleteAdminUsersSuccess = createAction('[Admin Settings] STATUS: delete Admin User Successfull')

    export const changePassword = createAction(
        '[Admin Settings] UPDATE: Change password',
        props<{updatePasswordDto : UpdatePasswordDto}>()
    )
    export const changePasswordFailed = createAction('[Admin Settings] STATUS: Change password failed')
    export const changePasswordSuccess = createAction('[Admin Settings] STATUS: Change password success')

    export const addCountry = createAction(
        '[Admin Settings] CREATE: Add country',
        props<{countryDto : CountryDto}>()
    )
    export const addCountryFailed = createAction('[Admin Settings] STATUS: Add country failed')
    export const addCountrySuccess = createAction('[Admin Settings] STATUS: Add country success')

    export const updateCountry = createAction(
        '[Admin Settings] UPDATE: Update country',
        props<{countryDto : CountryDto, countryId: string}>()
    )
    export const updateCountryFailed = createAction('[Admin Settings] STATUS: Update country failed')
    export const updateCountrySuccess = createAction('[Admin Settings] STATUS: Update country success')

    export const deleteCountry = createAction(
        '[Admin Settings] DELETE: Delete country',
        props<{countryId : string}>()
    )
    export const deleteCountryFailed = createAction('[Admin Settings] STATUS: delete country failed')
    export const deleteCountrySuccess = createAction('[Admin Settings] STATUS: delete country success')


    export const fetchAdminBanks = createAction('[Admin Settings] Fetch Admin Banks')
    export const fetchAdminBanksFailed = createAction('[Admin Settings] Fetch Admin Banks failed')

    export const setAdminBanks = createAction(
        '[Admin Settings] Set Admin Users',
        props<{ adminBanks : Bank[]}>()
    )


    export const addBank = createAction(
        '[Admin Settings] CREATE: Add banks',
        props<{bankDto : BankDto}>()
    )
    export const addBankFailed = createAction('[Admin Settings] STATUS: Add banks failed')
    export const addBankSuccess = createAction('[Admin Settings] STATUS: Add banks success')

    export const deleteBank = createAction(
        '[Admin Settings] CREATE: Delete banks',
        props<{bankId : string}>()
    )
    export const deleteBankFailed = createAction('[Admin Settings] STATUS: Delete banks failed')
    export const deleteBankSuccess = createAction('[Admin Settings] STATUS: Delete banks success')

    export const updateBank = createAction(
        '[Admin Settings] CREATE: Update banks',
        props<{bankDto : BankDto, bankId : string, bankCode: string}>()
    )
    export const updateBankFailed = createAction('[Admin Settings] STATUS: Update banks failed')
    export const updateBankSuccess = createAction('[Admin Settings] STATUS: Update banks success')
//
// ─── ADMIN FEES ─────────────────────────────────────────────────────────────────
//

    export const createAdminMerchantFee = createAction(
        '[Admin page] CREATE: Admin merchant fee record',
        props<{ adminMerchantFee : AdminMerchantFee, createType : string}>()
    )

    export const createAdminMerchantFeeSuccessful = createAction(
        '[Admin page] STATUS: Admin merchant fee record creation successfull',
        props<{ successType : string }>()
    )

    export const createAdminMerchantFeeFailed = createAction(
        '[Admin page] STATUS: Admin merchant fee record creation failed',
        props<{ failType : string }>()
    )

    export const createAdminTransferFee = createAction(
        '[Admin page] CREATE: Admin transfer fee record',
        props<{ adminTransferFee : AdminTransferFee,  createType : string, feeId ? : string}>()
    )

    export const createAdminTransferFeeSuccessful = createAction(
        '[Admin page] STATUS: Admin transfer fee record creation successful',
        props<{ successType : string }>()
    )

    export const createAdminTransferFeeFailed = createAction(
        '[Admin page] STATUS: Admin transfer fee record creation failed',
        props<{ failType : string }>()
    )

    export const deleteAdminTransferFee = createAction(
        '[Admin page] DELETE: Admin transfer fee record',
        props<{ feeId ? : string}>()
    )

    export const deleteAdminTransferFeeSuccessful = createAction(
        '[Admin page] STATUS: Admin transfer fee record deletion successful'
    )

    export const deleteAdminTransferFeeFailed = createAction(
        '[Admin page] STATUS: Admin transfer fee record deletion failed'
    )

    export const createAdminProductFee = createAction(
        '[Admin page] CREATE: Admin product fee record',
        props<{ adminProductFee : AdminProductFee, productId : string, createType : string}>()       
    )

    export const createAdminProductFeeSuccessful = createAction(
        '[Admin page] STATUS: Admin product fee record creation successful',
        props<{ successType : string }>()
    )

    export const createAdminProductFeeFailed = createAction(
        '[Admin page] STATUS: Admin product fee record creation failed',
        props<{ failType : string }>()
    )

    export const createAdminCardConfiguration = createAction(
        '[Admin page] CREATE: Admin card configuration',
        props<{ adminCardConfiguration : AdminCardConfigurationCreate,   createType : string }>()
    )

    export const createAdminCardConfigurationSuccess = createAction(
        '[Admin page] STATUS: Admin card configuration creation success',
        props<{ successType : string }>()
    )

    export const createAdminCardConfigurationFailed = createAction(
        '[Admin page] STATUS: Admin card configuration creation failed',
        props<{ failType : string }>()
    )

//
// ─── ADMIN MANAGE ORGANISATION  ────────────────────────────────────────────────
//


    export const createAdminOrgAccount = createAction(
        '[Admin page] CREATE: Admin organisation account',
        props<{orgAccount : CreateOrganisationDto,   createType : string, editId ?: string }>()
    )

    export const createAdminOrgAccountSuccess = createAction(
        '[Admin page] STATUS: Admin organisation account creation success',
        props<{ successType : string }>()
    )

    export const createAdminOrgAccountFailed = createAction(
        '[Admin page] STATUS: Admin organisation account creation failed',
        props<{ failType : string }>()
    )    

    export const fetchAdminOrgAccounts = createAction(
        '[Admin page] READ: fetch admin organisation accounts'
    )

    export const fetchSelectedAdminOrgAccount = createAction(
        '[Admin page] READ: fetch selected admin organisation account',
        props<{ orgId : string }>()
    )

    export const fetchSelectedAdminOrgAccountsFailed = createAction(
        '[Admin page] READ: fetch selected admin organisation account failed'
    )


    export const fetchAdminOrgAccountsSuccess = createAction(
        '[Admin page] STATUS(read): fetch admin organisation accounts success'
    )

    export const fetchAdminOrgAccountsFailed = createAction(
        '[Admin page] STATUS(read): fetch admin organisation accounts failed'
    )

    export const setAdminOrgAccounts = createAction(
        '[Admin page] Set admin organisation accounts',
        props<{adminOrgAccounts : AdminOrganisation[]}>()
    )

    export const setSelectedAdminOrgAccount = createAction(
        '[Admin page] Set selected admin organisation account',
        props<{adminSelectedOrgAccount : AdminOrganisation}>()
    )
    
//
// ─── ADMIN MANAGE ORGANISATION STAFF  ────────────────────────────────────────────────
//


    export const createAdminOrgStaffAccount = createAction(
        '[Admin page] CREATE: Admin organisation staff account',
        props<{orgId : string, staffAccount : CreateStaffDto,   createType : string, editId ?: string }>()
    )

    export const createAdminOrgStaffAccountSuccess = createAction(
        '[Admin page] STATUS: Admin organisation staff account creation success',
        props<{ successType : string }>()
    )

    export const createAdminOrgStaffAccountFailed = createAction(
        '[Admin page] STATUS: Admin organisation staff account creation failed',
        props<{ failType : string }>()
    )    

    export const createAdminOrgBulkStaffAccount = createAction(
        '[Admin page] CREATE: Admin organisation bulk staff account',
        props<{staffAccounts : CreateBulkStaffDto[]}>()
    )

    export const createAdminOrgBulkStaffAccountSuccess = createAction(
        '[Admin page] STATUS: Admin organisation bulk staff account creation success'
    )

    export const createAdminOrgBulkStaffAccountFailed = createAction(
        '[Admin page] STATUS: Admin organisation bulk staff account creation failed'
    )   

    export const fetchAdminOrgStaffAccounts = createAction(
        '[Admin page] READ: fetch admin organisation staff accounts',
        props<{ orgId : string }>()
    )

    export const fetchAdminOrgStaffAccountsSuccess = createAction(
        '[Admin page] STATUS(read): fetch admin organisation staff accounts success'
    )

    export const fetchAdminOrgStaffAccountsFailed = createAction(
        '[Admin page] STATUS(read): fetch admin organisation staff accounts failed'
    )

    export const setAdminOrgStaffAccounts = createAction(
        '[Admin page] Set admin organisation staff accounts',
        props<{adminOrgStaffAccounts : AdminOrganisationStaff[]}>()
    )

    export const fetchAdminOrgStaffLoanHistory = createAction(
        '[Admin page] READ: fetch admin organisation staff loan history',
        props<{ walletNo : string, schemeCode : string }>()
    )

    export const fetchAdminOrgStaffLoanHistorySuccess = createAction(
        '[Admin page] STATUS(read): fetch admin organisation staff loan history success'
    )

    export const fetchAdminOrgStaffLoanHistorysFailed = createAction(
        '[Admin page] STATUS(read): fetch admin organisation staff loan history failed'
    )

    export const setAdminOrgStaffLoanHistory = createAction(
        '[Admin page] Set admin organisation staff loan history',
        props<{adminOrgStaffLoanHistory : AdminOrganisationStaffLoanHistory[]}>()
    )

    export const fetchAdminOrgStaffLoanTrans = createAction(
        '[Admin page] READ: fetch admin organisation staff loan transaction',
        props<{ staffId : string }>()
    )

    export const fetchAdminOrgStaffLoanTransSuccess = createAction(
        '[Admin page] STATUS(read): fetch admin organisation staff loan transaction success'
    )

    export const fetchAdminOrgStaffLoanTransFailed = createAction(
        '[Admin page] STATUS(read): fetch admin organisation staff loan transaction failed'
    )

    export const setAdminOrgStaffLoanTrans = createAction(
        '[Admin page] Set admin organisation staff loan transaction',
        props<{adminOrgStaffLoanTrans : AdminOrganisationStaffLoanTrans[]}>()
    )

// ────────────────────────────────────────────────────────────────────────────────
//
// ─── ADMIN MANAGE ACCOUNT ACTIONS ────────────────────────────────────────────────
//

    export const createAdminAgentAccount = createAction(
        '[Admin page] CREATE: Admin agent account',
        props<{agentAccount : CreateAgentDto}>()
    )

    export const createAdminAgentAccountSuccess = createAction(
        '[Admin page] STATUS: Admin agent account success'
    )

    export const createAdminAgentAccountFailed = createAction(
        '[Admin page] STATUS: Admin agent account failed'
    )     

    export const fetchAdminAgentAccounts = createAction(
        '[Admin page] READ: fetch admin agent accounts'
    )

    export const fetchAdminAgentAccountsSuccess = createAction(
        '[Admin page] READ: fetch admin agent accounts success'
    )

    export const fetchAdminAgentAccountsFailed = createAction(
        '[Admin page] READ: fetch admin agent accounts failed'
    )

    export const setAdminAgentAccounts = createAction(
        '[Admin page] Set admin agent accounts',
        props<{adminAgentAccounts : AdminAgentAccount[]}>()
    )


    export const fetchAdminSubAgentAccounts = createAction(
        '[Admin page] READ: fetch admin sub agent accounts',
        props<{id : string}>()
    )

    export const fetchAdminSubAgentAccountsSuccess = createAction(
        '[Admin page] STATUS(read): fetch admin agent sub accounts success'
    )

    export const fetchAdminSubAgentAccountsFailed = createAction(
        '[Admin page] STATUS(read): fetch admin agent sub accounts failed'
    )

    export const setAdminSubAgentAccounts = createAction(
        '[Admin page] Set admin agent sub accounts',
        props<{adminSubAgentAccounts : AdminAgentAccount[]}>()
    )

    //TODO: change this method name to changeSuspensionStatusForAgent
    export const changeSuspensionStatusForAgent = createAction(
        '[Admin page] UPDATE: change agent suspension status for agent',
        props<{isSuspend : boolean, userId : string}>()
    ) 
    //TODO: change this method name to changeSuspensionStatusForAgent
    export const changeSuspensionStatusForAgentFailed = createAction(
        '[Admin page] STATUS: change agent suspension status for agent failed',
        props<{isSuspend : boolean}>()
    ) 
    //TODO: change this method name to changeSuspensionStatusForAgent  
    export const changeSuspensionStatusForAgentSuccess = createAction(
        '[Admin page] STATUS: change agent suspension status for agent success',
        props<{isSuspend : boolean}>()
    )

    export const synchronizeBalance = createAction(
        '[Admin page] READ: syncro',
        props<{accountId : string}>()
    )

    export const synchronizeBalanceSuccess = createAction(
        '[Admin page] STATUS(read): synchronize balance success'
    )

    export const synchronizeBalanceFailed = createAction(
        '[Admin page] STATUS(read): synchronize balance failed'
    )


/********************************************************************************************
 * 
 *  A D M I N   U P D A T E      A C T I O N S
 * 
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend a nisl sit amet 
 *  aliquet. Suspendisse eu viverra nunc. Maecenas
 * 
 * 
*********************************************************************************************/


     export const updateAdminCustomerMerchant = createAction(
        '[Admin page] Update admin customer merchant',
        props<{ merchantId : string | number , updateData : any}>()
     )

     export const updateAdminCustomerMerchantSuccessful = createAction(
        '[Admin page] Update admin customer merchant successful',
     )

     export const updateAdminCustomerMerchantFailed = createAction(
        '[Admin page] Update admin customer merchant failed',
     )





/********************************************************************************************
 * 
 *  A D M I N   F E T C H      A C T I O N S
 * 
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend a nisl sit amet 
 *  aliquet. Suspendisse eu viverra nunc. Maecenas
 * 
 * 
*********************************************************************************************/




//
// ─── ADMIN  CUSTOMER ────────────────────────────────────────────────────────────
//


        // General Admin Fetch
        export const fetchAdminMerchantEntTypes = createAction(
            '[Admin Page] READ: fetch Admin Merchant Ent Type'
        )

        export const fetchAdminMerchantEntTypesSuccessfull = createAction(
            '[Admin Page] READ: fetch Admin Merchant Ent Type successfull'
        )

        export const fetchAdminMerchantEntTypesFailed = createAction(
            '[Admin Page] READ: fetch Admin Merchant Ent Type failed'
        )

        // General Admin Fetch
        export const fetchAdminFeeAccountsByCountry = createAction(
            '[Admin Page] READ: fetch Admin Fee Accounts by country'
        )

        export const fetchAdminFeeAccountsByCountrySuccessfull = createAction(
            '[Admin Page] READ: fetch Admin Fee Accounts by country successfull'
        )

        export const fetchAdminFeeAccountsByCountryFailed = createAction(
            '[Admin Page] READ: fetch Admin Fee Accounts by country failed'
        )

        // Admin Customer Users
        export const fetchAdminCustomerUsers = createAction(
            '[Admin Page] READ: fetch Admin Customer Users',
            props<{page: number, pageSize: number, search: string}>()
        )
        // export const fetchAdminCustomerUsers = createAction(
        //     '[Admin Page] READ: fetch Admin Customer Users'
        // )

        export const customerUserSearchSuccessfull = createAction(
            '[Admin Page] READ: fetch Admin Customer User Search Successfull'
        )
        
        export const fetchAdminCustomerUsersFailed = createAction(
            '[Admin Page] READ: fetch Admin Customer Users Failed',
            props<{errorStatus : number}>()
        )

        export const fetchAdminCustomerUsersByRegistrationDate = createAction(
            '[Admin Page] READ: fetch Admin Customer Users Filtered By Registration Date',
            props<{searchData : SearchData, sortOption ?: string}>()
        )

        export const fetchAdminCustomerUsersByTransactions = createAction(
            '[Admin Page] READ: fetch Admin Customer Users Filtered By Transactions',
            props<{searchData : SearchData, category : string}>()
        )

        export const fetchAdminCustomerUsersByMoneySentByCountry = createAction(
            '[Admin Page] READ: fetch Admin Customer Users Filtered By Money Sent By country',
            props<{searchData : SearchData, flag : string}>()
        )

        export const fetchAdminCustomerUserTransactionsFailed = createAction(
            '[Admin Page] READ: fetch Admin Customer User Transaction Failed',
            props<{errorStatus ?: number}>()
        )

        export const fetchAdminCustomerUserTransactionsById = createAction(
            '[Admin Page] READ: fetch Admin Customer User Transactions by Id',
            props<{id : string}>()
        )


        export const resetCustomerUserTransaction = createAction(
            '[Admin Page] Reset Admin Customer User Transactions',
        )

        export const fetchAdminCustomerUserTransactionsByIdAndDateRange = createAction(
            '[Admin Page] READ: fetch Admin Customer User Transactions By Id and Date Range',
            props<{id : string, searchData : SearchData}>()
        )

        

        export const fetchAdminCustomerMerchants = createAction(
            '[Admin Page] READ: fetch Admin Customer Merchants',
            props<{page: number, pageSize: number, search: string}>()
        )
        // export const fetchAdminCustomerMerchants = createAction(
        //     '[Admin Page] READ: fetch Admin Customer Merchants'
        // )

        // Admin Merchant Accounts
        export const fetchAdminCustomerMerchantAccounts = createAction(
            '[Admin Page] READ: fetch Admin Customer Merchant Accounts',
            props<{page: number, pageSize: number, search: string}>()
        )
        // export const fetchAdminCustomerMerchantAccounts = createAction(
        //     '[Admin Page] READ: fetch Admin Customer Merchant Accounts',
        // )

        // Admin Merchant Accounts
        export const setAdminCustomerMerchantAccounts = createAction(
            '[Admin Page] READ: set Admin Customer Merchant Accounts',
            props<{merchantAccounts}>()
        )
        // export const setAdminCustomerMerchantAccounts = createAction(
        //     '[Admin Page] READ: set Admin Customer Merchant Accounts',
        //     props<{merchantAccounts : AdminMerchantAccount[]}>()
        // )

        export const createMerchantCard = createAction(
            '[Admin Page] READ: create merchant card',
            props<{createCardDto: CreateCardDto, ledger_id: string, cardType: string}>()
        )
        export const createMerchantCardFailed = createAction(
            '[Admin Page] Status: create merchant card failed',
        )
        export const createMerchantCardSuccess = createAction(
            '[Admin Page] Status: create merchant card success',
        )


        export const fetchAdminMerchantAccountsSuccesfull = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account Successfull'
        )
        
        export const fetchAdminMerchantAccountFailed = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account failed',
        )

        export const fetchAdminMerchantAccountByRegistrationDate = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account Filtered By Registration Date',
            props<{searchData : SearchData, sortOption ?: string}>()
        )

        export const fetchAdminMerchantAccountByTransactions = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account Filtered By Transactions',
            props<{searchData : SearchData, category : string}>()
        )

        export const fetchAdminMerchantAccountByMoneySentByCountry = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account Filtered By Money Sent By country',
            props<{searchData : SearchData, flag : string}>()
        )

        export const fetchAdminMerchantAccountTransactionsFailed = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account Transaction Failed',
            props<{errorStatus ?: number}>()
        )

        export const fetchAdminMerchantAccountTransactionsById = createAction(
            '[Admin Page] READ: fetch Admin Merchant Account Transactions by Id',
            props<{id : string}>()
        )


        export const customerMerchantSearchSuccessfull = createAction(
            '[Admin Page] READ: fetch Admin Customer Merchant Search Successfull'
        )

        export const changeAdminCustomerUserStatus = createAction(
            '[Admin Page] UPDATE: Change Admin Customer Users status',
            props<{statusType : string, walletNo : string | number, schemeCode : string | number}>()
        )

        export const adminCustomerUserStatusChangeSuccessfull  = createAction(
            '[Admin Page] STATUS: Admin Customer Users status changed successfully',
            props<{statusType : string}>()
        )

        export const adminCustomerUserStatusChangeFailed  = createAction(
            '[Admin Page] STATUS: Admin Customer Users status change Failed',
            props<{statusType : string}>()
        )


        // Admin Merchant
        
        export const createXcelMerchantCustomer = createAction(
            '[Admin Page] READ: create xcel merchant customer',
            props<{requestBody : CreateXcelMerchantCustomerDto}>()
        )
        export const createXcelMerchantCustomerFailed = createAction(
            '[Admin Page] READ: create xcel merchant customer failed'
        )
        export const createXcelMerchantCustomerSuccess = createAction(
            '[Admin Page] READ: create xcel merchant customer success'
        )

        export const fetchAdminCustomerMerchantById = createAction(
            '[Admin Page] READ: fetch Admin customer merchant by id',
            props<{merchantId : string | number}>()
        )

        export const fetchAdminCustomerMerchantAccountById = createAction(
            '[Admin Page] READ: fetch Admin customer merchant account list by id',
            props<{customerId : string}>()
        )

        export const fetchAdminCustomerMerchantAccountByIdSuccess = createAction(
            '[Admin Page] READ: fetch Admin customer merchant account list by id success'
        )

        export const fetchAdminCustomerMerchantAccountByIdFailed = createAction(
            '[Admin Page] READ: fetch Admin customer merchant account list by id failed'
        )

        export const setAdminCustomerMerchantAccountList = createAction(
            '[Admin Page] set Admin customer merchant account list',
            props<{accountList : AdminCustomerMerchantAccount[]}>()
        )

        export const changeAdminCustomerMerchantStatus = createAction(
            '[Admin Page] UPDATE: Change Admin Customer Merchant status',
            props<{activate : boolean, merchantId : string | number}>()
        )

        export const adminCustomerMerchantStatusChangeSuccessfull  = createAction(
            '[Admin Page] STATUS: Admin Customer Merchant status changed successfully',
            props<{activate : boolean}>()
        )

        export const adminCustomerMerchantStatusChangeFailed  = createAction(
            '[Admin Page] STATUS: Admin Customer Merchant status change Failed',
            props<{activate : boolean}>()
        )

      
        export const fetchAdminCustomerMerchantsByRegistrationDate = createAction(
            '[Admin Page] READ: fetch Admin Customer Merchants by Registration date',
            props<{searchData : SearchData}>()
        )

        export const fetchAdminCustomerMerchantsFailed = createAction(
            '[Admin Page] READ: fetch Admin Customer Merchants Failed'
        )

        export const fetchAdminCustomerTransactions = createAction(
            '[Admin Page] READ: fetch Admin Customer Transactions',
            props<{page: number, pageSize: number, search: string}>()
        )
        // export const fetchAdminCustomerTransactions = createAction(
        //     '[Admin Page] READ: fetch Admin Customer Transactions'
        // )

        export const customerTransactionSearchSuccessfull = createAction(
            '[Admin Page] READ: fetch Admin Customer Transaction Search Successfull'
        )

        export const fetchAdminCustomerTransactionsByDateRange = createAction(
            '[Admin Page] READ: fetch Admin Customer Transactions By Date Range',
            props<{searchData : SearchData}>()
        )

        export const fetchAdminCustomerTransactionsFailed = createAction(
            '[Admin Page] READ: fetch Admin Customer Transactions Failed'
        )

        export const fetchAdminCustomerPendingTransactionFeesById = createAction(
            '[Admin Page] READ: fetch Admin Customer User Transactions by Id',
            props<{id : string}>()
        )

        export const fetchAdminCustomerUserPendingTransactionFeesByIdAndDateRange = createAction(
            '[Admin Page] READ: fetch Admin Customer User Transactions By Id and Date Range',
            props<{id : string, searchData : SearchData, page:number, limit:number}>()
        )

        export const setAdminCustomerUserPendingTransactionFees = createAction(
            '[Admin Page] Set Admin Customer User Pending Fees',
            props<{pendingFees : AdminCustomerUserTransaction[]}>()
        )

        export const fetchAdminCustomerUserPendingTransactionFeesFailed = createAction(
            '[Admin Page] READ: fetch Admin Customer User Pending Fees Failed',
            props<{errorStatus ?: number}>()
        )

        export const chargeAdminUserPendingTransactionFees = createAction(
            '[Admin Page] ACTION: Charge admin user pending transaction fees',
            props<{payload : any}>()
        )
        
        export const chargeAdminUserPendingTransactionFeesFailed = createAction(
            '[Admin Page] STATUS: Charge admin user pending transaction fees failed'
        )
        
        export const chargeAdminUserPendingTransactionFeesSuccess = createAction(
            '[Admin Page] STATUS: Charge admin user pending transaction fees success'
        )
//
// ─── END OF ADMIN - CUSTOMER ────────────────────────────────────────────────────
//

//
// ─── ADMIN EXCHANGE RATE ACTIONS ────────────────────────────────────────────────
//

    
        export const fetchAdminExchangeRatesForPayment = createAction(
            '[Admin Page] READ: fetch Admin Exchange Rates For Paymemt'
        )

        export const fetchAdminExchangeRatesForPaymentFailed = createAction(
            '[Admin Page] READ: fetch Admin Exchange Rates For Paymemt Failed'
        )

        export const fetchAdminExchangeRatesForTransfer = createAction(
            '[Admin Page] READ: fetch Admin Exchange Rates For Transfer'
        )

        export const fetchAdminExchangeRatesForTransferFailed = createAction(
            '[Admin Page] READ: fetch Admin Exchange Rates For Transfer Failed'
        )
//
// ─── END OF ADMIN EXCHANGE RATE ACTIONS ─────────────────────────────────────────
//

    


//
// ─── ADMIN KYC ACTIONS ──────────────────────────────────────────────────────────
//

    
        export const fetchAdminKycAll = createAction(
            '[Admin Page] READ: fetch Admin Kyc All'
        )

        export const fetchAdminKycByDateRange = createAction(
            '[Admin Page] READ: fetch Admin kyc by date range',
            props<{ searchData : SearchData }>()
        )

        export const fetchAdminKycAllFailed = createAction(
            '[Admin Page] READ: fetch Admin Kyc All Failed'
        )
        
        export const fetchAdminKycErrors = createAction(
            '[Admin Page] READ: fetch Admin Kyc Errors'
        )
        
        export const fetchAdminKycErrorsByDateRange = createAction(
            '[Admin Page] READ: fetch Admin kyc errors by date range',
            props<{ searchData : SearchData }>()
        )
        
        export const fetchAdminKycErrorsFailed = createAction(
            '[Admin Page] READ: fetch Admin Kyc errors Failed'
        )
//
// ─── END OF ADMIN KYC ACTIONS ───────────────────────────────────────────────────
//

    


//
// ─── ADMIN LIMITS ACTIONS ───────────────────────────────────────────────────────
//

    
    export const fetchAdminLimits = createAction(
        '[Admin Page] READ: fetch Admin Limits'
    )

    export const fetchAdminLimitsFailed = createAction(
        '[Admin Page] READ: fetch Admin limits failed'
    )

//
// ─── END OF ADMIN LIMITS ACTION ─────────────────────────────────────────────────
//

    

export const fetchAdminFeeAccounts = createAction(
    '[Admin Page] READ: fetch Admin Fee Accounts'
)

export const fetchAdminSettlementAccounts = createAction(
    '[Admin Page] READ: fetch Admin Settlement Accounts'
)

export const fetchAdminLocalPaymentAccounts = createAction(
    '[Admin Page] READ: fetch Admin local payment Accounts'
)

export const fetchAdminManagedAccounts = createAction(
    '[Admin Page] READ: fetch Admin Managed Accounts'
)

export const fetchAdminManagedAccountsFailed = createAction(
    '[Admin Page] READ: fetch Admin Managed Accounts failed'
)
export const createGlAccount = createAction(

    '[Admin Page] CREATE: Create gl account',

    props<{glAccountDto : SettlementAccountDto}>()

)

export const createGlAccountFailed = createAction('[Admin Page] STATUS: Create gl account failed')

export const createGlAccountSuccess = createAction('[Admin Page] STATUS: Create gl account success')

export const createHoldAccount = createAction(
    '[Admin Page] CREATE: Create hold account',
    props<{holdAccountDto : SettlementAccountDto}>()

)

export const createHoldAccountFailed = createAction('[Admin Page] STATUS: Create hold account failed')

export const createHoldAccountSuccess = createAction('[Admin Page] STATUS: Create hold account success')

export const logAdminAccount = createAction(
    '[Admin Page] CREATE: Log admin account',
    props<{logAdminAccountDto : any}>()

)

export const logAdminAccountFailed = createAction('[Admin Page] STATUS: Log admin account failed')

export const logAdminAccountSuccess = createAction('[Admin Page] STATUS: Log admin account success')


export const fetchSettlementAccounts = createAction(
    '[Admin Page] READ: fetch Admin Settlement Accounts',
    props<{countryISO2 : string}>()

    )

export const fetchSettlementAccountsFailed = createAction('[Admin Page] READ: fetch Admin Settlement Accounts')

export const fetchSettlementAccountsSuccess = createAction('[Admin Page] READ: fetch Admin Settlement Accounts')
//
// ─── ADMIN FEES ACTIONS ─────────────────────────────────────────────────────────
//

    
    export const fetchAdminMerchantFees = createAction(
        '[Admin Page] READ: fetch Admin Merchant Fees'
    )

    export const fetchAdminMerchantFeesFailed = createAction(
        '[Admin Page] READ: fetch Admin Merchant Fees Failed'
    )

    export const fetchAdminTransferFees = createAction(
        '[Admin page] READ: fetch Admin Transfer Fees'
    )

    export const fetchAdminTransferFeesFailed = createAction(
        '[Admin page] READ: fetch Admin Transfer Fees Failed'
    )

    export const fetchAdminProductFees = createAction(
        '[Admin page] READ: fetch Admin Product Fees',
        props<{merchantId : string}>()
    )

    export const fetchAdminProductFeesFailed = createAction(
        '[Admin page] READ: fetch Admin Product Fees Failed'
    )

    export const fetchAdminCountries = createAction(
        '[Admin page] READ: fetch admin countries'
    )

    export const fetchAdminCardConfigurations = createAction(
        '[Admin page] READ: fetch admin card configurations'
    )

    export const fetchAdminCardConfigurationsSuccess = createAction(
        '[Admin page] STATUS(read): fetch admin card configurations success'
    )

    export const fetchAdminCardConfigurationsFailed = createAction(
        '[Admin page] STATUS(read): fetch admin card configurations failed'
    )

    export const fetchAdminFeeGroups = createAction(
        '[Admin Page] READ: fetch Admin Fees Groups',
        props<{feeType : string}>()
    )

    export const fetchAdminFeeGroupsFailed = createAction(
        '[Admin Page] STATUS(read): fetch Admin Fees Groups failed',
    )

    export const fetchAdminFeeGroupsSuccess = createAction(
        '[Admin Page] STATUS(read): fetch Admin Fees Groups success',
    )


/********************************************************************************************
 * A D M I N   S E T    A C T I O N S
 * 
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend a nisl sit amet 
 * aliquet. Suspendisse eu viverra nunc. Maecenas
 * 
*********************************************************************************************/

// General Search
export const setAdminFeesAccountByCountry = createAction(
    '[Admin Page] Set Admin Fees Account By Country',
    props<{adminFeesAccountByCountry : AdminFeeAccount[]}>()
)

export const setAdminFeeGroups = createAction(
    '[Admin Page] Set Admin Fees Groups',
    props<{adminFeeGroups : AdminFees[]}>()
)

// General Admin Fetch
export const setAdminMerchantEntTypes = createAction(
    '[Admin Page] set Admin Merchant Ent Type',
    props<{adminMerchantEntTypes : {_id : string, type : string}[] }>()
)


export const setAdminCountries = createAction(
    '[Admin Page] Set Admin Countries',
    props<{adminCountries : AdminCountry[]}>()
)

export const setAdminSelectedCountry = createAction(
    '[Admin Page] Set Selected Admin Country',
    props<{adminCountry : AdminCountry}>()
)


export const setAdminCustomerUsers = createAction(
    '[Admin Page] Set Admin Customer Users',
    props<{adminCustomerUsers}>()
)
// export const setAdminCustomerUsers = createAction(
//     '[Admin Page] Set Admin Customer Users',
//     props<{adminCustomerUsers : AdminCustomerUser[]}>()
// )

export const setAdminCustomerUserTransactions = createAction(
    '[Admin Page] Set Admin Customer User Transactions',
    props<{adminCustomerUserTransactions : AdminCustomerUserTransaction[]}>()
)



export const setAdminCustomerMerchants = createAction(
    '[Admin Page] Set Admin Customer Merchants',
    props<{adminCustomerMerchants}>()
)
// export const setAdminCustomerMerchants = createAction(
//     '[Admin Page] Set Admin Customer Merchants',
//     props<{adminCustomerMerchants : AdminCustomerMerchant[]}>()
// )

export const setFetchedAdminCustomerMerchant = createAction(
    '[Admin Page] Set Fetched Admin Customer Merchant',
    props<{fetchedAdminCustomerMerchant : AdminCustomerMerchant}>()
)

export const setAdminCustomerTransactions = createAction(
    '[Admin Page] Set Admin Customer Transactions',
    props<{adminCustomerTransactions}>()
)
// export const setAdminCustomerTransactions = createAction(
//     '[Admin Page] Set Admin Customer Transactions',
//     props<{adminCustomerTransactions : AdminCustomerTransaction[]}>()
// )

export const setAdminExchangeRatesForPayment = createAction(
    '[Admin Page] Set Admin Exchange Rates For Paymemt',
    props<{adminExchangeRatesForPayment : AdminExchangeRate[]}>()
)

export const setAdminExchangeRatesForTransfer = createAction(
    '[Admin Page] Set Admin Exchange Rates For Transfer',
    props<{adminExchangeRatesForTransfer : AdminExchangeRate[]}>()
)

export const setAdminKycAll = createAction(
    '[Admin Page] Set Admin Kyc All',
    props<{adminKycAll : AdminKycAll[]}>()
)

export const setAdminKycErrors = createAction(
    '[Admin Page] Set Admin Kyc Errors',
    props<{adminKycErrors : AdminKycError[]}>()
)

export const setAdminLimits = createAction(
    '[Admin Page] Set Admin Limits',
    props<{adminLimits : AdminLimit[]}>()
)


//
// ─── ADMIN ACCOUNT ACTIONS ────────────────────────────────────────────────
//

    
       

export const setAdminFeeAccounts = createAction(
    '[Admin Page] Set Admin Fee Accounts',
    props<{adminFeeAccounts : AdminFeeAccount[]}>()
)

export const setAdminSettlementAccounts = createAction(
    '[Admin Page] Set Admin Settlement Accounts',
    props<{adminSettlementAccounts : AdminSettlementAccount[]}>()
)

export const setAdminSettlementAccounts2 = createAction(
    '[Admin Page] Set Admin Settlement Accounts',
    props<{adminSettlementAccounts2 : AdminSettlementAccounts2[]}>()

)

export const setAdminGlAccounts = createAction(
    '[Admin Page] Set Admin Gl Accounts',
    props<{adminGLAccounts : AdminGlAccounts[]}>()

)

export const setAdminHoldAccounts = createAction(
    '[Admin Page] Set Admin hold Accounts',
    props<{adminHoldAccounts : AdminSettlementAccounts2[]}>()

)

export const setAdminNonSettlementAccounts = createAction(
    '[Admin Page] Set Admin Non-settlement Accounts',
    props<{adminNonSettlementAccounts : AdminNonSettlementAccount[]}>()

)

export const setAdminLocalPaymentAccount = createAction(
    '[Admin Page] Set Admin Local payment Accounts',
    props<{adminLocalPaymentAccounts : AdminLocalPaymentAccount[]}>()
)

export const setAdminMerchantFees = createAction(
    '[Admin Page] Set Admin Merchant Fees',
    props<{adminMerchantFees : AdminMerchantFee[]}>()
)

export const setAdminTransferFees = createAction(
    '[Admin Page] Set Admin Transfer Fees',
    props<{adminTransferFees : AdminTransferFee[]}>()
)

export const setAdminProductFees = createAction(
    '[Admin Page] Set Admin Product Fees',
    props<{adminProductFees : AdminProductFee[]}>()
)

export const setAdminCardConfigurations = createAction(
    '[Admin page] Set admin card configurations',
    props<{adminCardConfigurations : AdminCardConfiguration[]}>()
)




// CARD CREATION ACTIONS
export const createUserCard = createAction(
    '[Admin Page] CREATE: user card',
    props<{account_id : string, card_type : 'physical' | 'virtual' }>()
)

export const createUserCardSuccess = createAction(
    '[Admin Page] STATUS: user card creation success'
)

export const createUserCardFailed = createAction(
    '[Admin Page] STATUS: user card creation failed'
)


// ADMIN REFUND ACCOUNTSD

export const initializeRefundTransaction = createAction(
    '[Admin Page] ACTION: initialize refund transaction',
    props<{transactionDto : AdminCustomerTransaction, setting : "local" | "international"}>()
)


export const refundTransactionFailed = createAction(
    '[Admin Page] STATUS: refund transaction failed',
    props<{message ?: string, statusCode ?: number}>()
)

export const refundTransactionSuccess = createAction(
    '[Admin Page] STATUS: refund transaction success',
)

// ADMIN REFUND NIGERIA + AFRICA

export const initializeRefundAfrica = createAction(
    '[Admin Page] ACTION: initialize refund transaction for nigeria and africa',
    props<{payload :  AdminRefundAfrica}>()
)

export const refundTransactionAfricaFailed = createAction(
    '[Admin Page] STATUS: refund transaction africa failed'
)

export const refundTransactionAfricaSuccess = createAction(
    '[Admin Page] STATUS: refund transaction africa success',
)

export const adminCustomerUserPinBlockChange = createAction(
    '[Admin Page] UPDATE: customer pin block change',
    props<{blockAction : boolean, walletNo : string, schemeCode : string}>()
)

export const adminCustomerUserPinBlockChangeSuccessfull = createAction(
    '[Admin Page] STATUS: customer pin block change success',
    props<{blockAction : boolean}>()
)

export const adminCustomerUserPinBlockChangeFailed = createAction(
    '[Admin Page] STATUS: customer pin block change failed',
    props<{blockAction : boolean}>()
    
)

export const adminCustomerUserTrustChange = createAction(
    '[Admin Page] UPDATE: customer trust change',
    props<{trustAction : boolean, walletNo : string, schemeCode : string}>()
)

export const adminCustomerUserTrustChangeSuccessfull = createAction(
    '[Admin Page] STATUS: customer trust change success',
    props<{trustAction : boolean}>()
)

export const adminCustomerUserTrustChangeFailed = createAction(
    '[Admin Page] STATUS: customer trust change failed',
    props<{trustAction : boolean}>()
    
)

export const adminCustomerUserInternationTransferOptionChange = createAction(
    '[Admin Page] UPDATE: customer internation transfer option change',
    props<{international : boolean, walletNo : string, schemeCode : string}>()
)

export const adminCustomerUserInternationTransferOptionChangeSuccessfull = createAction(
    '[Admin Page] STATUS: customer internation transfer option change success',
    props<{international : boolean}>()
)

export const adminCustomerUserInternationTransferOptionChangeFailed = createAction(
    '[Admin Page] STATUS: customer internation transfer option  failed',
    props<{international : boolean}>()
    
)


export const adminCustomerUserCloseChange = createAction(
    '[Admin Page] UPDATE: customer user close change',
    props<{closeAction : boolean, accountId : string}>()
)

export const adminCustomerUserCloseChangeSuccessfull = createAction(
    '[Admin Page] STATUS: customer user close change success',
    props<{closeAction : boolean}>()
)

export const adminCustomerUserCloseChangeFailed = createAction(
    '[Admin Page] STATUS: customer user close change failed',
    props<{closeAction : boolean}>()
    
)

export const adminCustomerUserCardActivationChange = createAction(
    '[Admin Page] customer user card activation change',
    props<{activateAction : boolean, cardId : string}>()
)

export const adminCustomerUserCardActivationChangeSuccessfull = createAction(
    '[Admin Page] customer user card activation change success',
    props<{activateAction : boolean}>()
)

export const adminCustomerUserCardActivationChangeFailed = createAction(
    '[Admin Page] customer user card activation change failed',
    props<{activateAction : boolean}>()
    
)


export const adminCustomerUserCardDestory = createAction(
    '[Admin Page] customer user card destruction',
    props<{cardId : string}>()
)

export const adminCustomerUserCardDestroySuccessfull = createAction(
    '[Admin Page] customer user card destruction successfull'
)

export const adminCustomerUserCardDestroyFailed = createAction(
    '[Admin Page] customer user card destruction failed'
    
)

// Admin Raise Level 

export const setAdminUserLevel = createAction(
    '[Admin Page] set admin user level',
    props<{walletNo: string, level : number}>()
)

export const setAdminUserLevelSuccess = createAction(
    '[Admin Page] set admin user level success',
    props<{levelChanged : number}>()
)

export const setAdminUserLevelFailed = createAction(
    '[Admin Page] set admin user level failed'
)

// Admin user set bills and payment 

export const adminCreateBillInfo = createAction(
    '[Admin Page] Create bill information',
    props<{ createBillInfoDto : CreateBillInfoDto}>()
) 

export const adminCreateBillInfoSuccessful = createAction(
    '[Admin Page] bill information creation successfull'
) 

export const adminCreateBillInfoFailed = createAction(
    '[Admin Page] bill information creation failed'
)

export const adminUpdateBillInfo = createAction(
    '[Admin Page] UPDATE: bill information',
    props<{payment_id : string, updateBillInfoDto : UpdateBillInfoDto}>()
) 

export const adminUpdateBillInfoSuccessful = createAction(
    '[Admin Page] STATUS: bill information update successfull'
) 

export const adminUpdateBillInfoFailed = createAction(
    '[Admin Page] STATUS: bill information update failed'
)

export const adminDeleteBillInfo = createAction(
    '[Admin Page] DELETE: bill information',
    props<{ payment_id : string}>()
) 

export const adminDeleteBillInfoSuccessful = createAction(
    '[Admin Page] STATUS: bill information deletion successfull'
) 

export const adminDeleteBillInfoFailed = createAction(
    '[Admin Page] STATUS: bill information deletion failed'
)

export const fetchBillInfosByCountryCode = createAction(
    '[Admin Page] READ: fetch bill infos by country code',
   
)

export const setBillInfoList = createAction(
    '[Admin Page] set bill infos',
    props<{ billInfos : AdminBillInfo[]}>()
)

export const fetchBillInfosByCountryCodeSuccessfull = createAction(
    '[Admin Page] READ: fetch bill infos by country code successfull'
)

export const fetchBillInfosByCountryCodeFailed = createAction(
    '[Admin Page] READ: fetch bill infos by country code failed'
)

export const fetchAdminCustomerAccountBalance = createAction(
    '[Admin Page] READ: fetch admin customer account balance',
    props<{ cardNumOrAccountId : string, country : string}>()
)

export const fetchAdminCustomerAccountBalanceSuccess = createAction(
    '[Admin Page] STATUS(read): fetch admin customer account balance success',
    props<{ balance : any}>()
)

export const fetchAdminCustomerAccountBalanceFailed = createAction(
    '[Admin Page] STATUS(read): fetch admin customer account balance failed'
)

//ADMIN BILLER CONFIG
export const fetchBillerCountries = createAction(
    '[Admin-page] fetch biller countries'
)

export const fetchBillerCountriesFailed = createAction(
    '[Admin-page] STATUS: fetch biller countries failed'
)

export const fetchBillerCountriesSuccess = createAction(
    '[Admin-page] STATUS: fetch biller countries success',
    props<{billerCountries: BillerCountry[]}>()
)


export const fetchBillerServices = createAction(
    '[Admin-page] fetch biller services'
)

export const fetchBillerServicesFailed = createAction(
    '[Admin-page] STATUS: fetch biller services failed'
)

export const fetchBillerServicesSuccess = createAction(
    '[Admin-page] STATUS: fetch biller services success',
    props<{billerServices: BillerService[]}>()
)



export const fetchBillerChannels = createAction(
    '[Admin-page] fetch biller channels'
)

export const fetchBillerChannelsFailed = createAction(
    '[Admin-page] STATUS: fetch biller channels failed'
)

export const fetchBillerChannelsSuccess = createAction(
    '[Admin-page] STATUS: fetch biller channels success',
    props<{billerChannels: BillerChannel[]}>()
)



export const fetchBillerChannelServices = createAction(
    '[Admin-page] fetch biller channels services',
    props<{countryCode: string}>()
)

export const fetchBillerChannelServicesFailed = createAction(
    '[Admin-page] STATUS: fetch biller channels services failed'
)

export const fetchBillerChannelServicesSuccess = createAction(
    '[Admin-page] STATUS: fetch biller channels services success',
    props<{billerChannelsServices: BillerChannelService[]}>()
)



export const createBillerChannelService = createAction(
    '[Admin-page] create biller channels service',
    props<{billerChannelServiceDto: BillerChannelServiceDto}>()
)

export const createBillerChannelServiceFailed = createAction(
    '[Admin-page] STATUS: create biller channels service failed'
)

export const createBillerChannelServiceSuccess = createAction(
    '[Admin-page] STATUS: create biller channels service success'
)



export const createBillerChannel = createAction(
    '[Admin-page] create biller channel',
    props<{billerChannelDto: BillerChannelDto}>()
)

export const createBillerChannelFailed = createAction(
    '[Admin-page] STATUS: create biller channel failed'
)

export const createBillerChannelSuccess = createAction(
    '[Admin-page] STATUS: create biller channel success'
)



export const createBillerService = createAction(
    '[Admin-page] create biller service',
    props<{billerServiceDto: BillerServiceDto}>()
)

export const createBillerServiceFailed = createAction(
    '[Admin-page] STATUS: create biller service failed'
)

export const createBillerServiceSuccess = createAction(
    '[Admin-page] STATUS: create biller service success'
)


// ADMIN BLACKLIST


export const adminCreateBlacklistInfo = createAction(
    '[Admin Page] CREATE: blacklist information',
    props<{ createBlacklistInfoDto : CreateBlacklistDto}>()
) 

export const adminCreateBlacklistInfoSuccessful = createAction(
    '[Admin Page] STATUS: blacklist information creation successfull'
) 

export const adminCreateBlacklistInfoFailed = createAction(
    '[Admin Page] STATUS: blacklist information creation failed'
)

export const adminUpdateBlacklistInfo = createAction(
    '[Admin Page] UPDATE: blacklist information',
    props<{id : string, updateBlacklistInfoDto : UpdateBlacklistInfoDto}>()
) 

export const adminUpdateBlacklistInfoSuccessful = createAction(
    '[Admin Page] STATUS: blacklist information update successfull'
) 

export const adminUpdateBlacklistInfoFailed = createAction(
    '[Admin Page] STATUS: blacklist information update failed'
)

export const adminDeleteBlacklistInfo = createAction(
    '[Admin Page] DELETE: blacklist information',
    props<{ id : string}>()
) 

export const adminDeleteBlacklistInfoSuccessful = createAction(
    '[Admin Page] STATUS: blacklist information deletion successfull'
) 

export const adminDeleteBlacklistInfoFailed = createAction(
    '[Admin Page] STATUS: blacklist information deletion failed'
)

export const fetchBlacklistInfosByCountryCode = createAction(
    '[Admin Page] READ: fetch blacklist infos by country code',
   
)

export const setBlacklistInfoList = createAction(
    '[Admin Page] set blacklist infos',
    props<{ blacklistInfos : AdminBlacklistInfo[]}>()
)

export const fetchBlacklistInfosByCountryCodeSuccessfull = createAction(
    '[Admin Page] STATUS(read): fetch blacklist infos by country code successfull'
)

export const fetchBlacklistInfosByCountryCodeFailed = createAction(
    '[Admin Page] STATUS(read): fetch blacklist infos by country code failed'
)

export const sendAdminMerchantWelcomeMail = createAction(
    '[Admin Page] ACTION: Send admin merchant welcome mail',
    props<{messagePayload : MerchantAccountOpeningRequestDto}>()
)

export const sendAdminMerchantWelcomeMailFailed = createAction(
    '[Admin Page] STATUS: Send admin merchant welcome mail failed'
)

export const sendAdminMerchantWelcomeMailSuccess = createAction(
    '[Admin Page] STATUS: Send admin merchant welcome mail success'
)


export const sendAdminUserWelcomeMail = createAction(
    '[Admin Page] ACTION: Send admin user welcome mail',
    props<{messagePayload : UserAccountOpeningRequestDto}>()
)

export const sendAdminUserWelcomeMailFailed = createAction(
    '[Admin Page] STATUS: Send admin user welcome mail failed'
)

export const sendAdminUserWelcomeMailSuccess = createAction(
    '[Admin Page] STATUS: Send admin user welcome mail success'
)
export const postInternalFundTransfer = createAction(
    '[Admin Page] CREATE: execute internal fund transfer',
    props<{payload : InternalFundTransfer}>()
)

export const postExternalFundTransfer = createAction(
    '[Admin Page] CREATE: execute external fund transfer',
    props<{payload : ExternalFundTransfer}>()
)


export const postTransferFundsSuccess = createAction(
    '[Admin Page] STATUS: fund transfer success'
)


export const postTransferFundsFailed = createAction(
    '[Admin Page] STATUS: fund transfer failed'
)


export const fetchFailedUserRegistration = createAction(
    '[Admin Page] READ: fetch failed user registration'
)

export const fetchFailedUserRegistrationSuccess = createAction(
    '[Admin Page] READ: fetch failed user registration success'
)

export const fetchFailedUserRegistrationFailed = createAction(
    '[Admin Page] READ: fetch failed user registration failed'
)

export const deleteFailedUserRegistration = createAction(
    '[Admin Page] DELETE: delete failed user registration',
    props<{id : string}>()
)

export const deleteFailedUserRegistrationSuccess = createAction(
    '[Admin Page] STATUS: delete failed user registration success'
)

export const deleteFailedUserRegistrationFailed = createAction(
    '[Admin Page] STATUS: delete failed user registration failed'
)


export const createFailedUser = createAction(
    '[Admin Page] CREATE: create failed user',
    props<{payload : any}>()
)

export const createFailedUserSuccess = createAction(
    '[Admin Page] STATUS: create failed user successful'
)

export const createFailedUserFailed = createAction(
    '[Admin Page] STATUS: create failed user failed'
)

export const setFailedUserRegistration = createAction(
    '[Admin Page] set failed user registration',
    props<{failedReg : FailedRegistration[]}>()
)

export const sendKycEmail = createAction(
    '[Admin Page] send kyc email',
    props<{payload : {email : string}}>()
)

export const sendKycEmailSuccess = createAction(
    '[Admin Page] READ: send kyc email success'
)

export const sendKycEmailFailed = createAction(
    '[Admin Page] STATUS(read): send kyc email failed'
)

export const fetchAdminActivityLogs = createAction(
    '[Admin Page] READ: fetch admin activity logs'
)

export const fetchAdminActivityLogSuccess = createAction(
    '[Admin Page] STATUS(read): fetch admin activity log success'
)

export const fetchAdminActivityLogFailed = createAction(
    '[Admin Page] STATUS(read): fetch admin activity log failed'
)


export const setAdminActivityLog = createAction(
    '[Admin Page] set admin activity log failed',
    props<{adminActivityLogs : AdminActivityLog[]}>()
)

// export const adminCustomerUserPinUnBlock = createAction(
//     '[Admin Page] customer pin unblock',
//     props<{walletNo : string, schemeCode : string}>()
// )

// export const adminCustomerUserPinUnBlockSuccessfull = createAction(
//     '[Admin Page] customer pin unblock success'
// )

// export const adminCustomerUserPinUnBlockFailed = createAction(
//     '[Admin Page] customer pin unblock failed'
// )

export const saveToSource = createAction(
    '[Admin Page] save merchant account to source',
    props<{payload : any}>()
)

export const saveToSourceSuccess = createAction(
    '[Admin Page] save merchant account to soruce success'
)

export const saveToSourceFailed = createAction(
    '[Admin Page] save merchant account to soruce failed'
)


//New KYC Actions
export const fetchKycRecords = createAction(
    '[Admin Module] Fetch KYC Records',
    props<{ startDate?: string, endDate?: string, page?: number, pageSize?: number }>()
);

export const fetchKycRecordsSuccess = createAction(
    '[Admin Module] Fetch KYC Records Success',
    props<{ kycRecordsPage: number, kycRecordsPageSize: number, kycRecordsTotalCount: number, kycRecords: AdminKycAll[] }>()
);

export const fetchKycRecordsFailed = createAction(
    '[Admin Module] Fetch KYC Records Failed',
    props<{ message: string, code: number }>()
);

export const fetchKycErrors = createAction(
    '[Admin Module] Fetch KYC Errors',
    props<{ startDate?: string, endDate?: string, page?: number, pageSize?: number }>()
);

export const fetchKycErrorsSuccess = createAction(
    '[Admin Module] Fetch KYC Errors Success',
    props<{ kycErrorsPage: number, kycErrorsPageSize: number, kycErrorsTotalCount: number, kycErrors: AdminKycError[] }>()
);

export const fetchKycErrorsFailed = createAction(
    '[Admin Module] Fetch KYC Errors Failed',
    props<{ message: string, code: number }>()
);


