import { createAction, props } from '@ngrx/store';
import {
    CreateXcelMerchantCustomerDto,
    ExternalFundTransfer,
    InternalFundTransfer,
    MerchantAccountOpeningRequestDto,
    UserAccountOpeningRequestDto
} from './customers.dtos';
import {
    AdminCustomerMerchant,
    AdminCustomerMerchantAccount,
    AdminCustomerTransaction,
    AdminCustomerUser,
    AdminCustomerUserTransaction,
    AdminFeeAccount,
    AdminFees,
    AdminLimit,
    AdminRefundAfrica,
    MerchantAccount,
    SearchData,
    UsersListNewResponse,
    UsersListResponse,
    FilterType,
    AdminPayment
} from './customers.interfaces';


// Customer List Component Actions

export const postInternalFundTransfer = createAction(
    '[Customer Module] CREATE: execute internal fund transfer',
    props<{payload: InternalFundTransfer}>()
);
export const postTransferFundsSuccess = createAction(
    '[Customer Module] STATUS: fund transfer success'
);

export const postTransferFundsFailed = createAction(
    '[Customer Module] STATUS: fund transfer failed'
);
export const postExternalFundTransfer = createAction(
    '[Customer Module] CREATE: execute external fund transfer',
    props<{payload: ExternalFundTransfer}>()
);


export const initializeRefundAfrica = createAction(
    '[Customer Module] ACTION: initialize refund transaction for nigeria and africa',
    props<{payload: AdminRefundAfrica}>()
);

export const refundTransactionAfricaFailed = createAction(
    '[Customer Module] STATUS: refund transaction africa failed'
);

export const refundTransactionAfricaSuccess = createAction(
    '[Customer Module] STATUS: refund transaction africa success',
);

export const fetchAdminLimits = createAction(
    '[Customer Module] READ: fetch Admin Limits'
);
export const fetchAdminLimitsSuccess = createAction(
    '[Customer Module] Set Admin Limits',
    props<{adminLimits: AdminLimit[]}>()
);

export const fetchAdminLimitsFailed = createAction(
    '[Customer Module] READ: fetch Admin limits failed'
);


export const fetchAdminCustomerUsersByTransactions = createAction(
    '[Customer Module] READ: fetch Admin Customer Users Filtered By Transactions',
    props<{searchData: SearchData, category: string}>()
);
export const fetchAdminCustomerUsersByTransactionsSuccess = createAction(
    '[Customer Module] fetch Admin customer users by transactions success',
    props<{adminCustomerUsers: UsersListResponse}>()
);
export const fetchAdminCustomerUsersByTransactionsFailed = createAction(
    '[Customer Module] READ: fetch Admin customer users by transactions failed',
    props<{errorStatus: number}>()
);


export const fetchAdminCustomerUsersByRegistrationDate = createAction(
    '[Customer Module] READ: fetch Admin Customer Users Filtered By Registration Date',
    props<{searchData: SearchData, sortOption ?: string}>()
);
export const fetchAdminCustomerUsersByRegistrationDateSuccess = createAction(
    '[Customer Module] fetch admin customer users by registeration date success',
    props<{adminCustomerUsers: UsersListResponse}>()
);
export const fetchAdminCustomerUsersByRegistrationDateFailed = createAction(
    '[Customer Module] READ: fetch admin customer users by registeration date failed',
    props<{errorStatus: number}>()
);
export const customerUserSearchSuccessfull = createAction(
    '[Customer Module] READ: fetch Admin Customer User Search Successfull'
);



export const fetchAdminCustomerUsersByMoneySentByCountry = createAction(
    '[Customer Module] READ: fetch Admin Customer Users Filtered By Money Sent By country',
    props<{searchData: SearchData, flag: string}>()
);

export const fetchAdminCustomerUsersByMoneySentByCountrySuccess = createAction(
    '[Customer Module] fetch admin customer users by money sent by country success',
    props<{adminCustomerUsers: UsersListResponse}>()
);
export const fetchAdminCustomerUsersByMoneySentByCountryFailed = createAction(
    '[Customer Module] READ: fetch admin customer users by money sent by country failed',
    props<{errorStatus: number}>()
);


export const fetchAdminCustomerUsers = createAction(
    '[Customer Module] READ: fetch Admin Customer Users',
    props<{page: number, pageSize: number, search: string}>()
);

export const fetchAdminCustomerUsersSuccess = createAction(
    '[Customer Module] READ: fetch Admin Customer Users success',
    props<{adminCustomerUsers: UsersListResponse}>()
);
export const fetchAdminCustomerUsersFailed = createAction(
    '[Customer Module] READ: fetch Admin Customer Users Failed',
    props<{errorStatus: number}>()
);

export const fetchAdminCustomerUsersNew = createAction(
    '[Customer Module] READ: fetch Admin Customer Users New',
    props<{page: number, pageSize: number, search: string, isCompact: boolean, appNames?: string[], searchData?: any}>()
);

export const fetchAdminCustomerUsersNewSuccess = createAction(
    '[Customer Module] READ: fetch Admin Customer Users New success',
    props<{adminCustomerUsersNew: UsersListNewResponse}>()
);
export const fetchAdminCustomerUsersNewFailed = createAction(
    '[Customer Module] READ: fetch Admin Customer Users New Failed',
    props<{errorStatus: number}>()
);


export const fetchPendingApplications = createAction(
    '[Customer Module] READ: fetch Pending Admin Customer Users New',
    props<{page: number, pageSize: number, search: string, empty_kyc:boolean}>()
);

export const fetchPendingApplicationsSuccess = createAction(
    '[Customer Module] READ: fetch Pending Admin Customer Users New success',
    props<{adminCustomerUsersNew: UsersListNewResponse}>()
);
export const fetchPendingApplicationsFailed = createAction(
    '[Customer Module] READ: fetch Pending Admin Customer Users New Failed',
    props<{errorStatus: number}>()
);


export const synchronizeBalance = createAction(
    '[Customer Module] READ: syncro',
    props<{accountId: string}>()
);

export const synchronizeBalanceSuccess = createAction(
    '[Customer Module] STATUS(read): synchronize balance success'
);

export const synchronizeBalanceFailed = createAction(
    '[Customer Module] STATUS(read): synchronize balance failed'
);




export const adminCustomerUserCardActivationChange = createAction(
    '[Customer Module] customer user card activation change',
    props<{activateAction: boolean, cardId: string}>()
);

export const adminCustomerUserCardActivationChangeSuccessfull = createAction(
    '[Customer Module] customer user card activation change success',
    props<{activateAction: boolean}>()
);

export const adminCustomerUserCardActivationChangeFailed = createAction(
    '[Customer Module] customer user card activation change failed',
    props<{activateAction: boolean}>()

);


export const adminCustomerUserCardDestory = createAction(
    '[Customer Module] customer user card destruction',
    props<{cardId: string}>()
);

export const adminCustomerUserCardDestroySuccessfull = createAction(
    '[Customer Module] customer user card destruction successfull'
);

export const adminCustomerUserCardDestroyFailed = createAction(
    '[Customer Module] customer user card destruction failed'

);


export const adminCustomerUserCloseChange = createAction(
    '[Customer Module] UPDATE: customer user close change',
    props<{closeAction: boolean, accountId: string}>()
);

export const adminCustomerUserCloseChangeSuccessfull = createAction(
    '[Customer Module] STATUS: customer user close change success',
    props<{closeAction: boolean}>()
);

export const adminCustomerUserCloseChangeFailed = createAction(
    '[Customer Module] STATUS: customer user close change failed',
    props<{closeAction: boolean}>()

);


export const adminCustomerUserTrustChange = createAction(
    '[Customer Module] UPDATE: customer trust change',
    props<{trustAction: boolean, walletNo: string, schemeCode: string}>()
);

export const adminCustomerUserTrustChangeSuccessfull = createAction(
    '[Customer Module] STATUS: customer trust change success',
    props<{trustAction: boolean}>()
);

export const adminCustomerUserTrustChangeFailed = createAction(
    '[Customer Module] STATUS: customer trust change failed',
    props<{trustAction: boolean}>()
);



export const adminCustomerUserInternationTransferOptionChange = createAction(
    '[Customer Module] UPDATE: customer internation transfer option change',
    props<{international: boolean, walletNo: string, schemeCode: string}>()
);

export const adminCustomerUserInternationTransferOptionChangeSuccessfull = createAction(
    '[Customer Module] STATUS: customer internation transfer option change success',
    props<{international: boolean}>()
);

export const adminCustomerUserInternationTransferOptionChangeFailed = createAction(
    '[Customer Module] STATUS: customer internation transfer option  failed',
    props<{international: boolean}>()

);


export const setAdminUserLevel = createAction(
    '[Customer Module] set admin user level',
    props<{walletNo: string, level: number}>()
);

export const setAdminUserLevelSuccess = createAction(
    '[Customer Module] set admin user level success',
    props<{levelChanged: number}>()
);

export const setAdminUserLevelFailed = createAction(
    '[Customer Module] set admin user level failed'
);

export const adminCustomerUserPinBlockChange = createAction(
    '[Customer Module] UPDATE: customer pin block change',
    props<{blockAction: boolean, walletNo: string, schemeCode: string}>()
);

export const adminCustomerUserPinBlockChangeSuccessfull = createAction(
    '[Customer Module] STATUS: customer pin block change success',
    props<{blockAction: boolean}>()
);

export const adminCustomerUserPinBlockChangeFailed = createAction(
    '[Customer Module] STATUS: customer pin block change failed',
    props<{blockAction: boolean}>()

);

export const changeAdminCustomerUserStatus = createAction(
    '[Customer Module] UPDATE: Change Admin Customer Users status',
    props<{statusType: string, walletNo: string | number, schemeCode: string | number}>()
);

export const adminCustomerUserStatusChangeSuccessfull  = createAction(
    '[Customer Module] STATUS: Admin Customer Users status changed successfully',
    props<{statusType: string}>()
);

export const adminCustomerUserStatusChangeFailed  = createAction(
    '[Customer Module] STATUS: Admin Customer Users status change Failed',
    props<{statusType: string}>()
);


export const fetchAdminCustomerAccountBalance = createAction(
    '[Customer Module] READ: fetch admin customer account balance',
    props<{ cardNumOrAccountId: string, country: string, accountType: string}>()
);

export const fetchAdminCustomerAccountBalanceSuccess = createAction(
    '[Customer Module] STATUS(read): fetch admin customer account balance success',
    props<{ balance: string}>()
);

export const fetchAdminCustomerAccountBalanceFailed = createAction(
    '[Customer Module] STATUS(read): fetch admin customer account balance failed'
);


export const createUserCard = createAction(
    '[Customer Module] CREATE: user card',
    props<{account_id: string, card_type: 'physical' | 'virtual' }>()
);

export const createUserCardSuccess = createAction(
    '[Customer Module] STATUS: user card creation success'
);

export const createUserCardFailed = createAction(
    '[Customer Module] STATUS: user card creation failed'
);


export const sendAdminUserWelcomeMail = createAction(
    '[Customer Module] ACTION: Send admin user welcome mail',
    props<{messagePayload: UserAccountOpeningRequestDto}>()
);

export const sendAdminUserWelcomeMailFailed = createAction(
    '[Customer Module] STATUS: Send admin user welcome mail failed'
);

export const sendAdminUserWelcomeMailSuccess = createAction(
    '[Customer Module] STATUS: Send admin user welcome mail success'
);


// User Transaction actions
export const resetCustomerUserTransaction = createAction(
    '[Customer Module] Reset Admin Customer User Transactions',
);
export const fetchAdminCustomerUserTransactionsByIdAndDateRange = createAction(
    '[Customer Module] READ: fetch Admin Customer User Transactions By Id and Date Range',
    props<{id: string, searchData: SearchData}>()
);


export const fetchAdminCustomerUserTransactionsById = createAction(
    '[Customer Module] READ: fetch Admin Customer User Transactions by Id',
    props<{id: string, page?: number, pageSize?: number}>()
);
export const fetchAdminCustomerUserTransactionsByIdSuccess = createAction(
    '[Customer Module] READ: fetch Admin Customer User Transactions by Id success',
    props<{adminCustomerUserTransactions}>()
);
export const fetchAdminCustomerUserTransactionsByIdFailed = createAction(
    '[Customer Module] READ: fetch Admin Customer User Transactions by Id Failed',
    props<{errorStatus ?: number}>()
);

// Transactions actions
export const initializeRefundTransaction = createAction(
    '[Customer Module] ACTION: initialize refund transaction',
    props<{transactionDto: AdminCustomerTransaction, setting: 'local' | 'international'}>()
);
export const refundTransactionFailed = createAction(
    '[Customer Module] STATUS: refund transaction failed',
    props<{message ?: string, statusCode ?: number}>()
);
export const refundTransactionSuccess = createAction(
    '[Customer Module] STATUS: refund transaction success',
);

export const fetchAdminCustomerTransactions = createAction(
    '[Customer Module] READ: fetch Admin Customer Transactions',
    props<{page: number, pageSize: number, search: string, searchData: SearchData}>()
);
export const fetchAdminCustomerTransactionsFailed = createAction(
    '[Customer Module] READ: fetch Admin Customer Transactions Failed'
);
export const fetchAdminCustomerTransactionsSuccess = createAction(
    '[Customer Module] Set Admin Customer Transactions',
    props<{adminCustomerTransactions}>()
);
export const fetchAdminCustomerFiltersAccountType = createAction(
    '[Customer Module] fetch all unique account types'
);
export const fetchAdminCustomerFiltersChannelID = createAction(
    '[Customer Module] fetch all unique channelIDs'
);
export const fetchAdminCustomerFiltersTransType = createAction(
    '[Customer Module] fetch all transaction types'
);

export const fetchAdminCustomerFiltersSuccess = createAction(
    '[Customer Module] Set all unique channelIDs, transaction types and account types',
    props<{adminCustomerFiltersArray: any, filterType: FilterType}>()
);

export const customerTransactionSearchSuccessfull = createAction(
    '[Customer Module] READ: fetch Admin Customer Transaction Search Successfull'
);

export const fetchAdminCustomerTransactionsByDateRange = createAction(
    '[Customer Module] READ: fetch Admin Customer Transactions By Date Range',
    props<{searchData: SearchData}>()
);


// Admin Merchant Accounts
export const fetchAdminMerchantAccounts = createAction(
    '[Customer Module] READ: fetch Admin Customer Merchant Accounts'
);

// Admin Merchant Accounts
export const setAdminCustomerMerchantAccounts = createAction(
    '[Customer Module] READ: set Admin Customer Merchant Accounts',
    props<{merchantAccounts: MerchantAccount[]}>()
);


export const fetchAdminMerchantAccountsSuccesfull = createAction(
    '[Customer Module] READ: fetch Admin Merchant Account Successfull'
);

export const fetchAdminMerchantAccountsFailed = createAction(
    '[Customer Module] READ: fetch Admin Merchant Account failed',
);


export const saveToSource = createAction(
    '[Customer Module] save merchant account to source',
    props<{payload: any}>()
);

export const saveToSourceSuccess = createAction(
    '[Customer Module] save merchant account to soruce success'
);

export const saveToSourceFailed = createAction(
    '[Customer Module] save merchant account to soruce failed'
);

// Admin Merchants
export const fetchAdminCustomerMerchants = createAction(
    '[Customer Module] READ: fetch Admin Customer Merchants'
);
export const setAdminCustomerMerchants = createAction(
    '[Customer Module] Set Admin Customer Merchants',
    props<{adminCustomerMerchants: AdminCustomerMerchant[]}>()
);
export const fetchAdminCustomerMerchantsFailed = createAction(
    '[Customer Module] READ: fetch Admin Customer Merchants Failed'
);


export const fetchAdminCustomerMerchantsByRegistrationDate = createAction(
    '[Customer Module] READ: fetch Admin Customer Merchants by Registration date',
    props<{searchData: SearchData}>()
);
export const customerMerchantSearchSuccessfull = createAction(
    '[Customer Module] READ: fetch Admin Customer Merchant Search Successfull'
);


export const changeAdminCustomerMerchantStatus = createAction(
    '[Customer Module] UPDATE: Change Admin Customer Merchant status',
    props<{activate: boolean, merchantId: string | number}>()
);

export const adminCustomerMerchantStatusChangeSuccessfull  = createAction(
    '[Customer Module] STATUS: Admin Customer Merchant status changed successfully',
    props<{activate: boolean}>()
);

export const adminCustomerMerchantStatusChangeFailed  = createAction(
    '[Customer Module] STATUS: Admin Customer Merchant status change Failed',
    props<{activate: boolean}>()
);


export const sendAdminMerchantWelcomeMail = createAction(
    '[Customer Module] ACTION: Send admin merchant welcome mail',
    props<{messagePayload: MerchantAccountOpeningRequestDto}>()
);

export const sendAdminMerchantWelcomeMailFailed = createAction(
    '[Customer Module] STATUS: Send admin merchant welcome mail failed'
);

export const sendAdminMerchantWelcomeMailSuccess = createAction(
    '[Customer Module] STATUS: Send admin merchant welcome mail success'
);


export const createXcelMerchantCustomer = createAction(
    '[Customer Module] READ: create xcel merchant customer',
    props<{requestBody: CreateXcelMerchantCustomerDto}>()
);
export const createXcelMerchantCustomerFailed = createAction(
    '[Customer Module] READ: create xcel merchant customer failed'
);
export const createXcelMerchantCustomerSuccess = createAction(
    '[Customer Module] READ: create xcel merchant customer success'
);



export const fetchAdminCustomerMerchantAccountById = createAction(
    '[Customer Module] READ: fetch Admin customer merchant account list by id',
    props<{customerId: string}>()
);

export const fetchAdminCustomerMerchantAccountByIdSuccess = createAction(
    '[Customer Module] READ: fetch Admin customer merchant account list by id success'
);

export const fetchAdminCustomerMerchantAccountByIdFailed = createAction(
    '[Customer Module] READ: fetch Admin customer merchant account list by id failed'
);
export const setAdminCustomerMerchantAccountList = createAction(
    '[Customer Module] set Admin customer merchant account list',
    props<{accountList: AdminCustomerMerchantAccount[]}>()
);


export const fetchAdminFeeGroups = createAction(
    '[Customer Module] READ: fetch Admin Fees Groups',
    props<{feeType: string}>()
);

export const fetchAdminFeeGroupsFailed = createAction(
    '[Customer Module] STATUS(read): fetch Admin Fees Groups failed',
);

export const fetchAdminFeeGroupsSuccess = createAction(
    '[Customer Module] STATUS(read): fetch Admin Fees Groups success',
);
export const setAdminFeeGroups = createAction(
    '[Customer Module] Set Admin Fees Groups',
    props<{adminFeeGroups: AdminFees[]}>()
);



export const fetchAdminCustomerMerchantById = createAction(
    '[Customer Module] READ: fetch Admin customer merchant by id',
    props<{merchantId: string | number}>()
);
export const fetchAdminCustomerMerchantByIdFailed = createAction(
    '[Customer Module] READ: fetch Admin customer merchant by id failed'
);
export const setFetchedAdminCustomerMerchant = createAction(
    '[Customer Module] Set Fetched Admin Customer Merchant',
    props<{fetchedAdminCustomerMerchant: AdminCustomerMerchant}>()
);



export const updateAdminCustomerMerchant = createAction(
    '[Customer Module] Update admin customer merchant',
    props<{ merchantId: string | number , updateData: any}>()
 );

export const updateAdminCustomerMerchantSuccessful = createAction(
    '[Customer Module] Update admin customer merchant successful',
 );

export const updateAdminCustomerMerchantFailed = createAction(
    '[Customer Module] Update admin customer merchant failed',
 );




export const fetchAdminFeeAccountsByCountry = createAction(
    '[Customer Module] READ: fetch Admin Fee Accounts by country'
);

export const fetchAdminFeeAccountsByCountrySuccessfull = createAction(
    '[Customer Module] READ: fetch Admin Fee Accounts by country successfull'
);

export const fetchAdminFeeAccountsByCountryFailed = createAction(
    '[Customer Module] READ: fetch Admin Fee Accounts by country failed'
);
export const setAdminFeesAccountByCountry = createAction(
    '[Customer Module] Set Admin Fees Account By Country',
    props<{adminFeesAccountByCountry: AdminFeeAccount[]}>()
);
export const fetchAdminManagedAccountsFailed = createAction(
    '[Customer Module] READ: fetch Admin Managed Accounts failed'
);



export const fetchAdminMerchantEntTypes = createAction(
    '[Customer Module] READ: fetch Admin Merchant Ent Type'
);

export const fetchAdminMerchantEntTypesSuccessfull = createAction(
    '[Customer Module] READ: fetch Admin Merchant Ent Type successfull'
);

export const fetchAdminMerchantEntTypesFailed = createAction(
    '[Customer Module] READ: fetch Admin Merchant Ent Type failed'
);
export const setAdminMerchantEntTypes = createAction(
    '[Customer Module] set Admin Merchant Ent Type',
    props<{adminMerchantEntTypes: {_id: string, type: string}[] }>()
);




