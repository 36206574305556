import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-state-table',
  templateUrl: './loading-state-table.component.html',
  styleUrls: ['./loading-state-table.component.css']
})
export class LoadingStateTableComponent implements OnInit {
  @Input() tableColumns: any[];
  constructor() { }

  ngOnInit() {
  }

}
