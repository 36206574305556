import { createReducer, on } from '@ngrx/store';
import { Issuer } from './issuers.interfaces';
import * as issuerActions from './issuers.actions';

export interface IssuerState {
    issuers: Issuer[] | null;
    issuersLoading: boolean;
    issuersError: boolean;

    subIssuers: Issuer[] | null;
    subIssuersLoading: boolean;
    subIssuersError: boolean;
}

export const initialState: IssuerState = {
    issuers : null,
    issuersLoading : null,
    issuersError : null,

    subIssuers : null,
    subIssuersLoading : null,
    subIssuersError : null,
};


export const issuerReducer = createReducer(initialState,
    on(issuerActions.fetchIssuers, (state) => ({
        ...state, issuers : null, issuersLoading: true, issuersError: false
    })),

    on(issuerActions.fetchIssuersFailed, (state) => ({
        ...state, issuers : null, issuersLoading: false, issuersError: true
    })),
    on(issuerActions.fetchIssuersSuccess, (state, {issuers}) => ({
        ...state, issuers, issuersLoading: false, issuersError: false
    })),


    on(issuerActions.fetchSubIssuers, (state) => ({
        ...state, subIssuers : null, subIssuersLoading: true, subIssuersError: false
    })),

    on(issuerActions.fetchSubIssuersFailed, (state) => ({
        ...state, subIssuers : null, subIssuersLoading: false, subIssuersError: true
    })),
    on(issuerActions.fetchSubIssuersSuccess, (state, {subIssuers}) => ({
        ...state, subIssuers, subIssuersLoading: false, subIssuersError: false
    })),

);
