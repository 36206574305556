import { createReducer, on } from '@ngrx/store';
import {
    AdminCustomerMerchant,
    AdminCustomerMerchantAccount,
    AdminCustomerUser,
    AdminCustomerUserTransaction,
    AdminFeeAccount,
    AdminFees,
    AdminLimit,
    MerchantAccount,
    FilterType
} from './customers.interfaces';
import * as customerActions from './customers.actions';


export interface CustomerState {
    limits: AdminLimit[] | null;
    limitsLoading: boolean;
    limitsError: boolean;

    users: AdminCustomerUser[] | null;
    usersLoading: boolean;
    usersError: boolean;

    usersNew: AdminCustomerUser[] | null;
    usersNewLoading: boolean;
    usersNewError: boolean;

    usersPendingApplication: AdminCustomerUser[] | null;
    usersPendingApplicationLoading: boolean;
    usersPendingApplicationError: boolean;

    adminCustomerUserTransactions: AdminCustomerUserTransaction[] | null;
    adminCustomerUserTransactionsLoading: boolean;
    adminCustomerUserTransactionsError: boolean;
    adminCustomerUserPendingTransactionFees:AdminCustomerUserTransaction[]|null

    transactions;
    transactionsLoading: boolean;
    transactionsError: boolean;

    accountTypes: string[] | null;
    transactionTypes: string[] | null;
    channelIDs: string[] | null;

    merchantAccounts: MerchantAccount[] | null;
    merchantAccountsLoading: boolean;
    merchantAccountsError: boolean;

    merchants: AdminCustomerMerchant[] | null;
    merchantsLoading: boolean;
    merchantsError: boolean;

    feeGroups: AdminFees[] | null;
    feeGroupsLoading: boolean;
    feeGroupsError: boolean;

    merchant: AdminCustomerMerchant | null;
    merchantLoading: boolean;
    merchantError: boolean;

    merchantAccountList: AdminCustomerMerchantAccount[] | null;
    merchantAccountListLoading: boolean;
    merchantAccountListError: boolean;

    feesAccountByCountry: AdminFeeAccount[] | null;
    feesAccountByCountryLoading: boolean;
    feesAccountByCountryError: boolean;

    merchantEntTypes: {_id: string, type: string}[] | null;
    merchantEntTypesLoading: boolean;
    merchantEntTypesError: boolean;
}

export const initialState: CustomerState = {
    limits: null,
    limitsLoading: null,
    limitsError: null,

    users: null,
    usersLoading: null,
    usersError: null,

    usersNew: null,
    usersNewLoading: null,
    usersNewError: null,

    usersPendingApplication: null,
    usersPendingApplicationLoading: null,
    usersPendingApplicationError: null,

    adminCustomerUserTransactions: null,
    adminCustomerUserTransactionsLoading:  null,
    adminCustomerUserTransactionsError:  null,
    adminCustomerUserPendingTransactionFees : null,

    transactions: null,
    transactionsLoading:  null,
    transactionsError:  null,

    accountTypes: null,
    transactionTypes: null,
    channelIDs: null,

    merchantAccounts: null,
    merchantAccountsLoading:  null,
    merchantAccountsError:  null,


    merchants: null,
    merchantsLoading: null,
    merchantsError: null,

    feeGroups: null,
    feeGroupsLoading: null,
    feeGroupsError: null,

    merchant: null,
    merchantLoading: null,
    merchantError: null,

    merchantAccountList: null,
    merchantAccountListLoading: null,
    merchantAccountListError: null,

    feesAccountByCountry : null,
    feesAccountByCountryLoading : null,
    feesAccountByCountryError : null,

    merchantEntTypes : null,
    merchantEntTypesLoading : null,
    merchantEntTypesError : null,
};

export const customerReducer = createReducer(initialState,
    on(customerActions.fetchAdminLimits, (state) => ({
        ...state, limits : null, limitsLoading: true, limitsError: false
    })),

    on(customerActions.fetchAdminLimitsFailed, (state) => ({
        ...state, limits : null, limitsLoading: false, limitsError: true
    })),
    on(customerActions.fetchAdminLimitsSuccess, (state, {adminLimits}) => ({
        ...state, limits : adminLimits, limitsLoading: false, limitsError: false
    })),



    on(customerActions.fetchAdminCustomerUsersByTransactions, (state) => ({
        ...state, users : null, usersLoading: true, usersError: false
    })),

    on(customerActions.fetchAdminCustomerUsersByTransactionsFailed, (state) => ({
        ...state, users : null, usersLoading: false, usersError: true
    })),
    on(customerActions.fetchAdminCustomerUsersByTransactionsSuccess, (state, {adminCustomerUsers}) => ({
        ...state, users : adminCustomerUsers.data, usersLoading: false, usersError: false
    })),



    on(customerActions.fetchAdminCustomerUsersByRegistrationDate, (state) => ({
        ...state, users : null, usersLoading: true, usersError: false
    })),

    on(customerActions.fetchAdminCustomerUsersByRegistrationDateFailed, (state) => ({
        ...state, users : null, usersLoading: false, usersError: true
    })),
    on(customerActions.fetchAdminCustomerUsersByRegistrationDateSuccess, (state, {adminCustomerUsers}) => ({
        ...state, users : adminCustomerUsers.data, usersLoading: false, usersError: false
    })),


    on(customerActions.fetchAdminCustomerUsersByMoneySentByCountry, (state) => ({
        ...state, users : null, usersLoading: true, usersError: false
    })),

    on(customerActions.fetchAdminCustomerUsersByMoneySentByCountryFailed, (state) => ({
        ...state, users : null, usersLoading: false, usersError: true
    })),
    on(customerActions.fetchAdminCustomerUsersByMoneySentByCountrySuccess, (state, {adminCustomerUsers}) => ({
        ...state, users : adminCustomerUsers.data, usersLoading: false, usersError: false
    })),


    on(customerActions.fetchAdminCustomerUsers, (state) => ({
        ...state, users : null, usersLoading: true, usersError: false
    })),

    on(customerActions.fetchAdminCustomerUsersFailed, (state) => ({
        ...state, users : null, usersLoading: false, usersError: true
    })),
    on(customerActions.fetchAdminCustomerUsersSuccess, (state, {adminCustomerUsers}) => ({
        ...state, users : adminCustomerUsers.data, usersLoading: false, usersError: false
    })),


    on(customerActions.fetchAdminCustomerUsersNew, (state) => ({
        ...state, usersNew : null, usersNewLoading: true, usersNewError: false
    })),

    on(customerActions.fetchAdminCustomerUsersNewFailed, (state) => ({
        ...state, usersNew : null, usersNewLoading: false, usersNewError: true
    })),
    on(customerActions.fetchAdminCustomerUsersNewSuccess, (state, {adminCustomerUsersNew}) => {
        return ({
        ...state, usersNew : adminCustomerUsersNew.data.users, usersNewLoading: false, usersNewError: false
        })
    }),


    on(customerActions.fetchPendingApplications, (state) => ({
        ...state, usersPendingApplication : null, usersPendingApplicationLoading: true, usersPendingApplicationError: false
    })),

    on(customerActions.fetchPendingApplicationsFailed, (state) => ({
        ...state, usersPendingApplication : null, usersPendingApplicationLoading: false, usersPendingApplicationError: true
    })),
    on(customerActions.fetchPendingApplicationsSuccess, (state, {adminCustomerUsersNew}) => {
        return ({
        ...state, usersPendingApplication : adminCustomerUsersNew.data.users, usersPendingApplicationLoading: false, usersPendingApplicationError: false
        })
    }),



    on(customerActions.resetCustomerUserTransaction, (state) => ({
        ...state, adminCustomerUserTransactions : null
    })),


    on(customerActions.fetchAdminCustomerUserTransactionsByIdAndDateRange, (state) => ({
        ...state,
        adminCustomerUserTransactions: null,
        adminCustomerUserTransactionsLoading: true,
        adminCustomerUserTransactionsError: false
    })),


    on(customerActions.fetchAdminCustomerUserTransactionsById, (state) => ({
        ...state,
        adminCustomerUserTransactions: null,
        adminCustomerUserTransactionsLoading: true,
        adminCustomerUserTransactionsError: false
    })),

    on(customerActions.fetchAdminCustomerUserTransactionsByIdFailed, (state) => ({
        ...state,
        adminCustomerUserTransactions: null,
        adminCustomerUserTransactionsLoading: false,
        adminCustomerUserTransactionsError: true
    })),
    on(customerActions.fetchAdminCustomerUserTransactionsByIdSuccess, (state, {adminCustomerUserTransactions}) => ({
        ...state, adminCustomerUserTransactions:adminCustomerUserTransactions.data, adminCustomerUserTransactionsLoading: false, adminCustomerUserTransactionsError: false
    })),



    on(customerActions.fetchAdminCustomerTransactions, (state) => ({
        ...state, transactions : null, transactionsLoading: true, transactionsError: false
    })),

    on(customerActions.fetchAdminCustomerTransactionsFailed, (state) => ({
        ...state, transactions : null, transactionsLoading: false, transactionsError: true
    })),
    on(customerActions.fetchAdminCustomerTransactionsSuccess, (state, {adminCustomerTransactions}) => ({
        ...state, transactions : adminCustomerTransactions.data, transactionsLoading: false, transactionsError: false
    })),


    on(customerActions.fetchAdminMerchantAccounts, (state) => ({
        ...state, merchantAccounts : null, merchantAccountsLoading: true, merchantAccountsError: false
    })),

    on(customerActions.fetchAdminMerchantAccountsFailed, (state) => ({
        ...state, merchantAccounts : null, merchantAccountsLoading: false, merchantAccountsError: true
    })),
    on(customerActions.setAdminCustomerMerchantAccounts, (state, {merchantAccounts}) => ({
        ...state, merchantAccounts, merchantAccountsLoading: false, merchantAccountsError: false
    })),


    on(customerActions.fetchAdminCustomerMerchants, (state) => ({
        ...state, merchants : null, merchantsLoading: true, merchantsError: false
    })),

    on(customerActions.fetchAdminCustomerMerchantsFailed, (state) => ({
        ...state, merchants : null, merchantsLoading: false, merchantsError: true
    })),
    on(customerActions.setAdminCustomerMerchants, (state, {adminCustomerMerchants}) => ({
        ...state, merchants : adminCustomerMerchants, merchantsLoading: false, merchantsError: false
    })),


    on(customerActions.fetchAdminFeeGroups, (state) => ({
        ...state, feeGroups : null, feeGroupsLoading: true, feeGroupsError: false
    })),

    on(customerActions.fetchAdminFeeGroupsFailed, (state) => ({
        ...state, feeGroups : null, feeGroupsLoading: false, feeGroupsError: true
    })),
    on(customerActions.setAdminFeeGroups, (state, {adminFeeGroups}) => ({
        ...state, feeGroups : adminFeeGroups, feeGroupsLoading: false, feeGroupsError: false
    })),



    on(customerActions.fetchAdminCustomerMerchantById, (state) => ({
        ...state, merchant : null, merchantLoading: true, merchantError: false
    })),

    on(customerActions.fetchAdminCustomerMerchantByIdFailed, (state) => ({
        ...state, merchant : null, merchantLoading: false, merchantError: true
    })),
    on(customerActions.setFetchedAdminCustomerMerchant, (state, {fetchedAdminCustomerMerchant}) => ({
        ...state, merchant : fetchedAdminCustomerMerchant, merchantLoading: false, merchantError: false
    })),



    on(customerActions.fetchAdminCustomerMerchantAccountById, (state) => ({
        ...state, merchantAccountList : null, merchantAccountListLoading: true, merchantAccountListError: false
    })),

    on(customerActions.fetchAdminCustomerMerchantAccountByIdFailed, (state) => ({
        ...state, merchantAccountList : null, merchantAccountListLoading: false, merchantAccountListError: true
    })),
    on(customerActions.setAdminCustomerMerchantAccountList, (state, {accountList}) => ({
        ...state, merchantAccountList : accountList, merchantAccountListLoading: false, merchantAccountListError: false
    })),




    on(customerActions.fetchAdminFeeAccountsByCountry, (state) => ({
        ...state, feesAccountByCountry : null, feesAccountByCountryLoading: true, feesAccountByCountryError: false
    })),

    on(customerActions.fetchAdminManagedAccountsFailed, (state) => ({
        ...state, feesAccountByCountry : null, feesAccountByCountryLoading: false, feesAccountByCountryError: true
    })),
    on(customerActions.setAdminFeesAccountByCountry, (state, {adminFeesAccountByCountry}) => ({
        ...state, feesAccountByCountry : adminFeesAccountByCountry, feesAccountByCountryLoading: false, feesAccountByCountryError: false
    })),


    on(customerActions.fetchAdminMerchantEntTypes, (state) => ({
        ...state, merchantEntTypes : null, merchantEntTypesLoading: true, merchantEntTypesError: false
    })),

    on(customerActions.fetchAdminMerchantEntTypesFailed, (state) => ({
        ...state, merchantEntTypes : null, merchantEntTypesLoading: false, merchantEntTypesError: true
    })),
    on(customerActions.setAdminMerchantEntTypes, (state, {adminMerchantEntTypes }) => ({
        ...state, merchantEntTypes : adminMerchantEntTypes , merchantEntTypesLoading: false, merchantEntTypesError: false
    })),
    on(customerActions.fetchAdminCustomerFiltersSuccess, (state, {adminCustomerFiltersArray, filterType }) => {
        //console.log(adminCustomerFiltersArray)
        if(filterType == FilterType.ACCOUNT_TYPE){
            return {
                ...state, accountTypes: adminCustomerFiltersArray
            }
        }
        if(filterType == FilterType.CHANNEL_ID){
            return {
                ...state, channelIDs: adminCustomerFiltersArray
            }
        }
        if(filterType == FilterType.TRANSACTION_TYPE){
            return {
                ...state, transactionTypes: adminCustomerFiltersArray
            }
        }

        return {...state}
    }),
);
