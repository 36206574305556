import { createReducer, on } from "@ngrx/store";
import * as fwpAction from "./fwp.action";
import {
  IFwpMerchantResp,
  IFwpOverviewResp,
} from "../_interfaces/fwp.interface.";

export interface fwpState {
  overviews: IFwpOverviewResp;
  loading: boolean;
  merchants: IFwpMerchantResp;
}

export const initialState: fwpState = {
  overviews: null,
  loading: false,
  merchants: null,
};

export const _fwpReducer = createReducer(
  initialState,

  on(fwpAction.fetchFwpOverviews, (state) => ({
    ...state,
    loading: true,
  })),

  on(fwpAction.fetchFwpOverviewsSuccess, (state, { overviews }) => ({
    ...state,
    loading: false,
    overviews,
  })),

  on(fwpAction.fetchFwpOverviewsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(fwpAction.fetchMerchantLoans, (state) => ({
    ...state,
    loading: true,
  })),

  on(fwpAction.fetchMerchantLoansSuccess, (state, { merchants }) => ({
    ...state,
    loading: false,
    merchants,
  })),

  on(fwpAction.fetchMerchantLoansFailed, (state) => ({
    ...state,
    loading: false,
  }))
);

export const fwpReducer = (state: fwpState, action) =>
  _fwpReducer(state, action);
