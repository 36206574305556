import { Injectable } from '@angular/core';
import {
  CorporateRuleModel,
  FinancialInstitutionsModel,
  PepAndSanctionsModel,
  RulesModel,
  RulesSetsModel
} from '../_interfaces/trans-monitoring.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class TransMonitoringService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  fetchRuleSets() {
    return this.http.get(
      `${
        environment.API_URL
      }/transactions-service/ukaml/list-ruleset?country_code=${this.sharedService.getSelectedCountryFromSession()}`
    );
  }

  fetchAllDefaultRules(defaultItem: string) {
    return this.http.get(`${environment.API_URL}/transactions-service/ukaml/${defaultItem}`);
  }

  createDefaultRule(payload: any, defaultItem: any) {
    return this.http.post(`${environment.API_URL}/transactions-service/ukaml/${defaultItem}`, payload);
  }

  updateDefaultRule(payload: any, key:string, id:string) {
    return this.http.put(`${environment.API_URL}/transactions-service/ukaml/${key}/${id}`, payload);
  }

  updateDefualtParam(payload: any, defaultItem: any) {
    return this.http.put(`${environment.API_URL}/transactions-service/ukaml/${defaultItem}`, payload);
  }

  fetchCorporateRule(page?: string, pageSize?: string, accountNumber?: string): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/corporate-rule?page=${page}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }
    if (accountNumber) {
      url += `&accountNumber=${accountNumber}`;
    }
    if (this.sharedService.getSelectedCountryFromSession()) {
      url += `&country_code=${this.sharedService.getSelectedCountryFromSession()}`;
    }
    return this.http.get(url).pipe(retry(3));
  }

  // fetchCorporateRules(page?: string, pageSize?: string, pageNumber?: string) {
  //   return this.http.get(`${environment.API_URL}/transactions-service/ukaml/list-ruleset`);
  // }

  deleteRulesSets(rulesetId: string) {
    return this.http.delete(`${environment.API_URL}/transactions-service/ukaml/delete-ruleset/${rulesetId}`);
  }

  deleteCorporateRule(corporateRuleId: string) {
    return this.http.delete(`${environment.API_URL}/transactions-service/ukaml/corporate-rule/${corporateRuleId}`);
  }

  createRulesSets(item: RulesSetsModel) {
    // console.log('gggg', item);
    return this.http.post(
      `${
        environment.API_URL
      }/transactions-service/ukaml/create-ruleset?country_code=${this.sharedService.getSelectedCountryFromSession()}`,
      item
    );
  }

  updateRulesSets(rulesetId: string, rulesSet: RulesSetsModel): Observable<RulesSetsModel> {
    let items$ = this.http.put<RulesSetsModel>(
      `${environment.API_URL}/transactions-service/ukaml/update-ruleset/${rulesetId}`,
      rulesSet
    );
    return items$;
  }

  updateCorporateRule(corporateRuleId: string, corporateRule: CorporateRuleModel): Observable<RulesSetsModel> {
    let items$ = this.http.put<RulesSetsModel>(
      `${environment.API_URL}/transactions-service/ukaml/corporate-rule/${corporateRuleId}`,
      corporateRule
    );
    return items$;
  }

  createCorporateRule(item: CorporateRuleModel) {
    // console.log('gggg', item);
    return this.http.post(`${environment.API_URL}/transactions-service/ukaml/corporate-rule`, item);
  }

  createFinancialInstitution(item: FinancialInstitutionsModel) {
    // console.log('gggg', item);
    return this.http.post(
      `${
        environment.API_URL
      }/transactions-service/ukaml/create-finance-institution?country_code=${this.sharedService.getSelectedCountryFromSession()}`,
      item
    );
  }

  updateFinancialInstitution(
    financialInstitionId: string,
    financialInstitution: FinancialInstitutionsModel
  ): Observable<RulesSetsModel> {
    let items$ = this.http.put<RulesSetsModel>(
      `${environment.API_URL}/transactions-service/ukaml/update-finance-institution/${financialInstitionId}`,
      financialInstitution
    );
    return items$;
  }

  deleteFinancialInstitution(financialInstitionId: string) {
    return this.http.delete(
      `${environment.API_URL}/transactions-service/ukaml/delete-finance-institution/${financialInstitionId}`
    );
  }

  createPepAndSanctions(item: PepAndSanctionsModel) {
    // console.log('gggg', item);
    return this.http.post(`${environment.API_URL}/transactions-service/ukaml/peps-and-sanctions`, item);
  }

  fetchRules(): Observable<RulesModel[]> {
    return this.http.get<RulesModel[]>(
      `${
        environment.API_URL
      }/transactions-service/ukaml/list-rules?country_code=${this.sharedService.getSelectedCountryFromSession()}`
    );
  }

  fetchFinacialInstitutions() {
    return this.http.get(
      `${
        environment.API_URL
      }/transactions-service/ukaml/list-finance-institutions?country_code=${this.sharedService.getSelectedCountryFromSession()}`
    );
  }

  fetchTransactionTypes() {
    return this.http.get(
      `${
        environment.API_URL
      }/transactions-service/ukaml/list-transaction-types?country_code=${this.sharedService.getSelectedCountryFromSession()}`
    );
  }

  fetchTransactionChannels() {
    return this.http.get(
      `${
        environment.API_URL
      }/transactions-service/ukaml/list-channels?country_code=${this.sharedService.getSelectedCountryFromSession()}`
    );
  }

  fetchAllFlaggedTransactions(
    page?: string,
    pageSize?: string,
    startDate?: string,
    endDate?: string,
    srcInstId?: string,
    desInstId?: string,
    transactionRef?: string,
    amount?: string,
    serviceId?: string,
    channelCode?: string,
    transactionTypeCode?: string
  ): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/flagged-transactions?page=${page}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (srcInstId) {
      url += `&srcInstId=${srcInstId}`;
    }
    if (desInstId) {
      url += `&desInstId=${desInstId}`;
    }
    if (transactionRef) {
      url += `&transactionRef=${transactionRef}`;
    }
    if (amount) {
      url += `&amount=${amount}`;
    }
    if (serviceId) {
      url += `&serviceId=${serviceId}`;
    }
    if (transactionTypeCode) {
      url += `&transactionTypeCode=${transactionTypeCode}`;
    }
    if (this.sharedService.getSelectedCountryFromSession()) {
      url += `&country_code=${this.sharedService.getSelectedCountryFromSession()}`;
    }

    return this.http.get(url).pipe(retry(3));
  }

  // channelCode;

  fetchAllLoggedTransactions(startDate?: string, endDate?: string): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/logs?startDate=${startDate}`;
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    return this.http.get(url).pipe(retry(3));
  }

  fetchDashboardMetrics(startDate?: string, endDate?: string, filter_type?: string): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/dashboard?startDate=${startDate}`;
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (filter_type) {
      url += `&filter_type=${filter_type}`;
    }
    if (this.sharedService.getSelectedCountryFromSession()) {
      url += `&country_code=${this.sharedService.getSelectedCountryFromSession()}`;
    }
    return this.http.get(url).pipe(retry(3));
  }

  // fetchDashboardMetrics() {
  //   return this.http.get(`${environment.API_URL}/transactions-service/ukaml/dashboard`);
  // }

  // fetchFlagged() {
  //   return this.http.get(`${environment.API_URL}/transactions-service/ukaml/dashboard`);
  // }

  // fetchAllLoggedTransactions(startDate?: string, endDate?: string): Observable<any> {
  //   let url = `${environment.API_URL}/transactions-service/ukaml/logs?startDate=${startDate}`;
  //   if (endDate) {
  //     url += `&endDate=${endDate}`;
  //   }
  //   return this.http.get(url).pipe(retry(3));
  // }

  fetchLoggedTransactions(
    page?: string,
    pageSize?: string,
    startDate?: string,
    endDate?: string,
    srcInstId?: string,
    desInstId?: string,
    transactionRef?: string,
    amount?: string,
    serviceId?: string,
    channelCode?: string,
    transactionTypeCode?: string
  ): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/logs?page=${page}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (srcInstId) {
      url += `&srcInstId=${srcInstId}`;
    }
    if (desInstId) {
      url += `&desInstId=${desInstId}`;
    }
    if (transactionRef) {
      url += `&transactionRef=${transactionRef}`;
    }
    if (amount) {
      url += `&amount=${amount}`;
    }
    if (serviceId) {
      url += `&serviceId=${serviceId}`;
    }
    if (transactionTypeCode) {
      url += `&transactionTypeCode=${transactionTypeCode}`;
    }
    if (this.sharedService.getSelectedCountryFromSession()) {
      url += `&country_code=${this.sharedService.getSelectedCountryFromSession()}`;
    }

    return this.http.get(url).pipe(retry(3));
  }

  fetchFlaggedTransactionsByIdAndBySanctionCheck(transactionId?: string, sanctionCheck?: string): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/flagged-transaction/${transactionId}?sanctionCheck=${sanctionCheck}`;
    return this.http.get(url).pipe(retry(3));
  }

  fetchFlaggedTrailingTransactions(
    transactionId?: string,
    ruleId?: string,
    page?: string,
    pageSize?: string
  ): Observable<any> {
    let url = `${environment.API_URL}/transactions-service/ukaml/flagged-trailing-transactions/${transactionId}/${ruleId}?page=${page}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }
    return this.http.get(url).pipe(retry(3));
  }

  fetchRulesByRuleSetId(ruleSetId) {
    return this.http.get(`${environment.API_URL}/transactions-service/ukaml/get-ruleset/${ruleSetId}`);
  }

  createChannel(item: any) {
    // console.log('gggg', item);
    return this.http.post(
      `${
        environment.API_URL
      }/transactions-service/ukaml/create-channel?country_code=${this.sharedService.getSelectedCountryFromSession()}`,
      item
    );
  }

  updateChannel(item: any, channelId: string) {
    // console.log('gggg', item);
    return this.http.put(`${environment.API_URL}/transactions-service/ukaml/update-channel/${channelId}`, item);
  }

  deleteChannel(channelId: string) {
    // console.log('gggg', item);
    return this.http.delete(`${environment.API_URL}/transactions-service/ukaml/delete-channel/${channelId}`);
  }

  createTransactionType(item: any) {
    // console.log('gggg', item);
    return this.http.post(
      `${
        environment.API_URL
      }/transactions-service/ukaml/create-transaction-type?country_code=${this.sharedService.getSelectedCountryFromSession()}`,
      item
    );
  }

  updateTransactionType(item: any, channelId: string) {
    // console.log('gggg', item);
    return this.http.put(
      `${environment.API_URL}/transactions-service/ukaml/update-transaction-type/${channelId}`,
      item
    );
  }

  deleteTransactionType(channelId: string) {
    // console.log('gggg', item);
    return this.http.delete(`${environment.API_URL}/transactions-service/ukaml/delete-transaction-type/${channelId}`);
  }

  createRule(rulesetId: any, item: any) {
    // console.log('gggg', item);
    return this.http.post(
      `${
        environment.API_URL
      }/transactions-service/ukaml/create-rule?rulesetId=${rulesetId}&country_code=${this.sharedService.getSelectedCountryFromSession()}`,
      item
    );
  }

  updateRule(ruleId: any, item: any) {
    // console.log('gggg', item);
    return this.http.put(`${environment.API_URL}/transactions-service/ukaml/update-rule/${ruleId}`, item);
  }

  deleteRule(ruleId: any) {
    // console.log('gggg', item);
    return this.http.delete(`${environment.API_URL}/transactions-service/ukaml/delete-rule/${ruleId}`);
  }
}
