import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages = [
    {
      name: 'ENGLISH',
      lang: 'en'
    },
    {
      name: 'FRENCH',
      lang: 'fr'
    },
    {
      name: 'SPANISH',
      lang: 'es'
    },
    {
      name: 'PORTUGUESE',
      lang: 'pt'
    }
  ];

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.use(localStorage.getItem('language'));
  }

  setLang(lang: string) {
    if (lang) {
      this.translate.use(lang);
      localStorage.setItem('language', lang);
    }
  }

  getLang(): string {
    return localStorage.getItem('language') || this.translate.getDefaultLang();
  }
}
