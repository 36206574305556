import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ExchangeRateService } from '../exchange-rates.service';
import * as exRatesActions from './rates.actions';


@Injectable({
  providedIn: 'root',
})
export class ExchangeRatesEffects {


        fetchAdminExchangeRateForPayment$ = createEffect(() =>
        this.action$.pipe(
          ofType(exRatesActions.fetchAdminExchangeRatesForPayment),
          exhaustMap((action) =>
            this.exRatesService.getExchangeRateByType('payment').pipe(
              map((response: any) =>
                exRatesActions.setAdminExchangeRatesForPayment({
                  adminExchangeRatesForPayment: response.data,
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(exRatesActions.fetchAdminExchangeRatesForPaymentFailed())
              )
            )
          )
        )
      );

      fetchAdminExchangeRateForTransfer$ = createEffect(() =>
      this.action$.pipe(
        ofType(exRatesActions.fetchAdminExchangeRatesForTransfer),
        exhaustMap((action) =>
          this.exRatesService.getExchangeRateByType('transfer').pipe(
            map((response: any) =>
              exRatesActions.setAdminExchangeRatesForTransfer({
                adminExchangeRatesForTransfer: response.data,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(exRatesActions.fetchAdminExchangeRatesForTransferFailed())
            )
          )
        )
      )
    );

    constructor(
        private action$: Actions,
        private exRatesService: ExchangeRateService,
        private toastr: ToastrService,
        ) {}
}
