import { createAction, props } from '@ngrx/store';
import { CreateOrganisationDto } from '../_dtos/create-org.dto';
import { CreateBulkStaffDto, CreateStaffDto } from '../_dtos/create-staff.dto';
import { AdminOrganisation, AdminOrganisationStaff, AdminOrganisationStaffLoanHistory, AdminOrganisationStaffLoanTrans } from '../_interfaces/admin-organisation.interface';

export const createAdminOrgAccount = createAction(
    '[Organisation page] CREATE: Admin organisation account',
    props<{orgAccount: CreateOrganisationDto,   createType: string, editId ?: string }>()
);

export const createAdminOrgAccountSuccess = createAction(
    '[Organisation page] STATUS: Admin organisation account creation success',
    props<{ successType: string }>()
);

export const createAdminOrgAccountFailed = createAction(
    '[Organisation page] STATUS: Admin organisation account creation failed',
    props<{ failType: string }>()
);

export const fetchAdminOrgAccounts = createAction(
    '[Organisation page] READ: fetch admin organisation accounts'
);

export const fetchSelectedAdminOrgAccount = createAction(
    '[Organisation page] READ: fetch selected admin organisation account',
    props<{ orgId: string }>()
);

export const fetchSelectedAdminOrgAccountsFailed = createAction(
    '[Organisation page] READ: fetch selected admin organisation account failed'
);


export const fetchAdminOrgAccountsSuccess = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation accounts success'
);

export const fetchAdminOrgAccountsFailed = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation accounts failed'
);

export const setAdminOrgAccounts = createAction(
    '[Organisation page] Set admin organisation accounts',
    props<{adminOrgAccounts: AdminOrganisation[]}>()
);

export const setSelectedAdminOrgAccount = createAction(
    '[Organisation page] Set selected admin organisation account',
    props<{adminSelectedOrgAccount: AdminOrganisation}>()
);

export const createAdminOrgStaffAccount = createAction(
    '[Organisation page] CREATE: Admin organisation staff account',
    props<{orgId: string, staffAccount: CreateStaffDto,   createType: string, editId ?: string }>()
);

export const createAdminOrgStaffAccountSuccess = createAction(
    '[Organisation page] STATUS: Admin organisation staff account creation success',
    props<{ successType: string }>()
);

export const createAdminOrgStaffAccountFailed = createAction(
    '[Organisation page] STATUS: Admin organisation staff account creation failed',
    props<{ failType: string }>()
);

export const createAdminOrgBulkStaffAccount = createAction(
    '[Organisation page] CREATE: Admin organisation bulk staff account',
    props<{staffAccounts: CreateBulkStaffDto[]}>()
);

export const createAdminOrgBulkStaffAccountSuccess = createAction(
    '[Organisation page] STATUS: Admin organisation bulk staff account creation success'
);

export const createAdminOrgBulkStaffAccountFailed = createAction(
    '[Organisation page] STATUS: Admin organisation bulk staff account creation failed'
);

export const fetchAdminOrgStaffAccounts = createAction(
    '[Organisation page] READ: fetch admin organisation staff accounts',
    props<{ orgId: string }>()
);

export const fetchAdminOrgStaffAccountsSuccess = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation staff accounts success'
);

export const fetchAdminOrgStaffAccountsFailed = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation staff accounts failed'
);

export const setAdminOrgStaffAccounts = createAction(
    '[Organisation page] Set admin organisation staff accounts',
    props<{adminOrgStaffAccounts: AdminOrganisationStaff[]}>()
);

export const fetchAdminOrgStaffLoanHistory = createAction(
    '[Organisation page] READ: fetch admin organisation staff loan history',
    props<{ walletNo: string, schemeCode: string }>()
);

export const fetchAdminOrgStaffLoanHistorySuccess = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation staff loan history success'
);

export const fetchAdminOrgStaffLoanHistorysFailed = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation staff loan history failed'
);

export const setAdminOrgStaffLoanHistory = createAction(
    '[Organisation page] Set admin organisation staff loan history',
    props<{adminOrgStaffLoanHistory: AdminOrganisationStaffLoanHistory[]}>()
);

export const fetchAdminOrgStaffLoanTrans = createAction(
    '[Organisation page] READ: fetch admin organisation staff loan transaction',
    props<{ staffId: string }>()
);

export const fetchAdminOrgStaffLoanTransSuccess = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation staff loan transaction success'
);

export const fetchAdminOrgStaffLoanTransFailed = createAction(
    '[Organisation page] STATUS(read): fetch admin organisation staff loan transaction failed'
);

export const setAdminOrgStaffLoanTrans = createAction(
    '[Organisation page] Set admin organisation staff loan transaction',
    props<{adminOrgStaffLoanTrans: AdminOrganisationStaffLoanTrans[]}>()
);
