import { createAction, props } from '@ngrx/store';
import { AdminUsers } from '../../activity-log/_interfaces/admin.interface';
import { CountryDto } from '../_dtos/country.dto';
import { UpdatePasswordDto } from '../_dtos/update-password.dto';
import { Bank } from '../_interfaces/admin-bank.interface';
import { BankDto } from '../_interfaces/bank.dto';

export const fetchAdminUsers = createAction('[Admin Settings] Fetch Admin Users');
export const fetchAdminUsersFailed = createAction('[Admin Settings] Fetch Admin Users failed');
export const fetchAdminUsersSuccess = createAction('[Admin Settings] Fetch Admin Users Success');

export const setAdminUsers = createAction(
    '[Admin Settings] Set Admin Users',
    props<{ adminUsers: AdminUsers[]}>()
);

export const deleteAdminUsers = createAction(
    '[Admin Settings] DELETE: delete Admin User',
    props<{ userId: string}>()
);
export const deleteAdminUsersFailed = createAction('[Admin Settings] STATUS: delete Admin User failed');
export const deleteAdminUsersSuccess = createAction('[Admin Settings] STATUS: delete Admin User Successfull');

export const changePassword = createAction(
    '[Admin Settings] UPDATE: Change password',
    props<{updatePasswordDto: UpdatePasswordDto}>()
);
export const changePasswordFailed = createAction('[Admin Settings] STATUS: Change password failed');
export const changePasswordSuccess = createAction('[Admin Settings] STATUS: Change password success');

export const addCountry = createAction(
    '[Admin Settings] CREATE: Add country',
    props<{countryDto: CountryDto}>()
);
export const addCountryFailed = createAction('[Admin Settings] STATUS: Add country failed');
export const addCountrySuccess = createAction('[Admin Settings] STATUS: Add country success');

export const updateCountry = createAction(
    '[Admin Settings] UPDATE: Update country',
    props<{countryDto: CountryDto, countryId: string}>()
);
export const updateCountryFailed = createAction('[Admin Settings] STATUS: Update country failed');
export const updateCountrySuccess = createAction('[Admin Settings] STATUS: Update country success');

export const deleteCountry = createAction(
    '[Admin Settings] DELETE: Delete country',
    props<{countryId: string}>()
);
export const deleteCountryFailed = createAction('[Admin Settings] STATUS: delete country failed');
export const deleteCountrySuccess = createAction('[Admin Settings] STATUS: delete country success');

export const fetchAdminBanks = createAction('[Admin Settings] Fetch Admin Banks')
export const fetchAdminBanksFailed = createAction('[Admin Settings] Fetch Admin Banks failed')

export const setAdminBanks = createAction(
    '[Admin Settings] Set Admin Users',
    props<{ adminBanks : Bank[]}>()
)

export const addBank = createAction(
    '[Admin Settings] CREATE: Add banks',
    props<{bankDto : BankDto}>()
)
export const addBankFailed = createAction('[Admin Settings] STATUS: Add banks failed')
export const addBankSuccess = createAction('[Admin Settings] STATUS: Add banks success')

export const deleteBank = createAction(
    '[Admin Settings] CREATE: Delete banks',
    props<{bankId : string}>()
)
export const deleteBankFailed = createAction('[Admin Settings] STATUS: Delete banks failed')
export const deleteBankSuccess = createAction('[Admin Settings] STATUS: Delete banks success')

export const updateBank = createAction(
    '[Admin Settings] CREATE: Update banks',
    props<{bankDto : BankDto, bankId : string, bankCode: string}>()
)
export const updateBankFailed = createAction('[Admin Settings] STATUS: Update banks failed')
export const updateBankSuccess = createAction('[Admin Settings] STATUS: Update banks success')