import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  fetchAdminActivityLog(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.ADMIN_AUTH_URL}/activity-log`, { params });
  }

}
