import { createReducer, on } from '@ngrx/store';
import { AdminActivityLog } from '../_interfaces/admin-activity-log-interface';
import { setAdminActivityLog } from './activity-log.actions';

export interface ActivityLogState {
    adminActivityLogs: AdminActivityLog[] | null;
}

export const initialState: ActivityLogState = {
    adminActivityLogs : null
};

export const _activityLogReducer = createReducer(initialState,

    on(setAdminActivityLog, (state, { adminActivityLogs }) => ({
        ...state, adminActivityLogs
    }))

);

export const activityLogReducer = (state: ActivityLogState, action) => _activityLogReducer(state, action);
