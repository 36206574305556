import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../shared/services/language.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.css']
})
export class LanguageSwitchComponent implements OnInit {

  @Input() noBorder: boolean;
  lang: string;

  constructor(public languageService: LanguageService) { }

  ngOnInit(): void {
    this.lang = this.languageService.getLang();
    this.languageService.setLang(this.languageService.getLang());
  }

  switchLanguage(lang: string) {
    this.lang = lang;
    this.languageService.setLang(lang);
  }

}
