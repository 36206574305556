import { createAction, props } from '@ngrx/store';
import { AuthDto, PersonalInformation } from './auth.dtos';
import { AdminCountry, AuthUser, EmailVerification, OtpVerification, PhoneVerification } from './auth.interfaces';
import { Bank } from '../../customers/_state/customers.interfaces';


export const setPersonalInformation =
    createAction('[ Auth Module ] set personal information',
        props<{ personalData: PersonalInformation}>());


export const setOtpVerification =
 createAction('[ Auth Module ] set otp information',
    props<{ otpVerificationDetails: OtpVerification}>());



export const StoreEmailVerification =
    createAction('[ Auth Module ] StoreEmailVerification',
        props<{ emailVerification: EmailVerification}>());


export const StorePhoneVerification =
    createAction('[ Auth Module ] StorePersonalVerification',
        props<{ phoneVerification: PhoneVerification}>()
    );

export const StoreAuthDto =
    createAction('[ Auth DTO] Store Auth Dto', props<{ authDto: AuthDto}>());

export const fetchAuthUser = createAction(
    '[Auth Module] fetch authenticated user'
);

export const fetchAuthUserSuccess = createAction(
    '[Auth Module] fetch authenticated user success'
);

export const fetchAuthUserFailed = createAction(
    '[Auth Module] fetch authenticated user failed'
);

export const setAuthUser = createAction(
    '[Auth Module] set authenticated user',
    props<{ authUser: AuthUser}>()
);

export const triggerForgotPassword = createAction(
    '[Auth Module] trigger forgot password',
    props<{email: string}>()
);

export const triggerForgotPasswordSuccess = createAction(
    '[Auth Module] trigger forgot password success'
);

export const triggerForgotPasswordFailed = createAction(
    '[Auth Module] trigger forgot password failed'
);

export const resetPassword = createAction(
    '[Auth Module] reset password',
    props<{newPassword: string, resetSid: string}>()
);

export const resetPasswordSuccess = createAction(
    '[Auth Module]  reset password success'
);

export const resetPasswordFailed = createAction(
    '[Auth Module]  reset password failed'
);

export const checkResetSid = createAction(
    '[Auth Module] check reset sid',
    props<{sid: string}>()
);

export const resetSidValid = createAction(
    '[Auth Module] check reset sid valid'
);

export const resetSidInValid = createAction(
    '[Auth Module] check reset sid Invalid'
);

export const logout = createAction(
    '[Auth Module] logout'
);

export const fetchAdminCountries = createAction(
    '[Auth Module] READ: fetch admin countries'
);

export const fetchAdminCountriesFailed = createAction(
    '[Auth Module] READ: fetch admin countries failed'
);

export const fetchAdminCountriesSuccess = createAction(
    '[Auth Module] fetch admin countries success',
    props<{adminCountries: AdminCountry[]}>()
);

export const setAdminSelectedCountry = createAction(
    '[Auth Module] Set Selected Admin Country',
    props<{adminCountry: AdminCountry}>()
);

export const fetchAdminBanks = createAction('[Admin Settings] Fetch Admin Banks');

export const fetchAdminBanksFailed = createAction('[Admin Settings] Fetch Admin Banks failed');

export const setAdminBanks = createAction(
    '[Admin Settings] Set Admin Users',
    props<{ adminBanks : Bank[]}>()
);
