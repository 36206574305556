import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { SearchData } from '../../../shared/interfaces/search-data.interface';
import { CreateBlacklistDto } from '../_dtos/create-blacklist.dto';
import { UpdateBlacklistInfoDto } from '../_dtos/update-blacklist.dto';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KycService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  getAllKyc(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/kyc/all/all`, { params });
  }

  getKycByDateRange(searchData: SearchData){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/kyc/${searchData.startDate}/${searchData.endDate}`, { params });

  }

  getKycErrorsByDateRange(searchData: SearchData){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/kycerrors/${searchData.startDate}/${searchData.endDate}`, { params });

  }

  fetchFailedReg(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/support-service/report/failed-registrations?country_code=${this.sharedService.getSelectedCountryCodeFromSession()}`, { params });
  }

  deleteFailedReg(id){
      return this.http.delete(`${environment.API_URL}/support-service/report/failed-registrations/${id}`);
  }

  createFailedReg(payload){
    return this.http.post(`${environment.API_URL}/source/wallet/register`, payload);
  }

  getAllFailedTransaction(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.API_URL}/kycerrors/all/all`, { params });
  }

  sendKycMail(payload){
    return this.http.post(`${environment.API_URL}/support-service/report/kyc-mail`, payload);
  }

  getKycRecords(startDate , endDate, page = 1, pageSize = 5, is_business?: boolean) {
    let params = new HttpParams();
    params = params.append('issuerCode', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('subIssuerCode', this.sharedService.getSelectedSubIssuerCodeFromSession());
    if (startDate){
      params = params.append('startDate', startDate);
    }
    if (endDate){
      params = params.append('endDate', endDate);
    }
    if (is_business !== undefined) {
      params = params.append('is_business', String(is_business));
    }
    // return this.http.get(`${environment.API_URL}/user-service/verification/user-data?status=success&page=${page}&pageSize=${pageSize}`, { params });
    return this.http.get(`${environment.API_URL}/user-service/verification/user-data?status=success&country=${this.sharedService.getSelectedCountryFromSession()}&page=${page}&pageSize=${pageSize}`, { params });
  }

  getAllPendingKYCNew(page, pageSize, search){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    params = params.append('page', page)
    params = params.append('limit', pageSize)
    params = params.append('search', search)
    params= params.append('empty_kyc', 'true')
    return this.http.get(
        `${environment.XAS_URL}/accounts/${this.sharedService.getSelectedCountryFromSession()}`,
        {params}
    ).pipe(retry(3));
}


  getFailedKycRecords(startDate, endDate, page = 1, pageSize = 5, userId?:string, is_business?: boolean) {
    let params = new HttpParams();
    params = params.append('issuerCode', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('subIssuerCode', this.sharedService.getSelectedSubIssuerCodeFromSession());
    if (startDate){
      params = params.append('startDate', startDate);
    }
    if (endDate){
      params = params.append('endDate', endDate);
    }
    if(userId){
      params = params.append('userid', userId);
    }
    if (is_business !== undefined) {
      params = params.append('is_business', String(is_business));
    }
    return this.http.get(`${environment.API_URL}/user-service/verification/user-data?status=failed&country=${this.sharedService.getSelectedCountryFromSession()}&page=${page}&pageSize=${pageSize}`, { params });
  }

  getKycByScanReference(scanReference: string) {
    return this.http.get(`${environment.API_URL}/user-service/verification/user-data/${scanReference}`);
  }

  fetchBlacklistInformationList(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(
      `${environment.ADMIN_AUTH_URL}/blacklist/country/${this.sharedService.getSelectedCountryCodeFromSession()}`,
      { params }
    );
  }

  createBlacklistInformation(createBlacklistDto: CreateBlacklistDto){
      return this.http.post(`${environment.ADMIN_AUTH_URL}/blacklist`, createBlacklistDto);
  }

  updateBlacklistInformation(id: string, updateBlacklistDto: UpdateBlacklistInfoDto){
      return this.http.put(`${environment.ADMIN_AUTH_URL}/blacklist/${id}`, updateBlacklistDto);
  }

  deleteBlacklistInformation(id: string){
      return this.http.delete(`${environment.ADMIN_AUTH_URL}/blacklist/${id}`);
  }

  approveKYC = (uploadID, status) => {
    let params = new HttpParams();
    params = params.append('approve', status);
    return this.http.get(`${environment.USER_SERVICE_URL}/verification/approve-kyc/${uploadID}`, {params})
  }

  createAccount=(kycID,userID, isUpdate)=>{
    const payload={
      kyc_id:kycID,
      country:this.sharedService.getSelectedCountryFromSession(),
      user_id:userID,
      is_update:isUpdate
    }
    return this.http.post(`${environment.USER_SERVICE_URL}/verification/update-user-xaas`, payload)
  }

  reviewKYC= (kycId)=>{
    return this.http.put(`${environment.USER_SERVICE_URL}/verification/${kycId}/reviewed`, {kycId})
  }

}
