import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/reducers';
import * as authActions from '../_state/auth.actions';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;
  private unsubscribe$ = new Subject();
  requestLoading = false;
  requestSent = false;
  selectedResetSid: string = null;
  sidIsInvalid = false;
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) { }


  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      newPassword : new FormControl(null, [Validators.required,Validators.minLength(12),
        Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")]),
      verifyPassword : new FormControl(null, [Validators.required]),
    }, [
      this.validateVerifyPassword
  ]);

    this.route.params.subscribe(params => {
      if (params.sid == null || params.sid === undefined){
            this.router.navigateByUrl('/auth/login');
      }
      this.selectedResetSid = params.sid;
      this.store.dispatch(authActions.checkResetSid({sid : this.selectedResetSid}));
    });

    this.onResetPasswordFailed();
    this.onResetPasswordSuccess();
    this.onSidInValid();
    this.onSidValid();
  }

  onSubmit(){
      const formData = this.resetPasswordForm.get('newPassword').value;
      this.store.dispatch(authActions.resetPassword({newPassword : formData, resetSid : this.selectedResetSid}));
  }
  get newPassword() {
    return  this.resetPasswordForm.get('newPassword')
  }
  onResetPasswordSuccess(){
    this.actions$.pipe(
      ofType(authActions.resetPasswordSuccess),
      takeUntil(this.unsubscribe$)
    ).subscribe(response => {
        this.requestSent = true;
        this.requestLoading = false;
        // this.closeDeleteModal()
        this.toastService.success(this.translate.instant('AUTH.RESET_SUCCESS_MSG'), this.translate.instant('AUTH.SUCCESS'));
    });
  }

  onResetPasswordFailed(){
    this.actions$.pipe(
      ofType(authActions.resetPasswordFailed),
      takeUntil(this.unsubscribe$)
    ).subscribe(response => {
        this.requestLoading = false;
        // this.closeDeleteModal()
        this.toastService.error(this.translate.instant('AUTH.FAILED_MSG'), this.translate.instant('AUTH.FAILED'));
    });
  }

  onSidValid(){
    this.actions$.pipe(
      ofType(authActions.resetSidValid),
      takeUntil(this.unsubscribe$)
    ).subscribe(response => {
        this.sidIsInvalid = false;

    });
  }

  onSidInValid(){
    this.actions$.pipe(
      ofType(authActions.resetSidInValid),
      takeUntil(this.unsubscribe$)
    ).subscribe(response => {
        this.sidIsInvalid = true;

    });
  }



  validateVerifyPassword(form: FormGroup): {[s: string]: boolean} {
    const password = form.get('newPassword');
    const verifyPassword = form.get('verifyPassword');
    if (password.value !== verifyPassword.value){
      return {passwordDontMatch : true};
    }else{
      return null;
    }
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
