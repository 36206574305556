import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { CreateOrganisationDto } from '../_dtos/create-org.dto';
import { CreateBulkStaffDto, CreateStaffDto } from '../_dtos/create-staff.dto';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  createOrganisation(createOrgDto: CreateOrganisationDto){
      return this.http.post(`${environment.ADMIN_LOAN_URL}/org`, createOrgDto);
  }

  updateOrganisation(createOrgDto: CreateOrganisationDto, editId){
      return this.http.put(`${environment.ADMIN_LOAN_URL}/org/${editId}`, createOrgDto);
  }

  getAllOrganisation(){
    let params = new HttpParams();
    params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession());
    params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession());
    return this.http.get(`${environment.ADMIN_LOAN_URL}/org/by/${this.sharedService.getSelectedCountryFromSession()}`, { params });
  }

  getAdminOrganisationById(id: string){
      return this.http.get(`${environment.ADMIN_LOAN_URL}/org/${id}/by/${this.sharedService.getSelectedCountryFromSession()}`);
  }

  createOrganisationStaff(createOrgDto: CreateStaffDto, orgId: string){
      return this.http.post(`${environment.ADMIN_LOAN_URL}/staff/org/${orgId}`, createOrgDto);
  }

  createOrganisationBulkStaff(createBulkStaff: CreateBulkStaffDto[]){
      return this.http.post(`${environment.ADMIN_LOAN_URL}/staff/bulk`, createBulkStaff);
  }

  updateOrganisationStaff(params: {createOrgDto: CreateStaffDto, orgId: string, editId: string}){
      return this.http.put(`${environment.ADMIN_LOAN_URL}/staff/org/${params.orgId}/edit/${params.editId}`, params.createOrgDto);
  }

  getAllStaffForOrganisation(orgId: string){
      return this.http.get(`${environment.ADMIN_LOAN_URL}/staff/org/${orgId}`);
  }



  getStaffLoanHistory(walletNo: string, schemeCode: string){
      return this.http.get(`${environment.ADMIN_LOAN_URL}/lrp/history/${walletNo}/${schemeCode}`);
  }

  getStaffLoanTransaction(staffId: string){
      return this.http.get(`${environment.ADMIN_LOAN_URL}/lrp/transactions/${staffId}`);
  }

  getStaffLoanTransactionByOrgId(orgId: string){
      return this.http.get(`${environment.ADMIN_LOAN_URL}/lrp/transactions/${orgId}?searchBy=org`);
  }
}
