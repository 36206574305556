<form [ngClass]="{'no-border': noBorder}">
  <div class="form-group mx-3">
    <label for="language" class="sr-only" translate>TRANSLATOR.SELECT</label>
    <select class="form-control" [(ngModel)]="lang" (change)="switchLanguage($event.target.value)" name="language"
      id="language">
      <option *ngFor="let language of languageService.languages" [value]="language.lang">{{ 'TRANSLATOR.' +
        language.name | translate }}</option>
    </select>
  </div>
</form>
