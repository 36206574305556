import { createReducer, on } from '@ngrx/store';
import { AdminMerchantFee, AdminProductFee, AdminTransferFee } from '../_interfaces/admin-fees.interface';
import { setAdminMerchantFees, setAdminTransferFees, setAdminProductFees } from './fees.actions';

export interface FeesState {
    adminMerchantFees: AdminMerchantFee[] | null;
    adminTransferFees: AdminTransferFee[] | null;
    adminProductFees: AdminProductFee[] | null;
}

export const initialState: FeesState = {
    adminMerchantFees: null,
    adminProductFees: null,
    adminTransferFees: null
};

export const _feesReducer = createReducer(initialState,
    on(setAdminMerchantFees, (state, { adminMerchantFees }) => ({
        ...state, adminMerchantFees
    })),

    on(setAdminTransferFees, (state, { adminTransferFees }) => ({
        ...state, adminTransferFees
    })),

    on(setAdminProductFees, (state, { adminProductFees }) => ({
        ...state, adminProductFees
    }))
);

export const feesReducer = (state: FeesState, action) => _feesReducer(state, action);
