export interface IssuerUser {
  roles: string[];
  user_level: number;
  super_user: boolean;
  is_active: boolean;
  disabled: boolean;
  userType: string;
  _id: string;
  firstName: string;
  lastName: string;
  schemeCode: string;
  phoneNumber: string;
  email: string;
  iso_code_2: string;
  country_code: string;
  issuer: {
    type: string;
    id: string;
  };
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export enum UserPermissions {
  CAN_VIEW_CUSTOMER = 'CAN_VIEW_CUSTOMER',
  CAN_BROADCAST = 'CAN_BROADCAST',
  CAN_SEND_BROADCAST = 'CAN_SEND_BROADCAST',
  CAN_AUTHORIZE_REVERSAL = 'CAN_AUTHORIZE_REVERSAL',
  CAN_VIEW_OLD_USERS = 'CAN_VIEW_OLD_USERS',
  CAN_VIEW_PENDING_ACTIVATIONS = "CAN_VIEW_PENDING_ACTIVATIONS",
  CAN_SET_CUSTOMER_ACCOUNT_TO_TRUSTED = 'CAN_SET_CUSTOMER_ACCOUNT_TO_TRUSTED',
  CAN_SET_CUSTOMER_ACCOUNT_FOR_INTERNATIONAL_PAYMENT = 'CAN_SET_CUSTOMER_ACCOUNT_FOR_INTERNATIONAL_PAYMENT',
  CAN_LOCK_CUSTOMER_ACCOUNT = 'CAN_LOCK_CUSTOMER_ACCOUNT',
  CAN_BLOCK_CUSTOMER_PIN = 'CAN_BLOCK_CUSTOMER_PIN',
  CAN_ACTIVATE_CUSTOMER_CARD = 'CAN_ACTIVATE_CUSTOMER_CARD',
  CAN_SET_CUSTOMER_ACCOUNT_AS_CLOSED = 'CAN_SET_CUSTOMER_ACCOUNT_AS_CLOSED',
  CAN_SET_CUSTOMER_CARD_AS_CLOSED = 'CAN_SET_CUSTOMER_CARD_AS_CLOSED',
  CAN_SEND_CUSTOMER_EMAIL = 'CAN_SEND_CUSTOMER_EMAIL',
  CAN_SET_CUSTOMER_LIMIT_LEVEL = 'CAN_SET_CUSTOMER_LIMIT_LEVEL',
  CAN_VIEW_CUSTOMER_ACCOUNT_BALANCE = 'CAN_VIEW_CUSTOMER_ACCOUNT_BALANCE',
  CAN_SET_CUSTOMER_CARD_STATUS = 'CAN_SET_CUSTOMER_CARD_STATUS',
  CAN_TRANSFER_FUNDS_FOR_CUSTOMER = 'CAN_TRANSFER_FUNDS_FOR_CUSTOMER',
  CAN_REFUND_CUSTOMER_ACCOUNT = 'CAN_REFUND_CUSTOMER_ACCOUNT',
  CAN_SYNCHRONIZE_CUSTOMER_ACCOUNT_BALANCE = 'CAN_SYNCHRONIZE_CUSTOMER_ACCOUNT_BALANCE',
  CAN_VIEW_MERCHANTS = 'CAN_VIEW_MERCHANTS',
  CAN_ACTIVATE_MERCHANT = 'CAN_ACTIVATE_MERCHANT',
  CAN_VIEW_MERCHANT_ACCOUNT_BALANCE = 'CAN_VIEW_MERCHANT_ACCOUNT_BALANCE',
  CAN_CREATE_MERCHANT_ACCOUNT = 'CAN_CREATE_MERCHANT_ACCOUNT',
  CAN_VIEW_MERCHANT_ACCOUNT = 'CAN_VIEW_MERCHANT_ACCOUNT',
  CAN_SEND_MERCHANT_ACCOUNT_EMAIL = 'CAN_SEND_MERCHANT_ACCOUNT_EMAIL',
  CAN_SET_MERCHANT_CARD_STATUS = 'CAN_SET_MERCHANT_CARD_STATUS',
  CAN_TRANSFER_FUNDS_FOR_MERCHANT = 'CAN_TRANSFER_FUNDS_FOR_MERCHANT',
  CAN_REFUND_MERCHANT_ACCOUNT = 'CAN_REFUND_MERCHANT_ACCOUNT',
  CAN_SAVE_MERCHANT_ACCOUNT_TO_SOURCE = 'CAN_SAVE_MERCHANT_ACCOUNT_TO_SOURCE',
  CAN_VIEW_TRANSACTIONS = 'CAN_VIEW_TRANSACTIONS',
  CAN_VIEW_TRANSACTION_LOGS = 'CAN_VIEW_TRANSACTION_LOGS',
  CAN_VIEW_TRANSACTION_MONITORING = 'CAN_VIEW_TRANSACTION_MONITORING',
  CAN_VIEW_PRODUCTS = 'CAN_VIEW_PRODUCTS',
  CAN_VIEW_FWP = 'CAN_VIEW_FWP',
  CAN_VIEW_MANAGE_ACCOUNTS = 'CAN_VIEW_MANAGE_ACCOUNTS',
  CAN_REVERSE_TRANSACTIONS = 'CAN_REVERSE_TRANSACTIONS',
  CAN_VIEW_KYC_RECORDS = 'CAN_VIEW_KYC_RECORDS',
  CAN_VIEW_FAILED_REGISTRATIONS = 'CAN_VIEW_FAILED_REGISTRATIONS',
  CAN_COMPLETE_FAILED_REGISTRATION = 'CAN_COMPLETE_FAILED_REGISTRATION',
  CAN_SEND_KYC_EMAIL = 'CAN_SEND_KYC_EMAIL',
  CAN_DELETE_FAILED_REGISTRATION = 'CAN_DELETE_FAILED_REGISTRATION',
  CAN_VIEW_BLACKLIST_RECORDS = 'CAN_VIEW_BLACKLIST_RECORDS',
  CAN_VIEW_COUNTRY_LIMITS = 'CAN_VIEW_COUNTRY_LIMITS',
  CAN_CREATE_COUNTRY_LIMIT = 'CAN_CREATE_COUNTRY_LIMIT',
  CAN_UPDATE_COUNTRY_LIMIT = 'CAN_UPDATE_COUNTRY_LIMIT',
  CAN_VIEW_PAYMENT_RATES = 'CAN_VIEW_PAYMENT_RATES',
  CAN_CREATE_PAYMENT_RATE = 'CAN_CREATE_PAYMENT_RATE',
  CAN_UPDATE_PAYMENT_RATE = 'CAN_UPDATE_PAYMENT_RATE',
  CAN_VIEW_TRANSFER_RATE = 'CAN_VIEW_PAYMENT_RATE',
  CAN_CREATE_TRANSFER_RATE = 'CAN_CREATE_TRANSFER_RATE',
  CAN_UPDATE_TRANSFER_RATE = 'CAN_UPDATE_TRANSFER_RATE',
  CAN_VIEW_MERCHANT_FEES = 'CAN_VIEW_MERCHANT_FEES',
  CAN_CREATE_MERCHANT_FEE = 'CAN_CREATE_MERCHANT_FEE',
  CAN_UPDATE_MERCHANT_FEE = 'CAN_UPDATE_MERCHANT_FEE',
  CAN_VIEW_TRANSFER_FEES = 'CAN_VIEW_TRANSFER_FEES',
  CAN_CREATE_TRANSFER_FEE = 'CAN_CREATE_TRANSFER_FEE',
  CAN_UPDATE_TRANSFER_FEE = 'CAN_UPDATE_TRANSFER_FEE',
  CAN_DELETE_TRANSFER_FEE = 'CAN_DELETE_TRANSFER_FEE',
  CAN_VIEW_PRODUCT_FEES = 'CAN_VIEW_PRODUCT_FEES',
  CAN_VIEW_ISSUERS = 'CAN_VIEW_ISSUERS',
  CAN_VIEW_SERVICES = 'CAN_VIEW_SERVICES',
  CAN_CREATE_ISSUER = 'CAN_CREATE_ISSUER',
  CAN_UPDATE_ISSUER = 'CAN_UPDATE_ISSUER',
  CAN_DELETE_ISSUER = 'CAN_DELETE_ISSUER',
  CAN_VIEW_ORGANISATIONS = 'CAN_VIEW_ORGANISATIONS',
  CAN_CREATE_ORGANISATION = 'CAN_CREATE_ORGANISATION',
  CAN_UPDATE_ORGANISATION = 'CAN_UPDATE_ORGANISATION',
  CAN_VIEW_ORGANISATION_STAFF = 'CAN_VIEW_ORGANISATION_STAFF',
  CAN_CREATE_STAFF = 'CAN_CREATE_STAFF',
  CAN_UPLOAD_STAFF = 'CAN_UPLOAD_STAFF',
  CAN_UPDATE_STAFF = 'CAN_UPDATE_STAFF',
  CAN_VIEW_STAFF_LOAN_TRANSACTIONS = 'CAN_VIEW_STAFF_LOAN_TRANSACTIONS',
  CAN_VIEW_STAFF_LOAN_HISTORY = 'CAN_VIEW_STAFF_LOAN_HISTORY',
  CAN_MANAGE_ADMIN = 'CAN_MANAGE_ADMIN',
  CAN_MANAGE_BANKS = 'CAN_MANAGE_BANKS',
  CAN_MANAGE_MERCHANT_CATEGORIES = 'CAN_MANAGE_MERCHANT_CATEGORIES',
  CAN_VIEW_COUNTRIES = 'CAN_VIEW_COUNTRIES',
  CAN_ADD_COUNTRY = 'CAN_ADD_COUNTRY',
  CAN_UPDATE_COUNTRY = 'CAN_UPDATE_COUNTRY',
  CAN_DELETE_COUNTRY = 'CAN_DELETE_COUNTRY',
  CAN_ACCESS_PLAYGROUND = 'CAN_ACCESS_PLAYGROUND',
  CAN_VIEW_ACTIVITY_LOGS = 'CAN_VIEW_ACTIVITY_LOGS',
  CAN_VIEW_USERS = 'CAN_VIEW_USERS',
  CAN_CREATE_USER = 'CAN_CREATE_USER',
  CAN_UPDATE_USER = 'CAN_UPDATE_USER',
  CAN_DELETE_USER = 'CAN_DELETE_USER'
}
