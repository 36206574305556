import { createReducer, on } from "@ngrx/store";
import { Channel, CoporateRule, FI, FlaggedTransaction, FlaggedTransactionsResponse, IAuditTrail, LoggedTransaction, Rule, RuleSet, TransactionType } from "./aml.interfaces";
import * as amlActions from '../_state/aml.actions'

export interface AmlState {
    rules: Rule[] | null
    rulesError: boolean
    rulesLoading: boolean

    ruleSets: RuleSet[] | null
    ruleSetsError: boolean
    ruleSetsLoading: boolean

    flaggedTransactions: FlaggedTransaction[] | null
    flaggedTransactionsError: boolean
    flaggedTransactionsLoading: boolean

    loggedTransactions: LoggedTransaction[] | null
    loggedTransactionsError: boolean
    loggedTransactionsLoading: boolean

    channels: Channel[] | null
    channelsError: boolean
    channelsLoading: boolean

    coporateRule: CoporateRule[] | null
    coporateRuleError: boolean
    coporateRuleLoading: boolean

    auditTrail: IAuditTrail[] | null
    auditTrailError: boolean
    auditTrailLoading: boolean

    transactionTypes: TransactionType[] | null
    transactionTypesError: boolean
    transactionTypesLoading: boolean

    FIs: FI[] | null
    FIsError: boolean
    FIsLoading: boolean

    pepSanctionsLoading: boolean
}

export const initialState: AmlState = {
    rules: null,
    rulesError: null,
    rulesLoading: null,

    ruleSets: null,
    ruleSetsError: null,
    ruleSetsLoading: null,

    flaggedTransactions: null,
    flaggedTransactionsError: null,
    flaggedTransactionsLoading: null,

    loggedTransactions: null,
    loggedTransactionsError: null,
    loggedTransactionsLoading: null,

    channels: null,
    channelsError: null,
    channelsLoading: null,

    coporateRule: null,
    coporateRuleError: null,
    coporateRuleLoading: null,

    auditTrail: null,
    auditTrailError: null,
    auditTrailLoading: null,

    transactionTypes:  null,
    transactionTypesError: null,
    transactionTypesLoading: null,

    FIs: null,
    FIsError: null,
    FIsLoading: null,

    pepSanctionsLoading: false
}

export const amlReducer = createReducer(
    initialState,
    
    on(amlActions.fetchRules, (state) => ({
        ...state, rules: null, rulesError: null, rulesLoading: true
    })),

    on(amlActions.fetchRulesSuccess, (state, {rules}) => ({
        ...state, rules: rules, rulesError: false, rulesLoading: false
    })),

    on(amlActions.fetchRulesFailed, (state) => ({
        ...state, rules: null, rulesError: true, rulesLoading: false
    })),


    on(amlActions.searchPepAndSanctions, (state) => ({
        ...state, pepSanctionsLoading: true
    })),

    on(amlActions.searchPepAndSanctionsSuccess, (state) => ({
        ...state, pepSanctionsLoading: false
    })),

    on(amlActions.searchPepAndSanctionsFailed, (state) => ({
        ...state, rpepSanctionsLoading: false
    })),


    on(amlActions.fetchRuleSets, (state) => ({
        ...state, ruleSets: null, ruleSetsError: null, ruleSetsLoading: true
    })),

    on(amlActions.fetchRuleSetsSuccess, (state, {rulesets}) => ({
        ...state, ruleSets: rulesets, ruleSetsError: false, ruleSetsLoading: false
    })),

    on(amlActions.fetchRuleSetsFailed, (state) => ({
        ...state, ruleSets: null, ruleSetsError: true, ruleSetsLoading: false
    })),




    on(amlActions.fetchFlaggedTransactions, (state) => ({
        ...state, flaggedTransactions: null, flaggedTransactionsError: null, flaggedTransactionsLoading: true
    })),

    on(amlActions.fetchFlaggedTransactionsSuccess, (state, {flaggedTransactions}) => ({
        ...state, flaggedTransactions: flaggedTransactions.data, flaggedTransactionsError: false, flaggedTransactionsLoading: false
    })),

    on(amlActions.fetchFlaggedTransactionsFailed, (state) => ({
        ...state, flaggedTransactions: null, flaggedTransactionsError: true, flaggedTransactionsLoading: false
    })),


    on(amlActions.fetchLoggedTransactions, (state) => ({
        ...state, loggedTransactions: null, loggedTransactionsError: null, loggedTransactionsLoading: true
    })),

    on(amlActions.fetchLoggedTransactionsSuccess, (state, {loggedTransactions}) => ({
        ...state, loggedTransactions: loggedTransactions.data, loggedTransactionsError: false, loggedTransactionsLoading: false
    })),

    on(amlActions.fetchLoggedTransactionsFailed, (state) => ({
        ...state, loggedTransactions: null, loggedTransactionsError: true, loggedTransactionsLoading: false
    })),


    on(amlActions.fetchChannels, (state) => ({
        ...state, channels: null, channelsError: null, channelsLoading: true
    })),

    on(amlActions.fetchChannelsSuccess, (state, {channels}) => ({
        ...state, channels: channels, channelsError: false, channelsLoading: false
    })),

    on(amlActions.fetchChannelsFailed, (state) => ({
        ...state, channels: null, channelsError: true, channelsLoading: false
    })),

    on(amlActions.fetchCoporateRule, (state) => ({
        ...state, coporateRule: null, coporateRuleError: null, coporateRuleLoading: true
    })),

    on(amlActions.fetchCoporateRuleSuccess, (state, { coporateRule }) => ({
        ...state, coporateRule: coporateRule.data, coporateRuleError: false, coporateRuleLoading: false
    })),

    on(amlActions.fetchCoporateRuleFailed, (state) => ({
        ...state, coporateRule: null, coporateRuleError: true, coporateRuleLoading: false
    })),

    on(amlActions.fetchAuditTrail, (state) => ({
        ...state, auditTrail: null, auditTrailError: null, auditTrailLoading: true
    })),

    on(amlActions.fetchAuditTrailSuccess, (state, { auditTrail }) => ({
        ...state, auditTrail: auditTrail.data, auditTrailError: false, auditTrailLoading: false
    })),

    on(amlActions.fetchAuditTrailFailed, (state) => ({
        ...state, auditTrail: null, auditTrailError: true, auditTrailLoading: false
    })),


    on(amlActions.fetchTransactionTypes, (state) => ({
        ...state, transactionTypes: null, transactionTypesError: null, transactionTypesLoading: true
    })),

    on(amlActions.fetchTransactionTypesSuccess, (state, {transactionTypes}) => ({
        ...state, transactionTypes: transactionTypes, transactionTypesError: false, transactionTypesLoading: false
    })),

    on(amlActions.fetchTransactionTypesFailed, (state) => ({
        ...state, transactionTypes: null, transactionTypesError: true, transactionTypesLoading: false
    })),


    on(amlActions.fetchFIs, (state) => ({
        ...state, FIs: null, FIsError: null, FIsLoading: true
    })),

    on(amlActions.fetchFIsSuccess, (state, {FIs}) => ({
        ...state, FIs: FIs, FIsError: false, FIsLoading: false
    })),

    on(amlActions.fetchFIsFailed, (state) => ({
        ...state, FIs: null, FIsError: true, FIsLoading: false
    })),
)