import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class IssuerService {

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ){}

    createIssuer(data){
        return this.http.post(`${environment.ADMIN_AUTH_URL}/user/issuer`, data);
    }
    createBank(data){
        return this.http.post(`${environment.API_URL}/banks`, data)
    }
    updateBank(data,id, bankCode, countryCode){
        return this.http.post(`${environment.API_URL}/banks/${id}/${bankCode}/${countryCode}`, data)
    }
    updateIssuerById(data, issuerId){
        return this.http.put(`${environment.ADMIN_AUTH_URL}/user/issuer/${issuerId}`, data);
    }
    deleteIssuerById(issuerId){
        return this.http.delete(`${environment.ADMIN_AUTH_URL}/user/issuer/${issuerId}`);
    }
    getIssuers(){
        let params = new HttpParams();
        params = params.append('issuerCode', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('isoCode2', this.sharedService.getSelectedCountryFromSession());
        params = params.append('page', '1');
        params = params.append('pageSize', '1000');
        return this.http.get(`${environment.ADMIN_AUTH_URL}/user/issuer`, {params});
    }
    getSubIssuers(){
        let params = new HttpParams();
        params = params.append('issuerCode', this.sharedService.getSelectedIssuerCodeFromSession());
        params = params.append('subIssuerCode', this.sharedService.getSelectedSubIssuerCodeFromSession());
        params = params.append('isoCode2', this.sharedService.getSelectedCountryFromSession());
        params = params.append('page', '1');
        params = params.append('pageSize', '1000');
        return this.http.get(`${environment.ADMIN_AUTH_URL}/user/sub-issuer`, {params});
    }
    createSubIssuer(data){
        return this.http.post(`${environment.ADMIN_AUTH_URL}/user/sub-issuer`, data);
    }

    updateSubIssuerById(data, subIssuerId){
        return this.http.put(`${environment.ADMIN_AUTH_URL}/user/sub-issuer/${subIssuerId}`, data);
    }
    deleteSubIssuerById(subIssuerId){
        return this.http.delete(`${environment.ADMIN_AUTH_URL}/user/sub-issuer/${subIssuerId}`);
    }

}
