import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { IssuerUser } from '../_interfaces/issuer-user.interface';
import { UsersService } from '../_services/users.service';
import {
  createIssuerUser,
  createIssuerUserFailed,
  createIssuerUserSuccess,
  deleteIssuerUser,
  deleteIssuerUserFailed,
  deleteIssuerUserSuccess,
  fetchIssuerUsers,
  fetchIssuerUsersFailed,
  fetchIssuerUsersSuccess,
  updateIssuerUser,
  updateIssuerUserFailed,
  updateIssuerUserSuccess
} from './users.actions';

@Injectable({
  providedIn: 'root'
})
export class UsersEffects {

  fetchIssuerUsers$ = createEffect(() => this.action$.pipe(
    ofType(fetchIssuerUsers),
    exhaustMap(() => this.usersService.getIssuerUsers().pipe(
      map((response: IssuerUser[]) => fetchIssuerUsersSuccess({ users: response })),
      catchError(() => of(fetchIssuerUsersFailed()))
    ))
  ));

  createIssuerUser$ = createEffect(() => this.action$.pipe(
    ofType(createIssuerUser),
    exhaustMap(({ createIssuerUserDto }) => this.usersService.createIssuerUser(createIssuerUserDto).pipe(
      map((response: IssuerUser) => createIssuerUserSuccess({ newUser: response })),
      catchError(() => of(createIssuerUserFailed()))
    ))
  ));

  deleteIssuerUser$ = createEffect(() => this.action$.pipe(
    ofType(deleteIssuerUser),
    exhaustMap(({ issuerUserId }) => this.usersService.deleteIssuerUser(issuerUserId).pipe(
      map(() => deleteIssuerUserSuccess()),
      catchError(() => of(deleteIssuerUserFailed()))
    ))
  ));

  updateIssuerUser$ = createEffect(() => this.action$.pipe(
    ofType(updateIssuerUser),
    exhaustMap(({ issuerUserId, updateIssuerUserDto }) => this.usersService.updateIssuerUser(issuerUserId, updateIssuerUserDto).pipe(
      map(() => updateIssuerUserSuccess()),
      catchError(() => of(updateIssuerUserFailed()))
    ))
  ));

  constructor(private action$: Actions, private usersService: UsersService) {}

}
