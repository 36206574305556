import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { AdminKycAll, AdminKycError } from '../_interfaces/admin-kyc.interface';
import { KycService } from '../_services/kyc.service';
import * as kycActions from './kyc.actions';

@Injectable({
    providedIn: 'root'
})
export class KycEffects {

    fetchAdminKycAll$ = createEffect(() =>
    this.action$.pipe(
      ofType(kycActions.fetchAdminKycAll),
      exhaustMap((action) =>
        this.kycService.getAllKyc().pipe(
          map((response: any) =>
            kycActions.setAdminKycAll({ adminKycAll: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(kycActions.fetchAdminKycAllFailed())
          )
        )
      )
    )
  );

  fetchAdminKycByDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(kycActions.fetchAdminKycByDateRange),
      exhaustMap((action) =>
        this.kycService.getKycByDateRange(action.searchData).pipe(
          map((response: any) =>
            kycActions.setAdminKycAll({ adminKycAll: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(kycActions.fetchAdminKycAllFailed())
          )
        )
      )
    )
  );

  fetchAdminKycErrors$ = createEffect(() =>
    this.action$.pipe(
      ofType(kycActions.fetchAdminKycErrors),
      exhaustMap((action) =>
        this.kycService.getAllFailedTransaction().pipe(
          map((response: any) =>
            kycActions.setAdminKycErrors({ adminKycErrors: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(kycActions.fetchAdminKycErrorsFailed())
          )
        )
      )
    )
  );

  fetchAdminKycErrorsByDateRange$ = createEffect(() =>
    this.action$.pipe(
      ofType(kycActions.fetchAdminKycErrorsByDateRange),
      exhaustMap((action) =>
        this.kycService.getKycErrorsByDateRange(action.searchData).pipe(
          map((response: any) =>
            kycActions.setAdminKycErrors({ adminKycErrors: response.data })
          ),
          catchError((error: HttpErrorResponse) =>
            of(kycActions.fetchAdminKycErrorsFailed())
          )
        )
      )
    )
  );

  sendKycMail$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.sendKycEmail),
            exhaustMap((action) =>
                this.kycService.sendKycMail(action.payload).pipe(
                    map((response: any) =>
                        kycActions.sendKycEmailSuccess()
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(kycActions.sendKycEmailFailed())
                    )
                )
            )
        )
    );

    fetchKycRecords$ = createEffect(() => this.action$.pipe(
        ofType(kycActions.fetchKycRecords),
        exhaustMap(({
            startDate,
            endDate,
            page,
            pageSize,
            is_business
        }) => this.kycService.getKycRecords(startDate, endDate, page, pageSize, is_business).pipe(
            exhaustMap((response: {
                meta: {},
                status: boolean,
                data: {
                    page: number,
                    pageSize: number,
                    totalCount: number,
                    data: AdminKycAll[]
                }
            }) => [
                kycActions.fetchKycRecordsSuccess({
                    kycRecords: response.data.data,
                    kycRecordsPage: response.data.page,
                    kycRecordsPageSize: response.data.pageSize,
                    kycRecordsTotalCount: response.data.totalCount
                })
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors);
                return of(kycActions.fetchKycRecordsFailed({ message: error.error?.errors, code: error.status }));
            })
        ))
      ));

      fetchKycErrors$ = createEffect(() => this.action$.pipe(
        ofType(kycActions.fetchKycErrors),
        exhaustMap(({
            startDate,
            endDate,
            page,
            pageSize, 
            userId,
            is_business
        }) => this.kycService.getFailedKycRecords(startDate, endDate, page, pageSize, userId, is_business).pipe(
            exhaustMap((response: {
                meta: {},
                status: boolean,
                data: {
                    page: number,
                    pageSize: number,
                    totalCount: number,
                    data: AdminKycError[]
                }
            }) => [
              kycActions.fetchKycErrorsSuccess({
                    kycErrors: response.data.data,
                    kycErrorsPage: response.data.page,
                    kycErrorsPageSize: response.data.pageSize,
                    kycErrorsTotalCount: response.data.totalCount
                })
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors);
                return of(kycActions.fetchKycRecordsFailed({ message: error.error?.errors, code: error.status }));
            })
        ))
      ));

      fetchKycErrorsNoId$ = createEffect(() => this.action$.pipe(
        ofType(kycActions.fetchKycErrorsNoId),
        exhaustMap(({
            startDate,
            endDate,
            page,
            pageSize, 
            userId,
            is_business
        }) => this.kycService.getFailedKycRecords(startDate, endDate, page, pageSize, userId, is_business).pipe(
            exhaustMap((response: {
                meta: {},
                status: boolean,
                data: {
                    page: number,
                    pageSize: number,
                    totalCount: number,
                    data: AdminKycError[]
                }
            }) => [
              kycActions.fetchKycErrorsNoIdSuccess({
                    kycErrorsNoId: response.data.data,
                    kycErrorsNoIdPage: response.data.page,
                    kycErrorsNoIdPageSize: response.data.pageSize,
                    kycErrorsNoIdTotalCount: response.data.totalCount
                })
            ]),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error?.errors);
                return of(kycActions.fetchKycErrorsNoIdFailed({ message: error.error?.errors, code: error.status }));
            })
        ))
      ));

    createFailedUser$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.createFailedUser),
            exhaustMap((action) =>
                this.kycService.createFailedReg(action.payload).pipe(
                    map((response: any) =>
                        kycActions.createFailedUserSuccess()
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(kycActions.createFailedUserFailed())
                    )
                )
            )
        )
    );

    deleteFailedRegistration$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.deleteFailedUserRegistration),
            exhaustMap((action) =>
                this.kycService.deleteFailedReg(action.id).pipe(
                    exhaustMap((response: any) => [
                        kycActions.fetchFailedUserRegistration(),
                        kycActions.deleteFailedUserRegistrationSuccess()
                        ]
                    ),
                    catchError((error: HttpErrorResponse) => of(kycActions.deleteFailedUserRegistrationFailed()))
                )
            )
        )
    );

    fetchFailedRegList$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.fetchFailedUserRegistration),
            exhaustMap((action) =>
                this.kycService.fetchFailedReg().pipe(
                    map((response: any) =>
                        kycActions.setFailedUserRegistration({failedReg : response.data })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(kycActions.fetchFailedUserRegistrationFailed())
                    )
                )
            )
        )
    );

    fetchBlacklistInfosByCountryCode$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.fetchBlacklistInfosByCountryCode),
            exhaustMap((action) =>
                this.kycService.fetchBlacklistInformationList().pipe(
                    map((response: any) => kycActions.setBlacklistInfoList({blacklistInfos : response.data})),
                    catchError((error: HttpErrorResponse) =>
                        of(kycActions.fetchBlacklistInfosByCountryCodeFailed())
                    )
                )
            )
        )
    );


    createBlacklistInformation$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.adminCreateBlacklistInfo),
            exhaustMap((action) =>
                this.kycService.createBlacklistInformation(action.createBlacklistInfoDto).pipe(
                    exhaustMap((response: any) => [
                            kycActions.adminCreateBlacklistInfoSuccessful(),
                            kycActions.fetchBlacklistInfosByCountryCode()
                        ]
                    ),
                    catchError((error: HttpErrorResponse) => of(kycActions.adminCreateBlacklistInfoFailed()))
                )
            )
        )
    );



    updateBlacklistInformation$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.adminUpdateBlacklistInfo),
            exhaustMap((action) =>
                this.kycService.updateBlacklistInformation(action.id, action.updateBlacklistInfoDto).pipe(
                    exhaustMap((response: any) => [
                            kycActions.adminUpdateBlacklistInfoSuccessful(),
                            kycActions.fetchBlacklistInfosByCountryCode()
                        ]
                    ),
                    catchError((error: HttpErrorResponse) => {
                        return of(kycActions.adminUpdateBlacklistInfoFailed());
                    })
                )
            )
        )
    );


    deleteBlacklistInformation$ = createEffect(() =>
        this.action$.pipe(
            ofType(kycActions.adminDeleteBlacklistInfo),
            exhaustMap((action) =>
                this.kycService.deleteBlacklistInformation(action.id).pipe(
                    exhaustMap((response: any) => [
                        kycActions.adminDeleteBlacklistInfoSuccessful(),
                        kycActions.fetchBlacklistInfosByCountryCode()
                        ]
                    ),
                    catchError((error: HttpErrorResponse) => of(kycActions.adminDeleteBlacklistInfoFailed()))
                )
            )
        )
    );

  constructor(private action$: Actions, private kycService: KycService, private toastr: ToastrService, ) {}

}
